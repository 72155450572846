export const UPDATE_MENU_TAB_ACTIVE_BEGIN   = 'UPDATE_MENU_TAB_ACTIVE_BEGIN';
export const UPDATE_MENU_TAB_ACTIVE_SUCCESS = 'UPDATE_MENU_TAB_ACTIVE_SUCCESS';
export const UPDATE_MENU_TAB_ACTIVE_FAILURE = 'UPDATE_MENU_TAB_ACTIVE_FAILURE';
export const UPDATE_FRIENDS_TAB_ACTIVE_BEGIN   = 'UPDATE_FRIENDS_TAB_ACTIVE_BEGIN';
export const UPDATE_FRIENDS_TAB_ACTIVE_SUCCESS = 'UPDATE_FRIENDS_TAB_ACTIVE_SUCCESS';
export const UPDATE_FRIENDS_TAB_ACTIVE_FAILURE = 'UPDATE_FRIENDS_TAB_ACTIVE_FAILURE';
export const UPDATE_SIDEBAR_OPEN_STATUS = 'UPDATE_SIDEBAR_OPEN_STATUS';

export const updateMenuTabActiveBegin = () => ({
  type: UPDATE_MENU_TAB_ACTIVE_BEGIN
});

export const updateMenuTabActiveSuccess = (activeTab) => ({
  type: UPDATE_MENU_TAB_ACTIVE_SUCCESS,
  data: { activeTab }
});

export const updateMenuTabActiveError = (error) => ({
  type: UPDATE_MENU_TAB_ACTIVE_FAILURE,
  data: { error }
});

export const updateFriendsTabActiveBegin = () => ({
  type: UPDATE_FRIENDS_TAB_ACTIVE_BEGIN
});

export const updateFriendsTabActiveSuccess = (activeTab) => ({
  type: UPDATE_FRIENDS_TAB_ACTIVE_SUCCESS,
  data: { activeTab }
});

export const updateFriendsTabActiveError = (error) => ({
  type: UPDATE_FRIENDS_TAB_ACTIVE_FAILURE,
  data: { error }
});

export const updateSidebarOpenStatus = (sidebarStatus) => ({
  type: UPDATE_SIDEBAR_OPEN_STATUS,
  data: { sidebarStatus }
});
