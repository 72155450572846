export const CREATE_PICK_BEGIN   = 'CREATE_PICK_BEGIN';
export const CREATE_PICK_SUCCESS = 'CREATE_PICK_SUCCESS';
export const CREATE_PICK_FAILURE = 'CREATE_PICK_FAILURE';
export const CREATE_PARLEY_BEGIN   = 'CREATE_PARLEY_BEGIN';
export const CREATE_PARLEY_SUCCESS = 'CREATE_PARLEY_SUCCESS';
export const CREATE_PARLEY_FAILURE = 'CREATE_PARLEY_FAILURE';
export const COMPLETE_PICK_BEGIN   = 'COMPLETE_PICK_BEGIN';
export const COMPLETE_PICK_SUCCESS = 'COMPLETE_PICK_SUCCESS';
export const COMPLETE_PICK_FAILURE = 'COMPLETE_PICK_FAILURE';
export const FETCH_MY_PICKS_PREGAME_BEGIN   = 'FETCH_MY_PICKS_PREGAME_BEGIN';
export const FETCH_MY_PICKS_PREGAME_SUCCESS = 'FETCH_MY_PICKS_PREGAME_SUCCESS';
export const FETCH_MY_PICKS_PREGAME_FAILURE = 'FETCH_MY_PICKS_PREGAME_FAILURE';
export const FETCH_MY_PICKS_INPROGRESS_BEGIN   = 'FETCH_MY_PICKS_INPROGRESS_BEGIN';
export const FETCH_MY_PICKS_INPROGRESS_SUCCESS = 'FETCH_MY_PICKS_INPROGRESS_SUCCESS';
export const FETCH_MY_PICKS_INPROGRESS_FAILURE = 'FETCH_MY_PICKS_INPROGRESS_FAILURE';
export const FETCH_MY_PICKS_FINAL_BEGIN   = 'FETCH_MY_PICKS_FINAL_BEGIN';
export const FETCH_MY_PICKS_FINAL_SUCCESS = 'FETCH_MY_PICKS_FINAL_SUCCESS';
export const FETCH_MY_PICKS_FINAL_FAILURE = 'FETCH_MY_PICKS_FINAL_FAILURE';
export const FETCH_MY_PICKS_INCOMPLETE_BEGIN   = 'FETCH_MY_PICKS_INCOMPLETE_BEGIN';
export const FETCH_MY_PICKS_INCOMPLETE_SUCCESS = 'FETCH_MY_PICKS_INCOMPLETE_SUCCESS';
export const FETCH_MY_PICKS_INCOMPLETE_FAILURE = 'FETCH_MY_PICKS_INCOMPLETE_FAILURE';
export const FETCH_PICK_BEGIN   = 'FETCH_PICK_BEGIN';
export const FETCH_PICK_SUCCESS = 'FETCH_PICK_SUCCESS';
export const FETCH_PICK_FAILURE = 'FETCH_PICK_FAILURE';
export const FETCH_PARLEY_BEGIN   = 'FETCH_PARLEY_BEGIN';
export const FETCH_PARLEY_SUCCESS = 'FETCH_PARLEY_SUCCESS';
export const FETCH_PARLEY_FAILURE = 'FETCH_PARLEY_FAILURE';
export const FETCH_BUNDLE_BEGIN   = 'FETCH_BUNDLE_BEGIN';
export const FETCH_BUNDLE_SUCCESS = 'FETCH_BUNDLE_SUCCESS';
export const FETCH_BUNDLE_FAILURE = 'FETCH_BUNDLE_FAILURE';
export const PICK_PAYMENT_REQUIRED = 'PICK_PAYMENT_REQUIRED';
export const UPDATE_MY_BOUGHT_ITEM_SUCCESS = 'UPDATE_MY_BOUGHT_ITEM_SUCCESS';
export const UPDATE_MY_BOUGHT_ITEM_BEGIN = 'UPDATE_MY_BOUGHT_ITEM_BEGIN';
export const UPDATE_MY_BOUGHT_ITEM_FAILURE = 'UPDATE_MY_BOUGHT_ITEM_FAILURE';
export const PARLEY_PAYMENT_REQUIRED = 'PARLEY_PAYMENT_REQUIRED';
export const BUNDLE_PAYMENT_REQUIRED = 'BUNDLE_PAYMENT_REQUIRED';
export const REMOVE_STARTED_GAMES = 'REMOVE_STARTED_GAMES';
export const FETCH_USER_PICKS_FINAL_BEGIN   = 'FETCH_USER_PICKS_FINAL_BEGIN';
export const FETCH_USER_PICKS_FINAL_SUCCESS = 'FETCH_USER_PICKS_FINAL_SUCCESS';
export const FETCH_USER_PICKS_FINAL_FAILURE = 'FETCH_USER_PICKS_FINAL_FAILURE';
export const SEND_ME_THIS_PICK_BEGIN   = 'SEND_ME_THIS_PICK_BEGIN';
export const SEND_ME_THIS_PICK_SUCCESS = 'SEND_ME_THIS_PICK_SUCCESS';
export const SEND_ME_THIS_PICK_FAILURE = 'SEND_ME_THIS_PICK_FAILURE';
export const FETCH_MY_PICKS_CREATED_IDS_BEGIN   = 'FETCH_MY_PICKS_CREATED_IDS_BEGIN';
export const FETCH_MY_PICKS_CREATED_IDS_SUCCESS = 'FETCH_MY_PICKS_CREATED_IDS_SUCCESS';
export const FETCH_MY_PICKS_CREATED_IDS_FAILURE = 'FETCH_MY_PICKS_CREATED_IDS_FAILURE';

export const createPickBegin = () => ({
  type: CREATE_PICK_BEGIN
});

export const createPickSuccess = (pick) => ({
  type: CREATE_PICK_SUCCESS,
  data: { pick }
});

export const createPickError = (error) => ({
  type: CREATE_PICK_FAILURE,
  data: { error }
});

export const completePickBegin = () => ({
  type: COMPLETE_PICK_BEGIN
});

export const completePickSuccess = (pick) => ({
  type: COMPLETE_PICK_SUCCESS,
  data: { pick }
});

export const completePickError = (error) => ({
  type: COMPLETE_PICK_FAILURE,
  data: { error }
});

export const createParleyBegin = () => ({
  type: CREATE_PARLEY_BEGIN
});

export const createParleySuccess = (pick) => ({
  type: CREATE_PARLEY_SUCCESS,
  data: { pick }
});

export const createParleyError = (error) => ({
  type: CREATE_PARLEY_FAILURE,
  data: { error }
});

export const fetchMyPicksPregameBegin = () => ({
  type: FETCH_MY_PICKS_PREGAME_BEGIN
});

export const fetchMyPicksPregameSuccess = (data) => ({
  type: FETCH_MY_PICKS_PREGAME_SUCCESS,
  data: { data }
});


export const fetchMyPicksPregameError = (error) => ({
  type: FETCH_MY_PICKS_PREGAME_FAILURE,
  data: { error }
});

export const fetchMyPicksInprogressBegin = () => ({
  type: FETCH_MY_PICKS_INPROGRESS_BEGIN
});

export const fetchMyPicksInprogressSuccess = (data) => ({
  type: FETCH_MY_PICKS_INPROGRESS_SUCCESS,
  data: { data }
});

export const fetchMyPicksInprogressError = (error) => ({
  type: FETCH_MY_PICKS_INPROGRESS_FAILURE,
  data: { error }
});

export const fetchMyPicksFinalBegin = () => ({
  type: FETCH_MY_PICKS_FINAL_BEGIN
});

export const fetchMyPicksFinalSuccess = (data) => ({
  type: FETCH_MY_PICKS_FINAL_SUCCESS,
  data: { data }
});

export const fetchMyPicksFinalError = (error) => ({
  type: FETCH_MY_PICKS_FINAL_FAILURE,
  data: { error }
});

export const fetchMyPicksIncompleteBegin = () => ({
  type: FETCH_MY_PICKS_INCOMPLETE_BEGIN
});

export const fetchMyPicksIncompleteSuccess = (picks) => ({
  type: FETCH_MY_PICKS_INCOMPLETE_SUCCESS,
  data: { picks }
});

export const fetchMyPicksIncompleteError = (error) => ({
  type: FETCH_MY_PICKS_INCOMPLETE_FAILURE,
  data: { error }
});

export const fetchPickBegin = () => ({
  type: FETCH_PICK_BEGIN
});

export const fetchPickSuccess = (pick) => ({
  type: FETCH_PICK_SUCCESS,
  data: { pick }
});

export const fetchPickError = (error) => ({
  type: FETCH_PICK_FAILURE,
  data: { error }
});

export const pickPaymentRequired = (pick) => ({
  type: PICK_PAYMENT_REQUIRED,
  data: { pick }
});

export const fetchParleyBegin = () => ({
  type: FETCH_PARLEY_BEGIN
});

export const fetchParleySuccess = (parley) => ({
  type: FETCH_PARLEY_SUCCESS,
  data: { parley }
});

export const fetchParleyError = (error) => ({
  type: FETCH_PARLEY_FAILURE,
  data: { error }
});

export const fetchBundleBegin = () => ({
  type: FETCH_BUNDLE_BEGIN
});

export const fetchBundleSuccess = (bundle) => ({
  type: FETCH_BUNDLE_SUCCESS,
  data: { bundle }
});

export const fetchBundleError = (error) => ({
  type: FETCH_BUNDLE_FAILURE,
  data: { error }
});

export const updateMyBoughtItemBegin = () => ({
  type: UPDATE_MY_BOUGHT_ITEM_BEGIN
});

export const updateMyBoughtItem = (item, type) => ({
  type: UPDATE_MY_BOUGHT_ITEM_SUCCESS,
  data: { item, type }
});

export const updateMyBoughtItemFailure = (error) => ({
  type: UPDATE_MY_BOUGHT_ITEM_FAILURE,
  data: { error }
});

export const parleyPaymentRequired = (parley) => ({
  type: PARLEY_PAYMENT_REQUIRED,
  data: { parley }
});

export const bundlePaymentRequired = (bundle) => ({
  type: BUNDLE_PAYMENT_REQUIRED,
  data: { bundle }
});

export const removeStartedGames = () => ({
  type: REMOVE_STARTED_GAMES
});

export const fetchUserPicksFinalBegin = (id) => ({
  type: FETCH_USER_PICKS_FINAL_BEGIN,
  data: { id }
});

export const fetchUserPicksFinalSuccess = (data, id) => ({
  type: FETCH_USER_PICKS_FINAL_SUCCESS,
  data: { data, id }
});

export const fetchUserPicksFinalError = (error) => ({
  type: FETCH_USER_PICKS_FINAL_FAILURE,
  data: { error }
});

export const sendMePickBegin = () => ({
  type: SEND_ME_THIS_PICK_BEGIN
});

export const sendMePickSuccess = () => ({
  type: SEND_ME_THIS_PICK_SUCCESS
});

export const sendMePickError = (error) => ({
  type: SEND_ME_THIS_PICK_FAILURE,
  data: { error }
});

export const fetchCreatedPicksIdsBegin = () => ({
  type: FETCH_MY_PICKS_CREATED_IDS_BEGIN
});

export const fetchCreatedPicksIdsSuccess = (ids) => ({
  type: FETCH_MY_PICKS_CREATED_IDS_SUCCESS,
  data: { ids }
});

export const fetchCreatedPicksIdsError = (error) => ({
  type: FETCH_MY_PICKS_CREATED_IDS_FAILURE,
  data: { error }
});


