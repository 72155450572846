import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Button, Modal, Form, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import MultiSelect from "@kenshooui/react-multi-select";
import { createSubscription, fetchSubscriptions } from "../../../api/subscription";
import { alertErrorText, alertSuccessWithConfirmaiton } from '../../../actions/alerts';
import './style.scss';
import moment from 'moment';

class CreateSubscriptionModal extends Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);

    this.state = {
      error: false,
      formValid: false,
    };
  }

  handleCreateSubscription = async (event) => {

    event.preventDefault();
    const { dispatch, profile } = this.props;
    const { name, price, sportId, formValid, startDate, endDate, picksCycle, minTotalPicks, minPicksPerCycle } = this.state;


    if (this.validateData() && formValid) {
      const result = await alertSuccessWithConfirmaiton(`Do you agree to these terms?`,
        `I agree by making this subscription I must follow the terms I set when making the subscription and if these terms are not met for example the amount of picks are not made, then I understand I will not get paid for this subscription and the money will be refunded to the User. I also understand I cannot use malicious or deceitful tactics in unfounded promises that all of my predictions will be correct. By checking ths box and creating this subscription I am held to these terms and the Terms Of Service for this platform.`);

      if (result.value) {
        createSubscription(dispatch, { name, price, sportId, startDate, endDate, picksCycle, minTotalPicks, minPicksPerCycle })
        this.handleClose();
        fetchSubscriptions(dispatch, { limit: 100, offset: 0, sportId: 'all' })
      }

    } else {
      alertErrorText("Please correct all the errors")
    }
  }

  handleClose() {
    this.setState({ show: false, roomName: '' });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleChange = (event) => {
    this.setState({ error: true });
    this.setState({ formValid: false })
    if (event.target.name == 'startDate' || event.target.name == 'endDate') {
      this.setState({ [event.target.name]: moment(event.target.value).format() }, function () {
        this.validateData();
      })

    } else {
      this.setState({ [event.target.name]: event.target.value }, function () {
        this.validateData();
      })
    }

  }

  validateData = () => {
    const { name, price, sportId, startDate, endDate, minTotalPicks, minPicksPerCycle, picksCycle } = this.state;
    if (!name) {
      this.setState({ error: 'Please add subscription name' });
      return false
    }
    if (name.length < 2) {
      this.setState({ error: 'Please give valid name to subscription' });
      return false
    }
    if (!sportId || sportId === null) {
      this.setState({ error: 'Please choose relevant sport' });
      return false
    }
    if (!startDate || startDate === null) {
      this.setState({ error: 'Please enter valid start date' });
      return false
    }
    if (moment(startDate).isBefore(new Date(), "day")) {
      this.setState({ error: 'Please enter valid start date it must be in the future' });
      return false
    }
    if (!endDate || endDate === null) {
      this.setState({ error: 'Please enter valid end date' });
      return false
    }
    if (moment(endDate).isBefore(moment(startDate), "day") || moment(endDate).isSame(moment(startDate), "day")) {
      this.setState({ error: 'Please enter valid end date it must be after the start date' });
      return false
    }
    if (!minTotalPicks || minTotalPicks === null) {
      this.setState({ error: 'Please enter total number of picks included in subscription' });
      return false
    }
    if (!minPicksPerCycle || minPicksPerCycle === null) {
      this.setState({ error: 'Please enter minimum number of picks per cycle' });
      return false
    }
    if (Number(minTotalPicks) < Number(minPicksPerCycle)) {
      this.setState({ error: 'Picks per cycle cannot be greater then total number of picks' });
      return false
    }
    if (!picksCycle || picksCycle === null) {
      this.setState({ error: 'Please select picks cycle' });
      return false
    }
    if (!price || price === null) {
      this.setState({ error: 'Please enter valid price' });
      return false
    }
    this.setState({ error: false });
    this.setState({ formValid: true })
    return true

  }

  handleSportChange = (event) => {
    this.setState({ sportId: event.target.value });
  }
  handleRoomTypeChange = (event) => {
    this.setState({ type: event.target.value });
  }

  handleSelectChange(selectedUsers) {
    this.setState({ selectedUsers });
  }
  mergeUsers = (followers, following) => {
    const uniqueUsers = [];

    followers.forEach((follower) => {
      const isDuplicate = following.some((follow) => follower.id === follow.id);

      if (!isDuplicate) {
        uniqueUsers.push(follower);
      }
    });

    return following.concat(uniqueUsers);
  };

  render() {
    const { error, formValid } = this.state;
    const { Sports } = this.props

    return (
      <div className="create-new-subscription">
        <Button bsStyle="primary" bsSize="small" onClick={this.handleShow}>
          Create Subscription
        </Button>

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create new subscription</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <FormGroup>
                <ControlLabel>Subscription Name</ControlLabel>
                <FormControl type="text" name="name" onChange={this.handleChange} />
              </FormGroup>

              <FormGroup>
                <ControlLabel>Select Sport</ControlLabel>
                <FormControl componentClass="select" placeholder="Select Relevant Sport" name="sportId" onChange={this.handleChange}>
                  <option disabled selected hidden>Sport</option>
                  {Sports.map((sport) => <option key={sport.id} value={sport.id}>{sport.Name.toUpperCase()}</option>)}
                </FormControl>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Start Date</ControlLabel>
                <FormControl type="date" name="startDate" onChange={this.handleChange}></FormControl>
              </FormGroup>
              <FormGroup>
                <ControlLabel>End Date</ControlLabel>
                <FormControl type="date" name="endDate" onChange={this.handleChange}></FormControl>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Total number of picks included in subscription</ControlLabel>
                <FormControl type="number" min="1" name="minTotalPicks" onChange={this.handleChange} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Pick Cycle</ControlLabel>
                <FormControl componentClass="select" placeholder="Select Pick Cycle" name="picksCycle" onChange={this.handleChange}>
                  <option disabled selected hidden>Pick Cycle</option>
                  <option key={1} value="weekly">Weekly (I Commit to provide minimum number of picks in each cycle)</option>
                  <option key={2} value="monthly">Monthly (I Commit to provide minimum number of picks in each cycle)</option>
                </FormControl>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Minimum number of picks per cycle</ControlLabel>
                <FormControl type="number" min="1" name="minPicksPerCycle" onChange={this.handleChange} />
              </FormGroup>

              <FormGroup>
                <ControlLabel>Subscription Price</ControlLabel>
                <FormControl type="number" min="1" name="price" onChange={this.handleChange} />
              </FormGroup>
            </Form>
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCreateSubscription} disabled={!formValid}>Submit</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CreateSubscriptionModal;

CreateSubscriptionModal.propTypes = {
  dispatch: PropTypes.func,
  profile: PropTypes.object,
  Sports: PropTypes.array,
};

