import React, {Component} from 'react';
import PropTypes from 'prop-types';
// import './styles.scss';


class DropdownFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false
    }
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutsideDropdown);
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutsideDropdown);
  }

  handleClickDropdown = () => {
    const { showDropdown } = this.state;
    this.setState({showDropdown: !showDropdown})
  }

  handleClickOutsideDropdown = (e) => {
    if (this.dropdownRef && !this.dropdownRef.contains(e.target)) {
      this.setState({showDropdown: false})
    }
  }

  setDropdownRef = (node) => {
    this.dropdownRef = node;
  }

  handleClickOption = (e) => {
    const { handleSelect } = this.props;
    handleSelect(e);
    this.setState({showDropdown: false});
  }

  render() {
    const { showDropdown } = this.state;
    const { selectedItem, itemsList } = this.props;

    const options = itemsList.map((item) => {
      return <li key={item} onClick={this.handleClickOption}><a href>{item}</a></li>
    })

    return (
      <div ref={this.setDropdownRef} className={`btn-group ${showDropdown ? 'open' : ''}`}>
        <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false" onClick={this.handleClickDropdown}>
          {selectedItem}
          <span className="caret" />
        </button>
        <ul className="dropdown-menu" role="menu">
          {options}
        </ul>
      </div>
    );
  }
}

export default DropdownFilter;

DropdownFilter.propTypes = {
  handleSelect: PropTypes.func,
  itemsList: PropTypes.array,
  selectedItem: PropTypes.string
};
