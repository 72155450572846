import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment'
import i from 'immutable';
import Scrollbar from 'react-smooth-scrollbar';
import CONFIG from '../../config/config';
import { connect } from "../../store/context";
import catchRunTimeError from '../../util/ds-error';
import {
  getNotifications, readNotifications, deleteNotification, deleteAllNotifications
} from "../../api/notifications";


class NotificationsDropdown extends Component {
  constructor(props) {
    super(props);

  }

  componentDidMount = () => {
    const { profile: { id } } = this.props;

    if (id) {
      this.getData(id);
    }
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.profile;

    if (id !== prevProps.profile.id) {
      this.getData(id);
    }
  }

  componentWillUnmount = () => {
  }

  getData = (id) => {
    const { dispatch } = this.props;
    getNotifications(dispatch);
  }

  handleToggleDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.toggleDropDown();

    const { dispatch, notifications, isOpen } = this.props;

    if (isOpen) {
      readNotifications(dispatch, notifications);
    }
  }

  handleDeleteNotification = (notification) => {
    const { dispatch } = this.props;
    const { id, RecipientId } = notification;
    const notificationPath = `notification/${RecipientId}/${id}`;
    deleteNotification(dispatch, id);
  }

  handleDeleteAllNotifications = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { dispatch } = this.props;
    deleteAllNotifications(dispatch);
  }

  selectIcon = (status) => {
    if (['pickGradedWin', 'parlayGradedWin'].includes(status)) {
      return 'fa fa-trophy fg-success';
    } else if (['pickGradedLost', 'parlayGradedLost'].includes(status)) {
      return 'fa fa-ban fg-danger';
    } else if (['newReferral', 'follow'].includes(status)) {
      return 'fa fa-user-plus fg-success';
    } else if (['unfollow'].includes(status)) {
      return 'fa fa-user-times fg-danger';
    } else if (['someoneBoughtYourPick', 'someoneBoughtYourParley', 'YouGotTip', 'someoneBoughtYourBundle'].includes(status)) {
      return 'fa fa-usd fg-success';
    } else if (['becomeStatusTopRated'].includes(status)) {
      return 'fa fa-star fg-success';
    } else if (['becomeStatusRisingTalent'].includes(status)) {
      return 'fa fa-star-half-o fg-success';
    } else if (['becomeStatusNull'].includes(status)) {
      return 'fa fa-info fg-warning';
    }

    return 'fa fa-warning fg-warning'
  }

  status = (status) => {
    if (status === 'Win') {
      return 'Winning'
    } else if (status === 'Lost') {
      return 'Losing'
    }

    return status
  }

  backgroundStyle = (isRead) => {
    if (!isRead) {
      return { 'backgroundColor': 'aliceblue' }
    }

    return {};
  }

  render() {
    const { notifications, notificationsRef } = this.props;

    const notification = notifications.map((notification) =>
      (
        <div key={notification.id} className="media" style={this.backgroundStyle(notification.read)}>
          <button type="button" onClick={() => this.handleDeleteNotification(notification)} className="close" style={{ 'float': 'right' }}>x</button>
          <div className="media-object pull-left"><i className={this.selectIcon(notification.action)} /></div>
          <div className="media-body">
            <span className="media-text">{selectMessage(notification.action)}</span>

            <span className="media-meta">{moment(notification.createdAt).fromNow()}</span>
          </div>
        </div>
      )
    );

    const { length } = notifications.filter((notification) => !notification.read);

    return (
      <li
        ref={notificationsRef} id="tour-5" className={classNames(
          {"dropdown navbar-notification vertical": true,
            "open": this.props.isOpen}
        )}
      >
        <a href="/" onClick={this.handleToggleDropdown}  className="dropdown-toggle" data-toggle="dropdown">
          <i className="fa fa-bell-o" /> {length > 0 && <span className="count label label-danger rounded">{length}</span>}
          <span>Notifications</span>
        </a>

        <div className="dropdown-menu animated">
          <div className="dropdown-header">
            <span className="title">Notifications <strong>({notifications.length})</strong></span>
            {notifications.length > 0 &&
            <button
              type="button"
              onClick={this.handleDeleteAllNotifications}
              className="btn btn-link text-right"
            >clear all
            </button>}
          </div>

          <Scrollbar
            className="scrollbar"
            thumbMinSize={50}
          >
            <div className="dropdown-body">
              <div className="media-list small">
                {notification}
              </div>
            </div>
          </Scrollbar>
        </div>


      </li>
    );
  }
}

function select(state) {
  const profile = state.getIn(['users', 'profile'], i.Map()).toJS();
  const users = state.getIn(['users', 'items']);
  const notifications = state.getIn(['notifications', 'items'], i.List()).toJS();
  return { users, profile, notifications }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(NotificationsDropdown);

NotificationsDropdown.propTypes = {
  dispatch: PropTypes.func,
  isOpen: PropTypes.bool,
  notifications: PropTypes.array,
  notificationsRef: PropTypes.func,
  profile: PropTypes.object,
  toggleDropDown: PropTypes.func
};

const gradedPickMessage = (action) => {
  switch (action) {
    case 'pickGradedCanceled':
      return 'Game that you picked was canceled';
    case 'pickGradedLost':
      return 'Unfortunately result that you predicted was wrong. You have lost on a pick';
    case 'pickGradedWin':
      return 'Hurray! You have won on a pick'
    case 'pickGradedPush':
      return 'You have got a push'
    default:
      return action;
  }
}

const pickWasBought = (action) => {
  switch (action) {
    case 'someoneBoughtYourPick':
      return 'Congrats! Your pick was bought!';
    case 'YouBoughtPick':
      return 'Thank you for buying a new pick!';
    case 'YouBoughtParley':
      return 'Thank you for buying a new parlay!';
    case 'someoneBoughtYourParley':
      return 'Congrats! Your parlay was bought!';
    case 'someoneBoughtYourBundle':
      return 'Congrats! Your bundle was bought!';
    case 'YouBoughtBundle':
      return 'Thank you for buying a new bundle!';
    default:
      return action;
  }
}

const newFollower = (action) => {
  switch (action) {
    case 'follow':
      return 'Congrats! You have a new follower';
    case 'unfollow':
      return 'One of your followers just unfollowed your account';
    default:
      return action;
  }
}

const gradedParlayMessage = (action) => {
  switch (action) {
    case 'parlayGradedStarted':
      return 'First game of parlay that you picked has started';
    case 'parlayGradedLost':
      return 'Unfortunately result that you predicted was wrong. You have lost on a parlay';
    case 'parlayGradedWin':
      return 'Hurray! You have won on a parlay'
    default:
      return action;
  }
}

const becomeStatusMessage = (action) => {
  switch (action) {
    case 'becomeStatusTopRated':
      return 'Congrats! You are the Top Rated Consultant!';
    case 'becomeStatusRisingTalent':
      return 'Congrats! You are the Rising Talent Consultant!';
    case 'becomeStatusNull':
      return 'Sorry! You have lost your Top Consultant status'
    default:
      return action;
  }
}

const referralMessage = (action) => {
  switch (action) {
    case 'newReferral':
      return 'Congrats! You have a new referral';
    default:
      return action;
  }
}

const returnMoneyMessage = (action) => {
  switch (action) {
    case 'returnMoneyPickCanceled':
      return 'You have got a refund for canceled game';
    default:
      return action;
  }
}

const tipMessage = (action) => {
  switch (action) {
    case 'YouGotTip':
      return 'You have got tips';
    default:
      return action;
  }
}

const selectMessage = (action) => {
  if (action.includes('pickGraded')) {
    return gradedPickMessage(action);
  } else if (action.includes('Bought')) {
    return pickWasBought(action);
  } else if (action.includes('parlayGraded')) {
    return gradedParlayMessage(action);
  } else if (action.includes('Referral')) {
    return referralMessage(action);
  } else if (action.includes('becomeStatus')) {
    return becomeStatusMessage(action);
  } else if (action.includes('returnMoney')) {
    return returnMoneyMessage(action);
  } else if (action.includes('Tip')) {
    return tipMessage(action)
  }

  return newFollower(action);
}
