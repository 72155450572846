import React,  { Component } from 'react';
import PropTypes from 'prop-types';
import i from 'immutable';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, RedditShareButton, RedditIcon } from 'react-share';
import { Helmet } from 'react-helmet';
import { connect } from "../../../store/context";
import CONFIG from '../../../config/config';
import { EmptyCell, LinePickCell, PickCell } from "../../picks/PickCells";
import { promoteContent } from "../../../api/payments";
import { getProfile } from "../../../api/users";
import { alertSuccessWithConfirmaiton } from '../../../actions/alerts';
import { stringlifyBetTypes } from '../../dashboard/PickFeed';
import "./style.scss";


const pickRow = (props, pickType, hideInfo, userId, handlePromoteContent) => {
  const { AwayTeam, AwayROT, HomeTeam, HomeROT, MatchTime, Sport } = props.Match;
  const { MoneyLine, PointSpread, Total, Status, ROISpread, ROIMoneyLine, ROITotal, DeviationSpread = 0, DeviationTotal = 0, createdAt, Owner, OwnerId, id, Sponsored, HomePitcher, AwayPitcher, DrawLine, ROIDrawLine, SpreadCoef, MoneyLineCoef, TotalCoef, DrawCoef, TotalScores, Match } = props;
  const { AwayScore = 0, HomeScore = 0 } = props.Result;
  const { TotalNumber } = props.Odd;

  const ROISummary = Number(ROISpread ? ROISpread.toFixed(2) : 0) + Number(ROIMoneyLine ? ROIMoneyLine.toFixed(2) : 0) + Number(ROITotal ? ROITotal.toFixed(2) : 0) + Number(ROIDrawLine ? ROIDrawLine.toFixed(2) : 0);

  let labelClass = 'label label-danger text-capitalize pull-left';
  const winClass = 'label label-success text-capitalize pull-left';
  const lostClass = 'label label-danger text-capitalize pull-left';
  const pushClass = 'label label-warning text-capitalize pull-left';
  const title = `New pick for ${AwayTeam}${AwayROT ? `(${AwayROT})` : ''} vs ${HomeTeam}${HomeROT ? `(${HomeROT})` : ''}`;
  switch (Status) {
    case 'Win':
      labelClass = 'label label-success text-capitalize pull-left';
      break;
    case null:
      labelClass = 'label label-warning text-capitalize pull-left';
      break;
    default:
      break;
  }

  const spreadCellName = () => {
    if (Sport === 5) { //hockey
      return 'Puck Line';
    }

    if (Sport === 0) { //baseball
      return 'Run Line';
    }

    return 'Spread'
  }

  const totalCellName = () => {
    if ([11, 23].includes(Sport)) { //['mma', 'wbc']
      return 'Rounds';
    }

    return 'Total'
  }

  const getGameState = (status, matchTime) => {
    if (status) {
      return ''
    }
    if (moment(MatchTime) < moment()) {
      return 'In Progress'
    }
    return 'NOT FINISHED'

  }

  const cellWidth = DrawLine ? '3' : '4';

  return (
    <div className="pick">
      <table className="table user-pick shadow">
        <thead>
          <tr className="main-head">
            {pickType !== 'final' &&
              <th colSpan="1" className="status text-left">
                <span className={labelClass}>{getGameState(Status, MatchTime)}</span>
              </th>
            }
            <th colSpan="8" className="teams text-left">
              <div>
                <span>
                  <span className="team-name">{AwayTeam}</span>
                  {AwayROT && <span className="team-number">({AwayROT})</span>}
                </span>
                <span className="scores">
                  {pickType === 'pregame' && ' VS '}
                  {(pickType === 'inprogress' || pickType === 'final')  && `( ${AwayScore ? AwayScore : 0} - ${HomeScore ? HomeScore : 0} )`}
                </span>
                <span>
                  <span className="team-name">{HomeTeam}</span>
                  {HomeROT && <span className="team-number">({HomeROT})</span>}
                </span>
              </div>
            </th>
            <th colSpan={pickType === 'final' ? '4' : '3'} className="date text-right">
              {pickType === 'final' && Status === "Canceled" ?
                <span className={labelClass}>{Status.toUpperCase()}</span> : ''}
                {/* {pickType === 'final' && Status === "Push" ?
                  <span className={labelClass}>{Status.toUpperCase()}</span> : ''} */}
              <span>Game Date: {moment(MatchTime).format("MM/DD/YYYY h:mm A")}</span>
            </th>
          </tr>
          <tr className="head">
            <th colSpan={cellWidth}>
              {
              Boolean(ROISpread) && pickType === 'final' ? <span className={ROISpread > 0 ? winClass : lostClass}>{ROISpread > 0 ? 'WON' : 'LOST'}</span> : ''
                
              }
              {
              (ROISpread===0  && pickType === 'final')  ? <span className={pushClass}>{'PUSH'}</span>: ''
              }
              <span>{spreadCellName()}</span>
            </th>
            <th colSpan={cellWidth}>
              {ROIMoneyLine && pickType === 'final' ?
                <span className={ROIMoneyLine > 0 ? winClass : lostClass}>{ROIMoneyLine > 0 ? 'WON' : 'LOST'}</span> : ''
              }
              <span>Money Line</span>
            </th>
            <th colSpan={cellWidth}>
            {
              Boolean(ROITotal) ? <span className={ROITotal > 0 ? winClass : lostClass}>{ROITotal > 0 ? 'WON' : 'LOST'}</span> : ''
                
              }
              {
              (ROITotal === 0 && Boolean(Total)) ? <span className={pushClass}>{'PUSH'}</span>: ''
              }
              <span>{totalCellName()} O/U</span>
            </th>
            {DrawLine &&
              <th colSpan={cellWidth}>
                {ROIDrawLine && pickType === 'final' ?
                  <span className={ROIDrawLine > 0 ? winClass : lostClass}>{ROIDrawLine > 0 ? 'WON' : 'LOST'}</span> : ''
                }
                <span>Draw</span>
              </th>}
          </tr>
        </thead>
        {(pickType === 'inprogress' || pickType === 'pregame') &&
          <tbody>
            <tr>
              {PointSpread ? <PickCell itemName={Match[`${PointSpread}Team`]} itemNumber={props[`SpreadScores${PointSpread}`] ? props[`SpreadScores${PointSpread}`] : props.Odd[`PointSpread${PointSpread}`]} itemLine={SpreadCoef || props.Odd[`PointSpread${PointSpread}Line`]} colSpan={cellWidth} /> : <EmptyCell  colSpan={cellWidth} />}
              {MoneyLine ? <LinePickCell itemName={Match[`${MoneyLine}Team`]} itemLine={MoneyLineCoef || props.Odd[`MoneyLine${MoneyLine}`]} colSpan={cellWidth} /> : <EmptyCell colSpan={cellWidth} />}
              {Total ? <LinePickCell itemName={`${Total} ${TotalScores || TotalNumber}`} itemLine={TotalCoef || props.Odd[`${Total}Line`]} colSpan={cellWidth} /> : <EmptyCell colSpan={cellWidth} />}
              {DrawLine && <LinePickCell itemName={`Draw`} itemLine={DrawCoef || props.Odd.DrawLine} colSpan={cellWidth} />}
            </tr>
            {(HomePitcher || AwayPitcher) &&
            <tr>
              <td colSpan="4">
                Pitchers listed:
              </td>
              <td colSpan="4">
                {HomePitcher}(Home)
              </td>
              <td colSpan="4">
                {AwayPitcher}(Away)
              </td>
            </tr>}
          </tbody>
        }
        {pickType === 'final' &&
          <tbody>
            <tr>
              {PointSpread ? <PickCell itemName={Match[`${PointSpread}Team`]} itemNumber={props[`SpreadScores${PointSpread}`] ? props[`SpreadScores${PointSpread}`] : props.Odd[`PointSpread${PointSpread}`]} itemLine={SpreadCoef || props.Odd[`PointSpread${PointSpread}Line`]} colSpan={cellWidth} /> : <EmptyCell colSpan={cellWidth} />}
              {MoneyLine ? <LinePickCell itemName={Match[`${MoneyLine}Team`]} itemLine={MoneyLineCoef || props.Odd[`MoneyLine${MoneyLine}`]} colSpan={cellWidth} /> : <EmptyCell colSpan={cellWidth} />}
              {Total ? <LinePickCell itemName={`${Total} ${TotalScores || TotalNumber}`} itemLine={TotalCoef || props.Odd[`${Total}Line`]} colSpan={cellWidth} /> : <EmptyCell colSpan={cellWidth} />}
              {DrawLine && <LinePickCell itemName={`Draw`} itemLine={DrawCoef || props.Odd.DrawLine} colSpan={cellWidth} />}
            </tr>

            <tr>
              <td colSpan={cellWidth}>{ROISpread ? `ROI: ${ROISpread.toFixed(2)}, Deviation: ${DeviationSpread ? DeviationSpread.toFixed(2) : 0}` : "-"}</td>
              <td colSpan={cellWidth}>{ROIMoneyLine ? `ROI: ${ROIMoneyLine.toFixed(2)}` : "-"}</td>
              <td colSpan={cellWidth}>{ROITotal ? `ROI: ${ROITotal.toFixed(2)}, Deviation: ${DeviationTotal ? DeviationTotal.toFixed(2) : 0}` : "-"}</td>
              {DrawLine && <td colSpan={cellWidth}>{ROIDrawLine ? `ROI: ${ROIDrawLine.toFixed(2)}` : "-"}</td>}
            </tr>
          </tbody>
        }
      </table>
      {!hideInfo &&
        <div className="info">
          {pickType === 'final' &&
            <div className="item">
              <div className="title">ROI:</div>
              <div className="value">{ROISummary.toFixed(2)}</div>
            </div>
          }
          <div className="item">
            <div className="title">Created by:</div>
            <div className="value" title={`${Owner && Owner.firstName} ${(Owner && Owner.lastName) || ''}`}>
              <Link to={`/user/${OwnerId && OwnerId}`}>
                {`${Owner && Owner.firstName} ${(Owner && Owner.lastName) || ''}`}
              </Link>
            </div>
          </div>
          <div className="item">
            <div className="title">Created at:</div>
            <div className="value" title={moment(createdAt).format("MM/DD/YYYY h:mm A")}>
              {moment(createdAt).format("MM/DD/YYYY h:mm A")}
            </div>
          </div>
          {OwnerId === userId && pickType === 'pregame' && !Sponsored && (new Date(MatchTime) > moment().add(20, 'minutes')._d) &&
            <button id={id} onClick={handlePromoteContent} className="btn btn-warning" type="button">Promote</button>
          }
          <div className="socialShareWrap">
            <FacebookShareButton
              url={`${CONFIG.react_share_url}/pick/${id}`}
              quote={title}
              hashtag="#sportstrader"
            >
              <FacebookIcon
                size={28}
                round
              />
            </FacebookShareButton>

            <TwitterShareButton
              url={`${CONFIG.react_share_url}/pick/${id}`}
              title={title}
              hashtags={['#sportstrader']}
            >
              <TwitterIcon
                size={28}
                round
              />
            </TwitterShareButton>

            <RedditShareButton
              url={`${CONFIG.react_share_url}/pick/${id}`}
              title={title}
            >
              <RedditIcon
                size={28}
                round
              />
            </RedditShareButton>
          </div>
        </div>
      }
    </div>
  );
};

pickRow.propTypes = {
  AwayPitcher: PropTypes.string,
  DeviationSpread: PropTypes.number,
  DeviationTotal: PropTypes.number,
  DrawCoef: PropTypes.number,
  DrawLine: PropTypes.number,
  HomePitcher: PropTypes.string,
  Match: PropTypes.object,
  MoneyLine: PropTypes.string,
  MoneyLineCoef: PropTypes.number,
  Odd: PropTypes.object,
  Owner: PropTypes.string,
  OwnerId: PropTypes.number,
  PointSpread: PropTypes.string,
  ROIDrawLine: PropTypes.number,
  ROIMoneyLine: PropTypes.number,
  ROISpread: PropTypes.number,
  ROITotal: PropTypes.number,
  Result: PropTypes.object,
  Sponsored: PropTypes.bool,
  SpreadCoef: PropTypes.number,
  SpreadScoresAway: PropTypes.number,
  SpreadScoresHome: PropTypes.number,
  Status: PropTypes.string,
  Total: PropTypes.string,
  TotalCoef: PropTypes.number,
  TotalScores: PropTypes.number,
  createdAt: PropTypes.instanceOf(Date),
  id: PropTypes.number
};

class ProfilePick extends Component {
  static propTypes = {
    bundle: PropTypes.object,
    hideInfo: PropTypes.bool,
    itemType: PropTypes.string,
    parley: PropTypes.object,
    paymentRequired: PropTypes.bool,
    pick: PropTypes.object,
    pickType: PropTypes.string,
    picks: PropTypes.array,
    userId: PropTypes.number
  };

  handlePromoteContent = async (e) => {
    const { dispatch, getData, userId } = this.props;
    const pickId = e.target.id;
    const result = await alertSuccessWithConfirmaiton('Pick promotion confirmation',
      `Please confirm that you wish to promote this Pick for ${CONFIG.promotionPeriod} days to all users on SportsTrader® platform at ${CONFIG.promotionPrice} USD`);

    if (result.value) {
      await promoteContent(dispatch, pickId, 'Pick');
      getProfile(dispatch);
      getData(userId);
    }
  }

  render() {
    const { picks, pickType, paymentRequired, hideInfo, userId, parley, pick, bundle, itemType = '' } = this.props;
    let sports = [];
    let betTypes = {};
    let price = '';
    let subscription = {}

    if (pick && pick.sports) {
      ({ sports, betTypes, price, subscription } = pick);
    } else if (parley && parley.sports) {
      ({ sports, betTypes, price } = parley);
    } else if (bundle && bundle.sports) {
      ({ sports, betTypes, price } = bundle);
    }

    const rows = [];
    const teams = [];
    picks.forEach((element) => {
      rows.push(pickRow(element, pickType, hideInfo, userId, this.handlePromoteContent));
      teams.push(`${element.Match.AwayTeam} vs ${element.Match.HomeTeam}`);
    });

    const betTypesStr = stringlifyBetTypes(itemType, betTypes);
    let teamsImage = '';
    let pickStatus;
    let matchTime;

    if (itemType.toLowerCase() === 'pick' && picks.length === 1) {
      teamsImage = picks[0].TeamsImage;
      const oldMetaImg = document.getElementById("og-image");
      oldMetaImg && oldMetaImg.parentNode.removeChild(oldMetaImg);

      const oldMetaUrl = document.getElementById("og-url");
      oldMetaUrl && oldMetaUrl.parentNode.removeChild(oldMetaUrl);

      const price = picks[0].price || picks[0].Price;

      pickStatus = price > 0 ? 'Premium' : 'Free'
      matchTime = picks[0].Match.MatchTime;
    }

    return (
      <div className={`alt-table-responsive ${pickType}`}>
        {
          <Helmet>
            <title>{teams.toString().replace(/,/g, ', ')}</title>
            <meta property="og:description" content={`Game Date: ${moment(matchTime).utc().format('MMM Do YYYY, h:mm a (z)')}`} />
            <meta property="og:title" content={`${pickStatus} Pick for ${teams.toString().replace(/,/g, ', ')}`} />
            <meta property="og:image" content={teamsImage} />
            <meta property="og:image:url" content={teamsImage} />
            <meta property="og:image:secure_url" content={teamsImage} />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:url" content={window.location.href} />
            <meta name="twitter:card" content="summary_large_image" />
          </Helmet>
        }
        {paymentRequired &&
          <h4>
            {`This ${itemType} is for: ${sports.toString().replace(/,/g, ', ')} and includes `}
            {betTypesStr}
          </h4>
        }
        <div className="picks">
          {paymentRequired && !subscription.id &&
            <div className={`need-buy ${itemType === 'Pick' ? 'pick' : 'parlay'}`}>
              <p>{itemType} price is ${price}.</p>
              <p>You need to buy this {itemType} before you can view it.</p>
            </div>
          }
          {paymentRequired && subscription.id &&
            <div className={`need-buy ${itemType === 'Pick' ? 'pick' : 'parlay'}`}>
              <p>Subscription price is ${subscription.price}.</p>
              <p>This pick is part of subscription. So you'll need to buy subscription to view this pick</p>
            </div>
          }
          {rows}
        </div>
      </div>
    );
  }
}

function select(state) {
  const userId = state.getIn(['users', 'profile', 'id'], false);
  const paymentRequired = state.getIn(['picks', 'paymentRequired'], false);
  const parley = state.getIn(['picks', 'parley'], i.Map()).toJS();
  const pick = state.getIn(['picks', 'pick'], i.Map()).toJS();
  const bundle = state.getIn(['picks', 'bundle'], i.Map()).toJS();

  return { userId, paymentRequired, parley, pick, bundle }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(ProfilePick);
