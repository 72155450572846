import { Map, fromJS, merge } from 'immutable';
import {
  FETCH_MATCH_BEGIN,
  FETCH_MATCH_SUCCESS,
  FETCH_MATCH_FAILURE,
  FETCH_MATCH_LIVE_DATA_BEGIN,
  FETCH_MATCH_LIVE_DATA_SUCCESS,
  FETCH_MATCH_LIVE_DATA_FAILURE
} from '../actions/matches';


const initialState = Map({
  matchInfo: Map(),
  loading: false,
  liveDataLoading: false,
  error: null
});

export default function matchesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MATCH_BEGIN:
      state = state.set('loading', true);
      state = state.set('matchInfo', fromJS({}));
      break;

    case FETCH_MATCH_SUCCESS:
      state = state.set('matchInfo', merge(state.get('matchInfo'), fromJS(action.data.match)));
      state = state.set('loading', false);
      break;

    case FETCH_MATCH_FAILURE:
      state = state.set('loading', false);
      state = state.set('error', action.error);
      break;

    case FETCH_MATCH_LIVE_DATA_BEGIN:
      state = state.set('liveDataLoading', true);
      break;

    case FETCH_MATCH_LIVE_DATA_SUCCESS:
      state = state.setIn(['matchInfo', 'competitions'], fromJS(action.data.competitions));
      state = state.setIn(['matchInfo', 'status'], fromJS(action.data.status));
      state = state.set('liveDataLoading', false);
      break;

    case FETCH_MATCH_LIVE_DATA_FAILURE:
      state = state.set('liveDataLoading', false);
      state = state.set('error', action.error);
      break;

    default:
      return state;
  }

  return state;
}
