import _ from 'lodash';
import React, {Component} from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import {NavLink} from 'react-router-dom';
import { withRouter } from "react-router";
import PropTypes from 'prop-types';
import i from 'immutable';
import { connect } from "../../../store/context";
import { getSports } from '../../../api/sports';
import { updateMenuTabActiveSuccess } from '../../../actions/general';
import Footer from '../../../components/ui/Footer/index';
import mobileCorrectHeight from '../../../helpers/mobileCorrectHeight';
import { setDefaultAvatar } from '../../../helpers/defaultImage';
import SubMenu from './Submenu';
import './style.scss';


class LeftNav extends Component {
  state = {
    showList: false
  }

  componentDidMount() {
    const { dispatch } = this.props;

    getSports(dispatch);
    this.updateCurrentTab();

    mobileCorrectHeight(); // fix vh values for mobile browsers (include mobile navbar)
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (prevProps.location.pathname !== location.pathname) {
      this.updateCurrentTab();
    }
  }

  updateCurrentTab = () => {
    const { dispatch, location } = this.props;

    const categoryName = location.pathname.split('/')[1]; //eslint-disable-line prefer-destructuring
    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

    if (location.pathname === '/') {
      dispatch(updateMenuTabActiveSuccess('Home'));
    } else {
      dispatch(updateMenuTabActiveSuccess(capitalizeFirstLetter(categoryName)));
    }
  }

  toggleActive = (e, tab) => {
    const { dispatch, activeTab } = this.props;
    e.preventDefault();
    e.stopPropagation();

    if (activeTab !== tab) {
      dispatch(updateMenuTabActiveSuccess(tab));
      this.setState({showList: true});
    } else {
      this.setState({showList: !this.state.showList});
    }
  }

  render() {
    const { profile, activeTab, profileLoading, sports, isProfileLoaded } = this.props;
    const { avatar, firstName, lastName, role, balance } = profile;
    const { showList } = this.state;
    return (
      <aside id="sidebar-left" className="sidebar-circle">

        <Scrollbar
          className="scrollbar"
          thumbMinSize={50}
          style={{maxHeight: 'inherit'}}
        >
          <div className="scroll-wrap">
            {isProfileLoaded && !profileLoading &&
            <div id="tour-8" className="sidebar-content">
              <div className="media">
                <NavLink className="pull-left has-notif avatar text-center" to="/profile/edit">
                  <img src={avatar} alt="avatar" onError={setDefaultAvatar} />
                </NavLink>
                <div className="media-body">
                  <h4 className="media-heading fullname">
                    <NavLink
                      to="/profile"
                      title={`${firstName} ${lastName}`.length > 15 ? `${firstName} ${lastName}` : ''}
                    >
                      {firstName ? <span>{firstName} {lastName}</span> : <span>Anonymous</span>}
                    </NavLink>
                  </h4>
                  <p>Balance: <strong>{balance ? balance : '0'}</strong></p>
                  <p>Role: {role}</p>
                </div>
              </div>
            </div>}

            <ul id="tour-9" className="sidebar-menu">
              <li
                className={activeTab === 'Home' ? "active" : ''}
                onClick={(e) => this.toggleActive(e, 'Home')}
              >
                <NavLink id="home-page" to="/">
                  <span className="icon"><i className="fa fa-home" /></span>
                  <span className="text">Home</span>
                </NavLink>
              </li>
              <li className={`submenu ${activeTab === 'Odds' && `active ${showList && "show"}`}`}>
                <NavLink id="odds-page"  to="" onClick={(e) => this.toggleActive(e, 'Odds')}>
                  <span className="icon"><i className="fa fa-rss" /></span>
                  <span className="text">Odds</span>
                  <span className="arrow" />
                </NavLink>
                <ul className={activeTab === 'Odds' && showList ? "block menu-list" : "menu-list"}>
                  <SubMenu sports={sports} type={'odds'} />
                </ul>
              </li>

              {isProfileLoaded && profile.role === 'consultant' &&
              <li className={`submenu ${activeTab === 'Picks' && `active ${showList && "show"}`}`}>
                <NavLink id="picks-page" to="" onClick={(e) => this.toggleActive(e, 'Picks')}>
                  <span className="icon"><i className="fa fa-diamond" /></span>
                  <span className="text">Picks</span>
                  <span className="arrow" />
                </NavLink>
                <ul className={activeTab === 'Picks' && showList ? "block menu-list" : "menu-list"}>
                  <SubMenu sports={sports} type={'picks'} />
                </ul>
              </li>}

              <li
                className={activeTab === 'Consultants' ? "active" : ''}
                onClick={(e) => this.toggleActive(e, 'Consultants')}
              >
                <NavLink id="consultants-page" to="/consultants">
                  <span className="icon"><i className="fa fa-user" /></span>
                  <span className="text">Consultants</span>
                </NavLink>

                
              </li>

              <li
                className={activeTab === 'Rooms' ? "active" : ''}
                onClick={(e) => this.toggleActive(e, 'Rooms')}
              >
                <NavLink id="rooms-page" to="/rooms">
                  <span className="icon"><i className="fa fa-group" /></span>
                  <span className="text">Rooms</span>
                </NavLink>

                
              </li>

              <li
                className={activeTab === 'Subscriptions' ? "active" : ''}
                onClick={(e) => this.toggleActive(e, 'Subscriptions')}
              >
                <NavLink id="rooms-page" to="/subscriptions">
                  <span className="icon"><i className="fa fa-shopping-cart" /></span>
                  <span className="text">Subscriptions</span>
                </NavLink>

                
              </li>
            </ul>
            <Footer />
          </div>
        </Scrollbar>
      </aside>
    );
  }
}

const select = (state) => {
  const sports = state.getIn(['sports', 'items'], i.List()).toJS();
  const profile = state.getIn(['users', 'profile'], i.Map()).toJS();
  const profileLoading = state.getIn(['users', 'profileLoading'], false);
  const activeTab = state.getIn(['general', 'activeTab']);
  const isProfileLoaded = !_.isEmpty(profile);

  return { sports, profile, activeTab, profileLoading, isProfileLoaded }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default withRouter(connect(select, mapDispatchToProps)(LeftNav));

LeftNav.propTypes = {
  activeTab: PropTypes.string,
  dispatch: PropTypes.func,
  isProfileLoaded: PropTypes.bool,
  location: PropTypes.object,
  profile: PropTypes.object,
  profileLoading: PropTypes.bool,
  sports: PropTypes.array
};
