import { fromJS, Map, List } from 'immutable';
import { FETCH_PAYMENTS_HISTORY_BEGIN,
  FETCH_PAYMENTS_HISTORY_SUCCESS,
  FETCH_PAYMENTS_HISTORY_FAILURE,
  FETCH_SALES_BEGIN,
  FETCH_SALES_SUCCESS,
  FETCH_SALES_FAILURE,
  FETCH_SALES_STATS_BEGIN,
  FETCH_SALES_STATS_SUCCESS,
  FETCH_SALES_STATS_FAILURE,
  GET_AFFILIATE_BEGIN,
  GET_AFFILIATE_SUCCESS,
  GET_AFFILIATE_FAILURE,
  GET_PENDING_BALANCE_BEGIN,
  GET_PENDING_BALANCE_SUCCESS,
  GET_PENDING_BALANCE_FAILURE,
  GET_PENDING_AFFILIATE_BALANCE_BEGIN,
  GET_PENDING_AFFILIATE_BALANCE_SUCCESS,
  GET_PENDING_AFFILIATE_BALANCE_FAILURE,
  GET_AFFILIATE_LINK_BEGIN,
  GET_AFFILIATE_LINK_SUCCESS,
  GET_AFFILIATE_LINK_FAILURE,
  GET_AFFILIATE_REVENUE_BEGIN,
  GET_AFFILIATE_REVENUE_SUCCESS,
  GET_AFFILIATE_REVENUE_FAILURE,
  PROMOTE_CONTENT_BEGIN,
  PROMOTE_CONTENT_SUCCESS,
  PROMOTE_CONTENT_FAILURE,
  SEND_TIPS_FROM_BALANCE_BEGIN,
  SEND_TIPS_FROM_BALANCE_SUCCESS,
  SEND_TIPS_FROM_BALANCE_FAILURE,
  SUBMIT_WITHDRAW_REQUEST_BEGIN,
  SUBMIT_WITHDRAW_REQUEST_SUCCESS,
  SUBMIT_WITHDRAW_REQUEST_FAILURE } from '../actions/payments';


const initialState = Map({
  items: List(),
  soldItems: List(),
  salesFilter: 'picks',
  pages: 0,
  stats: Map(),
  loading: false,
  error: null,
  limit: 20,
  affiliate_users: List(),
  affilite_loading: false,
  pending_balance: 0,
  pending_balance_loading: false,
  affiliate_loading: false,
  affiliate_link: '',
  affiliate_revenue: 0,
  affiliate_revenue_loadin: false,
  promoteLoading: false
});

export default function paymentsReducer(state = initialState, action) {
  let balance = 0;
  let data;
  let index;
  let itemSet;

  switch (action.type) {
    case FETCH_PAYMENTS_HISTORY_BEGIN:
      state = state.set('loading', true);
      break;

    case FETCH_PAYMENTS_HISTORY_SUCCESS:
      state = state.set('items', fromJS(action.data.payments));
      state = state.set('loading', false);
      break;

    case FETCH_PAYMENTS_HISTORY_FAILURE:
      state = state.set('error', action.error);
      break;

    case FETCH_SALES_BEGIN:
      state = state.set('loading', true);
      break;

    case FETCH_SALES_SUCCESS:
      state = state.set('soldItems', fromJS(action.data.sales.payments));
      state = state.set('pages', fromJS(action.data.sales.pages));
      state = state.set('salesFilter', fromJS(action.data.sales.type));
      state = state.set('loading', false);

      break;

    case FETCH_SALES_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loading', false);
      break;

    case FETCH_SALES_STATS_BEGIN:
      state = state.set('loading', true);
      break;

    case FETCH_SALES_STATS_SUCCESS:
      state = state.set('stats', fromJS(action.data.stats));
      state = state.set('loading', false);
      break;

    case FETCH_SALES_STATS_FAILURE:
      state = state.set('error', action.error);
      break;

    case GET_AFFILIATE_BEGIN:
      state = state.set('affilite_loading', true);
      break;

    case GET_AFFILIATE_SUCCESS:
      state = state.set('affiliate_users', fromJS(action.data.users.users));
      state = state.set('affilite_loading', false);
      break;

    case GET_AFFILIATE_FAILURE:
      state = state.set('affilite_loading', action.error);
      break;

    case GET_AFFILIATE_REVENUE_BEGIN:
      state = state.set('affiliate_revenue_loading', true);
      break;

    case GET_AFFILIATE_REVENUE_SUCCESS:
      state = state.set('affiliate_revenue', fromJS(action.data.balance.balance));
      state = state.set('affiliate_revenue_loading', false);
      break;

    case GET_AFFILIATE_REVENUE_FAILURE:
      state = state.set('affiliate_revenue_loading', action.error);
      break;

    case GET_PENDING_BALANCE_BEGIN:
      state = state.set('pending_balance_loading', true);
      break;

    case GET_PENDING_BALANCE_SUCCESS:
      action.data.balance.payments.map((i) => balance += Number(i.price));
      state = state.set('pending_balance', balance);
      state = state.set('pending_balance_loading', false);
      break;

    case GET_PENDING_BALANCE_FAILURE:
      state = state.set('pending_balance_loading', action.error);
      break;

    case GET_PENDING_AFFILIATE_BALANCE_BEGIN:
      state = state.set('pending_affiliate_balance_loading', true);
      break;

    case GET_PENDING_AFFILIATE_BALANCE_SUCCESS:
      action.data.balance.payments.map((i) => balance += Number(i.price));
      state = state.set('pending_affiliate_balance', balance);
      state = state.set('pending_affiliate_balance_loading', false);
      break;

    case GET_PENDING_AFFILIATE_BALANCE_FAILURE:
      state = state.set('pending_affiliate_balance_loading', action.error);
      break;

    case GET_AFFILIATE_LINK_BEGIN:
      state = state.set('affiliate_loading', true);
      break;

    case GET_AFFILIATE_LINK_SUCCESS:
      state = state.set('affiliate_link', fromJS(action.data.link.link));
      state = state.set('affiliate_loading', false);
      break;

    case GET_AFFILIATE_LINK_FAILURE:
      state = state.set('affiliate_link', 'Error occurred while link loading');
      state = state.set('affiliate_loading', action.error);
      break;

    case PROMOTE_CONTENT_BEGIN:
      state = state.set('promoteLoading', true);
      break;

    case PROMOTE_CONTENT_SUCCESS:
      data = action.data.content;
      itemSet = data.type === 'Parlay' ? 'Parley' : 'Pick';
      index = state.get('soldItems').findIndex((item) => item.get('itemId') === data.id);
      state = state.setIn(['soldItems', index, itemSet, 'Sponsored'], true);
      state = state.set('promoteLoading', false);
      break;

    case PROMOTE_CONTENT_FAILURE:
      state = state.set('promoteLoading', false);
      break;

    case SEND_TIPS_FROM_BALANCE_BEGIN:
      state = state.set('loading', true);
      break;

    case SEND_TIPS_FROM_BALANCE_SUCCESS:
      state = state.set('loading', false);
      break;

    case SEND_TIPS_FROM_BALANCE_FAILURE:
      state = state.set('loading', false);
      break;

    case SUBMIT_WITHDRAW_REQUEST_BEGIN:
      state = state.set('loading', true);
      break;

    case SUBMIT_WITHDRAW_REQUEST_SUCCESS:
      state = state.set('loading', false);
      break;

    case SUBMIT_WITHDRAW_REQUEST_FAILURE:
      state = state.set('loading', false);
      break;

    default:
      return state;
  }

  return state;
}
