import React from "react";
import PropTypes from 'prop-types';
import i from 'immutable';
import { connect } from "../../store/context";
import { updateSidebarOpenStatus } from '../../actions/general';
import IncompletePicksNotification from '../picks/IncompletePicksNotification/';
import ConfirmEmailNotification from '../picks/confirmEmailNotification/';
import AdBlockDetectAlert from '../../components/general/AdBlockDetectAlert/index';


const Wrapper = (props) => {
  const {
    title,
    counter,
    icon,
    className,
    hideNotifications,
    emailVerified,
    isIncompletePicks,
    showAdBlockAlert = false,
    profile,
    pageContent
  } = props;

  const handleCloseSideBar = (e) => {
    const { dispatch, isSidebarOpen } = props;

    if (!isSidebarOpen) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    dispatch(updateSidebarOpenStatus(false));
  }

  return (
    <>
      <section
        id="page-content"
        ref={pageContent}
        className={`${className} ${!hideNotifications && (!emailVerified || isIncompletePicks) ? 'notification' : ''}`}
        onClickCapture={handleCloseSideBar}
        onClick={handleCloseSideBar}
      >
        {showAdBlockAlert && !profile && <AdBlockDetectAlert />}
        {title &&
          <div id="tour-11" className="header-content">
            <h1>
              <i className={icon} />
              <span>
                {title}
                {Boolean(counter) && ` (${counter})`}
              </span>
            </h1>
          </div>
        }
        <div className="body-content animated fadeIn">
          {props.children}
        </div>
      </section>
      {!hideNotifications && <IncompletePicksNotification />}
      {!hideNotifications && <ConfirmEmailNotification />}
    </>
  )
}

function select(state) {
  const emailVerified = state.getIn(['users', 'profile', 'emailVerified'], false);
  const incompletePicks = state.getIn(['picks', 'incompletePicks'], i.List()).toJS();
  const isIncompletePicks = incompletePicks.length !== 0;
  const isSidebarOpen = state.getIn(['general', 'sidebarOpen'], false);
  const profile = state.getIn(['users', 'profile'], false);

  return { emailVerified, isIncompletePicks, isSidebarOpen, profile };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Wrapper);

Wrapper.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  counter: PropTypes.number,
  dispatch: PropTypes.func,
  icon: PropTypes.string,
  isSidebarOpen: PropTypes.bool,
  location: PropTypes.object,
  title: PropTypes.string
}
