import axios from 'axios'
import CONFIG from '../config/config';


const axiosApi = axios.create({
  baseURL: CONFIG.api_url
});

export const setAuthHeader = (token) => {
  axiosApi.defaults.headers.common['Authorization'] = token
}

// Set the initial header from storage or something (should surround with try catch in actual app)
setAuthHeader(localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined' ?
  JSON.parse(localStorage.getItem('token')) : '');

export default axiosApi;
