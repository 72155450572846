export const GET_CONTENT_FEED_BEGIN   = 'GET_CONTENT_FEED_BEGIN';
export const GET_CONTENT_FEED_SUCCESS = 'GET_CONTENT_FEED_SUCCESS';
export const GET_CONTENT_FEED_FAILURE = 'GET_CONTENT_FEED_FAILURE';
export const SET_SINGLE_FEED_BEGIN   = 'SET_SINGLE_FEED_BEGIN';
export const SET_SINGLE_FEED_SUCCESS = 'SET_SINGLE_FEED_SUCCESS';
export const SET_SINGLE_FEED_FAILURE = 'SET_SINGLE_FEED_FAILURE';
export const GET_SPONSORED_CONTENT_FEED_BEGIN   = 'GET_SPONSORED_CONTENT_FEED_BEGIN';
export const GET_SPONSORED_CONTENT_FEED_SUCCESS = 'GET_SPONSORED_CONTENT_FEED_SUCCESS';
export const GET_SPONSORED_CONTENT_FEED_FAILURE = 'GET_SPONSORED_CONTENT_FEED_FAILURE';
export const GET_USER_CONTENT_FEED_BEGIN   = 'GET_USER_CONTENT_FEED_BEGIN';
export const GET_USER_CONTENT_FEED_SUCCESS = 'GET_USER_CONTENT_FEED_SUCCESS';
export const GET_USER_CONTENT_FEED_FAILURE = 'GET_USER_CONTENT_FEED_FAILURE';

export const getContentFeedBegin = () => ({
  type: GET_CONTENT_FEED_BEGIN
});

export const getContentFeedSuccess = (feed, isNewFeed) => ({
  type: GET_CONTENT_FEED_SUCCESS,
  data: { feed, isNewFeed }
});

export const getContentFeedError = (error) => ({
  type: GET_CONTENT_FEED_FAILURE,
  data: { error }
});

export const setSingleFeedBegin = () => ({
  type: SET_SINGLE_FEED_BEGIN
});

export const setSingleFeedSuccess = (feed) => ({
  type: SET_SINGLE_FEED_SUCCESS,
  data: { feed }
});

export const setSingleFeedError = (error) => ({
  type: SET_SINGLE_FEED_FAILURE,
  data: { error }
});

export const getSponsoredContentFeedBegin = () => ({
  type: GET_SPONSORED_CONTENT_FEED_BEGIN
});

export const getSponsoredContentFeedSuccess = (feed) => ({
  type: GET_SPONSORED_CONTENT_FEED_SUCCESS,
  data: { feed }
});

export const getSponsoredContentFeedError = (error) => ({
  type: GET_SPONSORED_CONTENT_FEED_FAILURE,
  data: { error }
});

export const getUserAPIContentfeedBegin = () => ({
  type: GET_USER_CONTENT_FEED_BEGIN
});

export const getUserAPIContentfeedSuccess = (feed) => ({
  type: GET_USER_CONTENT_FEED_SUCCESS,
  data: { feed }
});

export const getUserAPIContentfeedError = (error) => ({
  type: GET_USER_CONTENT_FEED_FAILURE,
  data: { error }
});
