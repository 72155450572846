import React from 'react';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import './style.scss';


const Footer = () => {
  const year = moment().year();
  return (
    <footer className="footer">
      <div className="links">
        <NavLink target="_blank" id="Privacy" to="/privacy">
          Privacy policy
        </NavLink>
        <NavLink target="_blank" id="tos" to="/tos">
          Terms
        </NavLink>
        <NavLink target="_blank" id="cookie" to="/cookie-policy">
          Cookie policy
        </NavLink>
      </div>
      <div className="copyright">
        <div>SportsTrader&reg; by NXT - {year}</div>
        <div>Contact Us info@sportstrader.pro </div>
      </div>
    </footer>
  );
};

export default Footer;
