import React from "react";
import PropTypes from 'prop-types';
import { setDefaultAvatar } from '../../../helpers/defaultImage';
import './style.scss';


function truncateNames(input, len=15) {
  if (input.length > len) {
    return input.substring(0, len) + '...';
  }
  return input
}

const Room = (props) => {
  const { room, online, onOpenRoom, active, newMessages, blockedByMe } = props;
  const { chatRoomId } = room;
  const owner = room.room.user
  const chatRoom = room.room
  const avatar = owner.avatar
  let { firstName, lastName } = owner;

  const clickOnChannel = () => {
    onOpenRoom(chatRoomId);
  }
  const isActive = active ? 'active' : '';
  const haveNewMessage = newMessages ? 'new' : '';
  const isOnline = online ? 'online' : '';
  const isBlocked = blockedByMe ? 'blocked' : '';

  firstName = firstName ? firstName: '';
  lastName = lastName ? lastName : '';
  const roomName = truncateNames(chatRoom.roomName, 20)
  // const roomDesc = truncateNames(chatRoom.roomDescription, 20)

  return (
    <li className={`${isActive} ${haveNewMessage} ${isOnline} ${isBlocked}`} key={chatRoomId} onClick={clickOnChannel} >
      <img src={avatar} alt="avatar" width="50" height="50" onError={setDefaultAvatar} />
      <div className="about">
        <div className="name">
          {roomName}
        </div>
        <div className="description">
        {firstName} {lastName}
        </div>
      </div>
      {blockedByMe && <div className="blocked-icon"><i className="fa fa-lock" /></div>}
    </li>);
}

export default Room;

Room.propTypes = {
  active: PropTypes.bool,
  blockedByMe: PropTypes.bool,
  newMessages: PropTypes.bool,
  onOpenRoom: PropTypes.func,
  online: PropTypes.bool,
  room: PropTypes.object
};
