import { Map, List, fromJS } from 'immutable';
import {
  CREATE_PICK_BEGIN,
  CREATE_PICK_SUCCESS,
  CREATE_PICK_FAILURE,
  CREATE_PARLEY_BEGIN,
  CREATE_PARLEY_SUCCESS,
  CREATE_PARLEY_FAILURE,
  COMPLETE_PICK_BEGIN,
  COMPLETE_PICK_SUCCESS,
  COMPLETE_PICK_FAILURE,
  FETCH_MY_PICKS_PREGAME_BEGIN,
  FETCH_MY_PICKS_PREGAME_SUCCESS,
  FETCH_MY_PICKS_PREGAME_FAILURE,
  FETCH_MY_PICKS_INPROGRESS_BEGIN,
  FETCH_MY_PICKS_INPROGRESS_SUCCESS,
  FETCH_MY_PICKS_INPROGRESS_FAILURE,
  FETCH_MY_PICKS_FINAL_BEGIN,
  FETCH_MY_PICKS_FINAL_SUCCESS,
  FETCH_MY_PICKS_FINAL_FAILURE,
  FETCH_MY_PICKS_INCOMPLETE_BEGIN,
  FETCH_MY_PICKS_INCOMPLETE_SUCCESS,
  FETCH_MY_PICKS_INCOMPLETE_FAILURE,
  FETCH_PICK_BEGIN,
  FETCH_PICK_SUCCESS,
  FETCH_PICK_FAILURE,
  FETCH_PARLEY_BEGIN,
  FETCH_PARLEY_SUCCESS,
  FETCH_PARLEY_FAILURE,
  PICK_PAYMENT_REQUIRED,
  PARLEY_PAYMENT_REQUIRED,
  REMOVE_STARTED_GAMES,
  FETCH_USER_PICKS_FINAL_BEGIN,
  FETCH_USER_PICKS_FINAL_SUCCESS,
  FETCH_USER_PICKS_FINAL_FAILURE,
  FETCH_MY_PICKS_CREATED_IDS_BEGIN,
  FETCH_MY_PICKS_CREATED_IDS_SUCCESS,
  FETCH_MY_PICKS_CREATED_IDS_FAILURE
} from '../actions/picks';

import {
  FETCH_BUNDLE_BEGIN,
  FETCH_BUNDLE_SUCCESS,
  FETCH_BUNDLE_FAILURE,
  CREATE_BUNDLE_BEGIN,
  CREATE_BUNDLE_SUCCESS,
  CREATE_BUNDLE_FAILURE,
  BUNDLE_PAYMENT_REQUIRED
} from '../actions/bundles';


const initialState = Map({
  picks: List(),
  createdPicks: List(),
  loading: false,
  error: null,
  pregamePicks: Map(),
  inprogressPicks: List(),
  finalPicks: Map(),
  incompletePicks: List(),
  paymentRequired: false,
  picksUpdatedTime: new Date(),
  loadingPreGame: false,
  loadingInProgress: false,
  loadingFinal: false,
  usersPicks: Map(),
  usersPicksLoading: false,
  bundles: List()
});

export default function picksReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_PICK_BEGIN:
      state = state.set('loading', true);
      break;
    case CREATE_PICK_SUCCESS:
      state = state.set('createdPicks', state.get('createdPicks').push(fromJS(action.data.pick.pick.OddID)));
      state = state.set('loading', false);
      break;
    case CREATE_PICK_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loading', false);
      break;
    case CREATE_PARLEY_BEGIN:
      state = state.set('loading', true);
      break;
    case CREATE_PARLEY_SUCCESS: {
      let incompletePicks = state.get('incompletePicks', Map());
      const { Picks } = action.data.pick.parley;
      Picks.forEach((element) => {
        incompletePicks = incompletePicks.filter((pick) => pick.get('OddID') !== fromJS(element.OddID));
      });
      state = state.set('incompletePicks', incompletePicks);
      state = state.set('loading', false);
      break;
    }
    case CREATE_PARLEY_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loading', false);
      break;
    case COMPLETE_PICK_BEGIN:
      state = state.set('loading', true);
      break;
    case COMPLETE_PICK_SUCCESS: {
      let incompletePicks = state.get('incompletePicks', Map());
      incompletePicks = incompletePicks.filter((pick) => pick.get('OddID') !== fromJS(action.data.pick.pick.OddID));
      state = state.set('incompletePicks', incompletePicks);
      state = state.set('loading', false);
      break;
    }
    case COMPLETE_PICK_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loading', false);
      break;
    case FETCH_MY_PICKS_PREGAME_BEGIN:
      state = state.set('loadingPreGame', true);
      state = state.set('paymentRequired', false);
      break;
    case FETCH_MY_PICKS_PREGAME_SUCCESS:
      state = state.setIn(['pregamePicks', 'picks'], fromJS(action.data.data.picks));
      state = state.setIn(['pregamePicks', 'pages'], fromJS(action.data.data.pages));
      state = state.setIn(['pregamePicks', 'page'], fromJS(action.data.data.currentPage));
      state = state.setIn(['pregamePicks', 'count'], fromJS(action.data.data.count));
      state = state.setIn(['pregamePicks', 'limit'], fromJS(action.data.data.limit));
      state = state.set('loadingPreGame', false);
      break;
    case FETCH_MY_PICKS_PREGAME_FAILURE:
      state = state.set('loadingPreGame', false);
      state = state.set('error', action.error);
      break;
    case FETCH_MY_PICKS_INPROGRESS_BEGIN:
      state = state.set('loadingInProgress', true);
      state = state.set('paymentRequired', false);
      break;
    case FETCH_MY_PICKS_INPROGRESS_SUCCESS:
      state = state.setIn(['progressPicks', 'picks'], fromJS(action.data.data.picks));
      state = state.setIn(['progressPicks', 'pages'], fromJS(action.data.data.pages));
      state = state.setIn(['progressPicks', 'page'], fromJS(action.data.data.currentPage));
      state = state.setIn(['progressPicks', 'count'], fromJS(action.data.data.count));
      state = state.setIn(['progressPicks', 'limit'], fromJS(action.data.data.limit));
      state = state.set('loadingInProgress', false);
      break;
    case FETCH_MY_PICKS_INPROGRESS_FAILURE:
      state = state.set('loadingInProgress', false);
      state = state.set('error', action.error);
      break;
    case FETCH_MY_PICKS_FINAL_BEGIN:
      state = state.set('loadingFinal', true);
      state = state.setIn(['finalPicks', 'picks'], fromJS([]));
      state = state.set('paymentRequired', false);
      break;
    case FETCH_MY_PICKS_FINAL_SUCCESS:
      state = state.setIn(['finalPicks', 'picks'], fromJS(action.data.data.picks));
      state = state.setIn(['finalPicks', 'pages'], fromJS(action.data.data.pages));
      state = state.setIn(['finalPicks', 'page'], fromJS(action.data.data.currentPage));
      state = state.setIn(['finalPicks', 'count'], fromJS(action.data.data.count));
      state = state.setIn(['finalPicks', 'limit'], fromJS(action.data.data.limit));
      state = state.set('loadingFinal', false);
      break;
    case FETCH_MY_PICKS_FINAL_FAILURE:
      state = state.set('loadingFinal', false);
      state = state.set('error', action.error);
      break;
    case FETCH_MY_PICKS_INCOMPLETE_BEGIN:
      state = state.set('loading', true);
      state = state.set('paymentRequired', false);
      break;
    case FETCH_MY_PICKS_INCOMPLETE_SUCCESS:
      state = state.set('incompletePicks', fromJS(action.data.picks));
      state = state.set('loading', false);
      break;
    case FETCH_MY_PICKS_INCOMPLETE_FAILURE:
      state = state.set('loading', false);
      state = state.set('error', action.error);
      break;
    case FETCH_PICK_BEGIN:
      state = state.set('loading', true);
      state = state.set('parley', fromJS({}));
      state = state.set('pick', fromJS({}));
      state = state.set('paymentRequired', false);
      break;
    case FETCH_PICK_SUCCESS:
      state = state.set('pick', fromJS(action.data.pick));
      state = state.set('loading', false);
      break;
    case FETCH_PICK_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loading', false);
      break;
    case PICK_PAYMENT_REQUIRED:
      state = state.set('pick', fromJS(action.data.pick.pick));
      state = state.set('loading', false);
      state = state.set('paymentRequired', true);
      break;
    case FETCH_PARLEY_BEGIN:
      state = state.set('loading', true);
      state = state.set('pick', fromJS({}));
      state = state.set('parley', fromJS({}));
      state = state.set('paymentRequired', false);
      break;
    case FETCH_PARLEY_SUCCESS:
      state = state.set('parley', fromJS(action.data.parley));
      state = state.set('loading', false);
      break;
    case FETCH_PARLEY_FAILURE:
      state = state.set('loading', false);
      state = state.set('error', action.error);
      break;
    case PARLEY_PAYMENT_REQUIRED:
      state = state.set('parley', fromJS(action.data.parley.parley));
      state = state.set('loading', false);
      state = state.set('paymentRequired', true);
      break;
    case REMOVE_STARTED_GAMES:
      state = state.set('picksUpdatedTime', new Date());
      break;
    case FETCH_USER_PICKS_FINAL_BEGIN:
      state = state.set('usersPicksLoading', true);
      state = state.setIn(['usersPicks', action.data.id.toString(), 'picks'], fromJS([]));
      break;
    case FETCH_USER_PICKS_FINAL_SUCCESS:
      state = state.setIn(['usersPicks', action.data.id.toString(), 'picks'], fromJS(action.data.data.picks));
      state = state.setIn(['usersPicks', action.data.id.toString(), 'pages'], fromJS(action.data.data.pages));
      state = state.setIn(['usersPicks', action.data.id.toString(), 'page'], fromJS(action.data.data.currentPage));
      state = state.setIn(['usersPicks', action.data.id.toString(), 'count'], fromJS(action.data.data.count));
      state = state.setIn(['usersPicks', action.data.id.toString(), 'limit'], fromJS(action.data.data.limit));
      state = state.set('usersPicksLoading', false);
      break;
    case FETCH_USER_PICKS_FINAL_FAILURE:
      state = state.set('error', action.error);
      state = state.set('usersPicksLoading', false);
      break;

    case FETCH_MY_PICKS_CREATED_IDS_BEGIN:
      state = state.set('loading', true);
      break;
    case FETCH_MY_PICKS_CREATED_IDS_SUCCESS:
      state = state.set('createdPicks', fromJS(action.data.ids));
      state = state.set('loading', false);
      break;
    case FETCH_MY_PICKS_CREATED_IDS_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loading', false);
      break;

    // bundles
    case FETCH_BUNDLE_BEGIN:
      state = state.set('loading', true);
      state = state.set('pick', fromJS({}));
      state = state.set('parley', fromJS({}));
      state = state.set('bundle', fromJS({}));
      state = state.set('paymentRequired', false);
      break;
    case FETCH_BUNDLE_SUCCESS:
      state = state.set('bundle', fromJS(action.data.bundle));
      state = state.set('loading', false);
      break;
    case FETCH_BUNDLE_FAILURE:
      state = state.set('loading', false);
      state = state.set('error', action.error);
      break;
    case BUNDLE_PAYMENT_REQUIRED:
      state = state.set('bundle', fromJS(action.data.bundle.bundle));
      state = state.set('loading', false);
      state = state.set('paymentRequired', true);
      break;
    case CREATE_BUNDLE_BEGIN:
      state = state.set('loading', true);
      break;
    case CREATE_BUNDLE_SUCCESS: {
      let incompletePicks = state.get('incompletePicks', Map());
      const { Picks } = action.data.pick.bundle;
      Picks.forEach((element) => {
        incompletePicks = incompletePicks.filter((pick) => pick.get('OddID') !== fromJS(element.OddID));
      });
      state = state.set('incompletePicks', incompletePicks);
      state = state.set('loading', false);
      break;
    }
    case CREATE_BUNDLE_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loading', false);
      break;
    default:
      return state;
  }

  return state;
}
