import React,  { Component } from 'react';
import i from 'immutable';
import PropTypes from 'prop-types';
import moment from 'moment';
import queryString from 'query-string';
import { connect } from "../../../store/context";
import { isValidTextField } from "../../../helpers/validators";
import { getPaymentsHistory } from "../../../api/payments";
import { executePayment, pay } from "../../../api/paypal";
import { getProfile } from "../../../api/users";
import Wrapper from '../../../components/ui/Wrapper'
import Loader from '../../../components/ui/Loader/index';
import { setDefaultAvatar } from '../../../helpers/defaultImage';
import "./style.scss";


class RefillBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price: '',
      errorMessage: '',
      formValid: false,
      buying: false
    };
  }

  componentDidMount = async () => {
    const { location: { search }, dispatch } = this.props;
    const { PayerID, paymentId } = queryString.parse(search);

    if (PayerID && paymentId) {
      await executePayment(dispatch, PayerID, paymentId, 'Refill', 0, false);
      window.history.pushState({}, document.title, '/profile/refill');
      getProfile(dispatch);
    }

    getPaymentsHistory(dispatch);
  }

  handleChangePrice = (e) => {
    e && e.preventDefault();
    const price = e.currentTarget.value;

    if (Number(price) || price === '') {
      const devidedString = (price).split('.');

      if (devidedString.length > 1 && devidedString[1].length > 2) {
        return
      }

      this.setState({ price });
    } else {
      return;
    }

    const { isValidField, error } = isValidTextField(price, e.currentTarget.name);

    if (isValidField) {
      this.setState({formValid: true, errorMessage: error});
    } else {
      this.setState({errorMessage: error, formValid: false});
    }
  }

  handleBuy = async (e) => {
    e.preventDefault()
    const { dispatch, history } = this.props;
    const { price, formValid } = this.state;

    if (!formValid) {
      return;
    }

    this.setState({ buying: true });

    try {
      await pay(dispatch, 0, 'Refill', price, history);
    } catch {
      this.setState({ buying: false });
    }
  }

  render() {
    const { profile, payments } = this.props;
    const { firstName, lastName, avatar } = profile;
    const { errorMessage, formValid, buying, price } = this.state;

    let totalAmount = 0;
    const filteredPayments = payments.filter((payment) => payment.itemType === 'Refill'); // filter payments to have only refills. TO DO: FIX API
    const payment = filteredPayments.map((payment) => {
      const { id, price, itemType, createdAt } = payment;
      totalAmount += Number(price);
      return (
        <tr key={id}>
          <td>{moment(createdAt).format('LLL')}</td>
          <td>{itemType}</td>
          <td>${price}</td>
        </tr>
      )
    })

    totalAmount = Number(totalAmount.toFixed(2))

    return (
      <Wrapper
        className="refill-page"
        title="Balance. (Use your paypal account to refill balance)"
        icon="fa fa-file-o"
      >
        {buying && <Loader />}

        {!buying &&
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-invoice rounded">
                <div className="panel-heading">
                  <h4 className="pull-left">Refill Balance</h4>
                  <div className="pull-right">
                    <div className="logo-block"><i className="fa fa-cc-paypal fa-5x" /></div>
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="panel-sub-heading">
                  <div className="row inner-all">
                    <div className="col-md-6">
                      <div className="client-info text-left">
                        <h3 className="mt-0">Client information</h3>
                        <strong>FROM</strong>
                        <div>
                          <strong>{firstName} {lastName}</strong>
                        </div>
                        {/* <address>
                          <div>TO DO: use user address</div>
                          <abbr title="Phone">P:</abbr> (111) 111-1111
                        </address> */}
                        <img src={avatar} className="client-avatar" alt="avatar" onError={setDefaultAvatar} />
                        <br />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="company-info text-right mb-10">
                        <h3 className="mt-0">Company information</h3>
                        <strong>TO</strong>
                        <div>
                          <strong>SportsTrader®</strong>
                        </div>
                        {/* <address>
                          <div>TO DO: Some address, Delaware</div>
                          <div>USA, DIY 94107</div>
                          <abbr title="Phone">P:</abbr> (777) 777-66666
                        </address> */}
                        <img src="/img/logo/logo.svg" className="company-logo" alt="avatar" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-md-offset-3">
                      <form className="form-inline" onSubmit={this.handleBuy}>
                        <div className="form-body">
                          <div className={errorMessage ? "form-group has-error" : "form-group"}>
                            <input
                              onChange={this.handleChangePrice}
                              value={price}
                              className="form-control"
                              placeholder="Amount"
                              type="text"
                              maxLength="10"
                              name="price"
                              required
                            />
                            <div className="paypal-button">
                              <button
                                type="submit"
                                className="btn btn-default paypal"
                                disabled={!formValid || price.length === 0}
                              >
                                <img className="paypal-img" src="/img/paypal/paypal.svg" alt="paypal" />
                              </button>
                            </div>
                          </div>
                          <span className="help-block">{errorMessage}</span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="panel-body">
                  <div className="talbe-responsive payments-history-talbe">
                    <h5>Payments history</h5>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          {/* NOTE Labels of the data are dublicated in ./style.scss*/}
                          <th>Date</th>
                          <th>Type</th>
                          <th>Total Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {payment}
                      </tbody>

                      <tfoot>
                        <tr>
                          <td colSpan="1" rowSpan="1">&nbsp;</td>
                          <td colSpan="1"><b>Total</b></td>
                          <td colSpan="1"><b>${totalAmount}</b></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </Wrapper>
    );
  }
}

const select = (state) => {
  const profile = state.getIn(['users', 'profile'], i.Map()).toJS();
  const payments = state.getIn(['payments', 'items'], i.List()).toJS();

  return { profile, payments }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(RefillBalance);

RefillBalance.propTypes = {
  dispatch: PropTypes.func,
  payments: PropTypes.array,
  profile: PropTypes.object
};
