import CONFIG from '../config/config';
import api from '../actions/api';
import {
  fetchSubscriptionBegin,
  fetchSubscriptionSuccess,
  fetchSubscriptionError,
  fetchMySubscriptionBegin
} from '../actions/subscriptions';
import { alertError, alertSuccess, alertErrorText, alertSuccessWithOk, alertSuccessWithConfirmaiton } from '../actions/alerts'

export const createSubscription = async (dispatch, postData) => {
    try {
      const { data } = await api.put(`${CONFIG.api_url}/subscriptions`, postData);
      alertSuccess('Your subscription have been created')
  
    } catch (error) {
      console.log(error); //eslint-disable-line no-console
      alertError(error)
    }
}

export const fetchSubscriptions = async (dispatch, queryData) => {
  try {
    let { limit, offset, sportId, type, subscriptionId } = queryData 
    dispatch(fetchSubscriptionBegin());
    const { data } = await api.get(`${CONFIG.api_url}/subscriptions?limit=${limit}&offset=${offset}&sportId=${sportId}&type=${type}&subscriptionId=${subscriptionId}`);
    dispatch(fetchSubscriptionSuccess(data));

  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    dispatch(fetchSubscriptionError(error));
  }
}

export const fetchMySubscription = async (dispatch, filter) => {
  try {
  const results = await api.get(`${CONFIG.api_url}/subscriptions/my?${filter}`)
  dispatch(fetchMySubscriptionBegin(results.data));
  } catch(error){
    console.log(error)
  }
}

export const makeSubscriptionInactive = async (id, postData) => {
  try {
      const { data } = await api.post(`${CONFIG.api_url}/subscriptions/${id}`, postData);
      return [data, null]

  } catch(error) {
    console.log(error)
    return [null, error]
  }
}