import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { NavLink } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import FormattedToBetType, { calculateDecimalMoneyLine } from '../../general/bet-type-formatter';
import './style.scss';


const Delta = (props) => {
  const { units = "", betsType } = props;
  let { firstValue, currentValue } = props;

  if (betsType === 'decimal') {
    firstValue = calculateDecimalMoneyLine(firstValue);
    currentValue = calculateDecimalMoneyLine(currentValue);
  }

  let delta = Number((currentValue - firstValue).toFixed(2));

  if (units === 'price' && Math.sign(currentValue) !== Math.sign(firstValue)) {
    const buffer = Math.sign(firstValue) > 0 ? -200 : 200;
    delta = Number((currentValue - firstValue - buffer).toFixed(2));
  }

  const deltaId = Math.random().toString(36).substring(7);
  const addPlus = (value) => value < 0 ? `+${-1 * value}` : `${-1 * value}`;
  const pointStr = delta ? `${addPlus(delta)} ${units} since opening` : '';

  if (firstValue === 0 || !delta) {
    return false;
  }

  return (
    <span data-tip={pointStr} data-for={deltaId} className={`delta delta-${delta < 0 ? "up" : "down"}`}>
      <ReactTooltip id={deltaId} effect="solid" multiline >{}</ReactTooltip>
      <i className={`fa fa-caret-${delta < 0 ? "up" : "down"}`} />
    </span>
  );
};

export const OddsTable = (props) => {
  const { Match,
    Result,
    OddType,
    PointSpreadAway,
    PointSpreadAwayLine,
    MoneyLineAway,
    TotalNumber,
    OverLine,
    PointSpreadHome,
    PointSpreadHomeLine,
    MoneyLineHome,
    UnderLine,
    DrawLine,
    firstMoneyLineHome,
    firstMoneyLineAway,
    firstPointSpreadHomeLine,
    firstPointSpreadAwayLine,
    firstOverLine,
    firstUnderLine,
    firstPointSpreadHome,
    firstPointSpreadAway,
    firstTotalNumber,
    firstDrawLine,
    sportName,
    betsType,
    id } = props;
  const { MatchTime, AwayTeam, HomeTeam, AwayROT, HomeROT, MatchID, dataSource } = Match;
  const { HomeScore, AwayScore, Final } = Result;

  if (OddType !== 'Game') {
    return null;
  }

  const isNoZero = (value) => value !== 0;
  const emptyTd = <td> - </td>;

  const spreadAwayJSX = (
    <td className={PointSpreadAway === 0 ? "" : "disable"} >
      <span className={`points ${PointSpreadAway > 0 ? 'plus' : 'minus'}`}>
        <Delta
          units="points"
          currentValue={PointSpreadAway}
          firstValue={firstPointSpreadAway}
          id={id}
        />
        <FormattedToBetType
          price={0}
          value={PointSpreadAway}
          spread
        />
      </span>
      <span className={`price ${PointSpreadAwayLine > 0 ? 'plus' : 'minus'}`}>
        <Delta
          units="price"
          betsType={betsType}
          currentValue={PointSpreadAwayLine}
          firstValue={firstPointSpreadAwayLine}
          id={id}
        />
        (
        <FormattedToBetType
          price={1}
          value={PointSpreadAwayLine}
        />)
      </span>
    </td>
  )

  const spreadHomeJSX = (
    <td>
      <span className={`points ${PointSpreadHome > 0 ? 'plus' : 'minus'}`}>
        <Delta
          units="points"
          currentValue={PointSpreadHome}
          firstValue={firstPointSpreadHome}
          id={id}
        />
        <FormattedToBetType
          price={0}
          value={PointSpreadHome}
          spread
        />
      </span>
      <span className={`price ${PointSpreadHomeLine > 0 ? 'plus' : 'minus'}`}>
        <Delta
          units="price"
          betsType={betsType}
          currentValue={PointSpreadHomeLine}
          firstValue={firstPointSpreadHomeLine}
          id={id}
        />
        (
        <FormattedToBetType
          price={1}
          value={PointSpreadHomeLine}
        />)
      </span>
    </td>
  )

  const moneyLineAwayJSX = (
    <td>
      <span className={`price ${MoneyLineAway > 0 ? 'plus' : 'minus'}`}>
        <Delta
          units="price"
          betsType={betsType}
          currentValue={MoneyLineAway}
          firstValue={firstMoneyLineAway}
          id={id}
        />
        <FormattedToBetType price={1} value={MoneyLineAway} />
      </span>
    </td>
  )

  const moneyLineHomeJSX = (
    <td>
      <span className={`price ${MoneyLineHome > 0 ? 'plus' : 'minus'}`}>
        <Delta
          units="price"
          betsType={betsType}
          currentValue={MoneyLineHome}
          firstValue={firstMoneyLineHome}
          id={id}
        />
        <FormattedToBetType price={1} value={MoneyLineHome} />
      </span>
    </td>
  )

  const totalAwayJSX = (
    <td>
      <span className="points">
        <Delta
          units="points"
          currentValue={TotalNumber}
          firstValue={firstTotalNumber}
          id={id}
        />
        {TotalNumber}
      </span>
      <span className={`price ${OverLine > 0 ? 'plus' : 'minus'}`}>
        <Delta
          units="price"
          betsType={betsType}
          currentValue={OverLine}
          firstValue={firstOverLine}
          id={id}
        />
        (
        <FormattedToBetType
          price={1}
          value={OverLine}
        />) O
      </span>
    </td>
  )

  const totalHomeJSX = (
    <td>
      <span className="points">
        <Delta
          units="points"
          currentValue={TotalNumber}
          firstValue={firstTotalNumber}
          id={id}
        />
        {TotalNumber}
      </span>
      <span className={`price ${UnderLine > 0 ? 'plus' : 'minus'}`}>
        <Delta
          units="price"
          betsType={betsType}
          currentValue={UnderLine}
          firstValue={firstUnderLine}
          id={id}
        />
        (
        <FormattedToBetType
          price={1}
          value={UnderLine}
        />) U
      </span>
    </td>
  )

  const drawJSX = (
    <td rowSpan="2" className="two-rows">
      <span className={`price ${DrawLine > 0 ? 'plus' : 'minus'}`}>
        <Delta
          units="price"
          betsType={betsType}
          currentValue={DrawLine}
          firstValue={firstDrawLine}
          id={id}
        />
        <FormattedToBetType price={1} value={DrawLine} />
      </span>
    </td>
  )

  const spreadCellName = () => {
    if (sportName === 'hockey') {
      return 'Puck Line';
    }

    if (sportName === 'baseball') {
      return 'Run Line';
    }

    return 'Spread'
  }

  const totalCellName = () => {
    if (['mma', 'wbc'].includes(sportName)) {
      return 'Rounds';
    }

    return 'Total'
  }

  let matchLabel = '';
  let textType = '';
  let liveData = '';
  const showLiveDataFor = ['soccer', 'baseball', 'basketball'];

  if (moment(MatchTime) < moment()) {
    if (Final) {
      matchLabel = 'finished';
      textType = <span className="finished-label">Finished</span>;

      if (dataSource === 'KAMBI' && showLiveDataFor.includes(sportName)) {
        liveData = <NavLink to={`/match/${MatchID}?sport=${sportName}`} className="live-link">View Results</NavLink>
      }
    } else {
      matchLabel = 'in-progress';
      textType = <span className="inprogress-label">In Progress</span>;

      if (dataSource === 'KAMBI' && showLiveDataFor.includes(sportName)) {
        liveData = <NavLink to={`/match/${MatchID}?sport=${sportName}`} className="live-link">Live Data</NavLink>
      }
    }
  }

  return (
    <table className={`${matchLabel} odds-table shadow`}>
      <thead>
        <tr>
          <td colSpan={sportName === 'soccer' ? '4' : '3'}>
            <>GAME DATE: </>
            <MediaQuery maxDeviceWidth={479}>
              {moment(MatchTime).format('h:mm A MM/DD/YYYY ')}
            </MediaQuery>
            <MediaQuery minDeviceWidth={480}>
              {moment(MatchTime).format('MMMM Do YYYY, h:mm:ss a ')}
            </MediaQuery>
            {textType} {liveData}
          </td>
          <td colSpan="1" className="text-right">{typeof HomeScore === 'number' && moment(MatchTime) < moment() ? `Result: (${AwayScore} - ${HomeScore})` : ''}</td>
        </tr>
      </thead>

      <tbody>
        <tr className="head">
          <td>Teams</td>
          <td>{spreadCellName()}</td>
          <td>Money Line</td>
          <td>{totalCellName()} O/U</td>
          {sportName === 'soccer' &&
            <td>Draw</td>
          }
        </tr>
        <tr>
          <td>{AwayROT} {AwayTeam} (Away)</td>
          {isNoZero(PointSpreadAwayLine) ? spreadAwayJSX : emptyTd}
          {isNoZero(MoneyLineAway) ? moneyLineAwayJSX : emptyTd}
          {isNoZero(TotalNumber) ? totalAwayJSX : emptyTd}
          {sportName === 'soccer' &&
            (isNoZero(DrawLine) ? drawJSX : <td rowSpan="2" className="two-rows"> - </td>)
          }
        </tr>

        <tr>
          <td>{HomeROT} {HomeTeam} (Home)</td>
          {isNoZero(PointSpreadHomeLine) ? spreadHomeJSX : emptyTd}
          {isNoZero(MoneyLineHome) ? moneyLineHomeJSX : emptyTd}
          {isNoZero(TotalNumber) ? totalHomeJSX : emptyTd}
          {sportName === 'soccer' && <td className="two-rows-mob" />}
        </tr>
      </tbody>
    </table>
  );
};

OddsTable.propTypes = {
  DrawLine: PropTypes.string,
  Match: PropTypes.object,
  MoneyLineAway: PropTypes.string,
  MoneyLineHome: PropTypes.string,
  OddType: PropTypes.string,
  OverLine: PropTypes.string,
  PointSpreadAway: PropTypes.string,
  PointSpreadAwayLine: PropTypes.string,
  PointSpreadHome: PropTypes.string,
  PointSpreadHomeLine: PropTypes.string,
  Result: PropTypes.object,
  TotalNumber: PropTypes.string,
  UnderLine: PropTypes.string,
  betsType: PropTypes.string,
  firstDrawLine: PropTypes.number,
  firstMoneyLineAway: PropTypes.number,
  firstMoneyLineHome: PropTypes.number,
  firstOverLine: PropTypes.number,
  firstPointSpreadAway: PropTypes.number,
  firstPointSpreadAwayLine: PropTypes.number,
  firstPointSpreadHome: PropTypes.number,
  firstPointSpreadHomeLine: PropTypes.number,
  firstTotalNumber: PropTypes.number,
  firstUnderLine: PropTypes.number,
  id: PropTypes.number,
  sportName: PropTypes.string
};

Delta.propTypes = {
  betsType: PropTypes.string,
  currentValue: PropTypes.number,
  firstValue: PropTypes.number,
  id: PropTypes.number,
  units: PropTypes.string
}
