import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from "../../../store/context";
import { updateMenuTabActiveSuccess } from '../../../actions/general';
import './styles.scss';


class ProfileDropdown extends Component {
  handleToggleDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.toggleDropDown();
  }

  handleLogout = (e) => {
    e.preventDefault();

    localStorage.removeItem('token');
    window.location.href = '/login';
  }

  handleOpenSocialTab = () => {
    const { dispatch } = this.props;
    dispatch(updateMenuTabActiveSuccess('Social'));
  }

  render() {
    const { activeTab, profileRole, profileRef } = this.props;
    return (
      <li
        ref={profileRef} id="tour-6" className={classNames(
          {"dropdown navbar-profile vertical": true,
            "open": this.props.isOpen}
        )}
      >
        <a
          onClick={this.handleToggleDropdown}
          href="/"
          className={activeTab === 'Profile' ? "dropdown-toggle active" : "dropdown-toggle"}
          data-toggle="dropdown"
        >
          <i className="fa fa-cog" />
          <span>Settings</span>
        </a>

        <ul className="dropdown-menu animated" onClick={this.handleToggleDropdown}>
          <li className="dropdown-header">Account</li>
          <li onClick={this.handleOpenSocialTab}><NavLink to="/profile"><i className="fa fa-user" />View profile</NavLink></li>
          <li className="divider" />
          <li onClick={this.handleOpenSocialTab}><NavLink to="/profile/edit"><i className="fa fa-pencil" />Edit profile</NavLink></li>

          <li className="dropdown-header">Balance</li>
          <li onClick={this.handleOpenSocialTab}><NavLink to="/profile/refill"><i className="fa fa-upload" />Fill In</NavLink></li>
          <li className="divider" />
          {profileRole === 'consultant' && <li onClick={this.handleOpenSocialTab}> <NavLink to="/profile/sales"><i className="fa fa-plus" />Sales</NavLink></li>}
          <li className="divider" />
          {profileRole === 'consultant' && <li onClick={this.handleOpenSocialTab}><NavLink to="/affiliate"><i className="fa fa-retweet" />Affiliate Program</NavLink></li>}

          <li className="divider" />
          <li>
            <a href="/" onClick={this.handleLogout} style={{cursor: "pointer"}}><i className="fa fa-sign-out" />Logout</a>
          </li>
        </ul>

      </li>
    );
  }
}

function select(state) {
  const activeTab = state.getIn(['general', 'activeTab']);
  const profileRole = state.getIn(['users', 'profile', 'role'], null);

  return { activeTab, profileRole }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(ProfileDropdown);

ProfileDropdown.propTypes = {
  activeTab: PropTypes.string,
  dispatch: PropTypes.func,
  isOpen: PropTypes.bool,
  profileRef: PropTypes.func,
  profileRole: PropTypes.string,
  toggleDropDown: PropTypes.func
};
