import React from "react";
import PropTypes from 'prop-types';
import './style.scss';
import Room from './room';


const Rooms = (props) => {
  const { rooms, onOpenRoom, currentRoom, roomsWithNewMessages } = props;
  return (
    <ul className="chat-room-list">

      {rooms.map((room, index) => {
        let  newMessages = false
        roomsWithNewMessages.forEach(roomWithNewMsg => {
          if (roomWithNewMsg.chatRoomId === room.chatRoomId) {
            newMessages = true;
          }
        });
        return (
          <Room
            key={room.chatRoomId}
            room={room}
            online={false}
            onOpenRoom={onOpenRoom}
            active={currentRoom === room.chatRoomId ? true: false}
            newMessages={newMessages}
            blockedByMe={false}
          />
        );
      }
      )}

    </ul>
  );
}

export default Rooms;

Rooms.propTypes = {
  currentRoom: PropTypes.number,
  onOpenRoom: PropTypes.func,
  rooms: PropTypes.array,
  roomsWithNewMessages: PropTypes.array
};
