export const FETCH_MY_PARLAYS_PREGAME_BEGIN   = 'FETCH_MY_PARLAYS_PREGAME_BEGIN';
export const FETCH_MY_PARLAYS_PREGAME_SUCCESS = 'FETCH_MY_PARLAYS_PREGAME_SUCCESS';
export const FETCH_MY_PARLAYS_PREGAME_FAILURE = 'FETCH_MY_PARLAYS_PREGAME_FAILURE';
export const FETCH_MY_PARLAYS_INPROGRESS_BEGIN   = 'FETCH_MY_PARLAYS_INPROGRESS_BEGIN';
export const FETCH_MY_PARLAYS_INPROGRESS_SUCCESS = 'FETCH_MY_PARLAYS_INPROGRESS_SUCCESS';
export const FETCH_MY_PARLAYS_INPROGRESS_FAILURE = 'FETCH_MY_PARLAYS_INPROGRESS_FAILURE';
export const FETCH_MY_PARLAYS_FINAL_BEGIN   = 'FETCH_MY_PARLAYS_FINAL_BEGIN';
export const FETCH_MY_PARLAYS_FINAL_SUCCESS = 'FETCH_MY_PARLAYS_FINAL_SUCCESS';
export const FETCH_MY_PARLAYS_FINAL_FAILURE = 'FETCH_MY_PARLAYS_FINAL_FAILURE';

export const fetchMyParlaysPregameBegin = () => ({
  type: FETCH_MY_PARLAYS_PREGAME_BEGIN
});

export const fetchMyParlaysPregameSuccess = (parlays) => ({
  type: FETCH_MY_PARLAYS_PREGAME_SUCCESS,
  data: { parlays }
});

export const fetchMyParlaysPregameError = (error) => ({
  type: FETCH_MY_PARLAYS_PREGAME_FAILURE,
  data: { error }
});

export const fetchMyParlaysInprogressBegin = () => ({
  type: FETCH_MY_PARLAYS_INPROGRESS_BEGIN
});

export const fetchMyParlaysInprogressSuccess = (parlays) => ({
  type: FETCH_MY_PARLAYS_INPROGRESS_SUCCESS,
  data: { parlays }
});

export const fetchMyParlaysInprogressError = (error) => ({
  type: FETCH_MY_PARLAYS_INPROGRESS_FAILURE,
  data: { error }
});

export const fetchMyParlaysFinalBegin = () => ({
  type: FETCH_MY_PARLAYS_FINAL_BEGIN
});

export const fetchMyParlaysFinalSuccess = (data) => ({
  type: FETCH_MY_PARLAYS_FINAL_SUCCESS,
  data: { data }
});

export const fetchMyParlaysFinalError = (error) => ({
  type: FETCH_MY_PARLAYS_FINAL_FAILURE,
  data: { error }
});
