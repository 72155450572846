import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withRouter, Link } from "react-router-dom";
import { connect } from "../store/context";
import Wrapper from '../components/ui/Wrapper';
import Loader from '../components/ui/Loader/index';
import Information from '../components/edit-profile/Information';
import Socials from '../components/edit-profile/Socials';
import Avatar from '../components/edit-profile/Avatar';
import Password from "../components/edit-profile/Password";
import General from "../components/edit-profile/General";
import Email from "../components/edit-profile/Email";
import Subscription from "../components/edit-profile/Subscription";


class EditProfile extends Component {
  checkLink = (name) => {
    const { pathname } = this.props.location;

    return pathname === `/profile/edit/${name}`
  };

  render() {
    const isEditProfile = this.props.location.pathname === `/profile/edit`;
    const { profileLoading } = this.props;

    return (
      <Wrapper
        className="edit-profile-page"
        title="Edit Profile"
        icon="fa fa-fw fa-pencil"
      >
        {profileLoading && <Loader />}

        {!profileLoading &&
          <>
            <div className="settings-tabs shadow">
              <Link
                to="/profile/edit/information"
                className={`item ${(this.checkLink('information') || isEditProfile) ? 'active' : ''}`}
              >
                <i className="fa fa-info" />
                <span>Information</span>
              </Link>
              <Link to="/profile/edit/avatar" className={`item ${this.checkLink('avatar') ? 'active' : ''}`}>
                <i className="fa fa-user" />
                <span>Avatar</span>
              </Link>
              <Link to="/profile/edit/socials" className={`item ${this.checkLink('socials') ? 'active' : ''}`}>
                <i className="fa fa-thumbs-up" />
                <span>Socials</span>
              </Link>
              <Link to="/profile/edit/email" className={`item ${this.checkLink('email') ? 'active' : ''}`}>
                <i className="fa fa-envelope" />
                <span>Email</span>
              </Link>
              <Link to="/profile/edit/password" className={`item ${this.checkLink('password') ? 'active' : ''}`}>
                <i className="fa fa-lock" />
                <span>Password</span>
              </Link>
              <Link to="/profile/edit/general" className={`item ${this.checkLink('general') ? 'active' : ''}`}>
                <i className="fa fa-cog" />
                <span>General</span>
              </Link>
            </div>

            <div className="settings-body shadow">
              {(this.checkLink('information') || isEditProfile)  && <Information />}

              {this.checkLink('avatar') && <Avatar />}

              {this.checkLink('socials') && <Socials />}

              {this.checkLink('email') && <Email />}

              {this.checkLink('password') && <Password />}

              {this.checkLink('general') && <General />}

              {this.checkLink('subscription') && <Subscription />}
            </div>
          </>
        }
      </Wrapper>
    );
  }
}


function select(state) {
  const profileLoading = state.getIn(['users', 'profileLoading'], false);
  return { profileLoading }
}

export default connect(select)(withRouter(EditProfile));

EditProfile.propTypes = {
  location: PropTypes.object,
  profileLoading: PropTypes.bool
};
