import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { setDefaultAvatar } from '../../helpers/defaultImage';


const UserInfo = (props) => {
  const {
    avatar,
    firstName,
    bio,
    lastName,
    isFollowing,
    isCurrentUser,
    id,
    role,
    onFollowUser,
    onUnFollowUser,
    onChangeType,
    isForSale,
    allowForSale = false,
    facebook,
    twitter
  } = props;

  let followButtonText = "Follow";
  const followButtonClasses = "btn btn-default";
  let followButtonHandler = onFollowUser;
  const isConsultant = Boolean(role === 'consultant');

  if (isFollowing) {
    followButtonText = 'Unfollow';
    followButtonHandler = onUnFollowUser;
  }

  let forSaleButtonText = "For Sale";
  const forSaleButtonClasses = "btn btn-warning";
  let forSaleButtonHandler = onChangeType;

  if (isForSale) {
    forSaleButtonText = 'Statistics';
    forSaleButtonHandler = onChangeType;
  }

  return (
    <div className="panel rounded shadow">
      <div className="panel-body">
        <div className="inner-all">
          <ul className="list-unstyled">
            <div className="social">
              {facebook && <a href={facebook} target="blank"><i className="fa fa-facebook" /></a>}
              {twitter && <a href={twitter} target="blank"><i className="fa fa-twitter" /></a>}
            </div>
            <li className="text-center avatar">
              <img className="img-circle img-bordered-default" src={avatar} height="130" width="130" alt={firstName} onError={setDefaultAvatar} />
            </li>
            <li className="text-center fullname">
              <h4 className="text-capitalize">
                {firstName
                  ? <span title={`${firstName} ${lastName}`.length > 18 ? `${firstName} ${lastName}` : ''}>{firstName} {lastName}</span>
                  : <span>Anonymous</span>
                }
              </h4>
              <p className="text-muted text-capitalize">{role}</p>
              <p className="bio">{bio}</p>
            </li>
            <li>
              <div className="btn-block profile-btns">
                {isFollowing &&
                  <Link
                    className="send-message btn btn-default"
                    title="Send message"
                    to={`/chat?room=${id}`}
                  >Send Message
                  </Link>
                }

                {!isCurrentUser && isConsultant &&
                  <a href userid={id} onClick={followButtonHandler} className={followButtonClasses}>{followButtonText}</a>
                }

                {
                  allowForSale && isConsultant &&
                  <a
                    href
                    id={isForSale ? 'stats' : 'for-sale'}
                    onClick={forSaleButtonHandler}
                    className={forSaleButtonClasses}
                  >
                    {forSaleButtonText}
                  </a>
                }
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
};

export default UserInfo;

UserInfo.propTypes = {
  allowForSale: PropTypes.bool,
  avatar: PropTypes.string,
  bio: PropTypes.string,
  facebook: PropTypes.string,
  firstName: PropTypes.string,
  id: PropTypes.string,
  isCurrentUser: PropTypes.bool,
  isFollowing: PropTypes.bool,
  isForSale: PropTypes.bool,
  lastName: PropTypes.string,
  onChangeType: PropTypes.func,
  onFollowUser: PropTypes.func,
  onUnFollowUser: PropTypes.func,
  role: PropTypes.string,
  twitter: PropTypes.string
}

