export const dashboardIntro = {
  steps: [
    {
      intro: 'Welcome to SportsTrader®! Let us introduce the app and show you main features, click "next" button to continue...',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#home-page',
      intro: 'Here you can see your pick feed. Every picks that created by consultant that you follow will be visible here. You will be able to buy their picks and also watch content related to a game picked.',
      position: 'right',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#odds-page',
      intro: 'Here you can find odds. You can view odds, games in progress and games results on this page. Past games will be available for 24 hours on this page.',
      position: 'right',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#picks-page',
      intro: 'Here you can create your picks. When you select an odds that you would like to pick and hit "Save Pick", your selection will be stored in our database, but it will be visible to you only. In order to start selling your pick, you need to click "Complete Picks" when it appears and fill in price field.',
      position: 'right',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#consultants-page',
      intro: 'Here you can find consultants. In order to receive new picks in your pick feed, you need to follow consultants that create them. Here you can find the best consultant on our platform. They will have a badge like: "Rising Start" and "Top Rated". But also you can find the best consultants by sport on this page and follow them as well.',
      position: 'right',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#myPicks',
      intro: 'Here you can find your picks. When you buy or create a public pick it will be available on this page. There are going to be 3 tabs, Pre-Game: these are games that did not started yet; In Progress: games that are running right now and their current results; Final - games that are over, you can find your ROI and stats on this tab.',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#myFriends',
      intro: 'Here you can find your friends. You will be able to see all users that you follow on this page. There you can look up their stats and unfollow if you need to.',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#tour-5',
      intro: 'Here you can find your notifications. All important events that are happening to your account will be on this dropdown.',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#tour-6',
      intro: 'Here you can set up your profile. You can change avatar, edit your name and bio. Let users know who you are in order to get more tracktion on the platform.',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    }
  ]
};

export const profileIntro = {
  steps: [
    {
      element: '#become-consultant',
      intro: 'Click here to become a consultant. If you want to switch your account from user to consultant, click this button. There you will have to fill in your verified PayPal account and start selling picks.',
      position: 'right',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#edit-profile',
      intro: 'Here you can edit your profile. Fill in as much information as possible so other users would know you better.',
      position: 'right',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#followers-stats',
      intro: 'Here is a number of followers that you have.',
      position: 'right',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#roi-on-total-stats',
      intro: 'Here is going to be ROI (Return On Investment) on Total for all your created or bought picks',
      position: 'right',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#deviation-on-total-stats',
      intro: 'Here is going to be an average deviation per game on Total for all your created or bought picks. It can be read as how many points you are on average from the actual result on Total.',
      position: 'right',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#roi-on-spread-stats',
      intro: 'Here is going to be ROI (Return On Investment) on Spread for all your created or bought picks',
      position: 'right',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#deviation-on-spread-stats',
      intro: 'Here is going to be an average deviation per game on Spread for all your created or bought picks. It can be read as how many points you are on average from the actual result on Spread.',
      position: 'right',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#roi-on-moneyline-stats',
      intro: 'Here is going to be ROI (Return On Investment) on Money Line for all your created or bought picks',
      position: 'right',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#roi-stats',
      intro: 'Here going to be your overall ROI (Return On Investment). Sum of all individual ROIs',
      position: 'right',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#roi-on-drawline-stats',
      intro: 'Here is going to be ROI (Return On Investment) on Draw Line for all your created or bought picks',
      position: 'right',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    }
  ]
}

export const createPickIntro = {
  steps: [
    {
      element: '#pick-teams',
      intro: 'Here you can see teams for selected picks.',
      position: 'top',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#complete-pick-btn',
      intro: 'Right now your pick is Private and visible to you only. Click here to complete creating pick and start selling it. All your followers will see it in their pick feed.',
      position: 'top',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    }
  ]
};

export const completeCreatePickIntro = {
  steps: [
    {
      element: '#page-content > div.body-content.animated.fadeIn > div > div > div.col-lg-8.col-md-7.col-sm-7.col-sm-12 > table > tbody > tr > td > div.plus',
      intro: 'Click here to select pick you want to start selling. If you select more then one pick you will be making a parley.',
      position: 'left',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#page-content > div.body-content.animated.fadeIn > div > div > div.col-lg-4.col-md-5.col-sm-5.col-xs-12 > form > div.form-group.pick-price > input',
      intro: 'Here you can enter price for the pick. Minimum price is 5 USD.',
      position: 'left',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#contentUrl',
      intro: 'Here you can add picture, YouTube video or SoundCould audio url. We encorage you to fill in this field, as rich graphic content helps to sell more.',
      position: 'left',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#description',
      intro: 'Here you can add game descriptions and you thoughts about a game. Try to attract users and encourage them to buy your pick.',
      position: 'left',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#page-content > div.body-content.animated.fadeIn > div > div > div.col-lg-4.col-md-5.col-sm-5.col-xs-12 > form > button',
      intro: 'After you fill in all required fields, click this button. You picks will be posted to all your followers and they will be able to buy it.',
      position: 'left',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    }
  ]
};

export const becameConsultantIntro = {
  steps: [
    {
      intro: 'You have successfully become a consultant! Now you can create, sell and buy picks.',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    },
    {
      element: '#picks-page',
      intro: 'Here you can create your picks. When you select an odds that you would like to pick and hit "Save Pick", your selection will be stored in our database, but it will be visible to you only. In order to start selling your pick, you need to click "Complete Picks" when it appears and fill in price field.',
      position: 'right',
      highlightClass: 'highlighHint',
      tooltipClass: 'tooltipClass'
    }
  ]
};

export const introOptions = {'showStepNumbers': false, 'hidePrev': true, 'hideNext': true};
