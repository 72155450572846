import CONFIG from '../config/config';
import api from '../actions/api';
import { alertError } from '../actions/alerts'
import {
  fetchMatchBegin,
  fetchMatchSuccess,
  fetchMatchError,
  fetchMatchLiveDataBegin,
  fetchMatchLiveDataSuccess,
  fetchMatchLiveDataError
} from '../actions/matches';

let liveData = {};

export const getMatchInfo = async (dispatch, id) => {
  try {
    dispatch(fetchMatchBegin());
    const { data } = await api.get(`${CONFIG.api_url}/matches/${id}`);
    dispatch(fetchMatchSuccess(data.match));
  } catch (error) {
    console.log(error);  //eslint-disable-line no-console
    alertError(error)
    dispatch(fetchMatchError(error));
  }
}

export const getLiveMatchData = async (dispatch, id) => {
  try {
    dispatch(fetchMatchLiveDataBegin());
    let { data } = await api.get(`${CONFIG.api_url}/matches/${id}/results`);
    if (data.results.length == 0) {
      dispatch(fetchMatchLiveDataError('not found'));
    } else {
     const status = {
        type: {
          completed: data.results[0].FinalType == "Finished",
        }
      }
      dispatch(fetchMatchLiveDataSuccess(data.results[0], status ));
    }
   
  } catch (error) {
    console.log(error);  //eslint-disable-line no-console
    alertError(error)
    dispatch(fetchMatchLiveDataError(error));
  }
}

export const unsobscribeMatchLiveData = () => {
  if (liveData.hasOwnProperty('name')) {
    liveData.unsubscribe();
    return;
  }

  return;
}
