import swal from 'sweetalert2';
import { copyToClipboard } from "../helpers/copyToClipboard";


export function alertError(errObj) {
  if (errObj.response) {
    if (errObj.response.data.error) {
      swal(errObj.response.data.error, '', "error")
      return
    } else if (errObj.response.data) {
      swal(errObj.response.data, '', "error")
      return
    }

  } else if (typeof errObj === 'string') {
    swal(errObj, '', "error")
    return
  }
  swal('Network Error', '', "error")

}

export function alertErrorText(text) {
  swal(text, '', "error")
}

export function alertSuccess(text) {
  swal({ type: "success", timer: 2000, title: text, showConfirmButton: false });
}

export const alertSuccessWithConfirmaiton = async (title, text) => {
  return await swal({ title, type: "info", text, showConfirmButton: true, showCancelButton: true });
}

export const alertSuccessWithOk = async (title, text) => {
  return await swal({ title, type: "success", text, showConfirmButton: true });
}

export const alertCopyLink = async (title, link) => {
  return await swal({
    title,
    type: "success",
    html: `<div class="copy-to-buffer"><span>${link}</span></div>`,
    showConfirmButton: true,
    onOpen() {
      const el = document.querySelector('.copy-to-buffer');
      el.addEventListener('click', () => {
        copyToClipboard(link);
        el.classList.add('copied');
      });
    }
  });
}

export const emailMe = async (title, text) => {
  return await swal({
    title,
    type: "success",
    text,
    input: 'email',
    inputAttributes: {
      autocapitalize: 'off',
      required: true
    },
    inputPlaceholder: 'Your email',
    allowEnterKey: true,
    confirmButtonText: 'Send',
    showCloseButton: true,
    showConfirmButton: true
  });
}

