import React, { Component } from 'react';
import PropTypes from 'prop-types';


class FeeRow extends Component {
  static propTypes = {
    sales: PropTypes.object
  };

  render() {
    const { buyCount, price} = this.props.sales;
    const fee =  buyCount * Number.parseFloat(price) * 0.25;

    return (
      <tr>
        <td colSpan="1">-</td>
        <td colSpan="1">Fee</td>
        <td colSpan="3">SportsTrader® Selling Fee 20%</td>
        <td colSpan="1">-</td>
        <td colSpan="1">${Math.round(fee * 100) / 100}</td>
        <td colSpan="2">-</td>
        <td colSpan="2">-</td>
        <td colSpan="1">-</td>
      </tr>
    );
  }
}

export default FeeRow;
