import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i from 'immutable';
import { connect } from "../store/context";
import { getMatchInfo, getLiveMatchData, unsobscribeMatchLiveData } from "../api/matches";
import Banner from "../components/banner";
import Wrapper from '../components/ui/Wrapper';
import Loader from '../components/ui/Loader/index';
import Info from '../components/match-info/Info';


class MatchInfo extends Component {
  componentDidMount = async () => {
    const { match: { params: { id }}, dispatch } = this.props;
    await getMatchInfo(dispatch, id);
    getLiveMatchData(dispatch, id);
  }

  componentWillUnmount = () => {
    unsobscribeMatchLiveData();
  }

  render() {
    const { matchInfo, liveDataLoading = false } = this.props;

    const {
      AwayTeam: teamAway = 'No data',
      HomeTeam: teamHome = 'No data'
    } = matchInfo;

    return (
      <Wrapper
        className="match-info-page"
        title={`${teamAway} - ${teamHome}`}
        icon="fa fa-futbol-o"
        showAdBlockAlert
      >
        {liveDataLoading && <Loader />}
        {!liveDataLoading &&
          <>
            <Banner />
            <Info matchInfo={matchInfo} />
          </>
        }
      </Wrapper>
    );
  }
}

function select(state) {
  const matchInfo = state.getIn(['matches', 'matchInfo'], i.Map()).toJS();
  const liveDataLoading = state.getIn(['matches', 'liveDataLoading'], false);
  return { matchInfo, liveDataLoading }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(MatchInfo);

MatchInfo.propTypes = {
  dispatch: PropTypes.func,
  liveDataLoading: PropTypes.bool,
  match: PropTypes.object,
  matchInfo: PropTypes.object
};
