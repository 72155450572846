import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from "../../../store/context";
import { resendConfirmationEmail } from '../../../api/users';
import "./styles.scss";


class ConfirmEmailNotification extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    emailVerified: PropTypes.bool
  };

  handleResendEmail = async () => {
    const { dispatch, location: { pathname }  } = this.props;
    resendConfirmationEmail(dispatch, pathname);
  }

  render() {
    const { emailVerified } = this.props;

    if (emailVerified || emailVerified === null) {
      return null;
    }

    return (
      <div className="incomplete-notification">
        <div className="box">
          <div className="teams">
            Your email is not confirmed. In order to use platform please check your inbox (spam/promotion) and click on confirmation link.
          </div>
          <button onClick={this.handleResendEmail} className="btn btn-warning btn-sm">Resend email </button>
        </div>
      </div>
    );
  }
}

function select(state) {
  const emailVerified = state.getIn(['users', 'profile', 'emailVerified'], null);
  return { emailVerified };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default withRouter(connect(select, mapDispatchToProps)(ConfirmEmailNotification));
