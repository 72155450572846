import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


class AffiliateUser extends Component {
  static propTypes = {
    user: PropTypes.object
  };

  render() {
    const { id, firstName, lastName, createdAt } = this.props.user;

    return (
      <tr>
        <td>
          <Link to={`/user/${id}`}>
            {firstName ? firstName : 'Unknown'} {lastName}
          </Link>
        </td>
        <td>{moment(createdAt).format('LLL')}</td>
      </tr>
    );
  }
}

export default AffiliateUser;
