import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { connect } from "../../store/context";
import { updateProfile } from "../../api/users";
import { getAPIContentfeed } from '../../api/content-feed'


class General extends Component {
  state = {
    betsType: this.props.betsType,
    allowAnonimusFeed: this.props.allowAnonimusFeed,
    formChanged: false,
    emailNewPick: this.props.emailNewPick,
    emailMatchStarted: this.props.emailMatchStarted,
    emailNewMessage: this.props.emailNewMessage,
    emailStats: this.props.emailStats
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { dispatch, id } = this.props;
    const { betsType, allowAnonimusFeed, emailNewPick, emailMatchStarted, emailNewMessage, emailStats } = this.state;
    const params = { betsType, allowAnonimusFeed, emailNewPick, emailMatchStarted, emailNewMessage, emailStats };

    await updateProfile(dispatch, params);
    getAPIContentfeed(dispatch, id, 20, 0, true);
  }

  handleClickRadio = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      formChanged: true
    });
  }

  handleClickCheckbox = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
      formChanged: true
    });
  }

  render() {
    const {
      betsType, allowAnonimusFeed, formChanged, emailNewPick, emailMatchStarted, emailNewMessage, emailStats
    } = this.state;

    const { loading } = this.props;

    return (
      <form className="form-horizontal no-overflow" onSubmit={this.handleSubmit}>
        <div className="general">
          <div className="bet-type">
            <h5 className="title">Choose the format of bets</h5>
            <div className="input-group radio-wrap rdio rdio-theme circle">
              <input
                id="american"
                type="radio"
                name="betsType"
                className="form-control"
                value="american"
                checked={betsType === "american"}
                onChange={this.handleClickRadio}
              />
              <label className="control-label" htmlFor="american">American</label>
            </div>
            <div className="input-group radio-wrap rdio rdio-theme circle">
              <input
                id="decimal"
                type="radio"
                name="betsType"
                className="form-control"
                value="decimal"
                checked={betsType === "decimal"}
                onChange={this.handleClickRadio}
              />
              <label className="control-label" htmlFor="decimal">Decimal</label>
            </div>
          </div>

          <div className="anonimous-feed">
            <h5 className="title">Allow anonymous feed</h5>
            <p>This option gives you the possibility to see picks and parlays from all users, not only you following.</p>
            <div className="input-group radio-wrap ckbox ckbox-theme rounded">
              <input
                id="anonimousFeed"
                type="checkbox"
                name="allowAnonimusFeed"
                className="form-control"
                checked={Boolean(allowAnonimusFeed)}
                onClick={this.handleClickCheckbox}
              />
              <label className="control-label" htmlFor="anonimousFeed">Anonymous feed</label>
            </div>
          </div>

          <div className="email-notifications">
            <h5 className="title">Email notifications</h5>
            <p>This option gives you the possibility to enable or disable Email notifications. </p>
            <div className="input-group radio-wrap ckbox ckbox-theme rounded">
              <input
                id="emailNewPick"
                type="checkbox"
                name="emailNewPick"
                className="form-control"
                checked={Boolean(emailNewPick)}
                onClick={this.handleClickCheckbox}
              />
              <label className="control-label" htmlFor="emailNewPick">New Pick</label>
            </div>

            <div className="input-group radio-wrap ckbox ckbox-theme rounded">
              <input
                id="emailMatchStarted"
                type="checkbox"
                name="emailMatchStarted"
                className="form-control"
                checked={Boolean(emailMatchStarted)}
                onClick={this.handleClickCheckbox}
              />
              <label className="control-label" htmlFor="emailMatchStarted">Match started</label>
            </div>

            <div className="input-group radio-wrap ckbox ckbox-theme rounded">
              <input
                id="emailNewMessage"
                type="checkbox"
                name="emailNewMessage"
                className="form-control"
                checked={Boolean(emailNewMessage)}
                onClick={this.handleClickCheckbox}
              />
              <label className="control-label" htmlFor="emailNewMessage">New Message</label>
            </div>

            <div className="input-group radio-wrap ckbox ckbox-theme rounded">
              <input
                id="emailStats"
                type="checkbox"
                name="emailStats"
                className="form-control"
                checked={Boolean(emailStats)}
                onClick={this.handleClickCheckbox}
              />
              <label className="control-label" htmlFor="emailStats">Weekly Statistics</label>
            </div>
          </div>
        </div>

        <div className="navigation">
          <Link to="/profile" className="btn btn-default" >Cancel</Link>
          <button
            disabled={!formChanged || loading}
            type="submit"
            className={`btn btn-warning ${loading && 'loading'}`}
          >Save
          </button>
        </div>
      </form>
    );
  }
}


function select(state) {
  const id = state.getIn(['users', 'profile', 'id']);
  const betsType = state.getIn(['users', 'profile', 'betsType']);
  const emailNewPick = state.getIn(['users', 'profile', 'emailNewPick']);
  const emailMatchStarted = state.getIn(['users', 'profile', 'emailMatchStarted']);
  const emailStats = state.getIn(['users', 'profile', 'emailStats']);
  const emailNewMessage = state.getIn(['users', 'profile', 'emailNewMessage']);
  const allowAnonimusFeed = state.getIn(['users', 'profile', 'allowAnonimusFeed']);
  const loading = state.getIn(['users', 'loading'], false);

  return { id, betsType, allowAnonimusFeed, loading, emailNewPick, emailMatchStarted, emailNewMessage, emailStats }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(General);

General.propTypes = {
  allowAnonimusFeed: PropTypes.bool,
  betsType: PropTypes.string,
  dispatch: PropTypes.func,
  emailMatchStarted: PropTypes.bool,
  emailNewMessage: PropTypes.bool,
  emailNewPick: PropTypes.bool,
  emailStats: PropTypes.bool,
  id: PropTypes.number,
  loading: PropTypes.bool
};
