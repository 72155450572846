import React from 'react';
import PropTypes from 'prop-types';
import ReactHighcharts from 'react-highcharts';


const PicksChart = (props) => {
  const { dates, win, total } = props;
  const series = [{
    type: 'column',
    name: 'Win',
    data: win
  },
  {
    type: 'column',
    name: 'Total',
    data: total
  }]

  const config = {
    chart: {
      height: 300,
      type: 'line'
    },
    xAxis: {
      categories: dates
    },
    yAxis: {
      title: false
    },
    title: {
      text: 'Picks'
    },
    series
  };
  return (
    <div className="chart-wrap">
      <ReactHighcharts config={config} />
    </div>
  );
}

export default PicksChart;

PicksChart.propTypes = {
  dates: PropTypes.array.isRequired,
  total: PropTypes.array.isRequired,
  win: PropTypes.array.isRequired
}
