import React,  { Component } from 'react';
import i from 'immutable';
import PropTypes from 'prop-types';
import moment from 'moment';
import MediaQuery from 'react-responsive';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, RedditShareButton, RedditIcon } from 'react-share';
import CONFIG from '../config/config';
import ProfilePick from "../components/users/ProfilePick/index";
import { connect } from "../store/context";
import { getMyParlaysPregame, getMyParlaysInprogress, getMyParlaysFinal } from '../api/parlays';
import { fetchMyParlaysPregameBegin } from '../actions/parlays';
import Wrapper from '../components/ui/Wrapper';
import Paginator from '../components/general/Paginator';
import { promoteContent } from "../api/payments";
import { getProfile } from "../api/users";
import { alertSuccessWithConfirmaiton } from '../actions/alerts';
import EmptyPage from '../components/general/EmptyPage';
import catchRunTimeError from '../util/ds-error';
import Loader from '../components/ui/Loader/index';


class MyParlays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parlayType: 'pregame',
      parlaysPerPage: 10
    }
  }

  componentDidMount() {
    const { id } = this.props.profile;
    const { dispatch, pregameParlays } = this.props;

    // begin loader if preGames not loaded yet
    if (!pregameParlays.length) {
      dispatch(fetchMyParlaysPregameBegin());
    }

    if (id) {
      this.getData(id);
    }
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.profile;

    if (id !== prevProps.profile.id) {
      this.getData(id);
    }
  }

  componentWillUnmount = () => {
    // this.preGame.unsubscribe();
    // this.inProgress.unsubscribe();
    // this.client.close();
  }

  getData = (id) => {
    const { dispatch } = this.props;
    const { parlaysPerPage } = this.state;

    // this.client.event.emit('getParlaysPreGame', id);
    // this.client.event.emit('getParlaysInProgress', id);
    // this.client.event.emit('getParlaysFinal', id);

    // this.preGame = this.client.record.getRecord(`parlays/${id}/pregame`);
    // this.preGame.subscribe((data) => {
    //   getMyParlaysPregame(dispatch, data.parlays);
    // });

    // this.inProgress = this.client.record.getRecord(`parlays/${id}/inprogress`);
    // this.inProgress.subscribe((data) => {
    //   getMyParlaysInprogress(dispatch, data.parlays);
    // });

    getMyParlaysFinal(dispatch, 1, parlaysPerPage);
  }

  handleClickOnTab = (e) => {
    e && e.preventDefault();
    const { history } = this.props;
    history.push('/my-parlays?page=1');
    this.setState({ parlayType: e.currentTarget.attributes.parlaytype.value });
  }

  handlePromoteContent = async (e) => {
    const { dispatch } = this.props;
    const parlayId = e.target.id;

    const result = await alertSuccessWithConfirmaiton('Parlay promotion confirmation',
      `Please confirm that you wish to promote this Parlay for ${CONFIG.promotionPeriod} days to all users on SportsTrader® platform at ${CONFIG.promotionPrice} USD`);

    const { id } = this.props.profile;

    if (result.value) {
      await promoteContent(dispatch, parlayId, 'Parlay');
      // this.client.event.emit('getParlaysPreGame', id);
      getProfile(dispatch);
      this.getData(id);
    }
  }

  onClickPagination = async (event) => {
    const page = event.target.innerText;
    const { parlayType, parlaysPerPage } = this.state;
    const { pregameParlays, inprogressParlays, history, finalParlaysPages, dispatch } = this.props;
    let pages = 0;

    if (parlayType === 'inprogress') {
      pages = Math.ceil(inprogressParlays.length / parlaysPerPage);
    } else if (parlayType === 'pregame') {
      pages = Math.ceil(pregameParlays.length / parlaysPerPage);
    } else {
      pages = finalParlaysPages;
    }

    let newPage = 1;

    if (page === 'First') {
      newPage = 1;
    } else if (page === 'Last') {
      newPage = pages;
    } else {
      newPage = Number(page) || 1;
    }

    history.push(`/my-parlays?page=${newPage}`);

    if (parlayType === 'final') {
      getMyParlaysFinal(dispatch, newPage, parlaysPerPage);
    }
  }

  render() {
    const { parlayType, parlaysPerPage } = this.state;
    const { id } = this.props.profile;
    const { pregameParlays, inprogressParlays, finalParlays, totalParlaysSize, loadingPreGame, location, finalParlaysCount, finalParlaysPages, loadingFinal } = this.props;
    const loading = loadingPreGame || loadingFinal;
    const { page = 1 } = queryString.parse(location.search);
    let parlays = pregameParlays;
    const winClass = 'label label-success text-capitalize pull-right';
    const lostClass = 'label label-danger text-capitalize pull-right';

    switch (parlayType) {
      case 'inprogress':
        parlays = inprogressParlays;
        break;
      case 'final':
        parlays = finalParlays;
        break;
      default:
        break;
    }

    let pages = 0;

    if (parlayType === 'final') {
      pages = finalParlaysPages;
    } else {
      pages = Math.ceil(parlays.length / parlaysPerPage);
      parlays = parlays.slice((page * parlaysPerPage) - parlaysPerPage, page * parlaysPerPage);
    }

    const parlaysItems = parlays.map((parlay) => (
      <div className="parlay" key={parlay.id}>
        {parlay.Picks.length > 0 &&
          <div className="info">
            <div className="item">
              <div className="title">Parlay name:</div>
              <div className="value">{parlay.Name}</div>
            </div>
            {parlay.status &&
              <div className="item">
                <div className="title">Status:</div>
                <div className="value">
                  <span className={parlay.ROI > 0 ? winClass : lostClass}>{parlay.status.toUpperCase()}</span>
                </div>
              </div>
            }
            {parlay.ROI &&
              <div className="item">
                <div className="title">ROI:</div>
                <div className="value">{parlay.ROI.toFixed(2)}</div>
              </div>
            }
            <div className="item">
              <div className="title">Created by:</div>
              <div className="value" title={`${parlay.Picks[0].Owner.firstName} ${parlay.Picks[0].Owner.lastName || ''}`}>
                {parlay.Picks[0] &&
                  <Link to={`/user/${parlay.OwnerId}`}>
                    {`${parlay.Picks[0].Owner.firstName} ${parlay.Picks[0].Owner.lastName || ''}`}
                  </Link>
                }
              </div>
            </div>
            <div className="item">
              <div className="title">Created at:</div>
              <div className="value" title={moment(parlay.createdAt).format("MM/DD/YYYY h:mm A")}>
                {moment(parlay.createdAt).format("MM/DD/YYYY h:mm A")}
              </div>
            </div>
            {parlay.OwnerId === id && parlayType === 'pregame' && !parlay.Sponsored && !parlay.Picks.some((pick) => pick.Match.MatchTime < moment().add(20, 'minutes')._d) &&
              <button id={parlay.id} onClick={this.handlePromoteContent} className="btn btn-warning" type="button">Promote</button>
            }
            <div className="socialShareWrap">
              <FacebookShareButton
                url={`${CONFIG.ui_url}/parlay/${parlay.id}`}
                quote={parlay.Name}
                hashtag="#sportstrader"
              >
                <FacebookIcon
                  size={28}
                  round
                />
              </FacebookShareButton>

              <TwitterShareButton
                url={`${CONFIG.ui_url}/parlay/${parlay.id}`}
                title={parlay.Name}
                hashtags={['#sportstrader']}
              >
                <TwitterIcon
                  size={28}
                  round
                />
              </TwitterShareButton>

              <RedditShareButton
                url={`${CONFIG.ui_url}/parlay/${parlay.id}`}
                title={parlay.Name}
              >
                <RedditIcon
                  size={28}
                  round
                />
              </RedditShareButton>
            </div>
          </div>
        }
        <ProfilePick picks={parlay.Picks} pickType={parlayType} hideInfo />
      </div>
    ));

    return (
      <Wrapper
        className="myparlays-page"
        title="My parlays"
        counter={totalParlaysSize}
        icon="fa fa-diamond"
      >
        <MediaQuery maxDeviceWidth={992}>
          <Link to="/my-picks/pregame" className="btn btn-default switch-btn"><i className="fa fa-diamond" />My Picks</Link>
        </MediaQuery>
        <div className="panel panel-tab panel-tab-double shadow">
          <div className="panel-heading no-padding">
            <ul className="nav nav-tabs">
              <li className={`nav-border nav-border-top-danger ${parlayType === 'pregame' ? 'active' : ''}`}>
                <a href onClick={this.handleClickOnTab} parlaytype="pregame" data-toggle="tab" aria-expanded="true">
                  <i className="fa fa-calendar fg-danger" />
                  <div>
                    <span className="text-strong">Pre-Game ({pregameParlays.length})</span>
                    <span>Not Started parlays</span>
                  </div>
                </a>
              </li>
              <li className={`nav-border nav-border-top-warning ${parlayType === 'inprogress' ? 'active' : ''}`}>
                <a href onClick={this.handleClickOnTab} parlaytype="inprogress" data-toggle="tab" aria-expanded="true">
                  <i className="fa fa-clock-o fg-warning" />
                  <div>
                    <span className="text-strong">In Progress ({inprogressParlays.length})</span>
                    <span>Parlays in progress</span>
                  </div>
                </a>
              </li>
              <li className={`nav-border nav-border-top-success ${parlayType === 'final' ? 'active' : ''}`}>
                <a href onClick={this.handleClickOnTab} parlaytype="final" data-toggle="tab" aria-expanded="true">
                  <i className="fa fa-flag-checkered fg-success" />
                  <div>
                    <span className="text-strong">Final ({finalParlaysCount})</span>
                    <span>Finished parlays</span>
                  </div>
                </a>
              </li>
            </ul>
            <MediaQuery minDeviceWidth={993}>
              <Link to="/my-picks/pregame" className="btn btn-default switch-btn"><i className="fa fa-diamond" />My Picks</Link>
            </MediaQuery>
          </div>
          {loading && <Loader />}
          {!loading && !parlaysItems.length && <EmptyPage />}
          {!loading && parlaysItems}
        </div>
        <Paginator pages={pages} currentPage={Number(page) || 1} handleClick={this.onClickPagination} />
      </Wrapper>
    );
  }
}

function select(state) {
  const profile = state.getIn(['users', 'profile'], i.Map()).toJS();
  const pregameParlays = state.getIn(['parlays', 'pregameParlays'], i.List()).toJS();
  const inprogressParlays = state.getIn(['parlays', 'inprogressParlays'], i.List()).toJS();
  const finalParlays = state.getIn(['parlays', 'finalParlays', 'parlays'], i.List()).toJS();
  const finalParlaysCount = state.getIn(['parlays', 'finalParlays', 'count'], 0);
  const finalParlaysPages = state.getIn(['parlays', 'finalParlays', 'pages'], 0);
  const totalParlaysSize = pregameParlays.length + inprogressParlays.length + finalParlaysCount;
  const loadingPreGame = state.getIn(['parlays', 'loadingPreGame'], false);
  const loadingFinal = state.getIn(['parlays', 'loading'], false);

  return { profile, pregameParlays, inprogressParlays, finalParlays, totalParlaysSize, loadingPreGame, finalParlaysCount, finalParlaysPages, loadingFinal }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(MyParlays);

MyParlays.propTypes = {
  dispatch: PropTypes.func,
  finalParlays: PropTypes.array,
  finalParlaysCount: PropTypes.number,
  finalParlaysPages: PropTypes.number,
  history: PropTypes.object,
  id: PropTypes.number,
  inprogressParlays: PropTypes.array,
  loadingFinal: PropTypes.bool,
  loadingPreGame: PropTypes.bool,
  location: PropTypes.object,
  pregameParlays: PropTypes.array,
  profile: PropTypes.object,
  totalParlaysSize: PropTypes.number
};
