import React, { Component } from 'react';
import i from 'immutable';
import PropTypes from 'prop-types';
import { connect } from "../store/context";
import UserStatProfile from "../components/users/UserStatsProfile/index";
import { getConsultants, followUser, unfollowUser } from "../api/users";
import { getAPIContentfeed } from '../api/content-feed';
import Wrapper from '../components/ui/Wrapper'
import ConsultantSearch from '../components/search/ConsultantSearch/index'
import EmptyPage from '../components/general/EmptyPage'
import Loader from '../components/ui/Loader/index';
import { addFollowersCount, substractFollowersCount } from '../actions/users'


class Consultants extends Component {
  componentDidMount = () => {
    const { dispatch } = this.props;
    getConsultants(dispatch);
  }

  handleFollowUser = async (event) => {
    const { dispatch, users, profile: { id } } = this.props;
    const followingId = event.target.attributes.userid.nodeValue
    await followUser(dispatch, followingId, users);
    dispatch(addFollowersCount(followingId));
    getAPIContentfeed(dispatch, id, 20, 0, true);
  }

  handleUnfollowUser = async (event) => {
    const { dispatch, followings, profile: { id } } = this.props;
    const followingId = event.target.attributes.userid.nodeValue
    await unfollowUser(dispatch, followingId, followings);
    dispatch(substractFollowersCount(followingId));
    getAPIContentfeed(dispatch, id, 20, 0, true);
  }

  render() {
    const { users, followingIds, isAuthorized, Sports, profile, loading } = this.props;

    return (
      <Wrapper
        className="consultants-page"
        title="Top consultants"
        icon="fa fa-user"
        counter={5}
      >
        <div className="panel">
          <div className="panel-body">
            <ConsultantSearch Sports={Sports} />
            <div className="consultants">
              {loading && <Loader />}
              {!loading && !users.length && <EmptyPage />}
              {
                !loading && users.map((user) => {
                  const { id } = user;
                  return (
                    <UserStatProfile
                      key={id}
                      onFollowUser={this.handleFollowUser}
                      onUnFollowUser={this.handleUnfollowUser}
                      isFollowing={followingIds.includes(id)}
                      isAuthorized={isAuthorized}
                      isCurrentUser={profile.id === id}
                      {...user}
                    />
                  )
                })
              }
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

function select(state) {
  const users = state.getIn(['users', 'items'], i.List()).toJS();
  const followings = state.getIn(['users', 'profile', 'following'], i.List()).toJS();
  const followingIds = followings.map((user) => user.id);
  const isAuthorized = Boolean(state.getIn(['users', 'profile']));
  const Sports = state.getIn(['sports', 'items'], i.List()).toJS();
  const profile = state.getIn(['users', 'profile'], i.Map()).toJS();
  const loading = state.getIn(['users', 'loading'], false);

  return { users, followings, followingIds, isAuthorized, Sports, profile, loading }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Consultants);

Consultants.propTypes = {
  Sports: PropTypes.array
};
