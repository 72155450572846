import React, { Component } from "react";
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { alertError, alertSuccess } from '../../actions/alerts'
import CONFIG from '../../config/config';
import { isValidTextField, isFormValid } from "../../helpers/validators";
import Wrapper from '../../components/ui/Wrapper';


export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      errorMessage: {},
      validFields: { "email": false }
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });

    const { validFields, password } = this.state;
    const { isValidField, error } = isValidTextField(event.target.value, event.target.name, password);
    const errorMessage = {};
    const key = event.target.name;
    errorMessage[key] = error;

    if (isValidField) {
      validFields[key] = true;
    } else {
      validFields[key] = false;
    }

    this.setState({errorMessage, validFields});
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { email } = this.state;
      const { data } = await axios.post(`${CONFIG.api_url}/users/password/forgot`, { email });

      if (data.success) {
        alertSuccess('Please check your email to find confirmation link');
      }

      const { history } = this.props;
      history.push("/login");
    } catch (e) {
      console.log(e.response.data);  //eslint-disable-line no-console
      alertError(e)
    }
  }


  render() {
    const { errorMessage, validFields } = this.state;
    const formIsInvalid = isFormValid(validFields);

    return (
      <Wrapper
        className="forgot-password-page auth-page"
      >
        <div className="auth">
          <form className="sign-in form-horizontal shadow no-overflow" onSubmit={this.handleSubmit} method="post">
            <div className="sign-header">
              <div className="form-group">
                <div className="sign-text">
                  <span>Reset password</span>
                </div>
              </div>
            </div>
            <div className="sign-body">
              <div className={errorMessage.email ? "form-group has-error" : "form-group"}>
                <div className="input-group input-group-lg rounded no-overflow">
                  <input
                    type="text"
                    className="form-control input-sm"
                    placeholder="Email "
                    id="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    required
                  />
                  <span className="input-group-addon"><i className="fa fa-envelope fa-fw" /></span>
                </div>
                <span className="help-block">{errorMessage.email}</span>
              </div>
            </div>
            <div className="sign-footer">
              <div className="form-group">
                <div className="row">
                  <div className="col-xs-6">
                    <NavLink to="/signup">Sign Up (Free)</NavLink>
                  </div>
                  <div className="col-xs-6 text-right">
                    <NavLink to="/login">Login</NavLink>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button disabled={formIsInvalid} type="submit" className="btn btn-theme btn-lg btn-block no-margin rounded" id="login-btn">Send me a code</button>
              </div>
            </div>
          </form>
        </div>
      </Wrapper>
    );
  }
}
