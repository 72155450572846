import React, { Component } from "react";
import PropTypes from 'prop-types';
import './style.scss';
import { submitMessage } from "../../../api/chat";


class Sender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      maxLength: 1000,
      currentLength: 0,
      lockButton: true
    };
  }

  handleInputChange = (event) => {
    const { value } = event.target;
    let shouldLock = false;

    if (value.length > 1000) {
      shouldLock = true;
    }

    this.setState({ message: value, currentLength: value.length, lockButton: shouldLock });
  }


  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const createdAt = new Date();

    let { message } = this.state;
    message = message.trim();

    if (message.length >= 1) {
      submitMessage(this.props.currentRoom, this.props.userId, this.props.receiverId, message, createdAt, this.props.dispatch);
      this.setState({ message: '', currentLength: 0 });
    }
  }

  handleEnterPress = (event) => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault();
      this.handleSubmit(event);
    }
  }

  render() {
    const { maxLength, currentLength, lockButton } = this.state;
    const { blockedByMe, closedChat } = this.props;

    return (
      <form className="chat-sender" onSubmit={this.handleSubmit}>
        {blockedByMe && <p>Unblock the user to be able send a message</p>}
        {closedChat && <p>You cant send message, because the user removed you from followers</p>}
        {!blockedByMe && !closedChat && <>
          <div className="textarea-wrap">
            <textarea
              name="message-to-send"
              id="message-to-send"
              placeholder="Type your message"
              rows="3"
              maxLength="1000"
              min="1"
              value={this.state.message}
              onChange={this.handleInputChange}
              onKeyDown={this.handleEnterPress}
              required
            />
          </div>
          {/* <i className="fa fa-file-o" />&nbsp;&nbsp;&nbsp;
          <i className="fa fa-file-image-o" /> */}
          <div className="length-indicator">
            <span className={currentLength > 0 && currentLength <= maxLength ? 'correct-length' : 'incorrect-length'}>{currentLength}</span>
            <span> / </span>
            <span>{maxLength}</span>
          </div>
          <button disabled={lockButton} type="submit">Send</button>
        </>}
      </form>);
  }
}

export default Sender;

Sender.propTypes = {
  blockedByMe: PropTypes.bool,
  closedChat: PropTypes.bool,
  currentRoom: PropTypes.number,
  userId: PropTypes.number,
  dispatch: PropTypes.func,
  receiverId: PropTypes.array
};

