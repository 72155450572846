import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withRouter, Link } from "react-router-dom";
import i from 'immutable';
import { connect } from "../store/context";
import Wrapper from '../components/ui/Wrapper';
import Loader from '../components/ui/Loader/index';
import Information from '../components/edit-profile/Information';
import Socials from '../components/edit-profile/Socials';
import Avatar from '../components/edit-profile/Avatar';
import Password from "../components/edit-profile/Password";
import General from "../components/edit-profile/General";
import Email from "../components/edit-profile/Email";
import Subscription from "../components/edit-profile/Subscription";
import { isValidTextField, isFormValid } from "../helpers/validators";
// import {createRoom} from "../api/rooms"


class CreateRoom extends Component {
    state = {   
    formMessages: this.props.formMessages,
    validFields: this.props.validFields,
  };
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });

   

    const { validFields, formMessages } = this.state;
    // alert( event.target.value)
     if (event.target.name == 'roomName' && (event.target.value.length < 5 || event.target.value.length > 50)) {
        formMessages.roomName = 'Room name should be 5 to 50 chars long'
        validFields.roomName = false
    } else if(event.target.name == 'roomName') {
        formMessages.roomName = null
        validFields.roomName = true
    }
    if (event.target.name == 'roomDesc' && (event.target.value.length < 5 || event.target.value.length > 100)) {
        formMessages.roomDesc = 'Chat room about info should be 5 to 100 chars long'
        validFields.roomDesc = false
    } else if(event.target.name == 'roomDesc') {
        formMessages.roomDesc = null
        validFields.roomDesc = true
    }

    if (event.target.name == 'sportId' && event.target.value == null) {
        formMessages.sportId = 'Select relevant sport'
        validFields.sportId = false
    } else if(event.target.name == 'sportId') {
        formMessages.sportId = null
        validFields.sportId = true
    }
    this.setState({formMessages, validFields});
  }

  formIsDisabled() {
      const { validFields } = this.state;
      const { loading } = this.props;
      if (loading) {
          return true
      }
      for (const key in validFields) {
          if (!validFields[key])  {
              return true
          }
      }
      return false
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { dispatch, loading } = this.props;
    const { roomName, roomDesc, sportId } = this.state;
    // await createRoom(dispatch, {name: roomName, desc: roomDesc, sportId: sportId})
  }
  

  render() {
    const { formMessages, validFields } = this.state
    const { loading, Sports } = this.props
    return (
      <Wrapper
        className="edit-room-page"
        title="Create Room"
        icon="fa fa-fw fa-group"
      >
        
        <div className="row">
          <div className="col-lg-4 col-md-2 col-sm-1">
          </div>
            <div className="col-lg-5 col-md-8 col-sm-10">
                <form className="" onSubmit={this.handleSubmit}>
                    <div className="social">
                    <div className={formMessages.roomName ? "form-group has-error" : "form-group"}>
                        <label className="control-label" htmlFor="roomName">Room Name</label>
                        <div className="input-group no-overflow shadow">
                        <input
                            id="roomName"
                            name="roomName"
                            className="form-control"
                            placeholder="Relevant name for the room"
                            type="name"
                            onChange={this.handleChange}
                        />
                        <span className="input-group-addon"><i className="fa fa-group" /></span>
                        </div>
                        <span className="help-block">{formMessages.roomName}</span>
                    </div>
                    <div className={formMessages.roomDesc ? "form-group has-error" : "form-group"}>
                        <label className="control-label" htmlFor="roomDesc">About</label>
                        <div className="input-group no-overflow shadow">
                        <input
                            id="roomDesc"
                            type="name"
                            name="roomDesc"
                            className="form-control"
                            placeholder="Tell us about the purpose of this room"
                            onChange={this.handleChange}
                        />
                        <span className="input-group-addon"><i className="fa fa-group" /></span>
                        </div>
                        <span className="help-block">{formMessages.roomDesc}</span>
                    </div>
                    <div className="form-group">
                        <label className="control-label" htmlFor="roomDesc">Select sport</label>
                        <select className="form-control" id="sportId" onChange={this.handleChange} name="sportId">
                            <option disabled selected hidden>Sport</option>
                            {Sports.map((sport) => <option key={sport.id} value={sport.id}>{sport.Name.toUpperCase()}</option>)}
                        </select>
                        <span className="help-block">{formMessages.sportId}</span>
                    </div>
                    </div>

                    <div className="navigation">
                        <Link to="/profile" className="btn btn-default" >Cancel</Link>
                        <button
                            disabled={this.formIsDisabled()}
                            type="submit"
                            className={`btn btn-warning`}
                        >Save
                        </button>
                    </div>
                </form>
            </div>
            <div className="col-lg-4 col-md-2 col-sm-1">
            </div>
           
          
            </div>
      </Wrapper>
    );
  }
}


function select(state) {
  const loading = state.getIn(['rooms','loading'], false);
  const Sports = state.getIn(['sports', 'items'], i.List()).toJS();
  const formMessages = state.getIn(['formMessages'], {roomName: null, roomDesc: null, sportId: null});
  const validFields = state.getIn(['validFields'], { roomName: false, roomDesc: false, sportId: null });

  return { loading, formMessages, validFields, Sports  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(withRouter(CreateRoom));

CreateRoom.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
};
