import { Map, List, fromJS } from 'immutable';
import {
  FETCH_SPORT_BEGIN,
  FETCH_SPORT_SUCCESS,
  FETCH_SPORT_FAILURE,
  FETCH_SPORTS_BEGIN,
  FETCH_SPORTS_SUCCESS,
  FETCH_SPORTS_FAILURE
} from '../actions/sports';


const initialState = Map({
  items: List(),
  selectedSport: Map(),
  loading: false,
  error: null
});

export default function sportsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SPORT_BEGIN:
      state = state.set('loading', true);
      break;

    case FETCH_SPORT_SUCCESS:
      state = state.set('selectedSport', fromJS(action.data.sport.sports));
      state = state.set('loading', false);
      break;

    case FETCH_SPORT_FAILURE:
      state = state.set('error', action.error);
      break;

    case FETCH_SPORTS_BEGIN:
      state = state.set('loading', true);
      break;

    case FETCH_SPORTS_SUCCESS:
      state = state.set('items', fromJS(action.data.sports.sports));
      state = state.set('loading', false);
      break;

    case FETCH_SPORTS_FAILURE:
      state = state.set('error', action.error);
      break;

    default:
      return state;
  }

  return state;
}
