import { fromJS, Map, List, merge } from 'immutable';
import decode from 'jwt-decode';
import i from 'immutable';
import {
  FETCH_ROOMS_BEGIN,
  FETCH_ROOMS_SUCCESS,
  FETCH_ROOMS_FAILURE,
  ADD_MESSAGE_BEGIN,
  ADD_MESSAGE_SUCCESS,
  ADD_MESSAGE_FAILURE,
  ADD_CURRENT_ROOM_BEGIN,
  ADD_CURRENT_ROOM_SUCCESS,
  ADD_CURRENT_ROOM_FAILURE,
  ADD_MESSAGES_LOADED_STATUS_BEGIN,
  ADD_MESSAGES_LOADED_STATUS_SUCCESS,
  ADD_MESSAGES_LOADED_STATUS_FAILURE,
  ADD_ROOMS_LOADED_STATUS_BEGIN,
  ADD_ROOMS_LOADED_STATUS_SUCCESS,
  ADD_ROOMS_LOADED_STATUS_FAILURE,
  ADD_ROOMS_LISTEN_STATUS_BEGIN,
  ADD_ROOMS_LISTEN_STATUS_SUCCESS,
  ADD_ROOMS_LISTEN_STATUS_FAILURE,
  FETCH_ROOM_ID_BEGIN,
  FETCH_ROOM_ID_SUCCESS,
  FETCH_ROOM_ID_FAILURE,
  ADD_MESSAGES_BEGIN,
  ADD_MESSAGES_SUCCESS,
  ADD_MESSAGES_FAILURE,
  FETCH_ROOMS_STATE_BEGIN,
  FETCH_ROOMS_STATE_SUCCESS,
  FETCH_ROOMS_STATE_FAILURE,
  JOINED_ROOMS_BEGIN,
  JOINED_ROOMS_SUCCESS,
  JOINED_ROOMS_FAILURE,
  GET_ONLINE_USERS_BEGIN,
  GET_ONLINE_USERS_SUCCESS,
  GET_ONLINE_USERS_FAILURE,
  GET_ROOM_MEMBERS_BEGIN,
  GET_ROOM_MEMBERS_SUCCESS,
  GET_ROOM_MEMBERS_FAILURE,
  CREATE_ROOM_BEGIN,
  CREATE_ROOM_SUCCESS,
  CREATE_ROOM_FAILURE,
  FETCH_SPORT_ROOMS_BEGIN,
  FETCH_SPORT_ROOMS_SUCCESS,
  FETCH_SPORT_ROOMS_FAILURE,
  MARK_ROOM_AS_READ
} from '../actions/chat';


const initialState = Map({
  users: List(),
  roomsLoaded: false,
  roomsListen: false,
  roomsWithNewMessages: [],
  closedRooms: [],
  currentRoom: '',
  loading: false,
  error: null,
  currentUserId: null,
  limit: 20,
  offset: 0,
  messages: Map(),
  joinedRooms: List(),
  members: Map(),
});

export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ROOMS_BEGIN:
      state = state.set('loading', true);
      break;

    case FETCH_ROOMS_SUCCESS: {
      state = state.set('users', fromJS(action.rooms.users));
      state = state.set('loading', false);
      break;
    }
    case FETCH_ROOMS_FAILURE:
      state = state.set('error', action.error);
      break;

    case ADD_MESSAGE_BEGIN:
      // state = state.set('loading', true);
      break;

    case ADD_MESSAGE_SUCCESS: {
      const { roomId, message } = action.payload;
      const messages = state.getIn(['messages', roomId, 'data'], List());
      state = state.setIn(['messages', roomId, 'data'], messages.unshift(fromJS(message)));
      state = state.set('loading', false);
      break;
    }
    case ADD_MESSAGE_FAILURE:
      state = state.set('error', action.error);
      break;

    case ADD_CURRENT_ROOM_BEGIN:
      state = state.set('loading', true);
      break;

    case ADD_CURRENT_ROOM_SUCCESS:
      state = state.set('currentRoom', action.payload.roomId);
      state = state.set('loading', false);
      break;

    case ADD_CURRENT_ROOM_FAILURE:
      state = state.set('error', action.error);
      break;

    case ADD_MESSAGES_LOADED_STATUS_BEGIN:
      // state = state.set('loading', true);
      break;

    case ADD_MESSAGES_LOADED_STATUS_SUCCESS: {
      // const {roomId, status} = action.payload;
      // state = state.setIn(['messaegs', roomId, 'messagesLoaded'], status, false);
      break;
    }
    case ADD_MESSAGES_LOADED_STATUS_FAILURE:
      state = state.set('error', action.error);
      break;

    case ADD_ROOMS_LOADED_STATUS_BEGIN:
      state = state.set('loading', true);
      break;

    case ADD_ROOMS_LOADED_STATUS_SUCCESS:
      state = state.set('roomsLoaded', action.payload.status);
      break;

    case ADD_ROOMS_LOADED_STATUS_FAILURE:
      state = state.set('error', action.error);
      break;

    case ADD_ROOMS_LISTEN_STATUS_BEGIN:
      state = state.set('loading', true);
      break;

    case ADD_ROOMS_LISTEN_STATUS_SUCCESS:
      state = state.set('roomsListen', action.payload.status);
      break;

    case ADD_ROOMS_LISTEN_STATUS_FAILURE:
      state = state.set('error', action.error);
      break;

    case FETCH_ROOM_ID_BEGIN:
      state = state.set('loading', true);
      break;

    case FETCH_ROOM_ID_SUCCESS:
      state = state.set('currentRoom', action.data.currentRoom.room.id);
      state = state.set('currentUserId', action.data.userId);
      break;

    case FETCH_ROOM_ID_FAILURE:
      state = state.set('error', action.error);
      break;

    case ADD_MESSAGES_BEGIN:
      state = state.set('loading', true);
      break;

    case ADD_MESSAGES_SUCCESS: {
      const { roomId, messages, count } = action.data;
      let { offset } = action.data;
      let newMessages = state.getIn(['messages', roomId, 'data'], List())
      let newOffset = state.getIn(['messages', roomId, 'offset'], 0) + 20;
      let messageCount = state.getIn(['messages', roomId, 'count'], 0) + count;
      if (offset == 0) {
        newMessages = List()
        messageCount = count
        newOffset = 20
      }
      newMessages = newMessages.concat(fromJS(messages));
      state = state.setIn(['messages', roomId, 'offset'], newOffset);
      state = state.setIn(['messages', roomId, 'data'], newMessages);
      state = state.setIn(['messages', roomId, 'count'], messageCount);
      state = state.set('loading', false);
      //set online status
      let onlineUsers = state.getIn(['onlineUsers'], i.List()).toJS();
      let users = state.getIn(['users'], i.List()).toJS();

      for (let i = 0; i < onlineUsers.length; i++) {
        for (let ind = 0; ind < users.length; ind++) {
          if (users[ind].id == onlineUsers[i]) {
            users[ind].online = 1
          }
        }
      }
      state = state.setIn(['users'], fromJS(users));
      break;
    }

    case GET_ROOM_MEMBERS_BEGIN: {
      const { roomId } = action.payload;
      state = state.setIn(['members', roomId, 'loading'], true);
      break;
    }

    case GET_ROOM_MEMBERS_SUCCESS: {
      const { members } = action.payload.data;
      const { roomId } = action.payload
      state = state.setIn(['members', roomId, 'loading'], false);
      state = state.setIn(['members', roomId, 'data'], members);
      break;
    }

    case GET_ROOM_MEMBERS_FAILURE: {
      const { roomId } = action.payload;
      state = state.setIn(['members', roomId, 'loading'], false);
      break;
    }

    case GET_ONLINE_USERS_BEGIN:
      state = state.set('loading', true);
      break;

    case GET_ONLINE_USERS_SUCCESS:
      state = state.set('onlineUsers', fromJS(action.data.users));
      state = state.set('loading', false);
      break;

    case GET_ONLINE_USERS_FAILURE:
      state = state.set('error', action.error);
      break;
    case ADD_MESSAGES_FAILURE:
      state = state.set('error', action.error);
      break;

    case FETCH_ROOMS_STATE_BEGIN:
      break;

    case MARK_ROOM_AS_READ: {
      const { roomId } = action.payload;
      let unreadRooms = state.getIn(['roomsWithNewMessages']);
      let newUnreadRooms = []
      unreadRooms.forEach(room => {
        if (room.chatRoomId != roomId) {
          newUnreadRooms.push(room)
        }
      });
      state = state.set('roomsWithNewMessages', newUnreadRooms);
      break;
    }
    case FETCH_ROOMS_STATE_SUCCESS: {
      const { rooms } = action.data;
      let unreadRooms = state.getIn(['roomsWithNewMessages']);
      rooms.forEach(room => {
        unreadRooms.push(room)
      });
      state = state.set('roomsWithNewMessages', unreadRooms);
      break;
    }
    case FETCH_ROOMS_STATE_FAILURE:
      state = state.set('error', action.error);
      break;

    case JOINED_ROOMS_BEGIN:
      state = state.set('loading', true);
      break;
    case JOINED_ROOMS_SUCCESS:
      state = state.set(action.state, fromJS(action.rooms.rooms));
      state = state.set('loading', false);
      break;

    case JOINED_ROOMS_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loading', false);
      break;

    case CREATE_ROOM_BEGIN:
      state = state.set('loading', true);
      break;
    case CREATE_ROOM_SUCCESS:
      state = state.set('loading', false);
      break;

    case CREATE_ROOM_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loading', false);
      break;

    case FETCH_SPORT_ROOMS_BEGIN:
      state = state.set('loading', true);
      break;

    case FETCH_SPORT_ROOMS_SUCCESS:
      state = state.set('items', fromJS(action.rooms.rooms));
      state = state.set('loading', false);
      break;
    case FETCH_SPORT_ROOMS_FAILURE:
      state = state.set('error', action.error);
      break;

    default:
      return state;
  }

  return state;
}


const getClosedAndNewMessages = (rooms) => {
  const result = { usersWithNewMessages: [], closedRooms: [] }
  const myUserId = getMyUserId();

  for (const room in rooms) {
    if (rooms.hasOwnProperty(room)) {
      if (rooms[room]) {
        const receiverId = getReceiverId(room, myUserId);

        if (receiverId && rooms[room].newMessages) {
          result.usersWithNewMessages.push(receiverId);
        }

        if (receiverId && rooms[room].closed) {
          result.closedRooms.push(receiverId);
        }
      }
    }
  }

  return result;
}

const getMyUserId = () => {
  const jwt = localStorage.getItem('token');

  if (!jwt) {
    return null;
  }

  jwt.replace("Bearer", "");
  const { user_id } = decode(jwt);

  return user_id;
}

const getReceiverId = (room, myUserId) => {
  let receiverId;
  const array = room.split('-');
  array.map((element) => {
    if (Number(element) && Number(element) !== myUserId) {
      receiverId = element;
    }

    return element;
  });

  return Number(receiverId);
}
