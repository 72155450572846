import React from 'react';
import PropTypes from 'prop-types';


const Head = (props) => {
  const { competitions, status, sport, matchInfo } = props;
  const {HomeScore, AwayScore} = competitions;
  const scoreHome = HomeScore;
  const scoreAway = AwayScore;
  const teamHome = matchInfo.HomeTeam;
  const teamAway = matchInfo.AwayTeam;
  // const homeLogo = home.team.logo;
  // const awayLogo = away.team.logo;

  let periodType = '';

  switch (sport) {
    case 'soccer':
      periodType = 'Half'
      break
    case 'baseball':
      periodType = 'Innings'
      break
    case 'nba':
      periodType = 'Quarter'
      break
    default:
      periodType = 'Period';
      break
  }


  return (
    <div className="head">
      <div className="away-team">
        <div className="name">
          {/* {awayLogo && <img alt="away team logo" className="team-logo" src={awayLogo} />} */}
          {teamAway}
        </div>
        <div className="score">{scoreAway}</div>
      </div>
      <div className="separator">-</div>
      <div className="home-team">
        <div className="name">
          {/* {homeLogo && <img alt="home team logo" className="team-logo" src={homeLogo} />} */}
          {teamHome}
        </div>
        <div className="score">{scoreHome}</div>
      </div>
      <div className="clock">
        {status.type.completed
          ? <p>Finished</p>
          : <><p>{status.displayClock}</p><p className="minutes">{periodType} - {status.period}</p></>}
      </div>
    </div>
  );
}


export default Head;

Head.propTypes = {
  competitions: PropTypes.object,
  sport: PropTypes.string,
  status: PropTypes.object,
  matchInfo: PropTypes.object,
}
