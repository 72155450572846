const CONFIG = {}; //Make this global to use all over the application

CONFIG.app = process.env.REACT_APP_API_APP || 'dev';
CONFIG.api_schema = process.env.REACT_APP_API_SCHEMA || 'http';
CONFIG.api_host = process.env.REACT_APP_API_HOST || 'localhost';
CONFIG.api_version = process.env.REACT_APP_API_VERSION || 'v1';
CONFIG.react_share_url = process.env.REACT_APP_SHARE_URL || '';

CONFIG.ui_schema = process.env.REACT_APP_UI_SCHEMA || 'http';
CONFIG.ui_host = process.env.REACT_APP_UI_HOST || 'localhost';
CONFIG.ui_home = process.env.REACT_APP_UI_HOME || '/';
CONFIG.ui_unauth = process.env.REACT_UN_AUTH || '/login';

CONFIG.deepstream_url = process.env.REACT_APP_DEEPSTREAM_URL || 'localhost:6020';

CONFIG.showAdBlockAlert = process.env.REACT_APP_SHOW_ADBLOCK_ALERT === 'true'; // hide pages where we show ads, if user use adblock.

// dev
CONFIG.api_url = `${CONFIG.api_schema}://${CONFIG.api_host}/${CONFIG.api_version}`
CONFIG.ui_url = process.env.REACT_APP_DOMAIN_URL || `${CONFIG.ui_schema}://${CONFIG.ui_host}`
CONFIG.wss_client_connection_url = process.env.REACT_APP_WSS_CLIENT_CONNECTION_URL || '';
CONFIG.promotionPrice = process.env.REACT_PROMOTION_PRICE || 10;
CONFIG.promotionPeriod = process.env.REACT_PROMOTION_PERIOD || 3;

CONFIG.SENTRY_REPORTING_KEY = process.env.REACT_APP_SENTRY_ERROR_REPORTING_KEY || '';
CONFIG.FREEPICK_VIEW_COUNT = process.env.REACT_APP_FREEPICK_VIEW_COUNT || 2;
CONFIG.SUBSCRIPTION_PRICE = process.env.REACT_APP_SUBSCRIPTION_PRICE || 75

module.exports = CONFIG;
