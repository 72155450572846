import React, { Component } from 'react';
import PropTypes from 'prop-types';


export class TeamSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ''
    }
  }

  handleChange = (event) => {
    this.setState({search: event.target.value});

    if (event.target.value === '') {
      this.handleSearch();
    }
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  }

  handleSearch = () => {
    const { onClickSearch } = this.props;
    const { search } = this.state;
    onClickSearch(search);
  }

  render() {
    return (
      <div className="input-group mb-15">
        <input type="text" className="form-control rounded" placeholder="Filter by Team" onChange={this.handleChange} onKeyPress={this.handleKeyPress} />
        <span className="input-group-btn">
          <button className="btn btn-default" style={{borderRadius: 0}} type="button" onClick={this.handleSearch}>Search</button>
        </span>
      </div>
    );
  }
}

TeamSearch.propTypes = {
  onClickSearch: PropTypes.func
};
