import CONFIG from '../config/config';
import api from '../actions/api';
import { alertError, alertSuccess, alertErrorText, alertSuccessWithOk, alertSuccessWithConfirmaiton } from '../actions/alerts'
import {
  fetchRoomsBegin,
  fetchRoomsSuccess,
  fetchRoomsError,
  addMessageBegin,
  addMessageSuccess,
  addMessageError,
  addMessagesBegin,
  addMessagesSuccess,
  addMessagesError,
  addCurrentRoomBegin,
  addCurrentRoomSuccess,
  addCurrentRoomError,
  addMessagesLoadedStatusBegin,
  addMessagesLoadedStatusSuccess,
  addMessagesLoadedStatusError,
  fetchRoomIdBegin,
  fetchRoomIdSuccess,
  fetchRoomIdError,
  blockUserChatRoomBegin,
  blockUserChatRoomSuccess,
  blockUserChatRoomError,
  unblockUserChatRoomBegin,
  unblockUserChatRoomSuccess,
  unblockUserChatRoomError,
  fetchRoomsStateBegin,
  fetchRoomsStateSuccess,
  fetchRoomsStateError,
  joinedRoomsBegin,
  joinedRoomsSuccess,
  joinedRoomsError,
  getOnlineUsersBegin,
  getOnlineUsersSuccess,
  getOnlineUsersError,
  getRoomMembersBegin,
  getRoomMembersSuccess,
  getRoomMembersError,
  createRoomBegin,
  createRoomSuccess,
  createRoomFailure,
  fetchSportRoomsBegin,
  fetchSportRoomsSuccess,
  fetchSportRoomsError,
  markRoomAsRead
} from '../actions/chat';

const soundfile = 'https://s3.amazonaws.com/static.sportstrader.pro/sounds/chatSound.mp3'
const navigate = 'https://s3.amazonaws.com/static.sportstrader.pro/sounds/navigate.mp3'
const audio = new Audio(soundfile);
const navigateSound = new Audio(navigate)
try {

} catch (error) {
  console.log(error); // eslint-disable-line no-console
}

export const activateChatSound = async() => {
  audio.src = navigate
  await audio.play();
  audio.src = soundfile;
}


export const fetchRooms = async (dispatch, inData) => {
  try {
    let {sportId, type, roomId, limit, offset } = inData
    if (sportId == null) {
        sportId = 'all'
    }
    dispatch(fetchSportRoomsBegin());
    const { data } = await api.get(`${CONFIG.api_url}/chat/rooms/sport/${sportId}?limit=${limit}&offset=${offset}&roomId=${roomId}&type=${type}`);
    dispatch(fetchSportRoomsSuccess(data));

  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    dispatch(fetchSportRoomsError(error));
  }
}


export const createRoom = async (dispatch, postData) => {
  try {
      dispatch(createRoomBegin());
      const { data } = await api.post(`${CONFIG.api_url}/chat/rooms/sport`, postData);
      alertSuccess(data.message);
      dispatch(createRoomSuccess());

  } catch(error) {
      console.log(error); //eslint-disable-line no-console
      dispatch(createRoomFailure(error));
      alertError(error);
  }
}




export const fetchMessages = async (dispatch, roomId, limit, offset, refresh) => {
  try {
    dispatch(addMessagesBegin());
    const { data } = await api.get(`${CONFIG.api_url}/chat/messages/${roomId}?limit=${limit}&offset=${offset}`);
    const { rows, count } = data.messages;
    dispatch(addMessagesSuccess(rows, count, roomId, limit, offset));
    
    if (refresh) {
      try {
        await audio.play()
      } catch (error) {
        console.log('sound file play error: ', error.message)
      }
    }
    

  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    dispatch(addMessagesError(error));
  }
}


export const addMessage = (dispatch, message, roomId) => {
  try {
    dispatch(addMessageBegin());
    dispatch(addMessageSuccess(message, roomId));
    changeMessagesLoadedStatus(dispatch, roomId, true);
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    dispatch(addMessageError(error));
  }
}


export const changeMessagesLoadedStatus = (dispatch, roomId, status) => {
  try {
    dispatch(addMessagesLoadedStatusBegin());
    dispatch(addMessagesLoadedStatusSuccess(status, roomId));
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    dispatch(addMessagesLoadedStatusError(error));
  }
}


export const submitMessage = async (roomId, userId, receiverId, message, createdAt, dispatch) => {
  try {
    console.log(roomId)
    const messageObj = { roomId, message, UserId: userId, createdAt, receivers: receiverId };
    const { data } = await api.post(`/chat/messages/${roomId}`, messageObj);
    dispatch(addMessageBegin());
    dispatch(addMessageSuccess(data.row, roomId));
    changeMessagesLoadedStatus(dispatch, roomId, true);
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
  }
}

export const changeCurrentRoom = async (dispatch, roomId, rooms) => {
  try {
    dispatch(addCurrentRoomBegin());
    await dispatch(addCurrentRoomSuccess(roomId));

    if (rooms && !('messages' in rooms[roomId])) {
      fetchMessages(dispatch, roomId);
    }
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    dispatch(addCurrentRoomError(error));
  }
}

export const setUsersOnline = (dispatch, data) => {
  try {
    dispatch(getOnlineUsersBegin());
    dispatch(getOnlineUsersSuccess(data));
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    dispatch(getOnlineUsersError(error));
  }
}

export const getChatRoomId = async (dispatch, selectedUserId, chatRoomId) => {
  try {
    dispatch(fetchRoomIdBegin());
    if (typeof chatRoomId == 'undefined' ||  chatRoomId === 0) {
      let {data} =  await api.get(`${CONFIG.api_url}/chat/rooms/user/${selectedUserId}`);
      dispatch(fetchRoomIdSuccess(data, selectedUserId));
    } else {
      // action.data.userId
      let data = {room: {id: chatRoomId}}
      dispatch(fetchRoomIdSuccess(data, selectedUserId));
    } 
   
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(fetchRoomIdError(error));
  }
}



export const getUserPersonalRooms = async (dispatch) => {
  try {
    dispatch(fetchRoomsBegin());
    const timestamp = new Date().getTime();
    const { data } = await api.get(`${CONFIG.api_url}/chat/personal?time=${timestamp}`);
    dispatch(fetchRoomsSuccess(data));
  } catch (error) {
    // alertError(error)
    console.log(error); //eslint-disable-line no-console
    dispatch(fetchRoomsError(error));
  }
}

export const blockUserRoom = async (dispatch, id) => {
  try {
    dispatch(blockUserChatRoomBegin());

    const { data } = await api.patch(`${CONFIG.api_url}/chat/rooms/${id}/block`);

    if (data) {
      dispatch(blockUserChatRoomSuccess());
    }

    getUserPersonalRooms(dispatch)
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    dispatch(blockUserChatRoomError(error));
  }
}

export const unblockUserRoom = async (dispatch, id) => {
  try {
    dispatch(unblockUserChatRoomBegin());
    await api.patch(`${CONFIG.api_url}/chat/rooms/${id}/unblock`);
    dispatch(unblockUserChatRoomSuccess());

    getUserPersonalRooms(dispatch)
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    dispatch(unblockUserChatRoomError());
  }
}

export const leaveRoom = async (dispatch, id) => {
  try {
    // dispatch(unblockUserChatRoomBegin());
    const {data} = await api.patch(`${CONFIG.api_url}/chat/room/${id}/leave`);
    alertSuccess(data.message)
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
  }
}

export const deleteRoom = async (dispatch, id) => {
  try {
    const {data} = await api.delete(`${CONFIG.api_url}/chat/room/${id}/delete`);
    alertSuccess(data.message)
  } catch (error) {
    console.log(error.message)
    alertError(error)
  }
}

export const blockUnblockUser = async (dispatch, roomId, postData) => {
  try {
    // dispatch(unblockUserChatRoomBegin());
    const {data} = await api.patch(`${CONFIG.api_url}/chat/room/${roomId}/user`, postData);
    alertSuccess(data.message)
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error.message)
  }
}


export const subscribeRoomsState = async (dispatch, userId) => {
  try {
    dispatch(fetchRoomsStateBegin());
    const { data } = await api.get(`${CONFIG.api_url}/chat/unread/rooms/`);
    dispatch(fetchRoomsStateSuccess(data.rooms));
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    dispatch(fetchRoomsStateError(error));
  }
}

export const markRoomAsReaded = async (dispatch, roomId) => {
  try {
    dispatch(markRoomAsRead(roomId))
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
  }
}

export const getUserRooms = async (dispatch, status) => {
    try {
      let state = 'all'
      if (status == 2) {
        state = 'blockedRooms'
      } else if (status == 1) {
        state = 'joinedRooms'
      }
        dispatch(joinedRoomsBegin());
        const { data } = await api.get(`${CONFIG.api_url}/chat/rooms/${status}`);
        dispatch(joinedRoomsSuccess(data, state));

    } catch(error) {
        console.log(error); //eslint-disable-line no-console
        dispatch(joinedRoomsError(error));
    }
}

export const joinRoom = async (dispatch, postData) => {
  try {
      const { data } = await api.post(`${CONFIG.api_url}/join/room`, postData);
      return [data, null]

  } catch(error) {
    return [null, error]
  }
}

export const getRoomMembers = async (dispatch, roomId) => {
  try {
    dispatch(getRoomMembersBegin(roomId))
    const { data } = await api.get(`${CONFIG.api_url}/chat/room/${roomId}/members`);
    dispatch(getRoomMembersSuccess(data, roomId))

  } catch (error) {
    dispatch(getRoomMembersError(error, roomId))
  }
}