import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { EmptyCell, LinePickCell, PickCell } from "../../picks/PickCells";
import "./style.scss";


export const completeRow = (props) => {
  const { AwayTeam, AwayROT, HomeTeam, HomeROT, MatchTime, Sport } = props.Match;
  const { MoneyLine, PointSpread, Total, ROISpread, ROIMoneyLine, ROITotal, DeviationSpread = 0, DeviationTotal = 0, DrawLine, ROIDrawLine, SpreadCoef, MoneyLineCoef, TotalCoef, DrawCoef, TotalScores, Status, Price, BundleId } = props;
  const { AwayScore = 0, HomeScore = 0 } = props.Result;
  const { TotalNumber } = props.Odd;
  const winClass = 'label label-success text-capitalize pull-left';
  const lostClass = 'label label-danger text-capitalize pull-left';
  const pushClass = 'label label-warning text-capitalize pull-left';
  const cellWidth = DrawLine ? '3' : '4';

  const spreadCellName = () => {
    if (Sport === 5) { //hockey
      return 'Puck Line';
    }

    if (Sport === 0) { //baseball
      return 'Run Line';
    }

    return 'Spread'
  }

  const totalCellName = () => {
    if ([11, 23].includes(Sport)) { //['mma', 'wbc']
      return 'Rounds';
    }

    return 'Total'
  }

  const pickRowClass = () => {
    if (['Lost', 'Canceled'].includes(Status)) {
      return lostClass;
    } else if (Status === 'Win') {
      return winClass;
    } else if (Status === 'Push') {
      return pushClass;
    }

    return null;
  
  }
  return (
    <div className="pick">
      <table className="table user-pick shadow">
        <thead>
          <tr className="main-head">
            <th colSpan="6" className="teams text-left">
              <div>
                <span>
                  <span className="team-name">{AwayTeam}</span>
                  {AwayROT && <span className="team-number">({AwayROT})</span>}
                </span>
                <span className="scores">
                  {AwayScore} - {HomeScore}
                </span>
                <span>
                  <span className="team-name">{HomeTeam}</span>
                  {HomeROT && <span className="team-number">({HomeROT})</span>}
                </span>
              </div>
            </th>

            <th colSpan={1}>
              {BundleId
                ? <span className={pushClass}>Bundle</span>
                : <span className={pushClass}>{Price > 0 ? 'Premium' : 'Free'}</span>
              }
            </th>

            <th colSpan={1}>
              {/* <span className={pickRowClass()}>{Status}</span> */}
            </th>

            <th colSpan={4} className="date text-right">
              <span>Game Date: {moment(MatchTime).format("MM/DD/YYYY h:mm A")}</span>
            </th>
          </tr>
          <tr className="head">
            <th colSpan={cellWidth}>   
              {
              Boolean(ROISpread) ? <span className={ROISpread > 0 ? winClass : lostClass}>{ROISpread > 0 ? 'WON' : 'LOST'}</span> : ''
              }
              {
              (ROISpread === 0) ? <span className={pushClass}>{'PUSH'}</span>: ''
              }
              <span>{spreadCellName()}</span>
            </th>
            <th colSpan={cellWidth}>
              {Boolean(ROIMoneyLine) &&
                <span className={ROIMoneyLine > 0 ? winClass : lostClass}>{ROIMoneyLine > 0 ? 'WON' : 'LOST'}</span>
              }
              <span>Money Line</span>
            </th>
            <th colSpan={cellWidth}>
              {
              Boolean(ROITotal) ? <span className={ROITotal > 0 ? winClass : lostClass}>{ROITotal > 0 ? 'WON' : 'LOST'}</span> : ''
                
              }
              {
              (ROITotal === 0 && Boolean(Total)) ? <span className={pushClass}>{'PUSH'}</span>: ''
              }
              
              <span>{totalCellName()} O/U</span>
            </th>
            {Boolean(DrawLine) &&
              <th colSpan={cellWidth}>
                {ROIDrawLine &&
                  <span className={ROIDrawLine > 0 ? winClass : lostClass}>{ROIDrawLine > 0 ? 'WON' : 'LOST'}</span>
                }
                <span>Draw</span>
              </th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            {PointSpread ? <PickCell itemName={props[`${PointSpread}Team`]} itemNumber={props[`SpreadScores${PointSpread}`] || props[`PointSpread${PointSpread}`]} itemLine={SpreadCoef || props[`PointSpread${PointSpread}Line`]} colSpan={cellWidth} /> : <EmptyCell colSpan={cellWidth} />}
            {MoneyLine ? <LinePickCell itemName={props[`${MoneyLine}Team`]} itemLine={MoneyLineCoef || props[`MoneyLine${MoneyLine}`]} colSpan={cellWidth} /> : <EmptyCell colSpan={cellWidth} />}
            {Total ? <LinePickCell itemName={`${Total} ${TotalScores || TotalNumber}`} itemLine={TotalCoef || props[`${Total}Line`]} colSpan={cellWidth} /> : <EmptyCell colSpan={cellWidth} />}
            {DrawLine && <LinePickCell itemName={`Draw`} itemLine={DrawCoef || props.Odd.DrawLine} colSpan={cellWidth} />}
          </tr>

          <tr>
            <td colSpan={cellWidth}>{ROISpread ? `ROI: ${ROISpread.toFixed(2)}, Deviation: ${DeviationSpread.toFixed(2)}` : "-"}</td>
            <td colSpan={cellWidth}>{ROIMoneyLine ? `ROI: ${ROIMoneyLine.toFixed(2)}` : "-"}</td>
            <td colSpan={cellWidth}>{ROITotal ? `ROI: ${ROITotal.toFixed(2)}, Deviation: ${DeviationTotal ? DeviationTotal.toFixed(2) : 0}` : "-"}</td>
            {DrawLine && <td colSpan={cellWidth}>{ROIDrawLine ? `ROI: ${ROIDrawLine.toFixed(2)}` : "-"}</td>}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

completeRow.propTypes = {
  BundleId: PropTypes.number,
  DeviationSpread: PropTypes.number,
  DeviationTotal: PropTypes.number,
  DrawCoef: PropTypes.number,
  DrawLine: PropTypes.bool,
  Match: PropTypes.object,
  MoneyLine: PropTypes.string,
  MoneyLineCoef: PropTypes.number,
  Odd: PropTypes.object,
  PointSpread: PropTypes.string,
  Price: PropTypes.number,
  ROIDrawLine: PropTypes.number,
  ROIMoneyLine: PropTypes.number,
  ROISpread: PropTypes.number,
  ROITotal: PropTypes.number,
  Result: PropTypes.object,
  SpreadCoef: PropTypes.number,
  Status: PropTypes.string,
  SpreadScoresAway: PropTypes.number,
  SpreadScoresHome: PropTypes.number,
  Total: PropTypes.string,
  TotalCoef: PropTypes.number,
  TotalScores: PropTypes.number
};

