import React from "react";
import PropTypes from 'prop-types';
import moment from 'moment';
import { setDefaultAvatar } from '../../../helpers/defaultImage';
import './style.scss';


const Message = (props) => {
  const { createdAt, message } = props.message;
  const { avatar, id} = props.user;
  let { firstName, lastName } = props.user;
  const { userId } = props;
  firstName = firstName ? firstName : '';
  lastName = lastName ? lastName : '';
  const userName = `${firstName} ${lastName}`;

  let messageTime;
  const today = new Date().setHours(0);

  if (new Date(createdAt) > today) {
    messageTime = moment(createdAt).format('LT');
  } else {
    messageTime = moment(createdAt).format('LLL');
  }

  return (
    <li className={id === userId ? "message my-message" : "message"}>
      <div className="avatar">
        <img src={avatar} alt="avatar" width="50" height="50" onError={setDefaultAvatar} />
      </div>
      <div className="message-wrapper">
        <div className="data">
          <span className="name">{firstName || lastName ? userName : 'Anonymous'}</span>
          <span className="time">{messageTime}</span>
        </div>
        <div className="text">{message}</div>
      </div>
    </li>
  );
}

export default Message;

Message.propTypes = {
  message: PropTypes.object,
  user: PropTypes.object,
  userId: PropTypes.number
};
