export const GET_NOTIFICATIONS_BEGIN   = 'GET_NOTIFICATIONS_BEGIN';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';
export const READ_NOTIFICATIONS_BEGIN   = 'READ_NOTIFICATIONS_BEGIN';
export const READ_NOTIFICATIONS_SUCCESS = 'READ_NOTIFICATIONS_SUCCESS';
export const READ_NOTIFICATIONS_FAILURE = 'READ_NOTIFICATIONS_FAILURE';
export const DELETE_NOTIFICATION_BEGIN   = 'DELETE_NOTIFICATION_BEGIN';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';
export const DELETE_ALL_NOTIFICATIONS_BEGIN   = 'DELETE_ALL_NOTIFICATIONS_BEGIN';
export const DELETE_ALL_NOTIFICATIONS_SUCCESS = 'DELETE_ALL_NOTIFICATIONS_SUCCESS';
export const DELETE_ALL_NOTIFICATIONS_FAILURE = 'DELETE_ALL_NOTIFICATIONS_FAILURE';

export const getNotificationsBegin = () => ({
  type: GET_NOTIFICATIONS_BEGIN
});

export const getNotificationsSuccess = (notifications) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  data: { notifications }
});

export const getNotificationsError = (error) => ({
  type: GET_NOTIFICATIONS_FAILURE,
  data: { error }
});

export const readNotificationsBegin = () => ({
  type: READ_NOTIFICATIONS_BEGIN
});

export const readNotificationsSuccess = (notifications) => ({
  type: READ_NOTIFICATIONS_SUCCESS,
  data: { notifications }
});

export const readNotificationsError = (error) => ({
  type: READ_NOTIFICATIONS_FAILURE,
  data: { error }
});

export const deleteNotificationBegin = (id) => ({
  type: DELETE_NOTIFICATION_BEGIN,
  data: { id }
});

export const deleteNotificationSuccess = () => ({
  type: DELETE_NOTIFICATION_SUCCESS
});

export const deleteNotificationError = (error) => ({
  type: DELETE_NOTIFICATION_FAILURE,
  data: { error }
});

export const deleteAllNotificationsBegin = () => ({
  type: DELETE_ALL_NOTIFICATIONS_BEGIN
});

export const deleteAllNotificationsSuccess = () => ({
  type: DELETE_ALL_NOTIFICATIONS_SUCCESS
});

export const deleteAllNotificationsError = (error) => ({
  type: DELETE_ALL_NOTIFICATIONS_FAILURE,
  data: { error }
});
