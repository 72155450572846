import CONFIG from '../config/config';
import api from '../actions/api';
import { alertError, alertSuccess } from '../actions/alerts'
import {
  fetchBundleBegin,
  fetchBundleSuccess,
  fetchBundleError,
  createBundleBegin,
  createBundleSuccess,
  createBundleError,
  bundlePaymentRequired
} from '../actions/bundles';


export const getBundle = async (dispatch, id) => {
  try {
    dispatch(fetchBundleBegin());
    const timestamp = new Date().getTime();
    const { data } = await api.get(`${CONFIG.api_url}/bundles/${id}?time=${timestamp}`);
    dispatch(fetchBundleSuccess(data.bundle));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console

    if (error.response.status === 402) {
      dispatch(bundlePaymentRequired(error.response.data));
    } else {
      dispatch(fetchBundleError(error));
    }
  }
}

export const createBundle = async (dispatch, Picks, Price, Description, Url, Name) => {
  try {
    dispatch(createBundleBegin());
    const { data } = await api.post(`${CONFIG.api_url}/bundles`, { Picks, Price, Description, Url, Name});
    const timestamp = new Date().getTime();
    const { data: bundle } = await api.get(`${CONFIG.api_url}/bundles/${data.bundle.id}?time=${timestamp}`);
    alertSuccess('Your Bundle has been published!');
    dispatch(createBundleSuccess(bundle));
    return true;
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    alertError(error)
    dispatch(createBundleError(error));
    return false;
  }
}
