import React from "react";
import Header from '../components/landing/Header'
import Footer from '../components/landing/Footer'
import AboutUs from '../components/landing/AboutUs'
import Advantages from '../components/landing/Advantages'
import WhyWe from '../components/landing/WhyWe'
import StartNow from '../components/landing/StartNow'


const Landing = () => {
  return (
    <div className="landing">
      <Header />
      <AboutUs />
      <StartNow />
      <Advantages />
      <WhyWe />
      <StartNow />
      <Footer />
    </div>
  )
}

export default Landing;
