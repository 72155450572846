import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Wrapper from '../components/ui/Wrapper'


const TOS = () => {
  return (
    <Wrapper className="404-page" title="Terms of Service" icon="fa fa-lock">
      <h3>
        <b>
          Terms of Service
        </b>
      </h3>
      <p>
        Welcome to NXT TECH’S SportsTrader.pro. The following terms of service (the "TOS") apply to your use of these Website, including access to the Services (as defined below) and Content (as defined below) available through the Website. Please read these terms and conditions carefully before using the Website, accessing the Content and/or using the Services. Please note NXT TECH LLC owns and operates Sports Trader and https://sportstrader.pro along with all logos and marketing materials.
      </p>
      <p>
        <b>
          BY USING THE WEBSITE, THE SERVICES, OR THE CONTENT, YOU ARE CONSENTING TO THE TERMS AND CONDITIONS IN THIS TERMS OF SERVICE AGREEMENT AS THEY APPLY TO YOU AND YOUR USAGE AND ACCESS.
        </b>
      </p>
      <p>
        Your use of the Website, the Content, and the Services is also subject to all applicable laws and regulations. If you do not agree to any of the terms of service in this agreement, you should not use the Website, the Services, or the Content. Your use of the Website, the Content, and the Services is also governed by our <NavLink to="/privacy">Privacy Policy</NavLink>, which is available through a link on our homepage as well as most of the other pages on the Website
        We hope you enjoy SportsTrader.pro!
      </p>

      <h5>
        <b>
          1. TERMS OF SERVICE
        </b>
      </h5>
        SportsTrader.pro provide the Website, the Content, and the Services to you subject to the following Terms of Service ("TOS"). The TOS may be updated by us from time to time without notice to you. We suggest that from time to time you review the TOS for possible changes. In addition, when using any Services or accessing any Content, you will be subject to any posted guidelines or rules applicable to such Services or Content. All such additional guidelines or rules are hereby incorporated by reference into the TOS.

      <h5>
        <b>
          2. SERVICES AND CONTENT
        </b>
      </h5>
      SportsTrader.pro currently provides users with various features and services, including statistical review, contests, handicapper picks, and other interactive and non-interactive features, all of which may be updated, deleted, or otherwise modified from time to time at the discretion of SportsTrader.pro. In addition, SportsTrader.pro provides access to certain photographs, text, images, statistics, logos, and other media and intellectual property related to or otherwise associated with SportsTrader.pro. Unless stated otherwise, the Website, the Services, and the Content are subject to the TOS. You understand and agree that the Website, the Services, and the Content are provided "AS IS" and that SportsTrader.pro assume no responsibility in connection with your use of the Website, the Services, or the Content. The Website, the Services, and the Content are provided for your non-commercial entertainment and enjoyment. Under the TOS, you may download certain Content and Services available on the Website to a single personal computing device for your use and entertainment. However, you may not distribute, modify, republish, or publicly display any of the Content or Services unless you have the prior written permission of SportsTrader.pro, which permission may be withheld at SportsTrader.pro’s sole discretion.

      <h5>
        <b>
          2a. REFUNDS AND PAYMENTS
        </b>
      </h5>
      All refunds will be paid back to the member’s account and all refunds can only be used to buy services from
      SportsTrader.pro. If you are a consultant with a verified Paypal you will be able to request any money left in your SportsTrader® account. The minimum that can be requested is $50 USD. Once a consultant reaches $5,000 in sales and hasn’t requested for a withdrawal then SportsTrader® (Owned by NXT TECH LLC) will transfer your money right into your verified paypal account in order to make sure all consultants get paid on a timely basis. NXT TECH LLC will not allow anyone to hoard money into their SportsTrader® account. Users and consultants need to be mindful that Paypal will also take a 2.9%-3.2% fee and $.30 for each transaction in which the person depositing money with SportsTrader® or requesting a withdrawal will incur these fees. Consultants will not pay for these charges when requesting withdrawlers.

      <h5>
        <b>
          2b. DISPUTES
        </b>
      </h5>
      If any member that has a verified paypal account or is a user that has bought a pick and need additional support please contact us at info@sportstrader.pro and we will do our very best to fix your issue as prompt as we can. NXT TECH LLC will always strive for excellent customer support service and to make sure everyone gets paid on time. We will also work with Paypal if there is an issue on their end to make sure our customers feel secure on our platform.

      <h5>
        <b>
          2c. PAYMENTS AND AFFILIATE PROGRAM
        </b>
      </h5>
      NXT TECH LLC, Owner of SportsTrader®, will take a 20% fee for every transaction on sportstrader.pro. The paypal transaction cost will be paid by the buyer. When a consultant request for a withdrawal NXT TECH LLC will pay for any transaction cost and the consultant will receive their amount due minus our fee. Any member can join our referral program. We will provide a link that you can use to promote other users into the platform. For 1 full year any purchases from users that used your link  to sign up you will be giving 5% of all purchases from that user. Later on we have plans to expand our referral program to be much more lucrative for everyone involved on our platform.

      <h5>
        <b>
          3. DISCLAIMER OF WARRANTIES
        </b>
      </h5>
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT:
        a. YOUR USE OF THE WEBSITE, THE SERVICES AND THE CONTENT IS AT YOUR SOLE RISK. THE WEBSITE, THE SERVICES AND THE CONTENT ARE PROVIDED ON AN " IS" AND " AVAILABLE" BASIS. SportsTrader.pro EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
        b. SportsTrader.pro MAKE NO WARRANTY THAT (i) THE WEBSITE, SERVICES OR THE CONTENT WILL MEET YOUR REQUIREMENTS, (ii) THE WEBSITE, THE SERVICES OR THE CONTENT WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM YOUR USE OF THE WEBSITE, THE SERVICES OR THE CONTENT WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE WEBSITE, THE SERVICES OR THE CONTENT WILL MEET YOUR EXPECTATIONS, AND (v) ANY ERRORS IN THE SOFTWARE OPERATING ON THE WEBSITE WILL BE CORRECTED.
        c. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE WEBSITE OR THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
        d. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE WEBSITE OR THROUGH OR FROM THE SERVICES OR THE CONTENT SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TOS.

      <h5>
        <b>
          4. LIMITATION OF LIABILITY
        </b>
      </h5>
      YOU EXPRESSLY UNDERSTAND AND AGREE THAT SportsTrader.pro SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF SportsTrader.pro HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE WEBSITE, THE SERVICES, OR THE CONTENT; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE WEBSITE, THE SERVICES, OR THE CONTENT; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE WEBSITE, THE SERVICES, OR IN THE CONTENT; OR (v) ANY OTHER MATTER RELATING TO THE WEBSITE, THE SERVICES, OR THE CONTENT.

      <h5>
        <b>
          5. EXCLUSIONS AND LIMITATIONS
        </b>
      </h5>
      SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.

      <h5>
        <b>
          6. ACCESS TO SERVICES
        </b>
      </h5>
      In order to use the Website or the Services or access the Content, you must obtain access to the World Wide Web and pay any service fees associated with such access. In addition, you must provide all equipment necessary to make such connection to the World Wide Web, including a computer and modem or other access device.
      You also must have a verified paypal account to become a consultant. We reserve the right to make all visitors, users or consultants to have a verified paypal account at a later date within a reasonable time (30 days or less).

      <h5>
        <b>
          7. BACKGROUND INFORMATION
        </b>
      </h5>
      In order to use the Services as a user to buy picks you will need to sign up as a user and provide a valid email address.If you want to sign up as a consultant you will be required to register with valid email and verify your account by providing certain information about yourself, including your name and email address used for PayPal. In consideration of your use of the Services, you agree to provide true, accurate, current and complete information about yourself as requested in connection with the provision of and/or registration for any of the Services, subject, of course, to all applicable laws. If you provide any information that is untrue, inaccurate, not current, or incomplete, or SportsTrader.pro  has reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete, SportsTrader.pro has the right to suspend or terminate your password and/or refuse any and all current or future use of the Services.

      When you register with SportsTrader® you are limited to one (1) username per person/email address. An " account holder" is defined as the natural person to whom the email address is assigned by an internet service provider, online service provider or other organization (e.g. business, educational institution etc.) that is responsible for assigning email addresses for the domain associated with the submitted email address.

      In order to use the Services you are required to review all site specific guidelines.

      <h5>
        <b>
          8. ACCESS BY MINORS
        </b>
      </h5>
      You must be at least 18 years old to register and become consultants at SportsTrader.pro. If you are under 18 years of age, you are not permitted to use the Website or the Services or to access the Content.

      <h5>
        <b>
          9. PRIVACY POLICY
        </b>
      </h5>
      All information collected about you during registration for use of the Services will be used in accordance with our Privacy Policy and all applicable laws. Click <NavLink to="privacy">https://sportstrader.pro/privacy</NavLink> to view our privacy policy.

      <h5>
        <b>
          10. MEMBER PASSWORD AND SECURITY
        </b>
      </h5>
      You are responsible for maintaining the confidentiality of the password, and are fully responsible for all activities that occur under your password. SportsTrader.pro cannot and will not be liable for any loss or damage arising from your failure to comply with this Section 7.

      <h5>
        <b>
          11. MEMBER CONDUCT
        </b>
      </h5>
      You agree that in connection with your use of the Website, the Services, and the Content you will not:
      a. upload, post, email or otherwise transmit any content that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of anyone's privacy, hateful, or racially, ethnically or otherwise objectionable;
      b. harm or attempt to harm minors in any way;
      c. impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity;
      d. upload, post, email or otherwise transmit any content that you do not have a right to transmit under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);
      e. upload, post, email or otherwise transmit any content that infringes any patent, trademark, trade secret, copyright or other intellectual property right of any party;
      f. upload, post, email or otherwise transmit any unsolicited or unauthorized advertising, promotional materials, "junk mail", "spam", "chain letters", "pyramid schemes", or any other form of solicitation;
      g. upload, post, email or otherwise transmit any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;
      h. interfere with or disrupt the Website, the Services, the Content or servers or networks connected to the Website, the Services or the Content, or disobey any requirements, procedures, policies or regulations of networks connected to the Website, the Services and/or the Content;
      i. intentionally or unintentionally violate any applicable local, state, national or international law.
      j. reproduce, republish, download, post, transmit, distribute, copy, publicly display or otherwise use any Content or any derivative works based on the Website, Services, Content or the Software, in whole or in part.

      <h5>
        <b>
          12. USER MESSAGES
        </b>
      </h5>
      a. With respect to any messages, information, data, graphics, photographs, images, creative ideas, concepts, know-how, techniques, suggestions or improvements or other content that you transmit, submit, post or upload to the Website and/or in connection with any of the Services (each, "Message"), you are, by transmitting or uploading such Message, granting Sports Trader.pro and a perpetual, royalty-free, sub-licensable, non-exclusive, worldwide and irrevocable right and license to use, reproduce, modify, publish, translate, prepare derivative works based upon, and distribute such Message for any purpose and in any form. This license is granted automatically and no payment of any kind will be due to you. At times, SportsTrader.pro may solicit Messages from users. Your provision of any such Messages in response to a solicitation by SportsTrader.pro shall be subject to a grant of a license to SportsTrader.pro as specified above.
      <br />
      b. When any user posts any Message to the Website or any of the Services, SportsTrader.pro shall have the right, but not the obligation, to review, edit or delete any Message for any reason, at SportsTrader.pro’s sole discretion. It is SportsTrader.pro’s policy to fully cooperate with law enforcement authorities and court orders which request or require SportsTrader.pro to disclose personal information or the postings of anyone posting Messages to the Website or any of the Services. Although SportsTrader.pro may monitor and/or review Messages from time to time, it is under no obligation to do so and does not assume any liability or responsibility with respect to any Messages including those that contain errors, defamatory content, pornography, profanity or inaccuracies.
      <br />
      c. You acknowledge and agree that SportsTrader.pro may preserve Messages and may also disclose Messages if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with legal process; (b) enforce the TOS; (c) respond to claims that any Message violates the rights of third-parties; or (d) protect the rights, property, or personal safety of SportsTrader.pro, its users and the public. You understand that the technical processing and transmission of the Services, including Messages, may involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices.

      <h5>
        <b>
          13. VOTING
        </b>
      </h5>
      SportsTrader.pro may offer you the opportunity to vote in connection with certain events. By casting your vote, you are agreeing to abide by the TOS and any and all voting guidelines posted on the Website.

      <h5>
        <b>
          14. INDEMNITY
        </b>
      </h5>
      You agree to indemnify, defend and hold harmless SportsTrader.pro, the directors, governors, owners, other officials, partners, partnerships, principals, employees, affiliates and other related entities, servants, agents, representatives, successors and assigns from and against any claim or demand, including reasonable attorneys' fees, arising out of Messages you post to, submit or transmit through the Website or the Services, your use of the Website or the Services, your connection to the Website or the Services, your violation of the TOS, or your violation of any rights of another.

      <h5>
        <b>
          15. MODIFICATIONS TO WEBSITE, INCLUDING THE SERVICES
        </b>
      </h5>
      SportsTrader.pro reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Website or Services (or any part thereof) with a reasonable notice. All money left in anyone's account will be sent to their verified paypal account if a sale of this company occurs or if SportsTrader.pro ceases operations permanently.

      <h5>
        <b>
          16. TERMINATION
        </b>
      </h5>
      You agree that SportsTrader.pro, in its sole discretion, may terminate your password or use of the Website and/or the Services, and remove and discard any Message within the Services, for any reason, including, without limitation, for lack of use or if SportsTrader.pro believe that you have violated or acted inconsistently with the letter or spirit of the TOS. SportsTrader.pro may also in its sole discretion and at any time discontinue providing the Website or the Services, or any part thereof, with or without notice. You agree that any termination of your access to the Services under any provision of this TOS may be effected without prior notice, and acknowledge and agree that SportsTrader.pro may immediately deactivate or delete your password and all related information and/or files corresponding to your password and/or bar any further access to such files or the Services. If you are terminated all funds from your account on SportsTrader® will be paid in a timely matter to your verified paypal account.

      <h5>
        <b>
          17. SportsTrader.pro ADVERTISERS AND VENDORS
        </b>
      </h5>
      Your correspondence or business dealings with, or participation in promotions of, advertisers and vendors found on or through the Services or the Website, including payment and delivery of related goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such advertiser and/or vendor. You agree that SportsTrader.pro shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers or vendors on the Website or in connection with the Services. ALL OF YOUR BUSINESS DEALINGS WITH VENDORS AND ADVERTISERS APPEARING ON THE WEBSITE OR IN CONNECTION WITH THE SERVICES SHALL BE AT YOUR SOLE RISK.

      <h5>
        <b>
          18. LINKING
        </b>
      </h5>
      The Website and/or Services may provide, or third parties may provide, links to other World Wide Web sites or resources. Because SportsTrader.pro have no control over such sites and resources, you acknowledge and agree that SportsTrader.pro are not responsible for the availability of such external sites or resources, and do not endorse and are not responsible or liable for any content, advertising, products, or other materials on or available from such sites or resources. You further acknowledge and agree that SportsTrader.pro shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such site or resource.

      <h5>
        <b>
          19. PROPRIETARY RIGHTS
        </b>
      </h5>
      Services and Content are the property of SportsTrader.pro and are licensed to SportsTrader.pro and may not be reproduced without the prior written consent of SportsTrader.pro. You further acknowledge and agree that (i) any necessary software used in connection with the Website, the Services, and the Content (the "Software") contains proprietary and confidential information that is protected by applicable intellectual property and other laws and are either owned by or licensed to SportsTrader.pro; and (ii) content contained in sponsor advertisements or information presented to you through the Website, the Services, and the Content or advertisers is protected by copyrights, trademarks, service marks, patents or other proprietary rights and laws. EXCEPT AS EXPRESSLY AUTHORIZED BY SportsTrader.pro, OR ADVERTISERS, YOU AGREE NOT TO REPRODUCE, REPUBLISH, UPLOAD POST, TRANSMIT, DISTRIBUTE, COPY, PUBLICLY DISPLAY OR OTHERWISE USE ANY CONTENT OR ANY DERIVATIVE WORKS BASED ON THE WEBSITE, SERVICES, CONTENT OR THE SOFTWARE, IN WHOLE OR IN PART. You agree not to modify the Software in any manner or form, or to use modified versions of the Software, including (without limitation) for the purpose of obtaining unauthorized access to the Services. You agree not to access the Services by any means other than through the interface that is provided b SportsTrader.pro  for use in accessing the Services.

      <h5>
        <b>
          20. NOTICE
        </b>
      </h5>
      Notices to you may be made via either email or regular mail. The Website may also provide notices of changes to the TOS or other matters by displaying notices or links to notices to you generally on the Services.

      <h5>
        <b>
          21. LINKING TO THE WEBSITE
        </b>
      </h5>
      No link to the Website may be "framed" to the extent such frame contains any sponsorship, advertising, or other commercial text or graphics. All links to the Website must be to www.SportsTrader.pro, the Website home page. "Deep Linking" to internal pages of the Website is expressly prohibited.

      <h5>
        <b>
          22. GENERAL PROVISIONS
        </b>
      </h5>
      The TOS constitute the entire agreement between you and SportsTrader.pro and governs your use of the Website, the Services, and access to the Content, superseding any prior agreements between you and SportsTrader.pro. You also may be subject to additional terms and conditions that may apply when you use affiliate services, third-party content or third-party software. The TOS and the relationship between you and SportsTrader.pro shall be governed by the laws of Delaware without regard to its conflict of law provisions. You and SportsTrader.pro agree to submit to the personal and exclusive jurisdiction of the courts located within the country of Delaware. The failure of SportsTrader.pro to exercise or enforce any right or provision of the TOS shall not constitute a waiver of such right or provision. If any provision of the TOS is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of the TOS remain in full force and effect. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Services or the TOS must be filed within one (1) year after such claim or cause of action arose or be forever barred. The section titles in the TOS are for convenience only and have no legal or contractual effect. The Laws and Courts of Delaware govern any dispute arising out of the use of the web site.


    </Wrapper>
  );
}

export default TOS;

TOS.propTypes = {
  text: PropTypes.string
}
