import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from '../components/ui/Wrapper'


const NotFound = ({text = "Not found!"}) => {
  return (
    <Wrapper className="404-page">
      <h1>{text}</h1>
    </Wrapper>
  );
}

export default NotFound;

NotFound.propTypes = {
  text: PropTypes.string
}
