export const PAY_BEGIN   = 'PAY_BEGIN';
export const PAY_SUCCESS = 'PAY_SUCCESS';
export const PAY_FAILURE = 'PAY_FAILURE';
export const EXECUTE_PAYMENT_BEGIN   = 'EXECUTE_PAYMENT_BEGIN';
export const EXECUTE_PAYMENT_SUCCESS = 'EXECUTE_PAYMENT_SUCCESS';
export const EXECUTE_PAYMENT_FAILURE = 'EXECUTE_PAYMENT_FAILURE';
export const LOGIN_BEGIN   = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const EXECUTE_LOGIN_BEGIN   = 'EXECUTE_LOGIN_BEGIN';
export const EXECUTE_LOGIN_SUCCESS = 'EXECUTE_LOGIN_SUCCESS';
export const EXECUTE_LOGIN_FAILURE = 'EXECUTE_LOGIN_FAILURE';
export const BUY_SUBSCRIPTION_BEGIN   = 'BUY_SUBSCRIPTION_BEGIN';
export const BUY_SUBSCRIPTION_SUCCESS = 'BUY_SUBSCRIPTION_SUCCESS';
export const BUY_SUBSCRIPTION_FAILURE = 'BUY_SUBSCRIPTION_FAILURE';
export const EXECUTE_SUBSCRIPTION_BEGIN   = 'EXECUTE_SUBSCRIPTION_BEGIN';
export const EXECUTE_SUBSCRIPTION_SUCCESS = 'EXECUTE_SUBSCRIPTION_SUCCESS';
export const EXECUTE_SUBSCRIPTION_FAILURE = 'EXECUTE_SUBSCRIPTION_FAILURE';

export const payBegin = () => ({
  type: PAY_BEGIN
});

export const paySuccess = () => ({
  type: PAY_SUCCESS
});

export const payError = () => ({
  type: PAY_FAILURE
});

export const executePaymentBegin = () => ({
  type: EXECUTE_PAYMENT_BEGIN
});

export const executePaymentSuccess = () => ({
  type: EXECUTE_PAYMENT_SUCCESS
});

export const executePaymentError = () => ({
  type: EXECUTE_PAYMENT_FAILURE
});

export const loginBegin = () => ({
  type: LOGIN_BEGIN
});

export const loginSuccess = () => ({
  type: LOGIN_SUCCESS
});

export const loginError = (error) => ({
  type: LOGIN_FAILURE,
  data: { error }
});

export const executeLoginBegin = () => ({
  type: LOGIN_BEGIN
});

export const executeLoginSuccess = () => ({
  type: LOGIN_SUCCESS
});

export const executeLoginError = (error) => ({
  type: LOGIN_FAILURE,
  data: { error }
});

export const buySubscriptionBegin = () => ({
  type: BUY_SUBSCRIPTION_BEGIN
});

export const buySubscriptionSuccess = () => ({
  type: BUY_SUBSCRIPTION_SUCCESS
});

export const buySubscriptionError = () => ({
  type: BUY_SUBSCRIPTION_FAILURE
});

export const executeSubscriptionBegin = () => ({
  type: EXECUTE_SUBSCRIPTION_BEGIN
});

export const executeSubscriptionSuccess = () => ({
  type: EXECUTE_SUBSCRIPTION_SUCCESS
});

export const executeSubscriptionError = () => ({
  type: EXECUTE_SUBSCRIPTION_FAILURE
});
