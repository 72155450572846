import {Map} from 'immutable';
import {SCROLL_RESTORE_POSITION} from '../actions/scroll-restore';


const initialState = Map({
  scrollPoint: 0
})

export default function scrollPositionReducer(state = initialState, action) {
  switch (action.type) {
    case SCROLL_RESTORE_POSITION:
      state = state.set('scrollPoint', action.data);
      break;
    default:
      return state;
  }

  return state;
}
