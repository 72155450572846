import React from 'react';
import Wrapper from '../components/ui/Wrapper'


const CookiePolicy = () => {
  return (
    <Wrapper className="404-page" title="Cookie policy" icon="fa fa-lock">
      <p>
        This Notice describes how we use cookies and similar technologies on our website and mobile apps.
      </p>
      <p>
        NXT TECH, a company registered in Delaware with registration number 7273645, ("we", "us", "our") uses cookies and similar technologies on our website www.https://sportstrader.pro ("Website") as described in this Notice. By consenting to this cookie notice, you agree that we can distinguish you from other users of our website and apps for the purposes set out below. This helps provide you with good user experience, tailored content and interest-based adverts. For example, we can recognise and remember important information, that will make it more comfortable for you to browse our website or use our apps such as preferred settings.
      </p>
      <h4>
        <b>
          What are Cookies and similar technologies?
        </b>
      </h4>
      <p>
        A cookie is a small file of letters and numbers that we store via your browser on the hard drive of your computer or mobile device.
      </p>
      <p>There are three main types of cookies:</p>
      <ul>
        <li>
          <b>Session cookies:</b> specific to a particular visit and limited to sending session identifiers (random numbers generated by the server) so you don't have to re-enter information when you navigate to a new page or check out. Session cookies are not permanently stored on your device and are deleted when the browser closes;
        </li>
        <li>
          <b>Persistent cookies:</b> record information about your preferences and are stored in your browser cache or mobile device. The expiry for these cookies is 12 months;
        </li>
        <li>
          <b>Third party cookies:</b> placed by someone other than us, which may gather data across multiple websites or sessions. The expiry for these cookies is no more than 24 months.
        </li>
      </ul>
      <p>
        We use cookies for the following [five] purposes:
      </p>
      <div className="row">
        <div className="col-md-2">
          Strictly necessary
        </div>
        <div className="col-md-10">
          These cookies are essential for you to browse our Website and use its features. The information collected relates to the operation of the Website (e.g. website scripting language and security tokens) and enables us to provide you with the service you have requested.
        </div>
        <div className="col-md-2">
          Functionality
        </div>
        <div className="col-md-10">
          These cookies remember the choices you have made, for example the country you visit our Website from, your language , any changes you have made to text size, and other parts of the web pages that you can customise to improve your user experience and to make your visits more tailored and enjoyable. Cookies also allow us to save passwords and preferences for a user so that you will not have to re-enter them on the next visit to a SportsTrader® by NXT website.
        </div>
        <div className="col-md-2">
          Performance/analytics
        </div>
        <div className="col-md-10">
          <p>
            These cookies collect information on how users use our Website, for example which pages you visit most often, whether you receive any error messages and how you arrived at our website. Information collected by these cookies is used to improve your use of our Website. This information may also be reported, in aggregate form only, to our current and prospective sponsors, advertisers and business partners. No personal data will be disclosed in such reports.
          </p>
          <p>
          These cookies are sometimes placed by third party providers of web traffic and analysis services.
          </p>
          <p>
            We use Google Analytics. For information on how Google processes and collects your information and how you can opt out, please <a href="https://developers.google.com/analytics/resources/concepts/gaConceptsTrackingOverview" target="_blank" rel="noopener noreferrer">click here</a>.
          </p>
        </div>
        <div className="col-md-2">
          Targeting or advertising
        </div>
        <div className="col-md-10">
          These cookies collect information about your browsing habits and inferred interests to make advertising more relevant to you. They are also used to limit the number of times you see an advert as well as to measure the effectiveness of an advertising campaign. These cookies are usually placed by third party advertising networks. They remember the other websites you visit and this information is shared with third party organisations, for example advertisers.
        </div>
        <div className="col-md-2">
          Social Media
        </div>
        <div className="col-md-10">
          <p>
            These cookies allow users to share our website content on social media such as Facebook and Twitter. These cookies are not within our control. Please refer to the respective privacy policies of the social media providers for how their cookies work.
          </p>
          <p>
            We may also automatically collect and store certain information about your interaction with our Website or app including IP address, browser type, internet service provider, referring/exit pages, operating system, date/time stamps and related data.
          </p>
        </div>
      </div>
      <h4>
        <b>
          Other technologies
        </b>
      </h4>
      <p>
        In addition to cookies, we use some other similar technologies as follows:
      </p>
      <ul>
        <li>
          <b>Web Beacons:</b> small graphic images included on our website or emails or those of third parties to provide analytics information;
        </li>
        <li>
          <b>Social widgets:</b> buttons or icons provided by third party social media networks which may collect browsing data and will be received by the third party and are controlled by the third party;
        </li>
        <li>
          <b>SDKs:</b> these are mobile application third party software development kits used in our apps. They enable us to collect information about app activity and the device it runs on;
        </li>
        <li>
          <b>Local shared objects:</b> these are sometimes called flash cookies and can be stored on your browser. They are used to maintain preferences and usage records.
        </li>
      </ul>
      <h4>
        <b>
          Refusing or deleting cookies
        </b>
      </h4>
      [Most internet browsers are set up by default to accept cookies. However if you want to refuse or delete them (or similar technologies) please refer to the help and support area on your browser for instructions on how to block or delete cookies (for example: Internet Explorer, Google Chrome, Mozilla Firefox and Safari). Please note you may not be able to take advantage of all the features of our Website, including certain personalised features, if you delete or refuse cookies.
      <h4>
        <b>
          Opting out of Google Analytics
        </b>
      </h4>
      To opt out of Google Analytics, visit https://tools.google.com/dlpage/gaoptout.
      <h4>
        <b>
        Managing Cookies
        </b>
      </h4>

      For more information on managing cookies, please go to www.allaboutcookies.org.
      <h5>
        <b>
          Cookie Settings
        </b>
      </h5>

      To opt out of marketing/advertising cookies, please go to http://optout.aboutads.info/?c=2#!/
      <h4>
        <b>
          If you are a resident in the EU:
        </b>
      </h4>
      For more information on managing cookies, please visit www.youronlinechoices.eu which has further information about behavioral advertising and online privacy.
      <h4>
        <b>
          Changes to our Cookie Notice
        </b>
      </h4>
      <p>
        This Cookie Notice may be updated from time to time.
      </p>
      <p>
        If we change anything important about this Cookie Notice we will notify you through a pop-up on the website for a reasonable length of time prior to and following the change. You may review the Cookie Notice by visiting the Website and clicking on the “Cookie Notice” link.
      </p>
      <b>Google Analytics</b> To opt out, please go to https://tools.google.com/dlpage/gaoptout. Please note, we have enabled IP anonymisation in Google Analytics.
      Our websites use the web analytics service Google Analytics that is provided by Google, Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”). Google Analytics uses “cookies”. Cookies are small text files that are placed on your computer which allow analyzing how you use the platform. The information generated by the cookie on your use of the platform will be transmitted to and stored by Google on servers in the United States. We have activated the so called “IP-anonymization” which means that your IP address will be truncated within the area of the European Union or the European Economic Area before being transferred to the United States. Only in exceptional cases the entire (ie. non-truncated) IP address will be transferred to a Google server in the US and truncated there. Google will use this information on our behalf for the purpose of evaluating your use of the platform, compiling reports on platform activity and providing other services relating to platform and internet usage. Google does not provide us with data that we may link back particularly to you. Your truncated IP address that is transferred to Google will not be associated with any other data held by Google. You may refuse accepting cookies by selecting the relevant settings of your browser. However, please note that if you do so you may not be able to use the full functionality of the platform. You can also opt out from being tracked via Google Analytics with effect for the future by downloading and installing Google Analytics Opt-out Browser Add-on under https://tools.google.com/dlpage/gaoptout

    </Wrapper>
  );
}

export default CookiePolicy;

