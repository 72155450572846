import _ from 'lodash';
import React, { Component } from 'react';
import i from 'immutable';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { connect } from "../store/context";
import { followUser, unfollowUser, getUser, getUserStatistic } from "../api/users";
import { getAPIContentfeed, getUserAPIContentfeed } from '../api/content-feed';
import { getUserPicksFinal } from "../api/picks";
import { minTwoDigits } from "../helpers/minTwoDigits.js";

import Paginator from '../components/general/Paginator';
import Wrapper from '../components/ui/Wrapper';
import UserInfo from '../components/profile/UserInfo';
import Cover from '../components/profile/Cover';
import Stats from '../components/profile/Stats/index';
import ChartsFilter from '../components/profile/ChartsFilter/index';
import RoiChart from '../components/profile/RoiChart';
import DeviationChart from '../components/profile/DeviationChart';
import PicksChart from '../components/profile/PicksChart';
import Loader from '../components/ui/Loader';
import EmptyPage from '../components/general/EmptyPage'
import { completeRow } from "../components/users/ProfilePick/complete-pick";
import { PickFeed } from '../components/dashboard/PickFeed/index';
import SportsFilter from '../components/general/SportsFilter';
import NotFound from './NotFound';


class User extends Component {
  constructor(props) {
    const { period = 30, sportId, selectedType = 'stats', page = 1, selectedSports = [], showAllSports = true } = queryString.parse(props.location.search);
    super(props);
    this.state = {
      selectedType,
      showAllSports: typeof showAllSports === 'boolean' ? showAllSports : JSON.parse(showAllSports),
      selectedSports: typeof selectedSports === 'object' ? selectedSports : [selectedSports],
      period,
      sportId,
      picksPerPage: 10,
      page
    };
  }

  componentDidMount = async () => {
    const { dispatch, match: { params: { id } } } = this.props;
    const { period, sportId, selectedType, page } = this.state;

    await getUser(dispatch, id);

    if (selectedType === 'picks') {
      getUserPicksFinal(dispatch, id, period, sportId, page);
    } else if (selectedType === 'stats') {
      getUserStatistic(dispatch, id, period, sportId);
    } else if (selectedType === 'for-sale') {
      getUserAPIContentfeed(dispatch, id);
    }
  }

  //eslint-disable-next-line consistent-return
  componentDidUpdate(prevProps, prevState) {
    const { dispatch, userProfile: { id }, limit, history } = this.props;
    const { period, sportId, selectedType } = this.state;
    const filterChanged = period !== prevState.period || sportId !== prevState.sportId || selectedType !== prevState.selectedType

    if (filterChanged) {
      if (selectedType === 'picks') {
        getUserPicksFinal(dispatch, id, period, sportId, 1, limit);
      } else if (selectedType === 'stats') {
        getUserStatistic(dispatch, id, period, sportId);
      } else if (selectedType === 'for-sale') {
        getUserAPIContentfeed(dispatch, id);
      }

      history.push({
        pathname: `/user/${id}`,
        search: queryString.stringify({ period, sportId, selectedType })
      });
    }
  }

  handleFollowUser = async (event) => {
    const { dispatch, userProfile, profile: { id }, location, history } = this.props;

    if (id) {
      await followUser(dispatch, event.target.attributes.userid.nodeValue, [userProfile]);
      getAPIContentfeed(dispatch, id, 20, 0, true);
    } else {
      const { pathname } = location;
      history.push(`/login?redirectUrl=${pathname}`);
    }
  }

  handleUnfollowUser = async (event) => {
    const { dispatch, followings, profile: { id } } = this.props;
    await unfollowUser(dispatch, event.target.attributes.userid.nodeValue, followings);
    getAPIContentfeed(dispatch, id, 20, 0, true);
  }

  handleChangeType = (event) => {
    const selectedType = event.target.value || event.target.id;
    const { period, sportId } = this.state;
    const { dispatch, match, history } = this.props;
    const { id } = match.params;

    if (selectedType === 'picks') {
      getUserPicksFinal(dispatch, id, period, sportId);
      this.setState({ showAllSports: true, selectedSports: [] });
    } else if (selectedType === 'stats') {
      getUserStatistic(dispatch, id, period, sportId);
      this.setState({ showAllSports: true, selectedSports: [] });
    } else if (selectedType === 'for-sale') {
      getUserAPIContentfeed(dispatch, id);
    }

    this.setState({ selectedType });

    history.push({
      pathname: `/user/${id}`,
      search: queryString.stringify({ period, sportId, selectedType })
    });
  }

  onChangePeriod = (event) => {
    this.setState({
      period: event.target.value
    });
  }

  onChangeSport = (event) => {
    this.setState({
      sportId: event.target.value || null
    });
  }

  onClickPagination = async (event) => {
    const page = event.target.innerText;
    const { dispatch, pages, history } = this.props;
    const { id } = this.props.match.params;
    const { period, sportId, picksPerPage, selectedType } = this.state;

    let newPage = 1;

    if (page === 'First') {
      newPage = 1;
    } else if (page === 'Last') {
      newPage = pages;
    } else {
      newPage = Number(page) || 1;
    }

    history.push({
      pathname: `/user/${id}`,
      search: queryString.stringify({ period, sportId, selectedType, page: newPage })
    });

    await getUserPicksFinal(dispatch, id, period, sportId, newPage, picksPerPage);
  }

  handleClickSport = (event) => {
    const { history } = this.props;
    const clickedSport = event.target.innerText;
    const { selectedSports, showAllSports, selectedType } = this.state;

    if (showAllSports) {
      this.setState({ showAllSports: false, selectedSports: [clickedSport], selectedType })
      history.push({
        search: queryString.stringify({ selectedSports: [clickedSport], showAllSports: false, selectedType })
      });
    } else {
      const index = selectedSports.indexOf(clickedSport);

      if (index === -1) {
        selectedSports.push(clickedSport);
      } else {
        selectedSports.splice(index, 1);
      }

      if (selectedSports.length === 0) {
        this.setState({ showAllSports: true, selectedSports })
        history.push({ search: queryString.stringify({ selectedSports, showAllSports: true, selectedType }) });
      } else {
        this.setState({ showAllSports: false, selectedSports });
        history.push({ search: queryString.stringify({ selectedSports, showAllSports: false, selectedType }) });
      }
    }
  }

  handleClickAll = () => {
    const { showAllSports, selectedType } = this.state;
    const { history, includedSports } = this.props;

    if (showAllSports) {
      this.setState({ showAllSports: !showAllSports, selectedSports: includedSports });
      history.push({
        search: queryString.stringify({ showAllSports: !showAllSports, selectedSports: includedSports, selectedType })
      });
    } else {
      this.setState({ showAllSports: !showAllSports, selectedSports: [] });
      history.push({ search: queryString.stringify({ showAllSports: !showAllSports, selectedSports: [], selectedType }) });
    }
  }

  isSportsSelected = (itemSports) => {
    const { selectedSports, showAllSports } = this.state;
    let shouldShow = false;

    if (showAllSports) {
      shouldShow = true;
    } else {
      selectedSports.forEach((selected) => {
        if (itemSports.indexOf(selected) > -1) {
          shouldShow = true;
        }
      })
    }

    return shouldShow;
  }

  render() {
    const { followingIds,
      userProfile,
      loading,
      profile,
      statisticDates,
      statisticRoiTotal,
      statisticRoiSpread,
      statisticRoiDrawLine,
      statisticDeviationSpread,
      statisticDeviationTotal,
      statisticRoiMoneyLine,
      statisticPicksWin,
      statisticPicksTotal,
      usersPicks,
      loadingPicks,
      pages,
      location,
      feed = [],
      loadingFeed,
      history,
      dispatch,
      includedSports = [],
      mySubscriptions } = this.props;
    const { page = 1 } = queryString.parse(location.search);
    const { firstName, lastName, id, role, avatar } = userProfile;
    const { selectedType, period, sportId, showAllSports, selectedSports } = this.state;

    if (loading) {
      return (
        <Wrapper>
          <Loader />
        </Wrapper>
      );
    }

    if (_.isEmpty(userProfile)) {
      return <NotFound text="User Not Found!" />
    }

    const picks = [];
    usersPicks.length > 0 && usersPicks.forEach((element) => {
      picks.push(completeRow(element));
    });

    const forSale = [];
    feed.length > 0 && feed.forEach((element) => {
      const { pickId, parleyId, bundleId, createdAt, sports } = element;
      const keyId = createdAt + (pickId || parleyId || bundleId)
      const shouldShow = this.isSportsSelected(sports);

      if (keyId && shouldShow) {
        forSale.push(<PickFeed key={keyId} {...element} forSale pageHistory={history} dispatch={dispatch} confirmed={profile.emailVerified} mySubscriptions={mySubscriptions} />);
      }
    });

    // helmet description
    const title = firstName ? `${firstName} ${lastName ? lastName : ''}` : 'Anonymous consultant'
    const description = `Made ${(Number(statisticRoiMoneyLine[statisticRoiMoneyLine.length - 1]) + Number(statisticRoiSpread[statisticRoiSpread.length - 1]) + Number(statisticRoiTotal[statisticRoiTotal.length - 1])).toFixed(2)}x, if you would follow ${title} and bet 100$ for each event he picks you would made ${(Number(statisticRoiMoneyLine[statisticRoiMoneyLine.length - 1]) + Number(statisticRoiSpread[statisticRoiSpread.length - 1]) + Number(statisticRoiTotal[statisticRoiTotal.length - 1])) * 100}$`

    const oldMetaImg = document.getElementById("og-image");
    oldMetaImg && oldMetaImg.parentNode.removeChild(oldMetaImg);

    const oldMetaUrl = document.getElementById("og-url");
    oldMetaUrl && oldMetaUrl.parentNode.removeChild(oldMetaUrl);

    return (
      <Wrapper
        className="profile-page"
        title={title}
        icon="fa fa-fw fa-user-o"
      >
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={avatar} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-4">
            {!_.isEmpty(userProfile) &&
              <UserInfo
                onFollowUser={this.handleFollowUser}
                onUnFollowUser={this.handleUnfollowUser}
                onChangeType={this.handleChangeType}
                isFollowing={followingIds.includes(userProfile.id)}
                isCurrentUser={profile.id === userProfile.id}
                isForSale={selectedType === 'for-sale'}
                allowForSale
                {...userProfile}
              />}
          </div>
          <div className="col-lg-9 col-md-8 col-sm-8">
            {selectedType !== 'for-sale' &&
              <div>
                <Cover profileImage={userProfile.profileImage} />
                <Stats
                  followersCount={userProfile.followersCount}
                  ROIMoneyLine={Number(statisticRoiMoneyLine[statisticRoiMoneyLine.length - 1])}
                  ROISpread={Number(statisticRoiSpread[statisticRoiSpread.length - 1])}
                  ROITotal={Number(statisticRoiTotal[statisticRoiTotal.length - 1])}
                  ROIDrawLine={Number(statisticRoiDrawLine[statisticRoiDrawLine.length - 1])}
                  DeviationSpread={Number(statisticDeviationSpread[statisticDeviationSpread.length - 1])}
                  DeviationTotal={Number(statisticDeviationTotal[statisticDeviationTotal.length - 1])}
                />
                <ChartsFilter
                  id={id}
                  role={role}
                  selectedType={selectedType}
                  onChangeType={this.handleChangeType}
                  selectedPeriod={period}
                  selectedSportId={sportId}
                  handleChangePeriod={this.onChangePeriod}
                  handleChangeSport={this.onChangeSport}
                />
              </div>
            }
            {selectedType === 'stats' &&
              <div>
                <RoiChart
                  dates={statisticDates}
                  spread={statisticRoiSpread}
                  total={statisticRoiTotal}
                  moneyLine={statisticRoiMoneyLine}
                  draw={statisticRoiDrawLine}
                  sportId={sportId}
                />
                <DeviationChart
                  dates={statisticDates}
                  spread={statisticDeviationSpread}
                  total={statisticDeviationTotal}
                />
                <PicksChart
                  dates={statisticDates}
                  win={statisticPicksWin}
                  total={statisticPicksTotal}
                />
              </div>
            }
            {selectedType === 'picks' && picks}
            {!loadingPicks && selectedType === 'picks' && <Paginator pages={pages} currentPage={Number(page) || 1} handleClick={this.onClickPagination} />}
            {!loadingPicks && selectedType === 'picks' && picks.length === 0 && <EmptyPage text="No finished picks yet" />}
            {loadingPicks && <Loader />}

            {selectedType === 'for-sale' && !loadingFeed && forSale.length > 0 &&
              <SportsFilter
                showAllSports={showAllSports}
                selectedSports={selectedSports}
                onClickAll={this.handleClickAll}
                includedSports={includedSports}
                onClickSport={this.handleClickSport}
              />
            }
            {selectedType === 'for-sale' && !loadingFeed && forSale}
            {!loadingFeed && selectedType === 'for-sale' && forSale.length === 0 && <EmptyPage text="No Picks for sale yet" />}
            {loadingFeed && <Loader />}
          </div>
        </div>
      </Wrapper>
    );
  }
}

function select(state, props) {
  const followings = state.getIn(['users', 'profile', 'following'], i.List()).toJS();
  const followingIds = followings.map((user) => user.id);
  const userProfile = state.getIn(['users', 'userProfile'], i.Map()).toJS();
  const loading = state.getIn(['users', 'userLoading']);
  const profile = state.getIn(['users', 'profile'], i.Map()).toJS();
  const userStatistic = state.getIn(['users', 'userStatistic'], i.List()).toJS();
  const statisticDates = userStatistic.map((item) => {
    const date = new Date(item.Date).getDate();
    const month = new Date(item.Date).getMonth() + 1;
    return `${minTwoDigits(date)}.${minTwoDigits(month)}`
  });
  const statisticRoiTotal = userStatistic.map((item) => parseFloat(item.ROITotal.toFixed(2)));
  const statisticRoiSpread = userStatistic.map((item) => parseFloat(item.ROISpread.toFixed(2)));
  const statisticRoiMoneyLine = userStatistic.map((item) => parseFloat(item.ROIMoneyLine.toFixed(2)));
  const statisticRoiDrawLine = userStatistic.map((item) => item.ROIDrawLine ? parseFloat(item.ROIDrawLine.toFixed(2)) : 0);
  const statisticDeviationSpread = userStatistic.map((item) => item.DeviationSpread ? parseFloat(item.DeviationSpread.toFixed(2)) : 0);
  const statisticDeviationTotal = userStatistic.map((item) => item.DeviationTotal ? parseFloat(item.DeviationTotal.toFixed(2)) : 0);
  const statisticPicksWin = userStatistic.map((item) => item.WinPicks);
  const statisticPicksTotal = userStatistic.map((item) => item.TotalPicks);
  const usersPicks = state.getIn(['picks', 'usersPicks', props.match.params.id, 'picks'], i.List()).toJS();
  const pages = state.getIn(['picks', 'usersPicks', props.match.params.id, 'pages'], 0);
  const page = state.getIn(['picks', 'usersPicks', props.match.params.id, 'page'], 0);
  const count = state.getIn(['picks', 'usersPicks', props.match.params.id, 'count'], 0);
  const limit = state.getIn(['picks', 'usersPicks', props.match.params.id, 'limit'], 10);
  const loadingPicks = state.getIn(['picks', 'usersPicksLoading'], false);
  let loadingFeed = state.getIn(['contentFeed', 'loading'], false);
  const buying = state.getIn(['contentFeed', 'buying'], false);
  const buyingPaypal = state.getIn(['paypal', 'loading'], false);
  const feed = state.getIn(['contentFeed', 'userFeed'], i.List()).toJS();
  const mySubscriptions = state.getIn(['subscriptions', 'mySubscriptions'], i.List()).toJS()

  loadingFeed = loadingFeed || buying || buyingPaypal;

  // 'reduce((acc, val) => acc.concat(val), [])' instead '.flat()'
  const includedSports = [...new Set(feed.map((f) => f.sports).reduce((acc, val) => acc.concat(val), []))];

  return {
    followings,
    followingIds,
    userProfile,
    loading,
    profile,
    statisticDates,
    statisticDeviationSpread,
    statisticDeviationTotal,
    statisticRoiTotal,
    statisticRoiSpread,
    statisticRoiMoneyLine,
    statisticRoiDrawLine,
    statisticPicksWin,
    statisticPicksTotal,
    usersPicks,
    loadingPicks,
    pages,
    page,
    count,
    limit,
    loadingFeed,
    feed,
    includedSports,
    mySubscriptions
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(User);

User.propTypes = {
  followingIds: PropTypes.array,
  followings: PropTypes.object
};
