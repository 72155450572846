import CONFIG from '../config/config';
import api from '../actions/api';
import { alertError } from '../actions/alerts'
import { fetchSportBegin,
  fetchSportSuccess,
  fetchSportError,
  fetchSportsBegin,
  fetchSportsSuccess,
  fetchSportsError } from '../actions/sports';


export const getSportById = async (dispatch, id) => {
  try {
    fetchSportBegin();
    const { data } = await api.get(`${CONFIG.api_url}/sports/${id}`);
    dispatch(fetchSportSuccess(data));
  } catch (error) {
    console.log(error);  //eslint-disable-line no-console
    alertError(error)
    dispatch(fetchSportError(error));
  }
}

export const getSports = async (dispatch) => {
  try {
    fetchSportsBegin();
    const { data } = await api.get(`${CONFIG.api_url}/sports`);
    dispatch(fetchSportsSuccess(data));
  } catch (error) {
    console.log(error);  //eslint-disable-line no-console
    alertError(error)
    dispatch(fetchSportsError(error));
  }
}
