import React, { Component } from "react";
import PropTypes from 'prop-types';
import queryString from 'query-string';
import i from 'immutable';
import Scrollbar from 'react-smooth-scrollbar';
import { withRouter } from 'react-router-dom';
import { connect } from "../../store/context";
import EmptyPage from '../general/EmptyPage';
import CONFIG from '../../config/config';
import { fetchMessages,
  subscribeRoomsState,
  getChatRoomId,
  getUserPersonalRooms,
  changeCurrentRoom,
  getUserRooms,
  getRoomMembers,
  markRoomAsReaded} from "../../api/chat";
import { executePayment } from "../../api/paypal";
import { isIos } from '../../util/browserDetectors';
import Head from "./Head/index";
import History from "./History/index";
import RoomHead from "./RoomHead/index";
import RoomHistory from "./RoomHistory/index";
// import Search from "./Search/index";
import Sender from "./Sender/index";
import Users from "./Users/index";
import Rooms from "./Rooms/index";
import './style.scss';


class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomsOpen: false,
      usersOpen: false,
      showDirect: false
    };
    
  }
  

  componentDidMount() {
    const { dispatch, location, activeRoom } = this.props;
    const { user, room, PayerID, paymentId } = queryString.parse(location.search);

    if (PayerID && paymentId) {
      executePayment(dispatch, PayerID, paymentId, 'Send Tips Paypal', 0, false, user)
    }
    getUserPersonalRooms(dispatch);
    if (user) {
      getChatRoomId(dispatch, Number(user));
      this.setState({ usersOpen: true });
      this.setState({ roomsOpen: false });
    } else if (room) {
      getRoomMembers(dispatch, Number(room))
      getChatRoomId(dispatch, null, Number(room));
      this.setState({ roomsOpen: true });
      this.setState({ usersOpen: false });
    }
    getUserRooms(dispatch, 1)
  }
  componentDidUpdate(prevProps) {
    const { currentRoom, messages } = this.props;

    if (prevProps.currentRoom !== currentRoom) {
      this.handleFetchChatData();
    }
  }


  handleFetchChatData = (listen = true)  => {
    const { dispatch, currentRoom, limit, offset, profile: { id }} = this.props;

    if (currentRoom) {
      fetchMessages(dispatch, currentRoom, limit, offset, false);
    }
  }

  handleOpenPersonalRoom = (userId, chatRoomId) => {
    const { dispatch, history, location } = this.props;
    const { user } = queryString.parse(location.search);
    
    if (Number(user) === userId) {
      markRoomAsReaded(dispatch, chatRoomId)
      return;
    }
    history.push(`/chat?user=${userId}`);
    getChatRoomId(dispatch, userId, chatRoomId);
    markRoomAsReaded(dispatch, chatRoomId)
  }

  handleOpenRoom = (chatRoomId) => {
    const { dispatch, history, location } = this.props;
    const { room } = queryString.parse(location.search);
    if (Number(room) === chatRoomId) {
      markRoomAsReaded(dispatch, chatRoomId)
      return;
    }
    history.push(`/chat?room=${chatRoomId}`);
    getChatRoomId(dispatch, null, chatRoomId);
    getRoomMembers(dispatch, chatRoomId)
    markRoomAsReaded(dispatch, chatRoomId)
  }

  handleCloseRoom = () => {
    const { dispatch, history } = this.props;

    history.push(`/chat`);
    changeCurrentRoom(dispatch, '');
  }

  handleRoomsCollapse = () => {
    const { roomsOpen } = this.state;
    this.setState({ roomsOpen: !roomsOpen });
  }

  handleUsersCollapse = () => {
    const { usersOpen } = this.state;
    this.setState({ usersOpen: !usersOpen });
  }

  render() {
    const { users,
      currentRoom,
      profile,
      onlineUsers,
      roomsLoaded,
      messages,
      chatUser,
      messagesLoading,
      hasNexPage,
      offset,
      currentUserId,
      roomsWithNewMessages,
      myUserId,
      closedChat,
      joinedRooms,
      activeRoom,
      members,
      currentRoomObj } = this.props;

    let blockedMe = false;
    let blockedByMe = false;

    if (chatUser) {
      const { Blocked, BlockedBy } = chatUser;
      Blocked && Blocked.length > 0 && Blocked.forEach((user) => {
        if (user.BlockedUserId === myUserId) {
          blockedMe = true;
        }
      });

      BlockedBy && BlockedBy.length > 0 && BlockedBy.forEach((user) => {
        if (user.UserId === myUserId) {
          blockedByMe = true;
        }
      });
    }
    const emptyPageText = "You do not have any connections/rooms yet. Try to follow any consultant on our platform and " +
                           "you will be able to chat with them here or join any open chat rooms"

    return (
      <div className={`chat clearfix ${isIos ? 'is-ios' : ''}`}>
        {!messagesLoading && (users.length === 0 && joinedRooms.length === 0)  && <EmptyPage text={emptyPageText} />}
        {(users.length > 0 || joinedRooms.length > 0) &&
          <div className={`chat-navigation ${currentRoom && 'hide-mb'}`}>
            <Scrollbar
              className="scrollbar"
              thumbMinSize={50}
              style={{ height: 'inherit' }}
            >
              {/* <Search /> */}
              <div className="chat-navigation-wrap">
                <div className="chat-label" onClick={this.handleUsersCollapse}>
                  <span>Direct messages</span>
                  <i className={this.state.usersOpen ? 'fa fa-chevron-down' : 'fa fa-chevron-left'} />
                </div>
                
                <div className={this.state.usersOpen ? 'visible' : 'hidden'}>
                  <Users
                    profile={profile}
                    onOpenRoom={this.handleOpenPersonalRoom}
                    onlineUsers={onlineUsers}
                    users={users}
                    currentUserId={currentUserId}
                    usersWithNewMessages={roomsWithNewMessages}
                  />
                </div>
                <div className="chat-label" onClick={this.handleRoomsCollapse}>
                  <span>Rooms</span>
                  <i className={this.state.roomsOpen ? 'fa fa-chevron-down' : 'fa fa-chevron-left'} />
                </div>
                <div className={this.state.roomsOpen ? 'visible' : 'hidden'}>
                  <Rooms
                    profile={profile}
                    onOpenRoom={this.handleOpenRoom}
                    onlineUsers={onlineUsers}
                    rooms={joinedRooms}
                    currentUserId={currentUserId}
                    currentRoom={currentRoom}
                    roomsWithNewMessages={roomsWithNewMessages}
                  />
                </div>
              </div>
            </Scrollbar>
          </div>
        }


        {(chatUser && chatUser.id) ?
          <div className="chat-body">
            <Head
              currentRoom={chatUser}
              userId={profile.id}
              roomsLoaded={roomsLoaded}
              blockedByMe={blockedByMe}
              onCloseRoom={this.handleCloseRoom}
            />

            <History
              messages={messages}
              currentRoom={currentRoom}
              myUser={profile}
              chatUser={chatUser}
              messagesLoading={messagesLoading}
              onLoadNextPage={this.handleFetchChatData}
              hasNextPage={hasNexPage}
              offset={offset}
            />

            <Sender
              userId={profile.id}
              receiverId={[currentUserId]}
              currentRoom={currentRoom}
              blockedMe={blockedMe}
              blockedByMe={blockedByMe}
              closedChat={closedChat}
              dispatch={this.props.dispatch}
            />
          </div> : activeRoom ? 
          <div className="chat-body">
          <RoomHead
            currentRoom={currentRoomObj}
            roomsLoaded={roomsLoaded}
            blockedByMe={blockedByMe}
            onCloseRoom={this.handleCloseRoom}
            members={members}
            user={profile}
          />

          <RoomHistory
            messages={messages}
            members={members}
            currentRoom={currentRoom}
            myUser={profile}
            chatUser={chatUser}
            messagesLoading={messagesLoading}
            onLoadNextPage={this.handleFetchChatData}
            hasNextPage={hasNexPage}
            offset={offset}
          />

          <Sender
            userId={profile.id}
            receiverId={members}
            currentRoom={currentRoom}
            blockedMe={blockedMe}
            blockedByMe={blockedByMe}
            closedChat={closedChat}
            dispatch={this.props.dispatch}
          />
        </div> : (users.length > 0 || joinedRooms.length> 0)? <div className="choose-user-message">Please choose user/room, to start chat with.</div> : ''
        }
      </div>
    )
  }
}

const select = (state) => {
  const profile = state.getIn(['users', 'profile'], i.Map()).toJS();
  let users = state.getIn(['chat', 'users'], i.List()).toJS();
  const joinedRooms = state.getIn(['chat', 'joinedRooms'], i.List()).toJS();
  
  const currentRoom = state.getIn(['chat', 'currentRoom']) ? state.getIn(['chat', 'currentRoom']): 0;
  let currentRoomObj;
  joinedRooms.map((room) => {
    if (room.chatRoomId == currentRoom) {
      currentRoomObj = room
      // break;
    }
  })
  const messages = state.getIn(['chat', 'messages', currentRoom, 'data'], i.List()).toJS().reverse();
  const members = state.getIn(['chat', 'members', currentRoom, 'data'], i.List());
  const roomsLoaded = state.getIn(['chat', 'roomsLoaded']);
  const roomsListen = state.getIn(['chat', 'roomsListen']);
  const onlineUsers = state.getIn(['chat', 'onlineUsers'], i.List()).toJS();
  const currentUserId = state.getIn(['chat', 'currentUserId'], null);
  const messagesLoading = state.getIn(['chat', 'loading'], false);
  const limit = state.getIn(['chat', 'messages', currentRoom, 'limit'], 20);
  const offset = state.getIn(['chat', 'messages', currentRoom, 'offset'], 0);
  const messageCount = state.getIn(['chat', 'messages', currentRoom, 'count'], 0);
  const hasNexPage = messageCount > offset;
  const myUserId = state.getIn(['users', 'myUserId'], null);
  const roomsWithNewMessages = state.getIn(['chat', 'roomsWithNewMessages']);
  const closedRooms = state.getIn(['chat', 'closedRooms'], []);
  let activeRoom = false
  if (currentRoom) {
    activeRoom = true
  }
  let chatUser = {};

  if (currentUserId) {
    chatUser = users.find((user) => user.id === currentUserId);
  }

  let closedChat = false;

  if (chatUser && chatUser.id) {
    closedChat = closedRooms.indexOf(chatUser.id) > -1;
  }
  return { profile,
    users,
    currentRoom,
    roomsLoaded,
    onlineUsers,
    roomsListen,
    messages,
    chatUser,
    messagesLoading,
    limit,
    offset,
    currentUserId,
    hasNexPage,
    myUserId,
    roomsWithNewMessages,
    closedChat,
    joinedRooms,
    activeRoom,
    members,
    currentRoomObj }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default withRouter(connect(select, mapDispatchToProps)(Chat));

Chat.propTypes = {
  chatUser: PropTypes.object,
  closedChat: PropTypes.bool,
  currentRoom: PropTypes.number,
  currentUserId: PropTypes.number,
  dispatch: PropTypes.func,
  hasNexPage: PropTypes.bool,
  history: PropTypes.object,
  limit: PropTypes.number,
  location: PropTypes.object,
  messages: PropTypes.array,
  messagesLoading: PropTypes.bool,
  myUserId: PropTypes.number,
  offset: PropTypes.number,
  onlineUsers: PropTypes.array,
  profile: PropTypes.object,
  rooms: PropTypes.array,
  joinedRooms: PropTypes.array,
  roomsLoaded: PropTypes.bool,
  roomsWithNewMessages: PropTypes.array,
  activeRoom: PropTypes.bool,
  members: PropTypes.object,
  currentRoomObj: PropTypes.object
};
