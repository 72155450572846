export const FETCH_BUNDLE_BEGIN   = 'FETCH_BUNDLE_BEGIN';
export const FETCH_BUNDLE_SUCCESS = 'FETCH_BUNDLE_SUCCESS';
export const FETCH_BUNDLE_FAILURE = 'FETCH_BUNDLE_FAILURE';
export const BUNDLE_PAYMENT_REQUIRED = 'BUNDLE_PAYMENT_REQUIRED';
export const CREATE_BUNDLE_BEGIN   = 'CREATE_BUNDLE_BEGIN';
export const CREATE_BUNDLE_SUCCESS = 'CREATE_BUNDLE_SUCCESS';
export const CREATE_BUNDLE_FAILURE = 'CREATE_BUNDLE_FAILURE';


export const fetchBundleBegin = () => ({
  type: FETCH_BUNDLE_BEGIN
});

export const fetchBundleSuccess = (bundle) => ({
  type: FETCH_BUNDLE_SUCCESS,
  data: { bundle }
});

export const fetchBundleError = (error) => ({
  type: FETCH_BUNDLE_FAILURE,
  data: { error }
});

export const bundlePaymentRequired = (bundle) => ({
  type: BUNDLE_PAYMENT_REQUIRED,
  data: { bundle }
});

export const createBundleBegin = () => ({
  type: CREATE_BUNDLE_BEGIN
});

export const createBundleSuccess = (pick) => ({
  type: CREATE_BUNDLE_SUCCESS,
  data: { pick }
});

export const createBundleError = (error) => ({
  type: CREATE_BUNDLE_FAILURE,
  data: { error }
});
