import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { connect } from "../../store/context";
import { updateProfile } from "../../api/users";
import { isValidTextField, isFormValid } from "../../helpers/validators";


class Information extends Component {
  state = {
    bio: this.props.bio,
    firstName: this.props.firstName,
    lastName: this.props.lastName,
    formMessages: {},
    validFields: { bio: true, firstName: true, lastName: true },
    formChanged: false
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });

    const { validFields, formMessages } = this.state;
    const { isValidField, error } = isValidTextField(event.target.value, event.target.name, null, event.target.required);
    const key = event.target.id;
    formMessages[key] = error;

    if (isValidField || (!event.target.required && event.target.value === "")) {
      validFields[key] = true;
      formMessages[key] = null;
    } else {
      validFields[key] = false;
    }

    this.setState({formMessages, validFields});

    if (!this.state.formChanged) {
      this.setState({formChanged: true});
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { dispatch } = this.props;
    const { firstName, lastName, bio } = this.state;

    updateProfile(dispatch, {firstName, lastName, bio});
  }

  render() {
    const {
      firstName,
      lastName,
      bio,
      formMessages,
      validFields,
      formChanged
    } = this.state;
    const { role, loading } = this.props;

    const formIsInvalid = isFormValid(validFields);

    return (
      <form className="form-horizontal no-overflow" onSubmit={this.handleSubmit}>
        <div className="information">
          <div className={formMessages.firstName ? "form-group has-error" : "form-group"}>
            <label className="control-label" htmlFor="firstName">
              <span>First Name</span>
              <span className="asterisk">*</span>
            </label>
            <div className="input-group no-overflow shadow">
              <input
                id="firstName"
                type="text"
                name="name"
                className="form-control"
                placeholder="First Name"
                value={firstName}
                onChange={this.handleChange}
                maxLength="30"
                required
              />
              <span className="input-group-addon"><i className="fa fa-user" /></span>
            </div>
            <span className="help-block">{formMessages.firstName}</span>
          </div>

          <div className={formMessages.lastName ? "form-group has-error" : "form-group"}>
            <label className="control-label" htmlFor="lastName">Last Name</label>
            <div className="input-group no-overflow shadow">
              <input
                id="lastName"
                type="text"
                name="name"
                className="form-control"
                placeholder="Last Name"
                value={lastName}
                onChange={this.handleChange}
                maxLength="30"
              />
              <span className="input-group-addon"><i className="fa fa-user" /></span>
            </div>
            <span className="help-block">{formMessages.lastName}</span>
          </div>

          {role === 'consultant' &&
            <div className={formMessages.bio ? "form-group has-error" : "form-group"}>
              <label className="control-label" htmlFor="bio">Bio</label>
              <div className="input-group no-overflow shadow">
                <textarea
                  id="bio"
                  className="form-control"
                  placeholder="Your Bio"
                  name="bio"
                  rows="4"
                  value={bio}
                  onChange={this.handleChange}
                />
                <span className="input-group-addon"><i className="fa fa-info" /></span>
              </div>
              <span className="help-block">{formMessages.bio}</span>
            </div>
          }
        </div>

        <div className="navigation">
          <Link to="/profile" className="btn btn-default" >Cancel</Link>
          <button
            disabled={formIsInvalid || !formChanged || loading}
            type="submit"
            className={`btn btn-warning ${loading && 'loading'}`}
          >Save
          </button>
        </div>
      </form>
    );
  }
}

function select(state) {
  const bio = state.getIn(['users', 'profile', 'bio'], String());
  const firstName = state.getIn(['users', 'profile', 'firstName'], String());
  const lastName = state.getIn(['users', 'profile', 'lastName'], String());
  const role = state.getIn(['users', 'profile', 'role'], String());
  const loading = state.getIn(['users', 'loading'], false);

  return { bio, firstName, lastName, role, loading }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Information);

Information.propTypes = {
  bio: PropTypes.string,
  dispatch: PropTypes.func,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  loading: PropTypes.bool,
  role: PropTypes.string
};
