import React, { Component } from "react";
import { Link } from "react-router-dom";
import { changePassword } from "../../api/users";
import { isValidTextField, isFormValid } from "../../helpers/validators";


class Password extends Component {
  state = {
    oldPassword: '',
    password: '',
    confirmPassword: '',
    formMessages: {},
    validFields: { password: false, confirmPassword: false },
    loading: false
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });

    const { validFields, password, formMessages } = this.state;
    const { isValidField, error } = isValidTextField(event.target.value, event.target.name, password, event.target.required);
    const key = event.target.id;
    formMessages[key] = error;

    if (isValidField || (!event.target.required && event.target.value === "")) {
      validFields[key] = true;
      formMessages[key] = null;
    } else {
      validFields[key] = false;
    }

    this.setState({formMessages, validFields});
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { oldPassword, password, confirmPassword } = this.state;

    if (confirmPassword.length > 0) {
      this.setState({loading: true});
      await changePassword(oldPassword, password, confirmPassword);
      this.setState({loading: false});
    }
  }

  render() {
    const {
      oldPassword,
      password,
      confirmPassword,
      formMessages,
      validFields,
      loading
    } = this.state;

    const formIsInvalid = isFormValid(validFields);

    return (
      <form className="form-horizontal no-overflow" onSubmit={this.handleSubmit}>
        <div className="password">
          <div className={formMessages.oldPassword ? "form-group has-error" : "form-group"}>
            <label className="control-label" htmlFor="oldPassword">Old password</label>
            <span className="asterisk">*</span>
            <div className="input-group no-overflow shadow">
              <input
                id="oldPassword"
                type="password"
                name="oldPassword"
                className="form-control"
                placeholder="Old password"
                value={oldPassword}
                onChange={this.handleChange}
                required
              />
              <span className="input-group-addon"><i className="fa fa-lock" /></span>
            </div>
            <span className="help-block">{formMessages.oldPassword}</span>
          </div>

          <div className={formMessages.password ? "form-group has-error" : "form-group"}>
            <label className="control-label" htmlFor="password">New password</label>
            <span className="asterisk">*</span>
            <div className="input-group no-overflow shadow">
              <input
                id="password"
                type="password"
                name="password"
                className="form-control"
                placeholder="New password"
                value={password}
                onChange={this.handleChange}
                required
              />
              <span className="input-group-addon"><i className="fa fa-lock" /></span>
            </div>
            <span className="help-block">{formMessages.password}</span>
          </div>

          <div className={formMessages.confirmPassword ? "form-group has-error" : "form-group"}>
            <label className="control-label" htmlFor="confirmPassword">Confirm new password</label>
            <span className="asterisk">*</span>
            <div className="input-group no-overflow shadow">
              <input
                id="confirmPassword"
                type="password"
                name="confirmPassword"
                className="form-control"
                placeholder="New password"
                value={confirmPassword}
                onChange={this.handleChange}
                disabled={!validFields.password || !password}
                required
              />
              <span className="input-group-addon"><i className="fa fa-check" /></span>
            </div>
            <span className="help-block">{formMessages.confirmPassword}</span>
          </div>
        </div>

        <div className="navigation">
          <Link to="/profile" className="btn btn-default" >Cancel</Link>
          <button
            disabled={formIsInvalid || loading}
            type="submit"
            className={`btn btn-warning ${loading && 'loading'}`}
          >Save
          </button>
        </div>
      </form>
    );
  }
}


export default Password;
