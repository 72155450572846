import { Map, List, fromJS } from 'immutable';
import {
  FETCH_MY_PARLAYS_PREGAME_BEGIN,
  FETCH_MY_PARLAYS_PREGAME_SUCCESS,
  FETCH_MY_PARLAYS_PREGAME_FAILURE,
  FETCH_MY_PARLAYS_INPROGRESS_BEGIN,
  FETCH_MY_PARLAYS_INPROGRESS_SUCCESS,
  FETCH_MY_PARLAYS_INPROGRESS_FAILURE,
  FETCH_MY_PARLAYS_FINAL_BEGIN,
  FETCH_MY_PARLAYS_FINAL_SUCCESS,
  FETCH_MY_PARLAYS_FINAL_FAILURE
} from '../actions/parlays';


const initialState = Map({
  loading: false,
  error: null,
  pregameParlays: List(),
  inprogressParlays: List(),
  finalParlays: Map(),
  loadingPreGame: false
});

export default function parlaysReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MY_PARLAYS_PREGAME_BEGIN:
      state = state.set('loadingPreGame', true);
      break;
    case FETCH_MY_PARLAYS_PREGAME_SUCCESS:
      state = state.set('pregameParlays', fromJS(action.data.parlays));
      state = state.set('loadingPreGame', false);
      break;
    case FETCH_MY_PARLAYS_PREGAME_FAILURE:
      state = state.set('loadingPreGame', false);
      state = state.set('error', action.error);
      break;
    case FETCH_MY_PARLAYS_INPROGRESS_BEGIN:
      state = state.set('loading', true);
      break;
    case FETCH_MY_PARLAYS_INPROGRESS_SUCCESS:
      state = state.set('inprogressParlays', fromJS(action.data.parlays));
      state = state.set('loading', false);
      break;
    case FETCH_MY_PARLAYS_INPROGRESS_FAILURE:
      state = state.set('loading', false);
      state = state.set('error', action.error);
      break;
    case FETCH_MY_PARLAYS_FINAL_BEGIN:
      state = state.set('loading', true);
      break;
    case FETCH_MY_PARLAYS_FINAL_SUCCESS:
      state = state.setIn(['finalParlays', 'parlays'], fromJS(action.data.data.parlays));
      state = state.setIn(['finalParlays', 'pages'], fromJS(action.data.data.pages));
      state = state.setIn(['finalParlays', 'page'], fromJS(action.data.data.currentPage));
      state = state.setIn(['finalParlays', 'count'], fromJS(action.data.data.count));
      state = state.setIn(['finalParlays', 'limit'], fromJS(action.data.data.limit));
      state = state.set('loading', false);
      break;
    case FETCH_MY_PARLAYS_FINAL_FAILURE:
      state = state.set('loading', false);
      state = state.set('error', action.error);
      break;
    default:
      return state;
  }

  return state;
}
