export const FETCH_SPORT_ROOMS_BEGIN   = 'FETCH_SPORT_ROOMS_BEGIN';
export const FETCH_SPORT_ROOMS_SUCCESS = 'FETCH_SPORT_ROOMS_SUCCESS';
export const FETCH_SPORT_ROOMS_FAILURE = 'FETCH_SPORT_ROOMS_FAILURE';


export const fetchSportRoomsBegin = () => ({
  type: FETCH_SPORT_ROOMS_BEGIN
});

export const fetchSportRoomsSuccess = (rooms) => ({
  type: FETCH_SPORT_ROOMS_SUCCESS,
  rooms
});

export const fetchSportRoomsError = (error) => ({
  type: FETCH_SPORT_ROOMS_FAILURE,
  payload: { error }
});

