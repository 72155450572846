import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import Avatar from 'react-avatar-edit'
import "./style.scss";


class AvatarModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
      preview: null
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleCloseImage = () => {
    this.setState({ preview: null })
  }

  handleSubmit = () => {
    this.props.newAvatar(this.state.preview);
    this.setState({ show: false });
  }

  handleCrop = (preview) => {
    this.setState({ preview })
  }

  render() {
    return (
      <div className="upload-avatar">
        <Button onClick={this.handleShow}>
          Upload Avatar
        </Button>

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Avatar</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="add-avatar">
              <Avatar
                className="avatar"
                width={300}
                height={300}
                onCrop={this.handleCrop}
                onClose={this.handleCloseImage}
              />
              {this.state.preview && <img src={this.state.preview} alt="Preview" />}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button onClick={this.handleSubmit} className="btn btn-warning btn-lg btn-block no-margin">Ok</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default AvatarModal;
