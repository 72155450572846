import React,  { Component } from 'react';
import i from 'immutable';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Steps } from 'intro.js-react';
// import { Activity } from "../components/users/Activity";
import { getUserStatistic } from "../api/users";
import { connect } from "../store/context";

import Wrapper from '../components/ui/Wrapper';
import Cover from '../components/profile/Cover';
import MyProfileInfo from '../components/profile/MyProfileInfo';
import Stats from '../components/profile/Stats/index';
import ChartsFilter from '../components/profile/ChartsFilter/index';
import RoiChart from '../components/profile/RoiChart';
import DeviationChart from '../components/profile/DeviationChart';
import PicksChart from '../components/profile/PicksChart';
import { minTwoDigits } from "../helpers/minTwoDigits.js";
import { profileIntro, introOptions } from '../helpers/intro';
import 'intro.js/introjs.css';


class Profile extends Component {
  constructor(props) {
    super(props);
    const { period = 7, sportId } = queryString.parse(props.location.search);
    this.state = {
      getData: true,
      stepsEnabled: false,
      introSteps: [],
      showPicks: false,
      period,
      sportId
    };
  }
  componentWillReceiveProps(newProps) {
    const { profile: { id } } = this.props;
    const { getData, period, sportId } = this.state;

    const shouldGetData = id && getData;

    if (shouldGetData) {
      const { dispatch } = this.props;
      getUserStatistic(dispatch, newProps.profile.id, period, sportId);
      this.setState({getData: false})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch, profile: { id }, history } = this.props;
    const { period, sportId } = this.state;

    const filterChanged = period !== prevState.period || sportId !== prevState.sportId

    if (filterChanged) {
      history.push({
        pathname: '/profile',
        search: `${period ? `?period=${period}` : ''}${sportId ? `&sportId=${sportId}` : ''}`
      });
      getUserStatistic(dispatch, id, period, sportId);
    }
  }

  handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem('token');
    window.location.reload();
  }

  handleHideIntro = () => {
    this.setState({stepsEnabled: false});
  }

  onChangePeriod = (event) => {
    this.setState({
      period: event.target.value
    });
  }

  onChangeSport = (event) => {
    this.setState({
      sportId: event.target.value || null
    });
  }

  render() {
    const { profile,
      statisticDates,
      statisticDeviationSpread,
      statisticDeviationTotal,
      statisticRoiTotal,
      statisticRoiSpread,
      statisticRoiMoneyLine,
      statisticRoiDrawLine,
      statisticPicksWin,
      statisticPicksTotal } = this.props;

    const { id } = profile;
    const { stepsEnabled, introSteps, sportId, period, showPicks } = this.state;

    if (id) {
      const firstOpenProfile = localStorage.getItem('profileIntro');

      if (!stepsEnabled && firstOpenProfile === 'true') {
        const steps = [].concat(profileIntro.steps);

        if (profile.role === 'consultant' && steps[0].element === '#become-consultant') {
          steps.splice(0, 1);
        }

        this.setState({stepsEnabled: true, introSteps: steps});
        localStorage.removeItem('profileIntro');
      }
    }

    return (
      <Wrapper className="profile-page">
        <Steps
          enabled={stepsEnabled}
          steps={introSteps}
          initialStep={0}
          onExit={this.handleHideIntro}
          options={introOptions}
        />
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-4">
            <MyProfileInfo
              onLogout={this.handleLogout}
              onShareProfile={this.handleShareProfile}
              {...profile}
            />
          </div>
          <div className="col-lg-9 col-md-8 col-sm-8">
            <Cover myProfile profileImage={profile.profileImage} />
            <Stats
              followersCount={profile.followersCount}
              ROIMoneyLine={Number(statisticRoiMoneyLine[statisticRoiMoneyLine.length - 1])}
              ROISpread={Number(statisticRoiSpread[statisticRoiSpread.length - 1])}
              ROITotal={Number(statisticRoiTotal[statisticRoiTotal.length - 1])}
              ROIDrawLine={Number(statisticRoiDrawLine[statisticRoiDrawLine.length - 1])}
              DeviationSpread={Number(statisticDeviationSpread[statisticDeviationSpread.length - 1])}
              DeviationTotal={Number(statisticDeviationTotal[statisticDeviationTotal.length - 1])}
            />
            <ChartsFilter
              id={id}
              showPicks={showPicks}
              selectedPeriod={period}
              selectedSportId={sportId}
              handleChangePeriod={this.onChangePeriod}
              handleChangeSport={this.onChangeSport}
            />
            <RoiChart
              dates={statisticDates}
              spread={statisticRoiSpread}
              total={statisticRoiTotal}
              moneyLine={statisticRoiMoneyLine}
              draw={statisticRoiDrawLine}
              sportId={sportId}
            />
            <DeviationChart
              dates={statisticDates}
              spread={statisticDeviationSpread}
              total={statisticDeviationTotal}
            />
            <PicksChart
              dates={statisticDates}
              win={statisticPicksWin}
              total={statisticPicksTotal}
            />
            {/* <div className="timeline">
              {
                followings.length > 0 && followings.map((user) => {
                  return user.id && <Activity key={user.id} {...user} />
                })
              }
            </div> */}
          </div>
        </div>
      </Wrapper>
    );
  }
}

function select(state) {
  const profile = state.getIn(['users', 'profile'], i.Map()).toJS();
  const followings = state.getIn(['users', 'profile', 'following'], i.Map()).toJS();
  const userStatistic = state.getIn(['users', 'userStatistic'], i.List()).toJS();
  const statisticDates = userStatistic.map((item) => {
    const date = new Date(item.Date).getDate();
    const month = new Date(item.Date).getMonth() + 1;
    return `${minTwoDigits(date)}.${minTwoDigits(month)}`
  });
  const statisticRoiTotal = userStatistic.map((item) => parseFloat(item.ROITotal.toFixed(2)));
  const statisticRoiSpread = userStatistic.map((item) => parseFloat(item.ROISpread.toFixed(2)));
  const statisticRoiMoneyLine = userStatistic.map((item) => parseFloat(item.ROIMoneyLine.toFixed(2)));
  const statisticRoiDrawLine = userStatistic.map((item) => item.ROIDrawLine ? parseFloat(item.ROIDrawLine.toFixed(2)) : 0);
  const statisticDeviationSpread = userStatistic.map((item) => item.DeviationSpread ? parseFloat(item.DeviationSpread.toFixed(2)) : 0);
  const statisticDeviationTotal = userStatistic.map((item) => item.DeviationTotal ? parseFloat(item.DeviationTotal.toFixed(2)) : 0);
  const statisticPicksWin = userStatistic.map((item) => item.WinPicks);
  const statisticPicksTotal = userStatistic.map((item) => item.TotalPicks);

  return { profile,
    userStatistic,
    followings,
    statisticDates,
    statisticDeviationSpread,
    statisticDeviationTotal,
    statisticRoiTotal,
    statisticRoiSpread,
    statisticRoiMoneyLine,
    statisticRoiDrawLine,
    statisticPicksWin,
    statisticPicksTotal }
}


export default connect(select)(Profile);

Profile.propTypes = {
  followings: PropTypes.object,
  profile: PropTypes.object,
  userId: PropTypes.number
};
