export const FETCH_ODDS_BEGIN   = 'FETCH_ODDS_BEGIN';
export const FETCH_ODDS_SUCCESS = 'FETCH_ODDS_SUCCESS';
export const FETCH_ODDS_FAILURE = 'FETCH_ODDS_FAILURE';

export const fetchOddsBegin = () => ({
  type: FETCH_ODDS_BEGIN
});

export const fetchOddsSuccess = (odds) => ({
  type: FETCH_ODDS_SUCCESS,
  data: { odds }
});

export const fetchOddsError = (error) => ({
  type: FETCH_ODDS_FAILURE,
  data: { error }
});
