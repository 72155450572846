import React,  { Component } from 'react';
import i from 'immutable';
import PropTypes from 'prop-types';
import CONFIG from '../../../config/config';
import Paginator from '../../../components/general/Paginator'
import { connect } from "../../../store/context";
import { getSales, getSalesStats, promoteContent, getPendingBalance, getAffiliatePendingBalance, submitWithdrawRequest } from "../../../api/payments";
import { getProfile } from "../../../api/users";
import Wrapper from '../../../components/ui/Wrapper';
import { alertSuccessWithConfirmaiton } from '../../../actions/alerts';
import WithdrawModal from '../withdraw-modal';
import SalesRow from "./sales-row";
import FeeRow from "./fee-row";
import "./style.scss";


class Sales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      withdrawOpen: false,
      salesFilter: 'picks',
      currentPage: 1
    };
  }

  componentDidMount = () => {
    const { dispatch } = this.props;
    getSalesStats(dispatch);
    getPendingBalance(dispatch);
    getAffiliatePendingBalance(dispatch);
    this.onSalesUpdate();
  }

  onSalesUpdate = () => {
    const { dispatch, limit } = this.props;
    const { currentPage, salesFilter } = this.state;
    getSales(dispatch, currentPage, limit, salesFilter);
  }

  handleWithdrawClick = () => {
    this.setState({ withdrawOpen: true});
  }

  handleClosemModal = () => {
    this.setState({ withdrawOpen: false});
  }

  handleWithdrawRequest = async (amount) => {
    const { dispatch } = this.props;
    await submitWithdrawRequest(dispatch, amount);
    getProfile(dispatch);
    this.handleClosemModal();
  }

  onPromoteContent = async (itemId, Pick) => {
    const { dispatch } = this.props;
    const result = await alertSuccessWithConfirmaiton(`${Pick ? 'Pick' : 'Parlay'} promotion confirmation`,
      `Please confirm that you wish to promote this ${Pick ? 'Pick' : 'Parlay'} for ${CONFIG.promotionPeriod} days to all users on SportsTrader® platform at ${CONFIG.promotionPrice} USD`);

    if (result.value) {
      await promoteContent(dispatch, itemId, Pick ? 'Pick' : 'Parlay');
      getProfile(dispatch);
    }
  }

  onClickPagination = async (event) => {
    const page = event.target.innerText;
    const { pages } = this.props;

    if (page === 'First') {
      await this.setState({currentPage: 1 });
    } else if (page === 'Last') {
      await this.setState({currentPage: pages });
    } else {
      await this.setState({currentPage: Number(page) || 1 });
    }

    this.onSalesUpdate();
  }

  handleChangeFilter = async (event) => {
    await this.setState({salesFilter: event.target.value, currentPage: 1});
    this.onSalesUpdate();
  }

  render() {
    const { profile, soldItems, pages, pendingBalance, pandingAffiliateBalance, loading } = this.props;
    const { soldToday, soldThisWeek, gamesToday, gamesThisWeek } = this.props.stats;
    const { balance } = profile;
    const { withdrawOpen, currentPage } = this.state;
    const items = soldItems.map((item) => {
      return (<>
        <SalesRow key={item.itemId} sales={item} handlePromoteContent={this.onPromoteContent} />
        <FeeRow key={`fee-${item.itemId}`} sales={item} />
        </>);
    });

    return (
      <Wrapper
        title="Sales Board"
        icon="fa fa-calendar-check-o"
      >
        <div className="body-content animated fadeIn" id="sales-page">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="mini-stat clearfix bg-facebook">
                <span className="mini-stat-icon">
                  <i className="fa fa-shopping-bag" />
                </span>
                <div className="mini-stat-info">
                  <span className="counter">{soldToday}</span>Sold Today
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="mini-stat clearfix bg-primary">
                <span className="mini-stat-icon">
                  <i className="fa fa-shopping-bag" />
                </span>
                <div className="mini-stat-info">
                  <span className="counter">{soldThisWeek}</span>Sold This Week
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="mini-stat clearfix bg-googleplus">
                <span className="mini-stat-icon">
                  <i className="fa fa-trophy" />
                </span>
                <div className="mini-stat-info">
                  <span className="counter">{gamesToday}</span>Games Today
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="mini-stat clearfix bg-bitbucket">
                <span className="mini-stat-icon">
                  <i className="fa fa-trophy" />
                </span>
                <div className="mini-stat-info">
                  <span className="counter">{gamesThisWeek}</span>Games This Week
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="panel shadow panel-team-board">
                <div className="panel-sub-heading inner-all">
                  <div className="pull-left">
                    <h5 className="panel-title">
                      <span>Available Balance: <span>${Number(balance).toFixed(2)}</span></span>
                      <span>Pending Balance: <span>${Number(pendingBalance).toFixed(2)}</span></span>
                      <span>Affiliate Balance: <span>${Number(pandingAffiliateBalance).toFixed(2)}</span></span>
                    </h5>
                  </div>
                  <div className="pull-right">
                    <h5 className="panel-title">
                      <button onClick={this.handleWithdrawClick} className="btn btn-warning">Withdraw</button>
                    </h5>
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="panel-body">
                  <select className="form-control sales-filter" onChange={this.handleChangeFilter}>
                    <option value="picks">Picks</option>
                    <option value="parlays">Parlays</option>
                    <option value="bundles">Bundles</option>
                    <option value="subscriptions">Subscriptions</option>
                  </select>
                  <table className="sales-table">
                    <thead>
                      <th colSpan="1">Sold</th>
                      <th>Status</th>
                      <th colSpan="3">Item Name</th>
                      <th colSpan="1">Price</th>
                      <th colSpan="1">Net Total</th>
                      <th colSpan="2">Game Date</th>
                      <th colSpan="2">Created</th>
                      <th colSpan="1">Actions</th>
                    </thead>

                    <tbody>
                      {items}
                    </tbody>
                  </table>
                </div>
              </div>
              <Paginator pages={pages} currentPage={currentPage} handleClick={this.onClickPagination} />
            </div>
          </div>
        </div>
        <WithdrawModal
          loading={loading}
          show={withdrawOpen}
          balance={profile.balance}
          onCloseModal={this.handleClosemModal}
          onSubmitWithdrawReqets={this.handleWithdrawRequest}
        />
      </Wrapper>
    );
  }
}

function select(state) {
  const profile = state.getIn(['users', 'profile'], i.Map()).toJS();
  const soldItems = state.getIn(['payments', 'soldItems'], i.List()).toJS();
  const pages = state.getIn(['payments', 'pages'], 0);
  const stats = state.getIn(['payments', 'stats'], i.Map()).toJS();
  const limit = state.getIn(['payments', 'limit'], 20);
  const offset = state.getIn(['payments', 'offset'], 0);
  const pendingBalance = state.getIn(['payments', 'pending_balance'], 0);
  const pandingAffiliateBalance = state.getIn(['payments', 'pending_affiliate_balance'], 0);
  const loading = state.getIn(['payments', 'loading'], false);

  return { profile, soldItems, pages, stats, limit, offset, pendingBalance, pandingAffiliateBalance, loading }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Sales);

Sales.propTypes = {
  dispatch: PropTypes.func,
  limit: PropTypes.number,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  pandingAffiliateBalance: PropTypes.number,
  pendingBalance: PropTypes.number,
  profile: PropTypes.object,
  soldItems: PropTypes.array,
  stats: PropTypes.object
};
