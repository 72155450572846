import React, { Component } from "react";
import axios from 'axios';
import queryString from 'query-string';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { alertError, alertSuccess } from '../../actions/alerts'
import CONFIG from '../../config/config';
import { isValidTextField, isFormValid } from "../../helpers/validators";
import Wrapper from '../../components/ui/Wrapper';


export default class ConfirmPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      errorMessage: {},
      validFields: { "password": false, "confirmPassword": false }
    };
  }

  componentDidMount = () => {
    const { search } = this.props.location;
    const { confirmationCode } = queryString.parse(search);
    this.setState({ confirmationCode });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });

    const { validFields, password } = this.state;
    const { isValidField, error } = isValidTextField(event.target.value, event.target.name, password);
    const errorMessage = {};
    const key = event.target.name;
    errorMessage[key] = error;

    if (isValidField) {
      validFields[key] = true;
    } else {
      validFields[key] = false;
    }

    this.setState({errorMessage, validFields});
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { password, confirmPassword, confirmationCode } = this.state;

      if (password === confirmPassword) {
        const { data } = await axios.post(`${CONFIG.api_url}/users/password/forgot/confirm`, { confirmationCode, password });

        if (data.success) {
          alertSuccess(data.message);
          const { history } = this.props;
          history.push("/login");
        }
      }
    } catch (e) {
      console.log(e.response.data);  //eslint-disable-line no-console
      alertError(e)
    }
  }


  render() {
    const { errorMessage, validFields } = this.state;
    const formIsInvalid = isFormValid(validFields);

    return (
      <Wrapper
        className="confirm-password-page auth-page"
      >
        <div className="auth">
          <form className="sign-in form-horizontal shadow no-overflow" onSubmit={this.handleSubmit} method="post">
            <div className="sign-header">
              <div className="form-group">
                <div className="sign-text">
                  <span>Reset password</span>
                </div>
              </div>
            </div>
            <div className="sign-body">
              <div className={errorMessage.password ? "form-group has-error" : "form-group"}>
                <div className="input-group input-group-lg rounded no-overflow">
                  <input
                    type="password"
                    className="form-control input-sm"
                    placeholder="Password"
                    id="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    required
                  />
                  <span className="input-group-addon"><i className="fa fa-lock fa-fw" /></span>
                </div>
                <span className="help-block">{errorMessage.password}</span>
              </div>
              <div className={errorMessage.password ? "form-group has-error" : "form-group"}>
                <div className="input-group input-group-lg rounded no-overflow">
                  <input
                    type="password"
                    className="form-control input-sm"
                    placeholder="Confirm Password"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={this.state.confirmPassword}
                    onChange={this.handleChange}
                    required
                  />
                  <span className="input-group-addon"><i className="fa fa-check fa-fw" /></span>
                </div>
                <span className="help-block">{errorMessage.confirmPassword}</span>
              </div>
            </div>
            <div className="sign-footer">
              <div className="form-group">
                <div className="row">
                  <div className="col-xs-6">
                    <NavLink to="/signup">Sign Up (Free)</NavLink>
                  </div>
                  <div className="col-xs-6 text-right">
                    <NavLink to="/login">Login</NavLink>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button disabled={formIsInvalid} type="submit" className="btn btn-theme btn-lg btn-block no-margin rounded" id="login-btn">Save new password</button>
              </div>
            </div>
          </form>
        </div>
      </Wrapper>
    );
  }
}

ConfirmPassword.propTypes = {
  location: PropTypes.object
};
