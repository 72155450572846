import React, { Component } from 'react';
import i from 'immutable';
import PropTypes from 'prop-types';
import { connect } from "../store/context";
import SportRoom from "../components/room/SportRoom/index";
import queryString from "query-string"
import { fetchRooms } from "../api/chat"
import { getUserRooms, joinRoom } from "../api/chat"
import { getAPIContentfeed } from '../api/content-feed';
import Wrapper from '../components/ui/Wrapper'
import RoomSearch from '../components/search/RoomSearch/index'
import EmptyPage from '../components/general/EmptyPage'
import CreateRoomModal from '../components/Chat/CreateRoomModal'
import Loader from '../components/ui/Loader/index';


class Rooms extends Component {
  componentDidMount = () => {
    const { dispatch } = this.props;
    const { id } = queryString.parse(window.location.search);
    fetchRooms(dispatch, {limit: 100, offset: 0, sportId: null, roomId: Number(id)});
    getUserRooms(dispatch, 'all')
  }

  getSportDetailsById(sportId) {
    const { Sports } = this.props;
    for (let index in Sports) {
      if (Sports[index].id == sportId) {
        return Sports[index]
      }
    }
    return { id: -1, name: 'unknown' }
  }

  render() {
    const { rooms, followingIds, isAuthorized, Sports, profile, loading, dispatch, userRooms, history } = this.props;

    return (
      <Wrapper
        className="rooms-page"
        title="Rooms"
        icon="fa fa-group"
      >
        <div className="row">
          <div className="col-lg-10 col-md-10 col-sm-10">
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
          {profile.role == 'consultant' && <CreateRoomModal profile={profile} dispatch={dispatch} Sports={Sports}/>}
            <br />
          </div>

        </div>

        <div className="panel">
          <div className="panel-body">
            <RoomSearch Sports={Sports} />
            <div className="rooms">
              {loading && <Loader />}
              {!loading && !rooms.length && <EmptyPage />}
              {
                !loading && rooms.map((room) => {
                  const { id } = room;
  
                  let member = false
                  let blocked = false
                  let pending = false
                  userRooms.map((userAllRoom) => {
                    if (id == userAllRoom.chatRoomId && userAllRoom.status == 1) {
                      member = true
                    } else if (id == userAllRoom.chatRoomId && userAllRoom.status == 2) {
                      blocked = true
                    } else if (id == userAllRoom.chatRoomId && userAllRoom.status == 3) {
                      pending = true
                    }
                  })
                  return (
                    <SportRoom
                      key={id}
                      room={room}
                      isFollowing={followingIds.includes(room.user.id)}
                      member={member}
                      blocked={blocked}
                      pending={pending}
                      isAuthorized={isAuthorized}
                      profile={profile}
                      roomName={room.roomName}
                      sport={this.getSportDetailsById(room.sportId)}
                      type={room.type}
                      dispatch={dispatch}
                      history={history}
                      {...room.user}
                    />
                  )
                })
              }
            </div>
          </div>
        </div>
        
      </Wrapper>
    );
  }
}

function select(state) {
  const rooms = state.getIn(['rooms', 'items'], i.List()).toJS();
  const followings = state.getIn(['users', 'profile', 'following'], i.List()).toJS();
  const followingIds = followings.map((user) => user.id);
  const userRooms = state.getIn(['chat', 'all'], i.List()).toJS();
  const isAuthorized = Boolean(state.getIn(['users', 'profile']));
  const Sports = state.getIn(['sports', 'items'], i.List()).toJS();
  const profile = state.getIn(['users', 'profile'], i.Map()).toJS();
  const loading = state.getIn(['rooms', 'loading'], false);

  return { rooms, followings, followingIds, isAuthorized, Sports, profile, loading, userRooms }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Rooms);

Rooms.propTypes = {
  Sports: PropTypes.array
};
