import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { alertCopyLink } from '../../actions/alerts';
import { setDefaultAvatar } from '../../helpers/defaultImage';
import CONFIG from '../../config/config';


const MyProfileInfo = (props) => {
  const { avatar, id, role, bio, firstName, lastName, balance, onLogout } = props;

  const handleShareProfile = () => {
    alertCopyLink('Profile sharable link', `${CONFIG.ui_url}/user/${id}`);
  }

  return (
    <div className="panel rounded shadow">
      <div className="panel-body">
        <div className="inner-all">
          <ul className="list-unstyled">
            <li className="text-center avatar">
              <img data-no-retina="" className="img-circle img-bordered-default" src={avatar} height="130" width="130" alt={firstName} onError={setDefaultAvatar} />
            </li>

            <li className="text-center fullname">
              <h4 className="text-capitalize">
                {firstName
                  ? <span title={`${firstName} ${lastName}`.length > 18 ? `${firstName} ${lastName}` : ''}>{firstName} {lastName}</span>
                  : <span>Anonymous</span>}
              </h4>
              <p className="text-muted text-capitalize">{role}</p>
              {role === 'consultant' &&
              <p className="bio">{bio}</p>}
            </li>

            {role === 'user' &&
            <li>
              <Link id="become-consultant" className="btn btn-warning text-center btn-block" to="/profile/become-consultant">Become Consultant</Link>
            </li>}
            <li className="text-left balance">
              <h4 className="text-capitalize">Balance: {balance ? balance : 0}</h4>
            </li>
            <li>
              <div className="btn-block profile-btns">
                <a href className="btn btn-primary" onClick={handleShareProfile}><i className="fa fa-share-alt pull-right" />Share Link</a>
                <Link id="edit-profile" className="btn btn-default" to="/profile/edit"><i className="fa fa-cog pull-right" />Edit Profile</Link>
                <a href className="btn btn-default" onClick={onLogout}><i className="fa fa-sign-out pull-right" />Logout</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
};

export default MyProfileInfo;

MyProfileInfo.propTypes = {
  avatar: PropTypes.string,
  balance: PropTypes.number,
  bio: PropTypes.string,
  firstName: PropTypes.string,
  id: PropTypes.number,
  lastName: PropTypes.string,
  onLogout: PropTypes.func,
  role: PropTypes.string
}

