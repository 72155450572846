import React, { Component } from 'react';
import moment from 'moment'
import classNames from 'classnames';
import PropTypes from 'prop-types';
import FormattedToBetType from '../../general/bet-type-formatter';
import "./style.scss";


class PicksTable extends Component {
  state = {
    OddID: false,
    PointSpread: null,
    Total: null,
    MoneyLine: null,
    Draw: null,
    Access: 127,
    homePitcher: null,
    awayPitcher: null,
    action: false
  };
  DefulatAccess = 127;
  MH_ACC_M = 117;
  MH_ACC = 189;
  MA_ACC_M = 122;
  MA_ACC = 190;
  SH_ACC_M = 117;
  SH_ACC = 183;
  SA_ACC_M = 122;
  SA_ACC = 187;
  TO_ACC = 95;
  TU_ACC = 111;
  DR_ACC = 112;
  MH = 1;
  MA = 1 << 1;
  SH = 1 << 2;
  SA = 1 << 3;
  TO = 1 << 4;
  TU = 1 << 5;
  DR = 1 << 6;

  updateAccessBits = (item, CanSelectMultyLines, accessMask, accessMaskMulty) => {
    const { Access, PointSpread, MoneyLine, Total, Draw } = this.state;
    let currectAccess = Access;
    let StateItem = PointSpread;

    switch (item) {
      case 'MoneyLine':
        StateItem = MoneyLine;
        break;
      case 'Total':
        StateItem = Total;
        break;
      case 'Draw':
        StateItem = Draw;
        break;
      default:
        break;
    }

    let accessBits = StateItem ? ~accessMask : accessMask;

    if (!CanSelectMultyLines) {
      accessBits = StateItem ? accessBits | ~accessMaskMulty : accessBits & accessMaskMulty;
    }

    currectAccess = StateItem ? currectAccess | accessBits : currectAccess & accessBits;

    return currectAccess;
  }

  matchEnd = (time) => {
    if (moment(time) > moment().add(10, 'minutes')) {
      return '';
    }

    return 'finish-soon';
  }

  //pick selection
  markSelectedOdd = async (odd) => {
    const { OddID, PointSpreadHome, PointSpreadAway } = this.props.odd;
    const { PointSpread, MoneyLine, Total, Draw } = odd;
    const { Access } = this.state;
    let Underdog = false;
    let CanSelectMultyLines = false;
    let selectMuliLines = false;

    if (PointSpreadHome > PointSpreadAway) {
      CanSelectMultyLines = PointSpreadHome > 0.5;
      Underdog = 'Home';
    } else if (PointSpreadHome < PointSpreadAway) {
      CanSelectMultyLines = PointSpreadAway > 0.5;
      Underdog = 'Away';
    }

    if ((PointSpread === Underdog || !PointSpread) && CanSelectMultyLines) {
      selectMuliLines = true
    } else if ((MoneyLine === Underdog || !PointSpread) && CanSelectMultyLines) {
      selectMuliLines = true
    }

    this.setState({OddID});

    let currectAccess = Access;

    if (PointSpread) {
      const StateSpread = this.state.PointSpread;
      const currentSpread = StateSpread ? null : PointSpread;

      if (StateSpread == null) {
        this.setState({Draw: null});
      }

      if (PointSpread === 'Home') {
        currectAccess = this.updateAccessBits('Spread', selectMuliLines, this.SH_ACC, this.SH_ACC_M);
      } else {
        currectAccess = this.updateAccessBits('Spread', selectMuliLines, this.SA_ACC, this.SA_ACC_M);
      }

      this.setState({ Access: currectAccess, PointSpread: currentSpread});
    } else if (MoneyLine) {
      const StateMoneyLine = this.state.MoneyLine;
      const currentMoneyLine = StateMoneyLine ? null : MoneyLine;

      if (StateMoneyLine == null) {
        this.setState({Draw: null});
      }

      if (MoneyLine === 'Home') {
        currectAccess = this.updateAccessBits('MoneyLine', selectMuliLines, this.MH_ACC, this.MH_ACC_M);
      } else {
        currectAccess = this.updateAccessBits('MoneyLine', selectMuliLines, this.MA_ACC, this.MA_ACC_M);
      }

      this.setState({MoneyLine: currentMoneyLine, Access: currectAccess});
    } else if (Total) {
      const StateTotal = this.state.Total;
      const currentTotal = StateTotal ? null : Total;

      if (Total === 'Over') {
        currectAccess = this.updateAccessBits('Total', false, this.TO_ACC, currectAccess);
      } else {
        currectAccess = this.updateAccessBits('Total', false, this.TU_ACC, currectAccess);
      }

      this.setState({Total: currentTotal, Access: currectAccess});
    } else if (Draw) {
      const StateDraw = this.state.Draw;

      if (StateDraw == null) {
        this.setState({PointSpread: null, MoneyLine: null});
      }

      const currentDraw = StateDraw ? null : Draw;

      currectAccess = this.updateAccessBits('Draw', false, this.DR_ACC, currectAccess);
      this.setState({Draw: currentDraw, Access: currectAccess});
    }
  }

    handleSavePick = () => {
      const { MoneyLine, PointSpread, Total, OddID, homePitcher, awayPitcher, Draw } = this.state;
      const { onCreatePick } = this.props;
      onCreatePick(MoneyLine, PointSpread, Total, OddID, homePitcher, awayPitcher, Draw);
    }

    handleSelectActionPitcher = () => {
      const { action } = this.state;
      this.setState({action: !action});
    }

    handleSelectHomePitcher = (value) => {
      const { homePitcher } = this.state;

      if (homePitcher === null) {
        this.setState({homePitcher: value});
      } else {
        this.setState({homePitcher: null});
      }
    }

    handleSelectAwayPitcher = (value) => {
      const { awayPitcher } = this.state;

      if (awayPitcher === null) {
        this.setState({awayPitcher: value});
      } else {
        this.setState({awayPitcher: null});
      }
    }

    render() {
      const { Match,
        OddType,
        PointSpreadAway,
        PointSpreadAwayLine,
        MoneyLineAway,
        TotalNumber,
        OverLine,
        PointSpreadHome,
        PointSpreadHomeLine,
        MoneyLineHome,
        UnderLine,
        DrawLine } = this.props.odd;
      const { MatchTime, AwayTeam, HomeTeam, AwayROT, HomeROT, Sport, AwayPitcher, HomePitcher, Details } = Match;
      const { sportName, loading } = this.props

      const { MoneyLine,
        PointSpread,
        Total,
        Access,
        action,
        homePitcher,
        awayPitcher,
        Draw } = this.state;

      if (OddType !== 'Game') {
        return null;
      }

      const saveAvailible = MoneyLine || PointSpread || Total || Draw;
      const isNoZero = (value) => value !== 0;
      const emptyButton = <button className="btn btn-primary btn-stroke disabled-pick" disabled>-</button>

      const spreadAwayButton = (
        <button
          disabled={(Access & this.SA) === 0} className={classNames(
            { "btn btn-primary": true,
              "btn-stroke": PointSpread !== 'Away',
              "btn-odd-selected": PointSpread === 'Away',
              "disabled-pick": (Access & this.SA) === 0}
          )}
          onClick={() => this.markSelectedOdd({PointSpread: 'Away'})}
        >
          <FormattedToBetType price={0} value={PointSpreadAway} spread /> (<FormattedToBetType price={1} value={PointSpreadAwayLine} />)
        </button>
      )

      const spreadHomeButton = (
        <button
          disabled={(Access & this.SH) === 0} className={classNames(
            { "btn btn-primary": true,
              "btn-stroke": PointSpread !== 'Home',
              "btn-odd-selected": PointSpread === 'Home',
              "disabled-pick": (Access & this.SH) === 0}
          )}
          onClick={() => this.markSelectedOdd({PointSpread: 'Home'})}
        >
          <FormattedToBetType price={0} value={PointSpreadHome} spread /> (<FormattedToBetType price={1} value={PointSpreadHomeLine} />)
        </button>
      )

      const moneyLineAwayButton = (
        <button
          disabled={(Access & this.MA) === 0} className={classNames(
            { "btn btn-primary": true,
              "btn-stroke": MoneyLine !== 'Away',
              "btn-odd-selected": MoneyLine === 'Away',
              "disabled-pick": (Access & this.MA) === 0}
          )}
          onClick={() => this.markSelectedOdd({MoneyLine: 'Away'})}
        >
          <FormattedToBetType price={1} value={MoneyLineAway} />
        </button>
      )

      const moneyLineHomeButton = (
        <button
          disabled={(Access & this.MH) === 0} className={classNames(
            { "btn btn-primary": true,
              "btn-stroke": MoneyLine !== 'Home',
              "btn-odd-selected": MoneyLine === 'Home',
              "disabled-pick": (Access & this.MH) === 0}
          )}
          onClick={() => this.markSelectedOdd({MoneyLine: 'Home'})}
        >
          <FormattedToBetType price={1} value={MoneyLineHome} />
        </button>
      )

      const totalAwayButton = (
        <button
          disabled={(Access & this.TO) === 0} className={classNames(
            { "btn btn-primary": true,
              "btn-stroke": Total !== 'Over',
              "btn-odd-selected": Total === 'Over',
              "disabled-pick": (Access & this.TO) === 0}
          )}
          onClick={() => this.markSelectedOdd({Total: 'Over'})}
        >
          {TotalNumber} (<FormattedToBetType price={1} value={OverLine} />) O
        </button>
      )

      const totalHomeButton = (
        <button
          disabled={(Access & this.TU) === 0} className={classNames(
            { "btn btn-primary": true,
              "btn-stroke": Total !== 'Under',
              "btn-odd-selected": Total === 'Under',
              "disabled-pick": (Access & this.TU) === 0}
          )}
          onClick={() => this.markSelectedOdd({Total: 'Under'})}
        >
          {TotalNumber} (<FormattedToBetType price={1} value={UnderLine} />) U
        </button>
      )

      const pitcherHomeButton = (
        <button
          className={classNames(
            { "btn btn-primary": true,
              "btn-stroke": true,
              "btn-odd-selected": homePitcher !== null,
              "disabled-pick": action === true}
          )}
          disabled={action === true}
          onClick={() => this.handleSelectHomePitcher(HomePitcher)}
        >
          <span>{HomePitcher}(Home)</span>
        </button>
      );

      const pitcherAwayButton = (
        <button
          className={classNames(
            { "btn btn-primary": true,
              "btn-stroke": true,
              "btn-odd-selected": awayPitcher !== null,
              "disabled-pick": action === true}
          )}
          disabled={action === true}
          onClick={() => this.handleSelectAwayPitcher(AwayPitcher)}
        >
          <span>{AwayPitcher}(Away)</span>
        </button>
      );

      const pitcherActionButton = (
        <button
          className={classNames(
            { "btn btn-primary": true,
              "btn-stroke": true,
              "btn-odd-selected": action === true,
              "disabled-pick": awayPitcher !== null || homePitcher !== null}
          )}
          disabled={awayPitcher !== null || homePitcher !== null}
          onClick={() => this.handleSelectActionPitcher()}
        >
          <span>Action</span>
        </button>
      );

      const DrawButton = (
        <button
          disabled={(Access & this.DR) === 0} className={classNames(
            { "btn btn-primary": true,
              "btn-stroke": !Draw,
              "btn-odd-selected": Draw,
              "disabled-pick": (Access & this.DR) === 0}
          )}
          onClick={() => this.markSelectedOdd({Draw: true})}
        >
          <FormattedToBetType price={1} value={DrawLine} />
        </button>
      );

      const spreadCellName = () => {
        if (sportName === 'hockey') {
          return 'Puck Line';
        }

        if (sportName === 'baseball') {
          return 'Run Line';
        }

        return 'Spread'
      }

      const totalCellName = () => {
        if (['mma', 'wbc'].includes(sportName)) {
          return 'Rounds';
        }

        return 'Total'
      }


      return (
        <div className="alt-table-responsive row picks-table">
          <div className="col-md-12">
            <table className="table col-md-12 shadow">
              <thead>
                <tr className={this.matchEnd(MatchTime)}>
                  <td colSpan={sportName === 'soccer' ? '5' : '4'}>GAME DATE: {moment(MatchTime).format('L')} {moment(MatchTime).format('LT')}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">Teams</td>
                  <td className="text-center">{spreadCellName()}</td>
                  <td className="text-center">Money Line</td>
                  <td className="text-center">{totalCellName()} O/U</td>
                  {sportName === 'soccer' && <td className="text-center">Draw</td>}
                </tr>
                <tr>
                  <td>
                    {AwayROT} {AwayTeam}
                    <p>
                      {Sport === 0 && AwayPitcher &&
                      `Pitcher: ${AwayPitcher}`
                      }
                    </p>
                  </td>

                  <td className="table-btn">
                    {isNoZero(PointSpreadAwayLine) ? spreadAwayButton : emptyButton}
                  </td>

                  <td className="table-btn">
                    {isNoZero(MoneyLineAway) ? moneyLineAwayButton : emptyButton}
                  </td>

                  <td className="table-btn">
                    {isNoZero(TotalNumber) ? totalAwayButton : emptyButton}
                  </td>

                  {sportName === 'soccer' &&
                    <td rowSpan="2" className="table-btn two-rows">
                      {isNoZero(DrawLine) ? DrawButton : emptyButton}
                    </td>
                  }
                </tr>

                <tr>
                  <td>
                    {HomeROT} {HomeTeam}
                    <p>
                      {Sport === 0 && HomePitcher &&
                      `Pitcher: ${HomePitcher}`
                      }
                    </p>
                  </td>

                  <td className="table-btn">
                    {isNoZero(PointSpreadHomeLine) ? spreadHomeButton : emptyButton}
                  </td>

                  <td className="table-btn">
                    {isNoZero(MoneyLineHome) ? moneyLineHomeButton : emptyButton}
                  </td>

                  <td className="table-btn">
                    {isNoZero(TotalNumber) ? totalHomeButton : emptyButton}
                  </td>

                  {sportName === 'soccer' && <td className="table-btn two-rows-mob" />}
                </tr>

              </tbody>
              {Details &&
                <tfoot>
                  {sportName === 'baseball' && HomePitcher && AwayPitcher &&
                    <tr>
                      <td>
                        Pitchers listed
                      </td>

                      <td className="table-btn">
                        {pitcherActionButton}
                      </td>

                      <td className="table-btn">
                        {pitcherAwayButton}
                      </td>

                      <td className="table-btn">
                        {pitcherHomeButton}
                      </td>
                    </tr>}
                  <tr>
                    <td colSpan={sportName === 'soccer' ? '5' : '4'}>{Details}</td>
                  </tr>
                </tfoot>
              }
            </table>

            {saveAvailible &&
              <button className={"btn btn-warning pull-right"} onClick={this.handleSavePick} disabled={loading}>
                <i className="fa fa-save" />
                <span>Save Pick</span>
              </button>
            }
          </div>
        </div>
      );
    }
}

export default PicksTable;

PicksTable.propTypes = {
  loading: PropTypes.bool,
  odd: PropTypes.object,
  onCreatePick: PropTypes.func,
  sportName: PropTypes.string
};
