import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';


const Stats = (props) => {
  const {
    DeviationSpread,
    DeviationTotal,
    ROIMoneyLine,
    ROISpread,
    ROITotal,
    ROIDrawLine,
    followersCount
  } = props;

  const ROI = ROISpread + ROIMoneyLine + ROITotal + ROIDrawLine;

  return (
    <div className="row profile-stats">
      <div id="followers-stats" className="col-lg-3 col-md-4 col-sm-6 col-xs-6">
        <div className="mini-stat-type-3 shadow border-danger">
          <span className="text-uppercase text-block text-center">Followers</span>
          <h3 className="text-strong text-center">
            <span className="counter">{followersCount ? followersCount : '-'}</span>
          </h3>
        </div>
      </div>
      <div id="roi-on-total-stats" className="col-lg-3 col-md-4 col-sm-6 col-xs-6">
        <div className="mini-stat-type-3 shadow border-danger">
          <span className="text-uppercase text-block text-center">ROI on Total</span>
          <h3 className="text-strong text-center">
            <span className="counter">{ROITotal ? `${ROITotal.toFixed(2)}` : '-'}</span>
          </h3>
        </div>
      </div>
      <div id="deviation-on-total-stats" className="col-lg-3 col-md-4 col-sm-6 col-xs-6">
        <div className="mini-stat-type-3 shadow border-danger">
          <span className="text-uppercase text-block text-center">Deviation on Total</span>
          <h3 className="text-strong text-center">
            <span className="counter">{DeviationTotal ? DeviationTotal.toFixed(2) : '-'}</span>
          </h3>
        </div>
      </div>
      <div id="roi-on-spread-stats" className="col-lg-3 col-md-4 col-sm-6 col-xs-6">
        <div className="mini-stat-type-3 shadow border-danger">
          <span className="text-uppercase text-block text-center">ROI on Spread</span>
          <h3 className="text-strong text-center">
            <span className="counter">{ROISpread ? `${ROISpread.toFixed(2)}` : '-'}</span>
          </h3>
        </div>
      </div>
      <div id="deviation-on-spread-stats" className="col-lg-3 col-md-4 col-sm-6 col-xs-6">
        <div className="mini-stat-type-3 shadow border-danger">
          <span className="text-uppercase text-block text-center">Deviation on Spread</span>
          <h3 className="text-strong text-center">
            <span className="counter">{DeviationSpread ? DeviationSpread.toFixed(2) : '-'}</span>
          </h3>
        </div>
      </div>
      <div id="roi-on-moneyline-stats" className="col-lg-3 col-md-4 col-sm-6 col-xs-6">
        <div className="mini-stat-type-3 shadow border-danger">
          <span className="text-uppercase text-block text-center">ROI on Money Line</span>
          <h3 className="text-strong text-center">
            <span className="counter">{ROIMoneyLine ? `${ROIMoneyLine.toFixed(2)}` : '-'}</span>
          </h3>
        </div>
      </div>
      <div id="roi-on-drawline-stats"className="col-lg-3 col-md-4 col-sm-6 col-xs-6">
        <div className="mini-stat-type-3 shadow border-danger">
          <span className="text-uppercase text-block text-center">ROI on Draw</span>
          <h3 className="text-strong text-center">
            <span className="counter">{ROIDrawLine ? `${ROIDrawLine.toFixed(2)}` : '-'}</span>
          </h3>
        </div>
      </div>
      <div id="roi-stats" className="col-lg-3 col-md-4 col-sm-6 col-xs-6">
        <div className="mini-stat-type-3 shadow border-danger">
          <span className="text-uppercase text-block text-center">OVERALL ROI</span>
          <h3 className="text-strong text-center">
            <span className="counter">{ROI ? `${ROI.toFixed(2)}` : '-'}</span>
          </h3>
        </div>
      </div>
    </div>
  );
}

export default Stats;

Stats.propTypes = {
  DeviationSpread: PropTypes.number,
  DeviationTotal: PropTypes.number,
  ROIDrawLine: PropTypes.number,
  ROIMoneyLine: PropTypes.number,
  ROISpread: PropTypes.number,
  ROITotal: PropTypes.number,
  followersCount: PropTypes.number
};
