import React from "react";
import Chat from '../components/Chat/index'
import Wrapper from '../components/ui/Wrapper'


const ChatPage = (location) => {
  return (
    <Wrapper
      className="chat-page"
      hideNotifications
    >
      <Chat location={location.location} />
    </Wrapper>
  )
}

export default ChatPage;
