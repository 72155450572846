import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Steps } from 'intro.js-react';
import i from 'immutable';
import { Link } from 'react-router-dom';
import { getMyPicksIncomplete } from '../../../api/picks';
import { connect } from "../../../store/context";
import "./style.scss";
import { createPickIntro, introOptions } from '../../../helpers/intro';
import 'intro.js/introjs.css';


class IncompletePicksNotification extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    incompletePicks: PropTypes.array,
    profile: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      stepsEnabled: false
    }
  }

  componentDidUpdate(prevProps) {
    const { profile: oldProfile } = prevProps;
    const { dispatch, profile } = this.props;

    if (_.isEmpty(oldProfile) && !_.isEmpty(profile)) {
      getMyPicksIncomplete(dispatch);
    }
  }

  handleHideIntro = () => {
    this.setState({ stepsEnabled: false });
  }

  render() {
    const { incompletePicks } = this.props;

    if (incompletePicks.length === 0) {
      return null;
    }

    const { stepsEnabled } = this.state;

    if (incompletePicks.length > 0) {
      const firstIncompletePick = localStorage.getItem('createPickIntro');

      if (!stepsEnabled && firstIncompletePick === 'true') {
        this.setState({stepsEnabled: true});
        localStorage.removeItem('createPickIntro');
      }
    }

    return (
      <div className="incomplete-notification">
        <Steps
          enabled={stepsEnabled}
          steps={createPickIntro.steps}
          initialStep={0}
          onExit={this.handleHideIntro}
          options={introOptions}
        />
        <div className="box">
          <div id="pick-teams" className="teams">
            {incompletePicks.map((pick) => {
              const { HomeTeam, AwayTeam } = pick.Match;
              return (
                <p key={pick.id}>{AwayTeam} <span>vs</span> {HomeTeam}</p>
              )
            })}
          </div>
          <Link id="complete-pick-btn" to="/picks/incomplete" className="btn btn-warning btn-sm">Complete Picks ({incompletePicks.length})</Link>
        </div>
      </div>
    );
  }
}

function select(state) {
  const incompletePicks = state.getIn(['picks', 'incompletePicks'], i.List()).toJS();
  const profile = state.getIn(['users', 'profile'], i.Map()).toJS();

  return { incompletePicks, profile };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(IncompletePicksNotification);
