import i from 'immutable';
import {
  GET_NOTIFICATIONS_BEGIN,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  READ_NOTIFICATIONS_BEGIN,
  READ_NOTIFICATIONS_SUCCESS,
  READ_NOTIFICATIONS_FAILURE,
  DELETE_NOTIFICATION_BEGIN,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
  DELETE_ALL_NOTIFICATIONS_BEGIN,
  DELETE_ALL_NOTIFICATIONS_SUCCESS,
  DELETE_ALL_NOTIFICATIONS_FAILURE
} from '../actions/notifications';


const initialState = i.Map({
  items: i.List(),
  loading: false,
  error: null
});

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS_BEGIN:
      state = state.set('loading', true);
      break;

    case GET_NOTIFICATIONS_SUCCESS:
      state = state.set('items', i.fromJS(action.data.notifications));
      state = state.set('loading', false);
      break;

    case GET_NOTIFICATIONS_FAILURE:
      state = state.set('error', action.error);
      break;

    case READ_NOTIFICATIONS_BEGIN:
      state = state.set('loading', true);
      break;

    case READ_NOTIFICATIONS_SUCCESS:
      state = state.set('items', i.fromJS(action.data.notifications));
      state = state.set('loading', false);
      break;

    case READ_NOTIFICATIONS_FAILURE:
      state = state.set('error', action.error);
      break;

    case DELETE_NOTIFICATION_BEGIN:
      state = state.set('loading', true);
      //eslint-disable-next-line
      const oldNotificationsList = state.get('items');
      //eslint-disable-next-line
      const newList = oldNotificationsList.filter((oldNotification) => oldNotification.get('id') !== action.data.id)

      state = state.set('items', i.fromJS(newList));
      break;

    case DELETE_NOTIFICATION_SUCCESS:
      state = state.set('loading', false);
      break;

    case DELETE_NOTIFICATION_FAILURE:
      state = state.set('error', action.error);
      break;

    case DELETE_ALL_NOTIFICATIONS_BEGIN:
      state = state.set('items', i.fromJS([]));
      break;

    case DELETE_ALL_NOTIFICATIONS_SUCCESS:
      state = state.set('loading', false);
      break;

    case DELETE_ALL_NOTIFICATIONS_FAILURE:
      state = state.set('error', action.error);
      break;

    default:
      return state;
  }

  return state;
}
