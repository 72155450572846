import React from 'react';
import PropTypes from 'prop-types';
import "./style.scss";

import { Link } from 'react-router-dom';
import { setDefaultAvatar } from '../../../helpers/defaultImage';
import CONFIG from '../../../config/config';
import { makeSubscriptionInactive, fetchSubscriptions } from "../../../api/subscription"
import { buySubscriptionFromBalance } from '../../../api/payments';
import { alertSuccessWithConfirmaiton, alertError, alertSuccess, alertErrorText } from '../../../actions/alerts'
import moment from 'moment';
const Subscription = (props) => {
  const {
    subscription,
    isFollowing,
    isAuthorized,
    purchased,
    owner,
    Sports,
    profile,
    dispatch,
    history
  } = props;
  const { id, type, user, sportId } = subscription

  const ROI = user.ROISpread + user.ROIMoneyLine + user.ROITotal + user.ROIDrawLine;

  const getSportDetailsById = (sportId) => {
    for (let index in Sports) {
      if (Sports[index].id == sportId) {
        return Sports[index]
      }
    }
    return { id: -1, name: 'unknown' }
  }

  const buySubscription = async function () {
    const { emailVerified } = profile

    if (emailVerified === false) {
      return alertErrorText("Your email is not confirmed. Please check you email box and click confirmation link. If you did not get email, please use 'Resend email' button below.");
    }
    const result = await alertSuccessWithConfirmaiton(`Buy Subscription "${subscription.name}" ending on ${moment(subscription.endDate).format('LL')} for $${subscription.price} ?`,
      `I understand that there are no refunds for buying any subscriptions. I also understand that it is my responsibility to know the sport and how many games are left. It is my responsibility when purchasing a subscription knowing how many games are left in the season. I also understand predictions by consultants are subject to be wrong in the short term or long term. By pressing OK and agreeing to buy this subscription I agree to these terms and agree to the Terms of Service on this platform.`);

    if (result.value) {

      return buySubscriptionFromBalance(dispatch, `Buy Subscription`, subscription.id, subscription.price, `Buy subscription ${subscription.price}`, history);
    }
  }

  const changeStatus = async () => {
    let status = 0
    if (Number(subscription.status) === 0) status = 1
    const [data, err] = await makeSubscriptionInactive(subscription.id, { status })
    if (data) {
      alertSuccess(data.message)
      fetchSubscriptions(dispatch, { limit: 100, offset: 0, sportId: null });
    } else {
      alertError(err)
    }
  }

  function truncateNames(input) {
    if (input.length > 15) {
      return input.substring(0, 15) + '...';
    }
    return input
  }
  const sport = getSportDetailsById(sportId)
  let expired = null
  if (moment(subscription.endDate).isBefore()) expired = true
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 room-panel" key={id}>
      <div className="panel panel-bg-primary rounded shadow">
        <div className="panel-body">
          {expired &&
            <div className="ribbon-wrapper top-left">
              <div className="ribbon ribbon-primary">expired</div>
            </div>
          }
          <ul className="inner-all list-unstyled">
            <li className="image">
              {<img className="img-circle img-bordered-default hundred-px" src={user.avatar} alt={user.firstName} onError={setDefaultAvatar} />}
            </li>
            <li className="username">
              <h4>

                {truncateNames(subscription.name)}
                {/* <span>{}</span> */}
              </h4>
            </li>
            <li className="statistics">
              <p><strong>Sport: </strong>{sport.Name}</p>
              {
                !owner && isFollowing &&
                <p><strong>Creator: </strong><Link to={`/user/${user.id}`}>{user.firstName}</Link>
                  <Link
                    className="send-message"
                    title="Send message"
                    to={`/chat?user=${user.id}`}
                  >
                    <i className="fa fa-fw fa-envelope" />
                  </Link>
                </p>
              }
              {
                !owner && <p><strong>Owner: </strong><Link to={`/user/${user.id}`}>{user.firstName}</Link></p>
              }

              {!owner && <p><strong>Owner ROI: </strong>{ROI ? ROI.toFixed(2) : 'Unknown'}</p>}
              <p><strong>Price: </strong>${subscription.price}</p>
              <p><strong>Start Date: </strong>{moment(subscription.startDate).format('LL')}</p>
              <p><strong>End Date: </strong>{moment(subscription.endDate).format('LL')}</p>
              <p><strong>Pick Cycle: </strong>{subscription.picksCycle}</p>
              <p><strong>Min Picks/Cycle: </strong>{subscription.minPicksPerCycle}</p>

            </li>
            {isAuthorized &&
              purchased &&
              <li className="text-center">
                <Link className="btn btn-default" to={`/my-picks/pregame?selectedSubscriptionId=${subscription.id}`}>See Picks</Link>
              </li>}
            {isAuthorized &&
              !owner && !purchased &&
              <li className="text-center">
                <a onClick={buySubscription} className='btn btn-warning'>Buy Now</a>
              </li>}
            {owner &&
              <li className="text-center">
                <Link className="btn btn-default" to={`/my-picks/pregame?selectedSubscriptionId=${subscription.id}`}>See Picks</Link>
              </li>}
            {owner && Number(subscription.status) == 1 &&
              <li className="text-center">
                <a onClick={changeStatus} className='btn btn-warning' disabled={expired}>Mark Inactive</a>
              </li>}
            {owner && Number(subscription.status) == 0 &&
              <li className="text-center">
                <a onClick={changeStatus} className='btn btn-warning' disabled={expired}>Mark Active</a>
              </li>}
            {owner && Number(subscription.status) == 2 &&
              <li className="text-center">
                <a className='btn btn-warning' disabled={true}>Validating for Payments</a>
              </li>}
            {owner && Number(subscription.status) == 4 &&
              <li className="text-center">
                <a className='btn btn-warning' disabled={true}>Approved for Payments</a>
              </li>}
            {owner && Number(subscription.status) == 5 &&
              <li className="text-center">
                <a className='btn btn-warning' disabled={true}>Canceled for Payments</a>
              </li>}
            {owner && Number(subscription.status) == 6 &&
              <li className="text-center">
                <a className='btn btn-warning' disabled={true}>Payments Settled</a>
              </li>}
            {!isAuthorized &&
              <li className="text-center">
                <Link to={`/login`}><a href className="btn btn-primary">Sign in to Buy</a></Link>
              </li>}
          </ul>


        </div>
      </div>
    </div>
  );
}

export default Subscription;

Subscription.propTypes = {
  isAuthorized: PropTypes.bool,
  profile: PropTypes.object,
  status: PropTypes.string,
  purchased: PropTypes.bool,
  owner: PropTypes.bool,
  dispatch: PropTypes.func,
  Sports: PropTypes.array,
  history: PropTypes.object,
}
