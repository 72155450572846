import React from "react";
import PropTypes from 'prop-types';
import User from './user';
import './style.scss';


const Users = (props) => {
  const { users, onlineUsers, onOpenRoom, currentUserId, usersWithNewMessages, profile } = props;
  return (
    <ul className="chat-user-list">

      {users.map((user) => {
        let isUserOnline = user.online === 1;
        const active = currentUserId === user.id;
        let newMessages = false
        usersWithNewMessages.forEach(roomWithNewMsg => {
          if (roomWithNewMsg.chatRoomId === user.chatRoomId) {
            newMessages = true;
          }
        });
        let blockedByMe = false;

        if (user.BlockedBy.length > 0) {
          user.BlockedBy.forEach((blockUser) => {
            if (blockUser.UserId === profile.id) {
              blockedByMe = true;
            }
          });
        }


        return (
          <User
            user={user}
            online={isUserOnline}
            onOpenRoom={onOpenRoom}
            active={active}
            newMessages={newMessages}
            blockedByMe={blockedByMe}
          />
        );
      }
      )}

    </ul>
  );
}

export default Users;

Users.propTypes = {
  currentUserId: PropTypes.number,
  onOpenRoom: PropTypes.func,
  onlineUsers: PropTypes.array,
  profile: PropTypes.object,
  users: PropTypes.array,
  usersWithNewMessages: PropTypes.array
};
