import React,  { Component } from 'react';
import i from 'immutable';
import PropTypes from 'prop-types';
import { connect } from "../store/context";
import UserFriend from "../components/users/UserFriend";
import UserStatsProfile from "../components/users/UserStatsProfile";
import { getProfile, followUser, unfollowUser } from "../api/users";
import { getAPIContentfeed } from '../api/content-feed';
import { updateFriendsTab } from "../api/general";
import Wrapper from '../components/ui/Wrapper'
import EmptyPage from '../components/general/EmptyPage'
import Loader from '../components/ui/Loader/index';


class Friends extends Component {
  handleFollowUser = async (e) => {
    e.preventDefault();
    const { dispatch, users, profile: { id } } = this.props;
    await followUser(dispatch, e.currentTarget.attributes.userid.nodeValue, users);
    getProfile(dispatch);
    getAPIContentfeed(dispatch, id, 20, 0, true);
  }

  handleUnfollowUser = async (e) => {
    e.preventDefault();
    const { dispatch, followings, profile: { id } } = this.props;
    await unfollowUser(dispatch, e.currentTarget.attributes.userid.nodeValue, followings);
    getAPIContentfeed(dispatch, id, 20, 0, true);
  }

  handleChangeTab = (e) => {
    e.preventDefault();
    const tabName = e.currentTarget.attributes.tabName.value;
    const { dispatch } = this.props;
    updateFriendsTab(dispatch, String(tabName));
  }

  render() {
    const { activeTab = 'following', followings, followingIds, followers, loading } = this.props;

    return (
      <Wrapper
        className="friends-page"
        title="Friends"
        counter={followings.length}
        icon="fa fa-group"
      >
        <div className="panel panel-tab panel-tab-double shadow">
          <div className="panel-heading no-padding">
            <ul className="nav nav-tabs">
              <li className={`nav-border nav-border-top-warning ${activeTab === 'following' && 'active'}`}>
                <a href onClick={this.handleChangeTab} tabName="following" data-toggle="tab" aria-expanded="true">
                  <i className="fa fa-user" />
                  <div>
                    <span className="text-strong">Following ({followings.length ? followings.length : '0'})</span>
                  </div>
                </a>
              </li>
              <li className={`nav-border nav-border-top-warning ${activeTab === 'followers' && 'active'}`}>
                <a href onClick={this.handleChangeTab} tabName="followers" data-toggle="tab" aria-expanded="true">
                  <i className="fa fa-user" />
                  <div>
                    <span className="text-strong">Followers ({followers.length ? followers.length : '0'})</span>
                  </div>
                </a>
              </li>
            </ul>
          </div>

          {loading && <Loader />}

          {!loading && activeTab === 'following' &&
            <div className="friends">
              {!followings.length && <EmptyPage />}
              {followings.length > 0 &&
              <>
                {
                  followings.map((user) => {
                    return (
                      <UserFriend
                        key={user.id}
                        onUnFollowUser={this.handleUnfollowUser}
                        {...user}
                      />
                    )
                  })
                }
              </>
              }
            </div>
          }

          {!loading && activeTab === 'followers' &&
            <div className="friends">
              {!followers.length && <EmptyPage />}
              {followers.length > 0 &&
              <>
                {
                  followers.map((user) => {
                    return (
                      <UserStatsProfile
                        key={user.id}
                        onFollowUser={this.handleFollowUser}
                        onUnFollowUser={this.handleUnfollowUser}
                        isFollowing={followingIds.includes(user.id)}
                        isAuthorized={Boolean(true)}
                        showSendMessageForAll={Boolean(true)}
                        {...user}
                      />
                    )
                  })
                }
              </>
              }
            </div>
          }
        </div>

      </Wrapper>
    )
  }
}

function select(state) {
  const users = state.getIn(['users', 'items'], i.List()).toJS();
  const activeTab = state.getIn(['general', 'activeFriendsTab']);
  const followings = state.getIn(['users', 'profile', 'following'], i.List()).toJS();
  const followingIds = followings.map((user) => user && user.id);
  const followers = state.getIn(['users', 'profile', 'followers'], i.List()).toJS();
  const profile = state.getIn(['users', 'profile'], i.Map()).toJS();
  const loading = state.getIn(['users', 'loading'], false);

  return { users, activeTab, followings, followingIds, followers, profile, loading }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Friends);

Friends.propTypes = {
  dispatch: PropTypes.func,
  followings: PropTypes.array
};
