import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "../../../store/context";
import { searchConsultants } from "../../../api/users";
import {fetchRooms} from "../../../api/chat"
import './style.scss';


class RoomSearch extends Component {
  static propTypes = {
    Sports: PropTypes.array,
    dispatch: PropTypes.func
  }

  state = {
    sportId: null,
    type: null,
  };

  handleSearchChange = (event) => {
    if (event.target.value === '') {
      this.setState({ [event.target.id]: null });
    } else if (event.target.tagName === 'SELECT' && event.target.value === 'null') {
      this.setState({ [event.target.id]: null });
    } else {
      this.setState({
        [event.target.id]: event.target.value
      });
    }
  }

  handleSearch = (event) => {
    event.preventDefault();
    const {sportId, type} = this.state
    const { dispatch } = this.props;
    fetchRooms(dispatch, {sportId, type, offset:0, limit:100});
  }

  render() {
    const { showDetailedSearch } = this.state;
    const { Sports } = this.props;

    return (
      <form className="consultant-search">
        {showDetailedSearch &&
          <div className="row detailed">
            <div className="col-md-12">

              
            </div>
          </div>
        }

        <div className="row">
          <div className="col-md-12">

            <div className="form-group col-md-3">
              <select className="form-control" id="sportId" onChange={this.handleSearchChange}>
                <option disabled hidden>Sport</option>
                <option value="null">Select Sport</option>
                {Sports.map((sport) => <option key={sport.id} value={sport.id}>{sport.Name.toUpperCase()}</option>)}
              </select>
            </div>
            <div className="form-group col-md-3">
              <select className="form-control" id="type" onChange={this.handleSearchChange}>
                <option disabled hidden>Room Type</option>
                <option value="null">Room Type</option>
                <option  value={2}>Public</option>
                <option value={3}>Private</option>
              </select>
            </div>

            <div className="form-group col-md-4">
              <button type="submit" className="btn btn-default" onClick={this.handleSearch}>Search</button>
            </div>
          </div>
        </div>

        <div className="row show-detailed">
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(mapDispatchToProps)(RoomSearch);
