import React, { Component } from "react";
import i from 'immutable';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { connect } from "../store/context";
import { getProfile, becomeConsultant, verifyPaypal } from "../api/users";
// import { getPaypalVerifiedInfo } from "../api/users";
import Wrapper from "../components/ui/Wrapper";
import { isValidTextField, isFormValid } from "../helpers/validators";
import { executePayment, pay } from '../api/paypal';
// import { login as paypalAuthLink } from '../api/paypal';
import { alertErrorText, alertSuccessWithConfirmaiton } from '../actions/alerts';
import Loader from '../components/ui/Loader/index';


class BecomeCunsultant extends Component {
  state = {
    errorMessage: {},
    validFields: { firstName: true },
    buying: false
  };

  async componentDidMount() {
    const { dispatch, location: { search } } = this.props;
    const { paymentId, PayerID } = queryString.parse(search);
    // const { code } = queryString.parse(search);

    if (paymentId) {
      await executePayment(dispatch, PayerID, paymentId)
      window.history.pushState({}, document.title, '/profile/become-consultant');
      // await getPaypalVerifiedInfo(dispatch, code);
    }

    return getProfile(dispatch);
  }

  componentWillReceiveProps(nextProps) {
    const { emailVerified = null } = nextProps.profile;
    const { history } = this.props;

    if (emailVerified === false) {
      alertErrorText("Your email is not confirmed. Please check you email box and click confirmation link. If you did not get email, please use 'Resend email' button below.");
      history.goBack();
    }
  }

  handleChange = (event) => {
    this.props.profile[event.target.id] = event.target.value;

    const { validFields, errorMessage } = this.state;
    const { isValidField, error } = isValidTextField(event.target.value, event.target.name, null, event.target.required);
    const key = event.target.id;
    errorMessage[key] = error;

    if (isValidField) {
      validFields[key] = true;
    } else {
      validFields[key] = false;
    }

    this.setState({ errorMessage, validFields });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { dispatch, history } = this.props;
    const { firstName, lastName, bio } = this.props.profile;

    await becomeConsultant(dispatch, firstName, lastName, bio);
    const dashboardIntro = localStorage.getItem('dashboardIntro');

    if (!dashboardIntro) {
      localStorage.setItem("becameConsultantIntro", true);
    }

    history.push('/')
  }

  handleCancel = () => {
    this.props.history.goBack();
  }

  handlePayPalSuccess = (payment) => {
    const { dispatch } = this.props;
    verifyPaypal(dispatch, payment);
  }

  handleBuy = async () => {
    const { value = false } = await alertSuccessWithConfirmaiton('PayPal transaction confirmation', 'After you click "Ok", your PayPal account will be charged with 0.01 USD. After we confirm your PayPal account this money will be refunded to you.');

    if (!value) {
      return;
    }

    const { dispatch } = this.props;
    this.setState({ buying: true });
    const error = await pay(dispatch, 0, 'Verify', 0.01)

    if (error.message === 'firefox in private mode') {
      this.setState({ buying: false });
    }
  }

  // handlePayPalAccount = (event) => {
  //   event.preventDefault();
  //   const { dispatch } = this.props;
  //   paypalAuthLink(dispatch, true)
  // }

  render() {
    const { verified, firstName, lastName, bio, role } = this.props.profile;
    const { errorMessage, validFields, buying } = this.state;
    const formIsInvalid = isFormValid(validFields);

    return (
      <Wrapper
        className="become-consultant-page edit-profile-page"
        title="Become Consultant"
        icon="fa fa-fw fa-user-o"
      >
        {buying && <Loader />}
        {!buying &&
        <div className="container shadow">
          {role === "consultant" && <p>You are already a consultant</p>}

          {role === "user" &&
            <form className="no-overflow" onSubmit={this.handleSubmit}>

              {!verified &&
                <div>
                  <p>In order to become consultant, you need to have verified PayPal account.</p>
                  <p>After you click "Verify with PayPal" SportsTrader® will check your account and if it is  verified you will become a consultant. </p>
                </div>
              }

              {verified &&
                <div>
                  <p>To become consultant, please fill in this form.</p>
                  <div className={errorMessage.firstName ? "form-group has-error" : "form-group"}>
                    <label className="control-label">
                      <span>First Name</span>
                      <span className="asterisk">*</span>
                    </label>
                    <div className="input-group input-group no-overflow shadow">
                      <input
                        id="firstName"
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="First Name"
                        value={firstName}
                        onChange={this.handleChange}
                        required
                      />
                      <span className="input-group-addon"><i className="fa fa-user" /></span>
                    </div>
                    <span className="help-block">{errorMessage.firstName}</span>
                  </div>
                  <div className={errorMessage.lastName ? "form-group has-error" : "form-group"}>
                    <label className="control-label">
                      <span>Last Name</span>
                    </label>
                    <div className="input-group input-group no-overflow shadow">
                      <input
                        id="lastName"
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        name="name"
                        value={lastName}
                        onChange={this.handleChange}
                      />
                      <span className="input-group-addon"><i className="fa fa-user" /></span>
                    </div>
                    <span className="help-block">{errorMessage.lastName}</span>
                  </div>
                  <div className={errorMessage.bio ? "form-group has-error" : "form-group"}>
                    <label className="control-label">
                      <span>Bio</span>
                    </label>
                    <div className="input-group input-group no-overflow shadow">
                      <textarea
                        id="bio"
                        className="form-control"
                        placeholder="Your Bio"
                        rows="4"
                        name="bio"
                        value={bio}
                        onChange={this.handleChange}
                      />
                      <span className="input-group-addon"><i className="fa fa-info" /></span>
                    </div>
                    <span className="help-block">{errorMessage.bio}</span>
                  </div>
                </div>
              }

              <div className="navigation">
                <button type="reset" className="btn btn-default" onClick={this.handleCancel}>Cancel</button>
                {verified && <button disabled={formIsInvalid} type="submit" className="btn btn-warning">Become Consultant</button>}
                {!verified &&
                  <button type="button" className="btn btn-primary paypal-login" onClick={this.handleBuy}>
                    <i className="fa fa-paypal fa-2x pull-left paypal-icon" />Verify with PayPal
                  </button>
                }
              </div>
            </form>
          }

        </div>
        }
      </Wrapper>
    );
  }
}

function select(state) {
  const profile = state.getIn(['users', 'profile'], i.Map()).toJS();
  return { profile }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default withRouter(connect(select, mapDispatchToProps)(BecomeCunsultant));

BecomeCunsultant.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  profile: PropTypes.object
};
