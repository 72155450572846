import React from 'react';
import './style.scss';


const Logo = () => {
  return (
    <a className="main-logo" href="/">
      <img className="logo" src="/img/logo/logo.svg" alt="SportsTrader®" width="28" height="28" />
      <div className="text">SportsTrader<sup><small>®</small></sup></div>
    </a>
  );
};

export default Logo;
