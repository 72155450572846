import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import Logo from '../../../components/ui/Logo';


const Footer = () => {
  return (
    <footer className="landing-footer">
      <div className="container">
        <div className="left">
          <Logo />
        </div>
        <div className="right">
          <div className="col">
            <div className="sub-title">Copyright</div>
            <div className="links">
              <Link to="/privacy" target="_blank">Privacy</Link>
              <Link to="/tos" target="_blank">Terms of Service</Link>
              <Link to="/cookie-policy" target="_blank">Cookie policy</Link>
            </div>
          </div>
          <div className="col">
            <div className="sub-title">Socials</div>
            <div className="socials">
              <a
                href="https://www.instagram.com/sportstrader.pro/"
                target="_blank"
                rel="noopener noreferrer"
              ><i className="fa fa-instagram" aria-hidden="true" />
              </a>
              <a
                href="https://www.facebook.com/OfficiallSportsTrader/?pageid=2356374941063151&ftentidentifier=2531528496881127&padding=0"
                target="_blank"
                rel="noopener noreferrer"
              ><i className="fa fa-facebook" aria-hidden="true" />
              </a>
              <a
                href="https://twitter.com/sportstrader888"
                target="_blank"
                rel="noopener noreferrer"
              ><i className="fa fa-twitter" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
