import React from 'react';
import PropTypes from 'prop-types';
import "./style.scss";


export const Switch = (props) => {
  const {betsType, onClickAmerican, onClickDecimal } = props;
  return (
    <div className="row">
      <div className="col-md-6">
        <button className={betsType === 'american' ? "btn btn-warning" : "btn btn-default"} type="button" onClick={onClickAmerican}>American</button>
      </div>
      <div className="col-md-6">
        <button className={betsType === 'decimal' ? "btn btn-warning" : "btn btn-default"} type="button" onClick={onClickDecimal}>Decimal</button>
      </div>
    </div>
  );
}

Switch.propTypes = {
  betsType: PropTypes.string,
  onClickAmerican: PropTypes.func,
  onClickDecimal: PropTypes.func
};
