import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../store/context';


class FormattedToBetType extends Component {
  static propTypes = {
    betsType: PropTypes.string,
    price: PropTypes.number,
    spread: PropTypes.bool,
    value: PropTypes.number
  };
  render() {
    const { price, betsType, spread } = this.props;
    let { value } = this.props;

    if (price && betsType === 'decimal') {
      value = calculateDecimalMoneyLine(value);
      value = Number(value).toFixed(2)
    } else if (value > 0) {
      value = `+${Number(value)}`;
    } else if (Number(value) === 0 && spread) {
      value = 'PK';
    } else {
      value = `–${Math.abs(value)}`
    }

    return (
      value
    );
  }
}

export const calculateDecimalMoneyLine = (moneyLine) => {
  let decimal;

  if (moneyLine > 0) {
    decimal = (moneyLine / 100) + 1;
  } else if (moneyLine < 0) {
    decimal = (100 / -moneyLine) + 1;
  }

  return decimal ? Number(decimal.toFixed(2)) : '';
}

function select(state) {
  const anonimus = state.getIn(['users', 'anonimus']);
  let betsType = 'american';

  if (anonimus === true) {
    betsType = state.getIn(['users', 'betsType'], 'american');
  } else {
    betsType = state.getIn(['users', 'profile', 'betsType'], 'american');
  }

  return { betsType };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(FormattedToBetType);
