import React, { Component } from 'react';
import i from 'immutable';
import PropTypes from 'prop-types';
import { connect } from "../store/context";
import Subscription from "../components/subscriptions/subscription/index";
import queryString from "query-string"
import { fetchSubscriptions, fetchMySubscription } from "../api/subscription"
import { Button } from 'react-bootstrap';
import { getAPIContentfeed } from '../api/content-feed';
import Wrapper from '../components/ui/Wrapper'
import SubscriptionSearch from '../components/search/SubscriptionSearch/index'
import EmptyPage from '../components/general/EmptyPage'
import CreateSubscriptionModal from '../components/subscriptions/CreateSubscriptionModal/index'
import Loader from '../components/ui/Loader/index';


class ConsultantSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchSubscriptionsType: 'own'
    }
  }
  componentDidMount = () => {
    const { dispatch, location: { search}  } = this.props
    const { subscriptionId = 0 } = queryString.parse(search)
    fetchSubscriptions(dispatch, {limit: 100, offset: 0, sportId: null, subscriptionId: subscriptionId});
    fetchMySubscription(dispatch)
    
  }
  componentDidUpdate = () => {
    
  }

  mySubscriptions = () => {
    const { dispatch } = this.props
    const { fetchSubscriptionsType } = this.state
    fetchSubscriptions(dispatch, {limit: 100, offset: 0, sportId: null, type: fetchSubscriptionsType});
    let toggle = (fetchSubscriptionsType == 'own') ? '': 'own'
    this.setState({ fetchSubscriptionsType: toggle});
  }

  render() {
    const {subscriptions, followingIds, isAuthorized, Sports, profile, loading, dispatch, history, mySubscriptions } = this.props;
    const { fetchSubscriptionsType } = this.state
    return (
      <Wrapper
        className="subscriptions-page"
        title="Consultant Subscriptions"
        icon="fa fa-shopping-cart"
      >
        <div className="row">
          <div className="col-lg-10 col-md-10 col-sm-10">
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
          {profile.role == 'consultant' && <CreateSubscriptionModal profile={profile} dispatch={dispatch} Sports={Sports}/>}
          {profile.role == 'user' && fetchSubscriptionsType=='own' && <Button bsStyle="primary" bsSize="small" onClick={this.mySubscriptions}>My Subscriptions</Button>}
          {profile.role == 'user' && fetchSubscriptionsType!='own' && <Button bsStyle="primary" bsSize="small" bsStyle="warning"  onClick={this.mySubscriptions}>All Subscriptions</Button>}
          <br />
          </div>

        </div>

        <div className="panel">
          <div className="panel-body">
            {profile.role != 'consultant' && <SubscriptionSearch Sports={Sports} />}
            <div className="rooms">
              {loading && <Loader />}
              {!loading && !subscriptions.length && <EmptyPage />}
              {
                !loading && subscriptions.map((subscription) => {
                  const { id } = subscription;
  
                  let purchased = false
                  let owner = false
                  mySubscriptions.map((mySubscription) => {
                    if (subscription.id == mySubscription.id) {
                      purchased = true
                    }
                  })
                  if (subscription.ownerId == profile.id){
                    owner= true
                    purchased = false
                  }
                  return (
                    <Subscription
                      key={id}
                      subscription={subscription}
                      isFollowing={followingIds.includes(subscription.user.id)}
                      purchased={purchased}
                      owner={owner}
                      isAuthorized={isAuthorized}
                      profile={profile}
                      dispatch={dispatch}
                      history={history}
                      Sports={Sports}
                      {...subscription.user}
                    />
                  )
                })
              }
            </div>
          </div>
        </div>
        
      </Wrapper>
    );
  }
}

function select(state) {
  const subscriptions = state.getIn(['subscriptions', 'items'], i.List()).toJS();
  const followings = state.getIn(['users', 'profile', 'following'], i.List()).toJS();
  const followingIds = followings.map((user) => user.id);
  const isAuthorized = Boolean(state.getIn(['users', 'profile']));
  const Sports = state.getIn(['sports', 'items'], i.List()).toJS();
  const profile = state.getIn(['users', 'profile'], i.Map()).toJS();
  const loading = state.getIn(['rooms', 'loading'], false);
  const mySubscriptions = state.getIn(['subscriptions', 'mySubscriptions'], i.List()).toJS()

  return { subscriptions, followings, followingIds, isAuthorized, Sports, profile, loading, mySubscriptions }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(ConsultantSubscription);

ConsultantSubscription.propTypes = {
  Sports: PropTypes.array,
  history: PropTypes.object,
};
