import React from 'react';
import PropTypes from 'prop-types';
import "./style.scss";
import LinesEllipsis from 'react-lines-ellipsis';
import { Link } from 'react-router-dom';
import { setDefaultAvatar } from '../../../helpers/defaultImage';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, RedditShareButton, RedditIcon } from 'react-share';
import CONFIG from '../../../config/config';
import { joinRoom, leaveRoom, deleteRoom, getUserRooms } from "../../../api/chat"
import { alertSuccessWithConfirmaiton, alertError, alertSuccess } from '../../../actions/alerts'
const SportRoom = (props) => {
  const {
    avatar,
    firstName,
    room,
    profile,
    isFollowing,
    isAuthorized,
    bio,
    status,
    role,
    ROISpread,
    ROIMoneyLine,
    ROITotal,
    ROIDrawLine,
    showSendMessageForAll = false,
    sport,
    member,
    blocked,
    dispatch,
    pending,
    history
  } = props;
  const { id, roomName, type } = room
  const { user } = room
  // console.log(user)
  let buttonText = "Join";
  let buttonClasses = "btn btn-warning";
  
  const ROI = ROISpread + ROIMoneyLine + ROITotal + ROIDrawLine;
  const isConsultant = Boolean(role === "consultant");
  

  const leaveSportRoom = () => {
    leaveRoom(dispatch, id)
    getUserRooms(dispatch, 'all')
  }

  const joinSportRoom = async function() {
    const [data, err] = await joinRoom(dispatch, {roomId: id, type: type})
    if (data) {
      alertSuccess(data.message);
      history.push(`/chat?room=${id }`)
    } else {
      alertError(err);
    }
    
    getUserRooms(dispatch, 'all')
  }

  const deleteSportRoom = async () => {
    const result = await alertSuccessWithConfirmaiton(`Delete room ?`,
        `Action can not be reveresed. This will delete all chat history.`);

      if (result.value) {
        await deleteRoom(dispatch, id)
      }
  }
  let buttonHandler = joinSportRoom;
  let buttonDisabled = blocked
  if (profile.id === room.ownerId) {
    buttonText = 'Delete';
    buttonClasses = "btn btn-danger";
    buttonHandler = deleteSportRoom;
  } else if (member) {
    buttonText = 'Leave';
    buttonClasses = "btn btn-default";
    buttonHandler = leaveSportRoom;
  } else if (blocked) {
    buttonText = 'Blocked';
    buttonClasses = "btn btn-danger";
    buttonHandler = '';
  } else if (pending) {
    buttonText = 'Request Sent';
    buttonClasses = "btn btn-warning";
    buttonHandler = '';
    buttonDisabled = true
  } else if (type == 3) {
    buttonText = 'Request To Join';
    buttonClasses = "btn btn-warning";
    buttonHandler = joinSportRoom;
  }

function truncateNames(input) {
  if (input.length > 15) {
    return input.substring(0, 15) + '...';
  }
  return input
}

  return (
    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 room-panel" key={id}>
      <div className="panel panel-bg-primary rounded shadow">
        <div className="panel-body">
          {status &&
            <div className="ribbon-wrapper top-left">
              <div className="ribbon ribbon-primary">{status}</div>
            </div>
          }
          <ul className="inner-all list-unstyled">
            <li className="image">
            {member && <Link to={`/chat?room=${id }`}><img className="img-circle img-bordered-default hundred-px" src={avatar} alt={firstName} onError={setDefaultAvatar} /></Link>}
            {!member && <img className="img-circle img-bordered-default hundred-px" src={avatar} alt={firstName} onError={setDefaultAvatar} />}
            </li>
            <li className="username">
              <h4>
              {member && <Link to={`/chat?room=${id }`}>{truncateNames(roomName)}</Link>}
              {!member && truncateNames(roomName)}
                  {/* <span>{}</span> */}
              </h4>
            </li>
            <li className="statistics">
              <p><strong>Sport: </strong>{sport.Name}</p>
              <p><strong>Type: </strong>{type == 2 ? 'Public': 'Private'}</p>
              <p><strong>Owner: </strong><Link to={`/user/${user.id }`}>{firstName}</Link> {(isFollowing || showSendMessageForAll) &&
                  <Link
                    className="send-message"
                    title="Send message"
                    to={`/chat?user=${user.id}`}
                  >
                    <i className="fa fa-fw fa-envelope" />
                  </Link>
                }</p>
              
              <p><strong>OwnerRole: </strong>{role}</p>
              <p><strong>Owner ROI: </strong>{ROI ? ROI.toFixed(2) : 'Unknown'}</p>
            </li>
            {isAuthorized &&
              <li className="text-center">
                <a href disabled={buttonDisabled} onClick={buttonHandler} className={buttonClasses}>{buttonText}</a>
              </li>}
              {!isAuthorized &&
              <li className="text-center">
                <Link to={`/login`}><a href className="btn btn-primary">Sign in to join</a></Link>
              </li>}
          </ul>

          <div className="socialShareWrap">
            <FacebookShareButton
              url={`${CONFIG.react_share_url}/room/${id}`}
              quote={roomName}
              hashtag="#sportstrader"
            >
              <FacebookIcon
                size={28}
                round
              />
            </FacebookShareButton>

            <TwitterShareButton
              url={`${CONFIG.react_share_url}/room/${id}`}
              title={roomName}
              hashtags={['#sportstrader']}
            >
              <TwitterIcon
                size={28}
                round
              />
            </TwitterShareButton>

            <RedditShareButton
              url={`${CONFIG.react_share_url}/room/${id}`}
              title={roomName}
            >
              <RedditIcon
                size={28}
                round
              />
            </RedditShareButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SportRoom;

SportRoom.propTypes = {
  DeviationSpread: PropTypes.number,
  DeviationTotal: PropTypes.number,
  ROIDrawLine: PropTypes.number,
  ROIMoneyLine: PropTypes.number,
  ROISpread: PropTypes.number,
  ROITotal: PropTypes.number,
  avatar: PropTypes.string,
  bio: PropTypes.string,
  followersCount: PropTypes.number,
  isAuthorized: PropTypes.bool,
  profile: PropTypes.object,
  room: PropTypes.object,
  onJoinRoom: PropTypes.func,
  onLeaveRoom: PropTypes.func,
  role: PropTypes.string,
  showSendMessageForAll: PropTypes.bool,
  status: PropTypes.string,
  sport: PropTypes.Object,
  member: PropTypes.bool,
  pending: PropTypes.bool,
  blocked: PropTypes.bool,
  dispatch: PropTypes.func,
  history: PropTypes.object,
}
