import React, { Component } from "react";
import PropTypes from 'prop-types';
import SmoothScrollbar from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
import Scrollbar from 'react-smooth-scrollbar';
import Message from './message';
import './style.scss';


SmoothScrollbar.use(OverscrollPlugin);

class History extends Component {
  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    const { messages, offset, currentRoom } = this.props;
    const newPageLoading =  prevProps.offset > 0 && prevProps.offset !== offset;
    const roomIsChanged = prevProps.currentRoom !== currentRoom

    if ((!newPageLoading && prevProps.messages.length < messages.length) || roomIsChanged) {
      this.scrollToBottom();
    }
  }

  handleOnScroll = (coordinates) => {
    const { offset } = coordinates;
    const { messagesLoading, hasNextPage } = this.props;
    const nearTop = offset.y === 0;

    if (nearTop && !messagesLoading && hasNextPage) {
      const { onLoadNextPage } = this.props;
      onLoadNextPage(false);
    }
  }

  scrollToBottom = () => {
    if (!this.container) {
      return;
    }

    const { scrollbar } = this.container;
    const contentHeight = scrollbar.size.content.height;
    const containerHeight = scrollbar.size.container.height;

    if (contentHeight > containerHeight) {
      scrollbar.scrollTop = contentHeight;
    }
  }

  render() {
    const { messages, myUser, chatUser, messagesLoading, offset } = this.props;

    const msgToRender = messages.map((message) => {
      const user = Number(message.userId) === Number(myUser.id) ? myUser : chatUser;

      return (
        <Message key={message.id} message={message} user={user} userId={chatUser.id} />
      );
    });

    return (
      <div className="chat-history-wrapper">
        <Scrollbar
          className="scrollbar"
          thumbMinSize={50}
          style={{height: 'inherit'}}
          onScroll={this.handleOnScroll}
          ref={(c) => this.container = c}
        >
          <div id="chat-history" className="chat-history">
            <ul>
              <div className={messagesLoading && offset === 0 ? "loader" : "loader hide"} >
                <img alt="Loader" src="/img/pics/loader.svg" width="50" height="50" />
              </div>
              {messages.length === 0 && !messagesLoading && <li className="no-messages">No messages in this conversation yet</li>}
              {msgToRender}
            </ul>
          </div>
        </Scrollbar>
      </div>);
  }
}

export default History;

History.propTypes = {
  chatUser: PropTypes.object,
  currentRoom: PropTypes.number,
  hasNextPage: PropTypes.bool,
  messages: PropTypes.array,
  messagesLoading: PropTypes.bool,
  myUser: PropTypes.object,
  offset: PropTypes.number,
  onLoadNextPage: PropTypes.func
};
