import React from 'react';
import PropTypes from 'prop-types';
import ReactHighcharts from 'react-highcharts';


const RoiChart = (props) => {
  const { dates, spread, moneyLine, total, draw, sportId } = props;

  const series = [{
    type: 'spline',
    name: 'Spread',
    data: spread
  },
  {
    type: 'spline',
    name: 'Total',
    data: total
  },
  {
    type: 'spline',
    name: 'Money Line',
    data: moneyLine
  }]

  if (Number(sportId) === 7) {
    series.push({
      type: 'spline',
      name: 'Draw Line',
      data: draw
    })
  }


  const config = {
    chart: {
      height: 300,
      type: 'line'
    },
    xAxis: {
      categories: dates
    },
    yAxis: {
      title: false
    },
    title: {
      text: 'ROI'
    },
    series
  };
  return (
    <div className="chart-wrap">
      <ReactHighcharts config={config} />
    </div>
  );
}

export default RoiChart;

RoiChart.propTypes = {
  dates: PropTypes.array.isRequired,
  draw: PropTypes.array.isRequired,
  moneyLine: PropTypes.array.isRequired,
  sportId: PropTypes.number,
  spread: PropTypes.array.isRequired,
  total: PropTypes.array.isRequired
}
