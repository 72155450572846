import React from 'react';
import PropTypes from 'prop-types';
import "./style.scss";
import LinesEllipsis from 'react-lines-ellipsis';
import { Link } from 'react-router-dom';
import { setDefaultAvatar } from '../../../helpers/defaultImage';


const UserFriend = (props) => {
  const {
    avatar,
    firstName,
    followersCount,
    lastName,
    id,
    bio,
    status,
    ROISpread,
    ROIMoneyLine,
    ROITotal,
    ROIDrawLine,
    DeviationSpread,
    DeviationTotal,
    onUnFollowUser
  } = props;

  const ROI = ROISpread + ROIMoneyLine + ROITotal + ROIDrawLine;

  return (
    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 user-stats-profile">
      <div className="panel panel-bg-primary rounded shadow">
        <div className="panel-body">
          {status &&
            <div className="ribbon-wrapper top-left">
              <div className="ribbon ribbon-primary">{status}</div>
            </div>
          }
          <div className="unfollow">
            <a href userId={id} onClick={onUnFollowUser}>
              <i className="fa fa-remove" title="Unfollow" />
            </a>
          </div>
          <ul className="inner-all list-unstyled">
            <li className="image">
              <Link to={`/user/${id}`}>
                <img className="img-circle img-bordered-default hundred-px" src={avatar} alt={firstName} onError={setDefaultAvatar} />
              </Link>
            </li>
            <li className="username">
              <h4>
                <Link to={`/user/${id}`}>
                  {firstName
                    ? <span title={`${firstName} ${lastName}`.length > 18 ? `${firstName} ${lastName}` : ''}>{firstName} {lastName}</span>
                    : <span>Anonymous</span>}
                </Link>

                <Link
                  className="send-message"
                  title="Send message"
                  to={`/chat?room=${id}`}
                >
                  <i className="fa fa-fw fa-envelope" />
                </Link>
              </h4>
            </li>
            <li className="statistics">
              <p><strong>Followers: </strong>{followersCount}</p>
              <p><strong>ROI: </strong>{ROI ? ROI.toFixed(2) : 'Unknown'}</p>
              <p><strong>Deviation Spread: </strong>{DeviationSpread ? DeviationSpread.toFixed(2) : 'Unknown'}</p>
              <p><strong>Deviation Total: </strong>{DeviationTotal ? DeviationTotal.toFixed(2) : 'Unknown'}</p>
            </li>
            <li className="bio">
              <p>
                {bio && <LinesEllipsis text={bio} maxLine="1" ellipsis="..." trimRight />}
              </p>
            </li>
            <li className="text-center">
              <Link to={`/user/${id}`} className="btn btn-warning">Check Details</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default UserFriend;

UserFriend.propTypes = {
  DeviationSpread: PropTypes.number,
  DeviationTotal: PropTypes.number,
  ROIDrawLine: PropTypes.number,
  ROIMoneyLine: PropTypes.number,
  ROISpread: PropTypes.number,
  ROITotal: PropTypes.number,
  avatar: PropTypes.string,
  bio: PropTypes.string,
  firstName: PropTypes.string,
  followersCount: PropTypes.number,
  id: PropTypes.number,
  lastName: PropTypes.string,
  onUnFollowUser: PropTypes.func,
  status: PropTypes.string
}
