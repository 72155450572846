import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i from 'immutable';
import moment from 'moment';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Button, Modal } from 'react-bootstrap';
import { connect } from "../store/context";
import { EmbededPlayer } from '../components/dashboard/embeded-player';
import { getUser } from "../api/users";
import { executePayment, pay } from "../api/paypal";
import { getPick, getParley, sendMePick } from '../api/picks';
import { getBundle } from '../api/bundles';
import { alertErrorText, emailMe, alertSuccess, alertSuccessWithConfirmaiton } from '../actions/alerts';
import Banner from "../components/banner";
import ProfilePick from "../components/users/ProfilePick/index";
import Wrapper from '../components/ui/Wrapper'
import Loader from '../components/ui/Loader/index';
import { buyFromBalance, buySubscriptionFromBalance } from '../api/payments';
import { setDefaultAvatar } from '../helpers/defaultImage';
import userIsAuthenticated from '../util/userIsAuthenticated'
import { FREEPICK_VIEW_COUNT } from '../config/config';


class Pick extends Component {
  state = {
    buying: false,
    showLoginModal: false
  };

  componentDidMount = async () => {
    const { location: { search }, match, dispatch } = this.props;
    const { id, itemType } = match.params;
    const { PayerID, paymentId } = queryString.parse(search);

    if (PayerID && paymentId) {
      await executePayment(dispatch, PayerID, paymentId, itemType, id, true)
    }

    dispatch({ type: 'GET_USER_BEGIN' });

    const isLoggedIn = userIsAuthenticated();
    const freeViewCount = localStorage.getItem('freeViewCount');
    const allowViewPick = !freeViewCount || Number(freeViewCount) < Number(FREEPICK_VIEW_COUNT);

    if (isLoggedIn || allowViewPick) {
      if (itemType === 'pick') {
        getPick(dispatch, id);
      } else if (itemType === 'parlay') {
        getParley(dispatch, id);
      } else if (itemType === 'bundle') {
        getBundle(dispatch, id)
      }
    } else {
      dispatch({ type: 'GET_USER_FAILURE' });
      this.setState({ showLoginModal: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch, item: { data } } = this.props;
    const { data: prevData = {} } = prevProps.item;

    if (!userIsAuthenticated() && data && !data.price && Object.keys(prevData).length === 0) {
      let freeViewCount = Number(localStorage.getItem('freeViewCount'));
      freeViewCount += 1;
      localStorage.setItem('freeViewCount', freeViewCount);
    }

    if (data && data.OwnerId !== prevData.OwnerId) {
      getUser(dispatch, data.OwnerId);
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch({ type: 'CLEAR_USER_DATA' });
  }

  handleGoBack = () => {
    this.props.history.push('/');
  }

  handleGoToProfile = () => {
    const { id } = this.props.ownerProfile;
    this.props.history.push(`/user/${id}`);
  }

  handleEmailMePick = async () => {
    // show popup
    const result = await emailMe('Email me this pick');

    if (result.value) {
      const { dispatch, ownerProfile } = this.props;
      const [pick] = this.props.item.data.Picks;
      const { PointSpread, MoneyLine, Total, DrawLine, Match, OwnerId,
        SpreadCoef, DrawCoef, TotalCoef, MoneyLineCoef, TotalScores } = pick;

      const { HomeTeam, AwayTeam, MatchTime } = Match;
      const spreadTeam = PointSpread ? `${PointSpread}Team` : null;
      let spread = PointSpread ? pick[`SpreadScores${PointSpread}`] : null;

      if (spread === '0.0') {
        spread = 'PK';
      }

      const spreadLine = PointSpread ? SpreadCoef : null;
      const lineTeam = MoneyLine ? `${MoneyLine}Team` : null;
      const line = MoneyLine ? MoneyLineCoef : null;
      const totalLine = Total ? `${TotalScores} ${TotalCoef}` : null;
      const drawMoneyLine = DrawLine ? DrawCoef : null;
      let message = null;
      const { ROISpread, ROIMoneyLine, ROITotal} = ownerProfile;

      if (ROISpread * ROIMoneyLine * ROITotal > 0) {
        message = `My name is ${ownerProfile.firstName}, I have ${PointSpread ? `${ROISpread} ROI on Spread${MoneyLine || DrawLine ? ', ' : ''}` : ''}${MoneyLine || DrawLine ? `${ROIMoneyLine} ROI on Money Line${Total ? ', ' : ''}` : ''}${Total ? `${ROITotal} ROI on Total ` : ''}`;
      }

      const res = await sendMePick(dispatch,
        result.value,
        `${AwayTeam} vs ${HomeTeam}`,
        moment(MatchTime).format('LLL'),
        spreadTeam,
        spread,
        spreadLine,
        lineTeam,
        line,
        Total,
        totalLine,
        DrawLine,
        drawMoneyLine,
        OwnerId,
        message);

      if (res) {
        alertSuccess('Email sent');
      } else {
        alertErrorText('Error has occured, try again later');
      }
    }
  }

  handleBuyFromBalance = async () => {
    const { dispatch, item, emailConfirmed, currentUserId, history, location } = this.props;

    if (emailConfirmed === false) {
      return alertErrorText("Your email is not confirmed. Please check you email box and click confirmation link. If you did not get email, please use 'Resend email' button below.");
    }

    if (!currentUserId) {
      const { pathname } = location;
      return history.push(`/signup?redirectUrl=${pathname}`);
    }

    const { id: itemId } = this.props.match.params;
    const { itemType, data: { price, description }} = item;

    return buyFromBalance(dispatch, `Buy ${itemType}`, itemId, price, description, history);
  }

  handleBuy = async () => {
    const { dispatch, match, item: { itemType }, currentUserId, history, location } = this.props;

    if (!currentUserId) {
      const { pathname } = location;
      history.push(`/signup?redirectUrl=${pathname}`);
      return null;
    }

    const { id: itemId } = match.params;

    try {
      this.setState({ buying: true });
      await pay(dispatch, itemId, itemType);
    } catch (e) {
      this.setState({ buying: false });
    }

    return null;
  }

  buySubscription = async () =>  {
    const {emailConfirmed, dispatch, history, item} = this.props
    const { data = {} } = item;
    const { subscription } = data;
      if (typeof emailConfirmed === 'boolean' && !emailConfirmed) {
        return alertErrorText("Your email is not confirmed. Please check you email box and click confirmation link. If you did not get email, please use 'Resend email' button below.");
      }
      const result = await alertSuccessWithConfirmaiton(`Buy Subscription "${subscription.name}" ending on ${moment(subscription.endDate).format('LL')} for $${subscription.price} ?`,
        `I understand that there are no refunds for buying any subscriptions. I also understand that it is my responsibility to know the sport and how many games are left. It is my responsibility when purchasing a subscription knowing how many games are left in the season. I also understand predictions by consultants are subject to be wrong in the short term or long term. By pressing OK and agreeing to buy this subscription I agree to these terms and agree to the Terms of Service on this platform.`);

      if (result.value) {
  
      return buySubscriptionFromBalance(dispatch, `Buy Subscription`, subscription.id, subscription.price, `Buy subscription ${subscription.price}`, history);
      }
  }

  goToSubscription = async () =>  {
    const { item, history} = this.props
    const { data = {} } = item;
    const { subscription } = data;
    history.push(`/subscriptions?subscriptionId=${subscription.id}`)
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  handleCloseLoginModal = () => {
    const { history } = this.props;
    this.setState({ showLoginModal: false });
    history.push(`/`);
  }

  handleLoginNav = () => {
    const { history, location: { pathname }} = this.props;
    history.push(`/login?redirectUrl=${pathname}`);
  }

  handleSignupNav = () => {
    const { history, location: { pathname }} = this.props;
    history.push(`/signup?redirectUrl=${pathname}`);
  }

  render() {
    const { item, ownerProfile, loading, ownerLoading, paypalLoading, currentUserId, location: { pathname }  } = this.props;
    const { buying, showLoginModal } = this.state;
    const { id, itemType: wrapperItemType } = this.props.match.params;
    const { ROIMoneyLine, ROISpread, ROITotal, DeviationSpread, DeviationTotal, followersCount, id: OwnerId } = ownerProfile
    const { data = {}, itemType, finalType } = item;
    const { Picks = [], price, subscription={} } = data;
    const load = buying || ownerLoading || loading || paypalLoading;

    const banner = (
      <div className="pick-banner">
        <Link to="/" className="logo" >
          <img src="/img/logo/logo.svg" alt="SportsTrader®" width="50" height="50" />
        </Link>
        <div className="description">
          <p>Do you want to stay up to date with all picks from <Link to={`/user/${OwnerId}`}>{ownerProfile.firstName} {ownerProfile.lastName}</Link>?</p>
          <p>Register on <Link to="/">SportsTrader.pro</Link> and follow <Link to={`/user/${OwnerId}`}>{ownerProfile.firstName} {ownerProfile.lastName}</Link>. This only takes 2 minutes to sign up.</p>
        </div>
        <Link to={`/signup?redirectUrl=${pathname}`} className="btn btn-warning" >Sign Up Now</Link>
      </div>
    )

    const profile = (
      <div className="profile">
        <div className="profile-info">
          <Link to={`/user/${OwnerId}`}>
            <img
              className="img-circle img-bordered-default"
              src={ownerProfile.avatar}
              height="38"
              width="38"
              alt={ownerProfile.firstName}
              onError={setDefaultAvatar}
            />
            <h5 className="text-strong">{ownerProfile.firstName} {ownerProfile.lastName}</h5>
          </Link>
        </div>

        <div className="profile-stat">
          <div className="separator" />
          <div className="item">
            <div className="title">Followers</div>
            <div className="value">{followersCount ? followersCount : '-'}</div>
          </div>
          <div className="separator" />
          <div className="item">
            <div className="title">ROI on Total</div>
            <div className="value">{ROITotal ? `${ROITotal.toFixed(2)}` : '-'}</div>
          </div>
          <div className="separator" />
          <div className="item">
            <div className="title">Deviation on Total</div>
            <div className="value">{DeviationTotal ? DeviationTotal.toFixed(2) : '-'}</div>
          </div>
          <div className="separator" />
          <div className="item">
            <div className="title">ROI on Spread</div>
            <div className="value">{ROISpread ? `${ROISpread.toFixed(2)}` : '-'}</div>
          </div>
          <div className="separator" />
          <div className="item">
            <div className="title">Deviation on Spread</div>
            <div className="value">{DeviationSpread ? DeviationSpread.toFixed(2) : '-'}</div>
          </div>
          <div className="separator" />
          <div className="item">
            <div className="title">ROI on Money Line</div>
            <div className="value">{ROIMoneyLine ? `${ROIMoneyLine.toFixed(2)}` : '-'}</div>
          </div>
        </div>
      </div>
    )

    const loginModal = (
      <Modal show={showLoginModal} onHide={this.handleCloseLoginModal} dialogClassName="modal-login">
        <Modal.Header className="modal-login-header">
          <Modal.Title className="text-center modal-login-title">Please sign in!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center modal-login-body">Only registered users can see free picks</Modal.Body>
        <Modal.Footer className="modal-login-footer">
          <Button onClick={this.handleLoginNav} className="modal-login-footer-button btnlogin">
            Sign In
          </Button>
          <Button onClick={this.handleSignupNav} className="modal-login-footer-button btnsignup">
            Sign Up
          </Button>
        </Modal.Footer>
      </Modal>
    );

    const anonymousFreePick = !currentUserId && itemType === 'Pick' && !price;

    return (
      <Wrapper
        className="pick-page"
        title={`${this.capitalizeFirstLetter(wrapperItemType)} - ${id}`}
        icon="fa fa-diamond"
        showAdBlockAlert
      >
        {(load) && <Loader />}
        {!load &&
          <>
            {!currentUserId && banner}
            {profile}
            <Banner />
            {loginModal}
            {data.Url && <EmbededPlayer url={data.Url} />}
            <ProfilePick picks={Picks} pickType={finalType} hideInfo itemType={itemType} />
            <div className="buy-buttons clearfix">
              {currentUserId && <button className="btn btn-default back pull-left" onClick={this.handleGoBack}>Feed</button>}
              {!currentUserId && <button className="btn btn-default back pull-left" onClick={this.handleGoToProfile}>VIEW PROFILE</button>}
              {anonymousFreePick && <button className="btn btn-warning pull-right" onClick={this.handleEmailMePick}>EMAIL ME PICK</button>}
              {Number(price) > 0 && !subscription.id &&
                <>
                  <button className="btn btn-default paypal pull-right" onClick={this.handleBuy}>
                    <img className="paypal-img" src="/img/paypal/paypal.svg" alt="paypal" />
                  </button>
                  <button className="btn btn-default pull-right" onClick={this.handleBuyFromBalance}>BUY NOW</button>
                </>
              }
              {subscription.id > 0 &&
                <>
                  <button className="btn btn-default pull-right" onClick={this.goToSubscription}>SEE DETAILS</button>
                  <button className="btn btn-default pull-right" onClick={this.buySubscription}>BUY SUBSCRIPTION</button>
                </>
              }
            </div>
          </>
        }
      </Wrapper>
    );
  }
}

function select(state) {
  const pick = state.getIn(['picks', 'pick'], i.Map()).toJS();
  const parley = state.getIn(['picks', 'parley'], i.Map()).toJS();
  const bundle = state.getIn(['picks', 'bundle'], i.Map()).toJS();
  const ownerProfile = state.getIn(['users', 'userProfile'], i.Map()).toJS();
  const loading = state.getIn(['picks', 'loading'], true);
  const ownerLoading = state.getIn(['users', 'userLoading'], true);
  const paypalLoading = state.getIn(['paypal', 'loading'], true);
  const emailConfirmed = state.getIn(['users', 'profile', 'emailVerified'], null);
  const currentUserId = state.getIn(['users', 'profile', 'id'], null);

  let item = {};

  if (Object.keys(pick).length) {
    item = {
      data: { Picks: [pick], price: pick.price, OwnerId: pick.OwnerId, description: pick.description, id: pick.id, Url: pick.Url, subscription: pick.subscription },
      itemType: 'Pick',
      finalType: pick.Result.FinalType === 'Finished' ? 'final' : 'pregame'
    };
  } else if (Object.keys(parley).length) {
    item = { data: parley, itemType: 'Parlay' }
  } else if (Object.keys(bundle).length) {
    item = { data: bundle, itemType: 'Bundle' }
  }

  return { item, ownerProfile, loading, ownerLoading, emailConfirmed, currentUserId, paypalLoading }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Pick);

Pick.propTypes = {
  currentUserId: PropTypes.number,
  dispatch: PropTypes.func,
  emailConfirmed: PropTypes.bool,
  history: PropTypes.object,
  id: PropTypes.number,
  item: PropTypes.object,
  loading: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  ownerLoading: PropTypes.bool,
  ownerProfile: PropTypes.object,
  paypalLoading: PropTypes.bool
};
