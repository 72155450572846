import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i from 'immutable';
import { Steps } from 'intro.js-react';
import { Modal } from 'react-bootstrap';
import { connect } from "../store/context";
import { getMyPicksIncomplete, completePick, createParley } from '../api/picks';
import { fetchMySubscription } from '../api/subscription';
import { createBundle } from '../api/bundles';
import IncompletePicksTable from '../components/picks/IncompletePicksTable/index';
import PicksToCompleteTable from '../components/picks/PicksToCompleteTable/index';
import Wrapper from '../components/ui/Wrapper';
import { roiCalculator } from '../components/picks/roiCalculator';
import { isValidTextField, isFormValid } from "../helpers/validators";
import { alertSuccess, alertErrorText, alertError } from '../actions/alerts';
import { completeCreatePickIntro, introOptions } from '../helpers/intro';
import 'intro.js/introjs.css';
import moment from 'moment';

class IncompletePicks extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    incompletePicks: PropTypes.array,
    loading: PropTypes.bool,
    mySubscriptions: PropTypes.array
  }

  constructor(props) {
    super(props);
    this.state = {
      addToSub: false,
      name: '',
      price: '',
      freePick: false,
      pickedSubs: "",
      url: '',
      description: '',
      selectedPicks: [],
      formMessages: {},
      stepsEnabled: false,
      type: 'pick',
      showModal: false,
      validFields: { "name": true, "price": false, "url": true, "description": true, "addToSub": true }
    }
    this.initialState = this.state;
  }

  componentDidMount = async () => {
    const { dispatch } = this.props;
    getMyPicksIncomplete(dispatch);
    fetchMySubscription(dispatch, `type=active`);
  }

  handleClickFreePick = () => {
    const { freePick, price, formMessages, validFields } = this.state;

    if (freePick) {
      const { isValidField, error } = isValidTextField(price, 'price', null, true);
      formMessages['price'] = error;
      validFields['price'] = isValidField;
    } else {
      formMessages['price'] = null;
      validFields['price'] = true;
    }

    this.setState({ validFields, freePick: !freePick });
  }

  addToSubscriptions = () => {
    const { addToSub, price, formMessages, validFields, selectedPicks } = this.state;
    const [pick] = selectedPicks;

    if (addToSub) {
      const { isValidField, error } = isValidTextField(price, 'price', null, true);
      formMessages['price'] = error;
      validFields['price'] = isValidField;
      validFields['addToSub'] = true;
    } else {
      formMessages['price'] = null;
      validFields['price'] = true;
      validFields['addToSub'] = false;
    }
    this.setState({ validFields, addToSub: !addToSub });
  }

  handleSubs = (e) => {
    const { selectedPicks, validFields } = this.state
    const [pick] = selectedPicks;
    const currentDate = moment()
    const matchTime = moment(pick.Match.MatchTime)
    const duration = moment.duration(matchTime.diff(currentDate));
    if (duration <= 1) {
      alertErrorText("Picks for matches starting in an hour or under cannot be assigned to subscriptions.")
      return
    }
    //validate subscription sport is valid for the pick
    const { mySubscriptions } = this.props;
    for (let subscription of mySubscriptions) {
      if (subscription.id == e.target.value && subscription.sportId != pick.Match.Sport) {
        validFields["addToSub"] = false
        this.setState({ validFields });
        alertErrorText("Selected subscription sport is not valid for this pick.")
        return
      }
    }
    validFields["addToSub"] = true
    this.setState({ pickedSubs: e.target.value, validFields });
  }


  handleChange = (event) => {
    event.preventDefault();

    this.setState({
      [event.target.id]: event.target.value
    });

    const { validFields, formMessages } = this.state;
    const { isValidField, error } = isValidTextField(event.target.value, event.target.name, null, event.target.required);
    const key = event.target.id;
    formMessages[key] = error;

    if (isValidField || (!event.target.required && event.target.value === "")) {
      validFields[key] = true;
      formMessages[key] = null;
    } else {
      validFields[key] = false;
    }

    this.setState({ formMessages, validFields });
  }

  handleModalChoice = (e) => {
    e.preventDefault();
    const { id } = e.target;

    const { validFields } = this.state;
    validFields['name'] = false;

    this.setState({ type: id, showModal: false, validFields });
  }

  handleCloseModal = () => {
    const { validFields } = this.state;
    validFields['name'] = true;

    this.setState({ showModal: false, selectedPicks: [], validFields });
  }

  handleSelectPick = (pick) => {
    const { selectedPicks, type } = this.state;
    const selectedIds = [];
    selectedPicks.forEach((selected) => {
      selectedIds.push(selected.id);
    });
    const index = selectedIds.indexOf(pick.id);

    if (index < 0) {
      if (selectedPicks.length === 13) {
        alertErrorText(`Can't create ${type} with more than 13 Picks`);
      } else {
        this.setState({ selectedPicks: [...this.state.selectedPicks, pick] });
      }

      if (selectedPicks.length === 1) {
        this.setState({ showModal: 'show' });
      }
    } else {
      selectedPicks.splice(index, 1);

      if (selectedPicks.length === 1) {
        alertSuccess('Your are creating Pick now!');
        const { validFields } = this.state;
        validFields['name'] = true;
        this.setState({ type: 'pick', validFields, name: '' });
      }
    }

    this.setState(selectedPicks);
  }

  handleSelectAllPicks = () => {
    const { incompletePicks } = this.props;
    const selectedPicks = incompletePicks.slice(0, 13);

    this.setState({ selectedPicks: [...selectedPicks] });

    if (incompletePicks.length > 1) {
      this.setState({ showModal: 'show' });
    }
  }

  handleUnselectAllPicks = () => {
    const { validFields } = this.state;
    validFields['name'] = true;
    this.setState({ selectedPicks: [], type: 'pick', validFields, name: '' });
  }

  handleSavePick = async (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    const { freePick, description, url, name, selectedPicks, type, addToSub, pickedSubs } = this.state;
    let { price } = this.state;

    if (freePick || addToSub) {
      price = 0;
    }

    if (!addToSub && !freePick && (price.length === 0 || !Number.isFinite(Number(price)) || Number(price) < 0)) {
      return;
    }

    let pickResult;
    if (selectedPicks.length === 1 && addToSub) {
      const [pick] = selectedPicks;
      pickResult = await completePick(dispatch, pick.id, price, description, url, pickedSubs);

    } else if (selectedPicks.length === 1) {
      const [pick] = selectedPicks;
      pickResult = await completePick(dispatch, pick.id, price, description, url);
    } else if (selectedPicks.length >= 1) {
      const pickIds = selectedPicks.map((p) => p.id);

      if (!name) {
        return;
      }

      if (type === 'bundle') {
        pickResult = await createBundle(dispatch, pickIds, price, description, url, name);
      } else {
        pickResult = await createParley(dispatch, pickIds, price, description, url, name);
      }
    }

    if (pickResult) {
      this.setState(this.initialState);
    }

    this.setState({ selectedPicks: [] });
  }

  handleHideIntro = () => {
    this.setState({ stepsEnabled: false });
  }

  render() {
    const { incompletePicks, loading, mySubscriptions } = this.props;
    const { addToSub, freePick, price, selectedPicks, url, name, description, formMessages, type, showModal, validFields } = this.state;
    const unselectedPicks = incompletePicks.filter((x) => !selectedPicks.filter((y) => y.id === x.id).length > 0);
    const { stepsEnabled } = this.state;
    const firstIncompletePick = localStorage.getItem('completeCreatePickIntro');
    const formIsInvalid = isFormValid(validFields);
    if (!stepsEnabled && firstIncompletePick === 'true') {
      this.setState({ stepsEnabled: true });
      localStorage.removeItem('completeCreatePickIntro');
    }

    return (
      <Wrapper
        className="incomplete-picks-page"
        title={incompletePicks.length > 0 ? 'Incomplete Picks' : 'Incomplete Pick'}
        counter={incompletePicks.length > 0 && incompletePicks.lenght}
        icon="fa fa-diamond"
        hideNotifications
      >
        <Steps
          enabled={stepsEnabled}
          initialStep={0}
          steps={completeCreatePickIntro.steps}
          onExit={this.handleHideIntro}
          options={introOptions}
        />

        <Modal show={showModal} className="incomplete-picks-modal" onHide={this.handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Choose what type of bet you wanna create.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Bundle is package of picks, Parlay is one bet of many picks.</p>
          </Modal.Body>
          <Modal.Footer>
            <button id="bundle" className="btn btn-warning" onClick={this.handleModalChoice}>Bundle</button>
            <button id="parlay" className="btn btn-warning" onClick={this.handleModalChoice}>Parlay</button>
          </Modal.Footer>
        </Modal>

        <div className="panel">
          <div className="panel-body">
            <div className="col-lg-8 col-md-7 col-sm-7 col-sm-12">
              <IncompletePicksTable
                onSelectPick={this.handleSelectPick}
                unselectedPicks={unselectedPicks}
                onSelectAllPicks={this.handleSelectAllPicks}
                onUnselectAllPicks={this.handleUnselectAllPicks}
              />

              {selectedPicks.length > 0 &&

                <div>
                  <div className="info-arrow">
                    <i className="fa fa-arrow-down" />
                    <span>Your selected {type}</span>
                  </div>

                  <div className="picks-to-complete-wrapper">
                    {selectedPicks.map((pick) => {
                      return pick.Match &&
                        <PicksToCompleteTable
                          onSelectPick={this.handleSelectPick}
                          key={pick.id}
                          pick={pick}
                        />
                    })}
                  </div>
                </div>
              }
            </div>
            <div className="col-lg-4 col-md-5 col-sm-5 col-xs-12">
              <form className={`form-horizontal ${selectedPicks.length > 0 && "available"}`}>

                {type !== 'pick' &&
                  <div className={formMessages.name ? "form-group has-error" : "form-group"}>
                    <input
                      type="text"
                      onChange={this.handleChange}
                      value={name}
                      id="name"
                      className="form-control name"
                      placeholder={`${type} Name*`}
                      name="name_with_space"
                      required
                    />
                    <span className="help-block">{formMessages.name}</span>
                  </div>}

                <div className={formMessages.price ? "form-group pick-price has-error" : "form-group pick-price"}>
                  <input
                    onChange={this.handleChange}
                    value={price}
                    className="form-control"
                    placeholder="Price*"
                    disabled={freePick || addToSub}
                    type="text"
                    name="price"
                    id="price"
                    maxLength="10"
                    required
                  />
                  <span className="help-block">{formMessages.price}</span>
                  <div>
                    <input
                      id="checkbox"
                      onClick={this.handleClickFreePick}
                      type="checkbox"
                      checked={freePick}
                      disabled={addToSub}
                    />
                    <label htmlFor="checkbox">Free {type}</label>
                  </div>

                  {type == 'pick' && <div>
                    <input
                      id="addToSub"
                      onClick={this.addToSubscriptions}
                      type="checkbox"
                      checked={addToSub}
                      disabled={freePick}
                    />
                    <label htmlFor="addToSub">Add {type} to subscription</label>
                  </div>}
                </div>
                <div className="form-group">
                  {addToSub ?
                    <select className="form-control" id="subsOptions" onChange={this.handleSubs}>
                      <option value="none" selected disabled hidden>
                        Select Subscription
                      </option>
                      {mySubscriptions.map((e) => <option key={e.id} value={e.id}>{e.name}</option>)}
                    </select> :
                    <></>
                  }
                </div>
                <div className={formMessages.url ? "form-group has-error" : "form-group"}>
                  <input
                    type="url"
                    onChange={this.handleChange}
                    value={url}
                    className="form-control"
                    placeholder="Content URL"
                    id="url"
                    name="url"
                  />
                  <span className="help-block">{formMessages.url}</span>
                  <label htmlFor="contentUrl">Please paste link to youtube video or soundcloud track.</label>
                </div>

                <div className={formMessages.description ? "form-group has-error" : "form-group"}>
                  <textarea
                    id="description"
                    onChange={this.handleChange}
                    value={description}
                    className="form-control"
                    rows="4"
                    name="bio"
                    placeholder="Short description. It will be publicly available."
                    disabled={!freePick}
                  />
                  <span className="help-block">{formMessages.description}</span>
                </div>

                <button disabled={formIsInvalid || loading} onClick={this.handleSavePick} className="btn btn-warning">
                  Publish {type}
                </button>

                <div className="potential-roi">
                  Potential ROI: <span>{selectedPicks.length > 0 ? roiCalculator(selectedPicks, type) : '0'}</span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

function select(state) {
  const incompletePicks = state.getIn(['picks', 'incompletePicks'], i.List()).toJS();
  const mySubscriptions = state.getIn(['subscriptions', 'mySubscriptions'], i.List()).toJS();
  const loading = state.getIn(['picks', 'loading'], false);

  return { incompletePicks, loading, mySubscriptions }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(IncompletePicks);
