import { Map, List, fromJS, isIndexed } from 'immutable';
import {
  FETCH_ODDS_BEGIN,
  FETCH_ODDS_SUCCESS,
  FETCH_ODDS_FAILURE
} from '../actions/odds';


const initialState = Map({
  items: List(),
  loading: false,
  error: null
});

export default function oddsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ODDS_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      state = state.set('loading', true);
      break;
    case FETCH_ODDS_SUCCESS:
      if (isIndexed(fromJS(action.data.odds))) {
        state = state.set('items', fromJS(action.data.odds));
      }

      state = state.set('loading', false);
      break;

    case FETCH_ODDS_FAILURE:
      state = state.set('error', action.error);
      break;

    default:
      return state;
  }

  return state;
}
