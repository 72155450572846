import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from "../../../store/context";
import { updateSidebarOpenStatus } from '../../../actions/general';
import LeftHeader from './LeftHeader/index';
import RightHeader from './RightHeader/index';
import './style.scss';


class Header extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    isSidebarOpen: PropTypes.bool,
    location: PropTypes.object
  }

  state = {
    scrolling: false
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleCloseSideBar = (e) => {
    const { dispatch, isSidebarOpen } = this.props;

    if (!isSidebarOpen) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    dispatch(updateSidebarOpenStatus(false));
  }

  handleScroll = () => {
    if (window.scrollY === 0 && this.state.scrolling === true) {
      this.setState({scrolling: false});
    } else if (window.scrollY > 50 && this.state.scrolling !== true) {
      this.setState({scrolling: true});
    }
  }

  render() {
    const { location } = this.props;

    return (
      <header
        id="header"
        className={this.state.scrolling || location.pathname === '/chat' ? "on-scrolling" : ""}
        onClickCapture={this.handleCloseSideBar}
        onClick={this.handleCloseSideBar}
      >
        <LeftHeader />
        <RightHeader />
      </header>
    );
  }
}

function select(state) {
  const isSidebarOpen = state.getIn(['general', 'sidebarOpen'], false);
  return { isSidebarOpen }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default withRouter(connect(select, mapDispatchToProps)(Header));
