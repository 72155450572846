import { fromJS, Map, List } from 'immutable';
import decode from 'jwt-decode';
import {
  FETCH_SPORT_ROOMS_BEGIN,
  FETCH_SPORT_ROOMS_SUCCESS,
  FETCH_SPORT_ROOMS_FAILURE,
  CREATE_SPORT_ROOMS_BEGIN,
  CREATE_SPORT_ROOMS_SUCCESS,
  CREATE_SPORT_ROOMS_FAILURE
} from '../actions/rooms';


const initialState = Map({
  items: List(),
  roomsLoaded: false,
  loading: false,
  error: null,
  currentUserId: null,
  limit: 20,
  offset: 0,
});

export default function roomsReducer(state = initialState, action) {
    switch (action.type) {
    case FETCH_SPORT_ROOMS_BEGIN:
      state = state.set('loading', true);
      break;

    case FETCH_SPORT_ROOMS_SUCCESS:
      state = state.set('items', fromJS(action.rooms.rooms));
      state = state.set('loading', false);
      break;
    case FETCH_SPORT_ROOMS_FAILURE:
      state = state.set('error', action.error);
      break;
    
    }

    
    return state;
}