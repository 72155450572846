import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { connect } from "../../store/context";
import { updateProfile } from "../../api/users";
import { isValidTextField, isFormValid } from "../../helpers/validators";


class Socials extends Component {
  state = {
    facebook: this.props.facebook,
    twitter: this.props.twitter,
    formMessages: {},
    validFields: { facebook: true, twitter: true },
    formChanged: false
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });

    const { validFields, formMessages } = this.state;
    const { isValidField, error } = isValidTextField(event.target.value, event.target.name, null, event.target.required);
    const key = event.target.id;
    formMessages[key] = error;

    if (isValidField || (!event.target.required && event.target.value === "")) {
      validFields[key] = true;
      formMessages[key] = null;
    } else {
      validFields[key] = false;
    }

    this.setState({formMessages, validFields});

    if (!this.state.formChanged) {
      this.setState({formChanged: true});
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { dispatch } = this.props;
    const { facebook, twitter } = this.state;
    const CheckIfEmpty = (value) => value === '' ? null : value;

    updateProfile(dispatch, { facebook: CheckIfEmpty(facebook), twitter: CheckIfEmpty(twitter) });
  }

  render() {
    const {
      facebook,
      twitter,
      formMessages,
      validFields,
      formChanged
    } = this.state;

    const { loading } = this.props;

    const formIsInvalid = isFormValid(validFields);

    return (
      <form className="form-horizontal no-overflow" onSubmit={this.handleSubmit}>
        <div className="social">
          <div className={formMessages.facebook ? "form-group has-error" : "form-group"}>
            <label className="control-label" htmlFor="facebook">Facebook</label>
            <div className="input-group no-overflow shadow">
              <input
                id="facebook"
                type="url"
                name="url"
                className="form-control"
                placeholder="Facebook Link"
                value={facebook}
                onChange={this.handleChange}
              />
              <span className="input-group-addon"><i className="fa fa-facebook" /></span>
            </div>
            <span className="help-block">{formMessages.facebook}</span>
          </div>
          <div className={formMessages.twitter ? "form-group has-error" : "form-group"}>
            <label className="control-label" htmlFor="twitter">Twitter</label>
            <div className="input-group no-overflow shadow">
              <input
                id="twitter"
                type="url"
                name="url"
                className="form-control"
                placeholder="Twitter Link"
                value={twitter}
                onChange={this.handleChange}
              />
              <span className="input-group-addon"><i className="fa fa-twitter" /></span>
            </div>
            <span className="help-block">{formMessages.twitter}</span>
          </div>
        </div>

        <div className="navigation">
          <Link to="/profile" className="btn btn-default" >Cancel</Link>
          <button
            disabled={formIsInvalid || !formChanged || loading}
            type="submit"
            className={`btn btn-warning ${loading && 'loading'}`}
          >Save
          </button>
        </div>
      </form>
    );
  }
}

function select(state) {
  const facebook = state.getIn(['users', 'profile', 'facebook'], String());
  const twitter = state.getIn(['users', 'profile', 'twitter'], String());
  const loading = state.getIn(['users', 'loading'], false);

  return { facebook, twitter, loading }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Socials);

Socials.propTypes = {
  dispatch: PropTypes.func,
  facebook: PropTypes.string,
  loading: PropTypes.bool,
  twitter: PropTypes.string
};
