import React from 'react';


const Paginator = (params) => {
  const { pages, handleClick, currentPage } = params;
  const items = [];
  const delta = 3;
  const left = pages - currentPage < delta ? pages - 5 : currentPage - delta;
  const right = currentPage < delta ? 6 : currentPage + delta;

  if (pages && pages > 1) {
    items.push(
      <li key="first">
        <a href id="first" className={currentPage === 1 && 'not-active'} onClick={currentPage !== 1 && handleClick}>First</a>
      </li>
    );

    for (let pageNumber = 1; pageNumber <= pages; pageNumber++) {
      if (pageNumber >= left && pageNumber < right) {
        items.push(
          <li key={pageNumber} className={pageNumber === currentPage ? "active" : ''}>
            <a href id={pageNumber} onClick={handleClick}>{pageNumber}</a>
          </li>
        );
      }
    }

    items.push(
      <li key="last">
        <a href id="last" className={currentPage === pages && 'not-active'} onClick={currentPage !== pages && handleClick}>Last</a>
      </li>
    );
  }

  return (
    <div className="pagination-wrap text-center">
      <div className="pagination">
        {items}
      </div>
    </div>
  );
};

export default Paginator;
