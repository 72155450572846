import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from "../../../store/context";
import { blockUserRoom, unblockUserRoom, leaveRoom, deleteRoom, getUserRooms } from "../../../api/chat";
import TipsModal from "../TipsModal";
import MembersModal from "../Members";
import './style.scss';
import { alertSuccessWithConfirmaiton } from '../../../actions/alerts'


class RoomHead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }
  // componentDidMount() {
  //   alert(1)
  // }
  handleClickBlock = () => {
    const { dispatch } = this.props;
    const { currentRoom, blockedByMe } = this.props;

    if (blockedByMe) {
      unblockUserRoom(dispatch, currentRoom.id);
    } else {
      blockUserRoom(dispatch, currentRoom.id);
    }
  }

  handleLeaveRoom = async () => {
    const { dispatch } = this.props;
    const { currentRoom, user } = this.props;
    // alert(currentRoom.chatRoomId)
    if (currentRoom && currentRoom.room.ownerId == user.id) {
      const result = await alertSuccessWithConfirmaiton(`Delete room ?`,
        `Action can not be reveresed. This will delete all chat history.`);

      if (result.value) {
        deleteRoom(dispatch, currentRoom.chatRoomId)
        getUserRooms(dispatch, 1)
      }
    } else {
      leaveRoom(dispatch, currentRoom.chatRoomId)
      getUserRooms(dispatch, 1)
    }

  }

  handleOpenModal = () => {
    this.setState({showModal: true});
  }
  handleOpenMembersModal = () => {
    this.setState({showMembersModal: true});
  }
  onCloseModalMembersModal = () => {
    this.setState({showMembersModal: false});
  }


  onCloseModal = () => {
    this.setState({showModal: false});
  }

  render() {
    const { currentRoom, blockedByMe, onCloseRoom, members, user } = this.props;
    const { showModal, showMembersModal } = this.state;
    let leaveRoomTxt = 'Leave'
    if (currentRoom && currentRoom.room.ownerId == user.id) {
      leaveRoomTxt = 'Delete'
    }


    return (
      <div className="chat-header">
        <div className="chat-with">
          <div className="close-room" onClick={onCloseRoom}><i className="fa fa-angle-left" /></div>
          <div className="title"></div>
          
          <div>
            <span className="send-tip" onClick={this.handleOpenMembersModal}>
              <i className="fa fa-user" />
              <span>Members</span>
            </span>
            <MembersModal showModal={showMembersModal} handleCloseModal={this.onCloseModalMembersModal} members={members} user={user} currentRoom={currentRoom} />
            <span className="send-tip" onClick={this.handleOpenModal}>
              <i className="fa fa-gift" />
              <span>Send tip</span>
            </span>
            <TipsModal showModal={showModal} handleCloseModal={this.onCloseModal} />
            
            <span className="block-unblock-user" onClick={this.handleLeaveRoom}>
            <i className="fa fa-trash" /><span>{leaveRoomTxt}</span>
            </span>
          </div>
        </div>
      </div>);
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(mapDispatchToProps)(RoomHead);

RoomHead.propTypes = {
  blockedByMe: PropTypes.bool,
  currentRoom: PropTypes.object,
  dispatch: PropTypes.func,
  onCloseRoom: PropTypes.func,
  members: PropTypes.object,
  user: PropTypes.object,
};
