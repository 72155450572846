import React from 'react';
import PropTypes from 'prop-types';
import { alertErrorText } from '../../actions/alerts';
import { uploadProfileImage } from "../../api/users";
import { connect } from "../../store/context";


const Cover = (props) => {
  const handleFileChange = async (event) => {
    const [file] = event.target.files;

    if (!file) {
      return;
    }

    const [type] = file.type.split('/');

    if (type !== 'image' || file.size > 1000000) {
      alertErrorText("Wrong file type or size > 1MB");
      return;
    }

    const formData = new FormData();
    formData.append('upfile', file);

    const { dispatch } = props;
    await uploadProfileImage(dispatch, formData);
  }

  const { profileImage, myProfile } = props;

  if (!profileImage && !myProfile) {
    return '';
  }

  return (
    <div className="cover rounded shadow no-overflow">
      {myProfile && <label htmlFor="upload-profile-image">{profileImage ? 'Change Profile Image' : 'Upload Profile Image'}</label>}
      <input id="upload-profile-image" type="file" onChange={handleFileChange} />
      <div className="inner-cover">
        {profileImage && <img data-no-retina="" src={profileImage} className="img-responsive" alt="cover" />}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(mapDispatchToProps)(Cover);

Cover.propTypes = {
  myProfile: PropTypes.bool,
  profileImage: PropTypes.string
}
