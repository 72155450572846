import i from 'immutable';
import { combineReducers } from 'redux-immutablejs';

import odds from './odds';
import sports from './sports';
import picks from './picks';
import users from './users';
import general from './general';
import contentFeed from './content-feed';
import chat from './chat';
import rooms from './rooms';
import payments from './payments';
import paypal from './paypal'
import notifications from './notifications';
import parlays from './parlays'
import matches from './matches';
import scrollRestore from './scroll-restore'
import subscriptions from './subscriptions';


export const reducers = combineReducers(i.Map({
  odds,
  sports,
  picks,
  users,
  general,
  contentFeed,
  chat,
  payments,
  paypal,
  notifications,
  parlays,
  matches,
  scrollRestore,
  rooms,
  subscriptions
}));
