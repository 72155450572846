
export const roiCalculator = (selectedPicks, itemType) => {
  let coefficients = [];

  if (itemType === 'pick') {
    const [pick] = selectedPicks;
    coefficients = pushCoefficientsToArray(pick);
    const coefficientsRoi = calculateRoi(coefficients);
    const newBets = coefficientsRoi.reduce((a, b) => a + b);
    return newBets.toFixed(2);
  }

  selectedPicks.forEach((pick) => {
    coefficients = coefficients.concat(pushCoefficientsToArray(pick));
  });

  let coefficientsRoi;
  let newBets;

  if (itemType === 'parlay') {
    coefficientsRoi = calculateRoi(coefficients, true);
    newBets = coefficientsRoi.reduce((a, b) => a * b) - 1;
  }

  if (itemType === 'bundle') {
    coefficientsRoi = calculateRoi(coefficients);
    newBets = coefficientsRoi.reduce((a, b) => a + b);
  }

  return newBets.toFixed(2);
}

const pushCoefficientsToArray = (pick) => {
  const coefficients = [];
  const { PointSpread, MoneyLine, Total, Odd, DrawLine } = pick;

  if (PointSpread === 'Home') {
    coefficients.push(Odd.PointSpreadHomeLine);
  } else if (PointSpread === 'Away') {
    coefficients.push(Odd.PointSpreadAwayLine);
  }

  if (MoneyLine === 'Home') {
    coefficients.push(Odd.MoneyLineHome);
  } else if (MoneyLine === 'Away') {
    coefficients.push(Odd.MoneyLineAway);
  }

  if (Total === 'Over') {
    coefficients.push(Odd.OverLine);
  } else if (Total === 'Under') {
    coefficients.push(Odd.UnderLine);
  }

  if (DrawLine) {
    coefficients.push(Odd.DrawLine);
  }

  return coefficients;
};

const calculateRoi = (coefficients, inParlay) => {
  const coefficientsRoi = coefficients.map((odd) => {
    let ROI = 0;

    if (odd < 0) {
      ROI = Number((100 / Math.abs(odd)).toFixed(2));
    } else if (odd > 0) {
      ROI = Number((odd / 100).toFixed(2));
    }

    if (inParlay) {
      ROI += 1;
    }

    return ROI;
  });

  return coefficientsRoi;
};
