import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import "./style.scss";


const IncompletePicksTable = (props) => {
  const { unselectedPicks, onSelectPick, onSelectAllPicks, onUnselectAllPicks } = props;

  const handleSelectPick = (e) => {
    e.preventDefault();
    const pick = unselectedPicks.find((pick) => pick.id === Number(e.currentTarget.id));
    onSelectPick(pick);
  };

  const handleSelectAllPicks = (e) => {
    e.preventDefault();

    if (unselectedPicks.length === 0) {
      return onUnselectAllPicks();
    }

    return onSelectAllPicks();
  }

  return (
    <table className="table incomplete-picks-table">
      <thead>
        <tr>
          <td colSpan="12">
            <h4 className="pull-left">Select Picks</h4>
            <a href className="pull-right btn btn-warning" onClick={handleSelectAllPicks}>
              {unselectedPicks.length === 0 ? "Remove" : "Add"} all
            </a>
          </td>
        </tr>
      </thead>
      <tbody>
        {unselectedPicks.map((pick) => {
          const { AwayTeam, HomeTeam, MatchTime } = pick.Match;
          return (
            <tr key={pick.id}>
              <td colSpan="12">
                <div className="pull-left">
                  <span className="team-name">{AwayTeam}</span>
                  <span className="team-separator">VS</span>
                  <span className="team-name">{HomeTeam}</span>
                </div>
                <div className="pull-right">
                  Game Date: {moment(MatchTime).format('L')} {moment(MatchTime).format('LT')}
                </div>
                <div id={pick.id} className="plus" onClick={handleSelectPick}>
                  <i className="fa fa-plus-circle" />
                </div>
              </td>
            </tr>)
        })
        }
      </tbody>
    </table>
  );
}

export default IncompletePicksTable;

IncompletePicksTable.propTypes = {
  onSelectAllPicks: PropTypes.func,
  onSelectPick: PropTypes.func,
  onUnselectAllPicks: PropTypes.func,
  unselectedPicks: PropTypes.array
};
