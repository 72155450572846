import React from 'react';
import moment from 'moment';
import { NavLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import "./style.scss";
import { alertErrorText, alertSuccessWithConfirmaiton } from '../../../actions/alerts';
import { EmbededPlayer } from '../embeded-player';
import { pay } from '../../../api/paypal';
import { buyFromBalance, buySubscriptionFromBalance } from '../../../api/payments';


export const PickFeed = (props) => {
  const { sponsored,
    title,
    description,
    matchDate,
    owner,
    ownerId,
    url,
    price,
    pickId,
    subscriptionId,
    subscription,
    picksCount,
    parleyId,
    bundleId,
    pageHistory,
    dispatch,
    boughtByMe,
    createdAt,
    confirmed,
    upcomingMatch,
    betTypes = {},
    sports = [],
    forSale = false,
    mySubscriptions = [] } = props;

  let userSubscribed = false

  for (let userSubscription of mySubscriptions) if (userSubscription.id == subscriptionId) userSubscribed = true
  const checkItemType = () => {
    if (pickId) {
      return 'Pick'
    } else if (parleyId) {
      return 'Parlay'
    } else if (bundleId) {
      return 'Bundle'
    }

    return '';
  };

  const itemType = checkItemType();

  const onBuyFromBalance = (event) => {
    event.preventDefault();

    if (typeof confirmed === 'boolean' && !confirmed) {
      return alertErrorText(
        "Your email is not confirmed. Please check you email box and click confirmation link. If you did not get email, please use 'Resend email' button below."
      );
    }

    const itemId = pickId || parleyId || bundleId;
    return buyFromBalance(dispatch, `Buy ${itemType}`, itemId, price, description, pageHistory);
  }

  const checkMatchTime = () => {
    let date = new Date();
    date = new Date(date.setMinutes(date.getMinutes() + 10));

    if (matchDate && new Date(matchDate) < date) {
      return false;
    } else if (upcomingMatch && new Date(upcomingMatch) < date) {
      return false;
    }

    return true;
  }

  const handleBuy = (event) => {
    event.preventDefault();
    const itemId = pickId || parleyId || bundleId;
    return pay(dispatch, itemId, itemType, null, pageHistory)
  }

  const goToSubscription = (event) => {
    pageHistory.push(`/subscriptions?subscriptionId=${subscription.id}`)
  }
  const buySubscription = async function (event) {
    event.preventDefault();
    if (typeof confirmed === 'boolean' && !confirmed) {
      return alertErrorText("Your email is not confirmed. Please check you email box and click confirmation link. If you did not get email, please use 'Resend email' button below.");
    }
    const result = await alertSuccessWithConfirmaiton(`Buy Subscription "${subscription.name}" ending on ${moment(subscription.endDate).format('LL')} for $${subscription.price} ?`,
      `I understand that there are no refunds for buying any subscriptions. I also understand that it is my responsibility to know the sport and how many games are left. It is my responsibility when purchasing a subscription knowing how many games are left in the season. I also understand predictions by consultants are subject to be wrong in the short term or long term. By pressing OK and agreeing to buy this subscription I agree to these terms and agree to the Terms of Service on this platform.`);

    if (result.value) {

      return buySubscriptionFromBalance(dispatch, `Buy Subscription`, subscription.id, subscription.price, `Buy subscription ${subscription.price}`, pageHistory);
    }
  }

  const betTypesStr = stringlifyBetTypes(itemType, betTypes);

  return (
    <div className={`${sponsored && 'sponsored'} ${url && 'with-media'} blog-item rounded shadow pick-feed`}>
      {url && <EmbededPlayer url={url} />}

      <div
        className={classNames({
          "blog-details": true,
          "blog-details--space": !boughtByMe && price > 0 && checkMatchTime()
        })}
      >
        {sponsored &&
          <div className="ribbon-wrapper top-right">
            <div className="ribbon ribbon-warning">Sponsored</div>
          </div>}
        <h4 className="blog-title"><NavLink to={(pickId && `/pick/${pickId}`) || (parleyId && `/parlay/${parleyId}`) || (bundleId && `/bundle/${bundleId}`)}>{title}</NavLink></h4>
        <ul className="blog-meta">
          {picksCount > 0 && <li>Number of picks: <a href>{picksCount}</a></li>}
          {!forSale &&
            <li><span>By:</span>
              <Link to={`/user/${ownerId}`}>
                <span>{owner}</span>
              </Link>
            </li>}
          {matchDate &&
            <li>
              <span>Game Date:</span>
              <span> {moment(matchDate).format('MMM Do YYYY, h:mm a')}</span>
            </li>
          }
          {createdAt &&
            <li>
              <span>Created {moment(createdAt).fromNow()}</span>
            </li>
          }
        </ul>
        <div className="blog-summary">
          <p>{description}</p>
          <div className="flex-box">
            <div className="price">
              {!subscriptionId && <span>{price > 0 ? `Price: $${price}` : `Free ${itemType}`}</span>}
              {!subscriptionId && price > 0 && !boughtByMe && checkMatchTime() &&
                <div className="buy-buttons">
                  <button className="btn btn-default paypal" onClick={handleBuy}>
                    <img className="paypal-img" src="/img/paypal/paypal.svg" alt="paypal" />
                  </button>
                  <button className="btn btn-default" onClick={onBuyFromBalance}>BUY NOW</button>
                </div>
              }
              {subscriptionId && subscription && <span>Price: ${subscription.price}</span>}
              {subscriptionId && subscription && !userSubscribed &&
                <div className="buy-buttons">
                  <button className="btn btn-default" onClick={goToSubscription}>SEE DETAILS</button>
                  <button className="btn btn-default" onClick={buySubscription}>BUY SUBSCRIPTION</button>
                </div>}
            </div>
          </div>
        </div>
        <ul className="blog-meta">
          <li>{sports.length > 1 ? 'Sports:' : 'Sport:'} <span>{sports.toString().replace(/,/g, ', ')}</span></li>
          <li>
            <span>Bet type: </span>
            <span>
              {betTypesStr}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}


PickFeed.propTypes = {
  betTypes: PropTypes.object,
  boughtByMe: PropTypes.bool,
  bundleId: PropTypes.number,
  confirmed: PropTypes.bool,
  createdAt: PropTypes.string,
  description: PropTypes.string,
  dispatch: PropTypes.func,
  forSale: PropTypes.bool,
  matchDate: PropTypes.string,
  owner: PropTypes.string,
  ownerId: PropTypes.number,
  pageHistory: PropTypes.object,
  parleyId: PropTypes.number,
  pickId: PropTypes.number,
  picksCount: PropTypes.number,
  price: PropTypes.number,
  sponsored: PropTypes.bool,
  sports: PropTypes.array,
  title: PropTypes.string,
  upcomingMatch: PropTypes.string,
  url: PropTypes.string,
  mySubscriptions: PropTypes.array
};


export const stringlifyBetTypes = (itemType = '', betTypes) => {
  const { PointSpread, MoneyLine, Total } = betTypes;
  let str = '';

  if (itemType.toLowerCase() === 'parlay' || itemType.toLowerCase() === 'bundle') {
    str = str.concat(PointSpread > 0 ? `${PointSpread} Spread` : '');
    str = str.concat(PointSpread > 0 && (MoneyLine > 0 || Total > 0) ? `, ` : '');
    str = str.concat(MoneyLine > 0 ? `${MoneyLine} Money Line` : '');
    str = str.concat(MoneyLine > 0 && Total > 0 ? `, ` : '');
    str = str.concat(Total > 0 ? `${Total} Total` : '');
  } else if (itemType.toLowerCase() === 'pick') {
    str = str.concat(PointSpread > 0 ? 'Spread' : '');
    str = str.concat(PointSpread > 0 && (MoneyLine > 0 || Total > 0) ? ', ' : '');
    str = str.concat(MoneyLine > 0 ? 'Money Line' : '');
    str = str.concat(MoneyLine > 0 && Total > 0 ? ', ' : '');
    str = str.concat(Total > 0 ? 'Total' : '');
  }

  return str;
}
