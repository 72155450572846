import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Wrapper from '../components/ui/Wrapper';
import { connect } from "../store/context";
import { confirmEmail, getProfile } from '../api/users';
import { alertErrorText } from '../actions/alerts'


class ConfirmEmail extends Component {
  componentDidMount() {
    const { location: { search }} = this.props;
    const { confirmationCode, redirectUrl } = queryString.parse(search);

    if (confirmationCode) {
      this.handleConfirmEmail(confirmationCode, redirectUrl);
    } else {
      alertErrorText('Confirmation code is wrong');
    }
  }

  getPatamsObject = (params) => {
    const obj = {};
    const arr = params.split('&');

    for (let i = 0; i < arr.length; i++) {
      const [paramName, paramValue] = arr[i].split('=');

      if (paramName && paramValue) {
        obj[paramName] = paramValue;
      }
    }

    return obj;
  }

  handleConfirmEmail = async (confirmationCode, redirectUrl) => {
    const { history, dispatch } = this.props;
    await confirmEmail(confirmationCode);
    await getProfile(dispatch);

    if (redirectUrl && redirectUrl !== '/') {
      history.push(redirectUrl);
    } else {
      history.push('/select-role');
    }
  }


  render() {
    return (
      <Wrapper
        className="odds-page"
        title={'Email Confirmation Page'}
      >
        <div style={{ textAlign: 'center', margin: '10%'}}>
          <h3>Wait until your email is confirmed!</h3>
          <h4>After that you will be redirected to Home page</h4>
        </div>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(mapDispatchToProps)(ConfirmEmail);

ConfirmEmail.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object
};
