import decode from 'jwt-decode';


const userIsAuthenticated = () => {
  const jwt = localStorage.getItem('token');

  if (!jwt) {
    return false;
  }

  jwt.replace("Bearer", "");
  const dateNow = new Date().getTime() / 1000;
  const { exp } =  decode(jwt);

  if (dateNow < exp) {
    return true;
  }

  localStorage.removeItem('token');
  return false;
}

export default userIsAuthenticated;
