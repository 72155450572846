import i from 'immutable';
import {
  GET_CONTENT_FEED_BEGIN,
  GET_CONTENT_FEED_SUCCESS,
  GET_CONTENT_FEED_FAILURE,
  SET_SINGLE_FEED_BEGIN,
  SET_SINGLE_FEED_SUCCESS,
  SET_SINGLE_FEED_FAILURE,
  GET_SPONSORED_CONTENT_FEED_BEGIN,
  GET_SPONSORED_CONTENT_FEED_SUCCESS,
  GET_SPONSORED_CONTENT_FEED_FAILURE,
  GET_USER_CONTENT_FEED_BEGIN,
  GET_USER_CONTENT_FEED_SUCCESS,
  GET_USER_CONTENT_FEED_FAILURE
} from '../actions/content-feed';

import {
  UPDATE_MY_BOUGHT_ITEM_SUCCESS, UPDATE_MY_BOUGHT_ITEM_BEGIN, UPDATE_MY_BOUGHT_ITEM_FAILURE
} from '../actions/picks';


const initialState = i.Map({
  feed: i.List(),
  sponsoredFeed: i.List(),
  userFeed: i.List(),
  loading: false,
  loadingSponsored: false,
  buying: false,
  error: null,
  count: null,
  limit: 10,
  offset: 0
});

export default function contentFeedReducer(state = initialState, action) {
  let previousPage;
  let newFeed;
  let feed;
  let offset;
  let index = 0;
  let sponsoredIndex = 0;
  let sponsored;

  switch (action.type) {
    case GET_CONTENT_FEED_BEGIN:
      state = state.set('loading', true);
      break;
    case GET_CONTENT_FEED_SUCCESS:
      previousPage = state.get('feed', i.List());

      if (action.data.isNewFeed) {
        newFeed = i.fromJS(action.data.feed.data.data);
      } else {
        newFeed = previousPage.concat(i.fromJS(action.data.feed.data.data));
      }

      state = state.set('feed', newFeed);
      state = state.set('count', i.fromJS(action.data.feed.data.count));
      state = state.set('loading', false);
      offset = state.get('offset', 0) + 10;
      state = state.set('offset', offset);
      break;

    case GET_CONTENT_FEED_FAILURE:
      state = state.set('error', action.error);
      break;

    case SET_SINGLE_FEED_BEGIN:
      state = state.set('loading', true);
      break;

    case SET_SINGLE_FEED_SUCCESS:
      previousPage = state.get('feed', i.List());
      feed = i.fromJS([action.data.feed]);
      index = 0;
      index = previousPage.findIndex((item) => item.get('pickId') === action.data.feed.pickId || item.get('parleyId') === action.data.feed.parleyId);

      if (index >= 0) {
        newFeed = feed.concat(previousPage);
        state = state.set('feed', newFeed);
      }

      state = state.set('loading', false);
      break;

    case SET_SINGLE_FEED_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loading', false);
      break;

    case UPDATE_MY_BOUGHT_ITEM_BEGIN:
      state = state.set('buying', true);
      break;

    case UPDATE_MY_BOUGHT_ITEM_SUCCESS:
      previousPage = state.get('feed', i.List());
      sponsored = state.get('sponsoredFeed', i.List());
      sponsored = sponsored ? sponsored : i.List();
      index = -1;
      sponsoredIndex = -1;

      if (action.data.type === 'Buy Pick') {
        index = previousPage.findIndex((item) => item.get('pickId') === Number(action.data.item));
        sponsoredIndex = sponsored.findIndex((item) => item.get('pickId') === Number(action.data.item));
      } else if (action.data.type === 'Buy Parlay') {
        index = previousPage.findIndex((item) => item.get('parleyId') === Number(action.data.item));
        sponsoredIndex = sponsored.findIndex((item) => item.get('parleyId') === Number(action.data.item));
      } else if (action.data.type === 'Buy Bundle') {
        index = previousPage.findIndex((item) => item.get('bundleId') === Number(action.data.item));
        sponsoredIndex = sponsored.findIndex((item) => item.get('bundleId') === Number(action.data.item));
      }

      if (index >= 0) {
        previousPage = previousPage.setIn([index, 'boughtByMe'], true);
        state = state.set('feed', previousPage);
      }

      if (sponsoredIndex >= 0) {
        sponsored = sponsored.setIn([sponsoredIndex, 'boughtByMe'], true);
        state = state.set('sponsoredFeed', sponsored);
      }

      state = state.set('buying', false);
      break;

    case UPDATE_MY_BOUGHT_ITEM_FAILURE:
      state = state.set('error', action.error);
      state = state.set('buying', false);
      break;

    case GET_SPONSORED_CONTENT_FEED_BEGIN:
      state = state.set('loadingSponsored', true);
      break;
    case GET_SPONSORED_CONTENT_FEED_SUCCESS:
      feed = i.fromJS(action.data.feed.data.records);
      state = state.set('sponsoredFeed', feed.size === 0 ? false : feed);
      state = state.set('loadingSponsored', false);
      break;

    case GET_SPONSORED_CONTENT_FEED_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loadingSponsored', false);
      break;

    case GET_USER_CONTENT_FEED_BEGIN:
      state = state.set('loading', true);
      break;
    case GET_USER_CONTENT_FEED_SUCCESS:
      feed = i.fromJS(action.data.feed);
      state = state.set('userFeed', feed);
      state = state.set('loading', false);
      break;

    case GET_USER_CONTENT_FEED_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loading', false);
      break;

    case 'REMOVE_STARTED_ITEMS': {
      const date = new Date();
      date.setMinutes(date.getMinutes() + 20);

      const filtered = state.get('feed').filter((item) => {
        const time = item.get('matchDate') || item.get('upcomingMatch')
        if (typeof time == 'undefined') return true
        return new Date(time) > date;
      });

      state = state.set('feed', filtered);
      break;
    }

    default:
      return state;
  }

  return state;
}
