import React from 'react';
import './style.scss';


const StartNow = () => {
  return (
    <section className="landing-start-now">
      <div className="container">
        <div className="start_up_inner">
          <a href="#sign-up">Register now</a>
        </div>
      </div>
    </section>
  );
};

export default StartNow;
