import React from 'react';
import i from 'immutable';
import PropTypes from 'prop-types';
import { connect } from '../../../store/context';
import './style.scss';


const ChartsFilter = (props) => {
  const { sports,
    role,
    onChangeType,
    selectedType,
    selectedPeriod,
    selectedSportId,
    handleChangePeriod,
    handleChangeSport } = props;

  return (
    <div className="chart-filter">
      <div className="row">
        {role === 'consultant' &&
          <div className="col-md-2 col-sm-12 col-xs-12">
            <a href onClick={onChangeType} id={selectedType === 'picks' ? 'stats' : 'picks'} className="btn btn-default switch-btn">
              {selectedType === 'picks' ? <i className="fa fa-line-chart" /> : <i className="fa fa-diamond" />}
              {selectedType === 'picks' ? 'Stats' : 'Picks'}
            </a>
          </div>
        }
        <div className="col-md-5 col-sm-6 col-xs-6">
          <label htmlFor="chartFilter">Show statistic for sport:</label>
          <select value={selectedSportId} onChange={handleChangeSport}>
            <option value="">All</option>
            {
              sports && sports.length > 0 &&
              sports.map((sport) => <option key={sport.id} value={sport.id}>{sport.Name.toUpperCase()}</option>)
            }
          </select>
        </div>
        <div className="col-md-5 col-sm-6 col-xs-6">
          <label htmlFor="chartFilter">Show statistic for:</label>
          <select id="chartFilter" value={selectedPeriod} onChange={handleChangePeriod}>
            <option value="7">Last 7 days</option>
            <option value="10" selected>Last 10 days</option>
            <option value="20" selected>Last 20 days</option>
            <option value="30" selected>Last 30 days</option>
            {/* <option value="60" selected>Last 60 days</option>
            <option value="all" selected>All</option> */}
          </select>
        </div>
      </div>
    </div>
  );
}

function select(state) {
  const sports = state.getIn(['sports', 'items'], i.List()).toJS();

  return { sports }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(ChartsFilter);

ChartsFilter.propTypes = {
  handleChangePeriod: PropTypes.func,
  handleChangeSport: PropTypes.func,
  onChangeType: PropTypes.func,
  role: PropTypes.string,
  selectedPeriod: PropTypes.number,
  selectedSportId: PropTypes.number,
  selectedType: PropTypes.bool,
  sports: PropTypes.array
}
