import React, { Component } from "react";
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FacebookLoginButton, TwitterLoginButton } from 'react-social-login-buttons';
import { connect } from "../../store/context";
import { isValidTextField, isFormValid } from "../../helpers/validators";
import { signUp, getProfile } from "../../api/users";
import { login as paypalAuthLink, executeLogin as executePaypalLogin } from '../../api/paypal';
import CONFIG from '../../config/config';
import Loader from '../ui/Loader/index';
import Wrapper from '../../components/ui/Wrapper';


class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      errorMessage: {},
      validFields: { "email": false, "password": false, "confirmPassword": false, "termsBox": false },
      ref: null,
      redirectUrl: CONFIG.ui_home
    };
  }

  componentDidMount = async () => {
    const { dispatch, history, location: { search }} = this.props;
    const { ref, code, redirectUrl } = queryString.parse(search);

    if (code) {
      const referral = localStorage.getItem('referral')
      localStorage.removeItem('referral');
      await executePaypalLogin(dispatch, history, code, referral)
      window.history.pushState({}, document.title, `/signup`);
      getProfile(dispatch);
    }

    if (ref) {
      localStorage.setItem('referral', ref);
      this.setState({ ref });
    }

    if (redirectUrl) {
      this.setState({ redirectUrl });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
    const { validFields, password, errorMessage } = this.state;
    const { isValidField, error } = isValidTextField(event.target.value, event.target.name, password);
    const key = event.target.name;
    errorMessage[key] = error;

    if (isValidField) {
      validFields[key] = true;
    } else {
      validFields[key] = false;
    }

    this.setState({errorMessage, validFields});
  }

  handleCheckOfTerms = (e) => {
    const { validFields } = this.state;
    validFields['termsBox'] = e.target.checked;
    this.setState({validFields});
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { dispatch, history } = this.props;
    const { email, password, confirmPassword, ref, redirectUrl } = this.state;
    const payload = { email, password, confirmPassword, ref, redirectUrl };
    signUp(dispatch, payload, history);
  }

  handlePayPalAuth = (event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    paypalAuthLink(dispatch, 'signup');
  }

  handleFacebookAuth = () => {
    window.location = `${CONFIG.api_url}/auth/facebook`;
  }

  handleTwitterAuth = () => {
    window.location = `${CONFIG.api_url}/auth/twitter`;
  }

  render() {
    const { email, password, confirmPassword, errorMessage, validFields, redirectUrl } = this.state;
    const formIsInvalid = isFormValid(validFields);
    const { loading } = this.props;
    const redirectLogin = `/login${redirectUrl ? `?redirectUrl=${redirectUrl}` : ''}`

    return (
      <Wrapper
        className="sign-up-page auth-page"
      >
        {loading && <Loader />}
        {!loading &&
          <div className="auth">
            <form
              className="form-horizontal rounded shadow no-overflow"
              onSubmit={this.handleSubmit}
            >
              <div className="sign-header">
                <div className="form-group">
                  <div className="sign-text">
                    <span>Create free account</span>
                  </div>
                </div>
              </div>
              <div className="sign-body">
                <div className={errorMessage.email ? "form-group has-error" : "form-group"}>
                  <div className="input-group input-group-lg rounded no-overflow">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      className="form-control"
                      placeholder="Your Email"
                      onChange={this.handleChange}
                      required
                    />
                    <span className="input-group-addon"><i className="fa fa-envelope fa-fw" /></span>
                  </div>
                  <span className="help-block">{errorMessage.email}</span>
                </div>
                <div className={errorMessage.password ? "form-group has-error" : "form-group"}>
                  <div className="input-group input-group-lg rounded no-overflow">
                    <input
                      id="password"
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={this.handleChange}
                      required
                    />
                    <span className="input-group-addon"><i className="fa fa-lock fa-fw" /></span>
                  </div>
                  <span className="help-block">{errorMessage.password}</span>
                </div>
                <div className={errorMessage.confirmPassword ? "form-group has-error" : "form-group"}>
                  <div className="input-group input-group-lg rounded no-overflow">
                    <input
                      id="confirmPassword"
                      type="password"
                      name="confirmPassword"
                      className="form-control"
                      placeholder="Confirm Password"
                      onChange={this.handleChange}
                      value={confirmPassword}
                      disabled={!validFields.password || !password}
                      required
                    />
                    <span className="input-group-addon"><i className="fa fa-check fa-fw" /></span>
                  </div>
                  <span className="help-block">{errorMessage.confirmPassword}</span>
                </div>
              </div>
              <div className="sign-footer">
                <div className="form-group">
                  <div className="callout callout-info no-margin">
                    <p className="text-muted">To confirm and activate your new account, we will need to send the activation link to your email.</p>
                  </div>
                </div>
                <div className="form-group">
                  <div className="ckbox ckbox-theme">
                    <input id="term-of-service" value="1" type="checkbox" required onChange={this.handleCheckOfTerms} />
                    <label htmlFor="term-of-service" className="rounded">I agree with <NavLink to="/tos" target="_blank">Terms Of Service</NavLink></label>
                  </div>
                </div>
                <div className="form-group">
                  <button disabled={formIsInvalid} type="submit" className="btn btn-theme btn-lg btn-block no-margin rounded">Sign Up</button>
                  <div className="social-login-title">Sign up with Socials</div>
                  <div className="social-login-btns">
                    <FacebookLoginButton onClick={this.handleFacebookAuth}>
                      <span>Sign up with Facebook</span>
                    </FacebookLoginButton>
                    <TwitterLoginButton onClick={this.handleTwitterAuth}>
                      <span>Sign up with Twitter</span>
                    </TwitterLoginButton>
                    {/* <button className="btn btn-primary paypal-login" onClick={this.handlePayPalAuth}>
                      <i className="fa fa-paypal fa-2x pull-left paypal-icon" />Sign up with PayPal
                    </button> */}
                  </div>
                </div>
                <div>Already a member? <NavLink to={redirectLogin} >Sign In</NavLink></div>
              </div>
            </form>
          </div>
        }
      </Wrapper>
    );
  }
}

const select = (state) => {
  const generalLoading = state.getIn(['users', 'loading'], true);
  const userLoading = state.getIn(['users', 'userLoading'], true);
  const profileLoading = state.getIn(['users', 'profileLoading'], true);

  const loading = generalLoading || userLoading || profileLoading;

  return { loading }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(SignUp);

SignUp.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  loading: PropTypes.bool,
  location: PropTypes.object
};
