import CONFIG from '../config/config';
import api from '../actions/api';
import { alertError, alertSuccess } from '../actions/alerts'
import { createPickBegin,
  createPickSuccess,
  fetchMyPicksPregameSuccess,
  createPickError,
  createParleyBegin,
  createParleySuccess,
  createParleyError,
  completePickBegin,
  completePickSuccess,
  completePickError,
  fetchMyPicksPregameBegin,
  fetchMyPicksPregameError,
  fetchMyPicksInprogressBegin,
  fetchMyPicksInprogressSuccess,
  fetchMyPicksInprogressError,
  fetchMyPicksFinalBegin,
  fetchMyPicksFinalSuccess,
  fetchMyPicksFinalError,
  fetchMyPicksIncompleteBegin,
  fetchMyPicksIncompleteSuccess,
  fetchMyPicksIncompleteError,
  fetchPickBegin,
  fetchPickSuccess,
  fetchPickError,
  fetchParleyBegin,
  fetchParleySuccess,
  fetchParleyError,
  fetchBundleBegin,
  fetchBundleSuccess,
  fetchBundleError,
  pickPaymentRequired,
  parleyPaymentRequired,
  bundlePaymentRequired,
  fetchUserPicksFinalBegin,
  fetchUserPicksFinalSuccess,
  fetchUserPicksFinalError,
  sendMePickBegin,
  sendMePickSuccess,
  sendMePickError,
  fetchCreatedPicksIdsBegin,
  fetchCreatedPicksIdsSuccess,
  fetchCreatedPicksIdsError } from '../actions/picks';



export const createPick = async (dispatch, MoneyLine, PointSpread, Total, OddID, homePitcher, awayPitcher, draw) => {
  try {
    dispatch(createPickBegin());
    const { data } = await api.post(`${CONFIG.api_url}/picks`, { OddID, MoneyLine, PointSpread, Total, HomePitcher: homePitcher, AwayPitcher: awayPitcher, DrawLine: draw});
    alertSuccess('Your Pick has been saved!');
    dispatch(createPickSuccess(data));
    return true;
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    alertError(error)
    dispatch(createPickError(error));
    return false;
  }
}


export const completePick = async (dispatch, id, Price, Description, Url, SubscriptionId) => {
  try {
    dispatch(completePickBegin());
    const { data } = await api.patch(`${CONFIG.api_url}/picks/complete/${id}`, { Price, Description, Url, SubscriptionId });
    alertSuccess('Your Pick has been published!');
    dispatch(completePickSuccess(data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    alertError(error)
    dispatch(completePickError(error));
    return false;
  }
}

export const createParley = async (dispatch, Picks, Price, Description, Url, Name) => {
  try {
    dispatch(createParleyBegin());
    const { data } = await api.post(`${CONFIG.api_url}/parleys`, { Picks, Price, Description, Url, Name});
    const timestamp = new Date().getTime();
    const { data: parley } = await api.get(`${CONFIG.api_url}/parleys/${data.parley.id}?time=${timestamp}`);
    alertSuccess('Your Parley has been published!');
    dispatch(createParleySuccess(parley));
    return true;
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    alertError(error)
    dispatch(createParleyError(error));
    return false;
  }
}

export const getMyPicksPregame = async (dispatch, page, limit, sport, period, order, subscriptionId) => {
  try {
    dispatch(fetchMyPicksPregameBegin());
    order = order.toLowerCase().replace(/ /g, '');
    const time = new Date().getTime();
    const { data } = await api.get(`${CONFIG.api_url}/picks/my/pregame`, {
      params: { page, limit, sport, period, order, time, subscriptionId }
    });
    dispatch(fetchMyPicksPregameSuccess(data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(fetchMyPicksPregameError(error));
  }
}

export const getMyPicksInprogress = async (dispatch, page, limit, sport, period, order, subscriptionId) => {
  try {
    dispatch(fetchMyPicksInprogressBegin());
    order = order.toLowerCase().replace(/ /g, '');
    const time = new Date().getTime();
    const { data } = await api.get(`${CONFIG.api_url}/picks/my/inprogress`, {
      params: { page, limit, sport, period, order, time, subscriptionId }
    });
    dispatch(fetchMyPicksInprogressSuccess(data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(fetchMyPicksInprogressError(error));
  }
}

export const getMyPicksFinal = async (dispatch, page, limit, sport, period, order, subscriptionId) => {
  try {
    dispatch(fetchMyPicksFinalBegin());
    order = order.toLowerCase().replace(/ /g, '');
    const timestamp = new Date().getTime();
    const { data } = await api.get(`${CONFIG.api_url}/picks/my/final`, {
      params: { page, limit, sport, period, order, timestamp, subscriptionId }
    });
    dispatch(fetchMyPicksFinalSuccess(data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(fetchMyPicksFinalError(error));
  }
}

export const getMyPicksIncomplete = async (dispatch) => {
  try {
    dispatch(fetchMyPicksIncompleteBegin());
    const timestamp = new Date().getTime();
    const result = await api.get(`${CONFIG.api_url}/picks/my/incomplete?time=${timestamp}`);

    dispatch(fetchMyPicksIncompleteSuccess(result.data.picks));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(fetchMyPicksIncompleteError(error));
  }
}

export const getPick = async (dispatch, id) => {
  try {
    dispatch(fetchPickBegin());
    const timestamp = new Date().getTime();
    const { data } = await api.get(`${CONFIG.api_url}/picks/${id}?time=${timestamp}`);
    dispatch(fetchPickSuccess(data.pick));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console

    if (error.response.status === 402) {
      dispatch(pickPaymentRequired(error.response.data));
    } else {
      dispatch(fetchPickError(error));
    }
  }
}

export const getParley = async (dispatch, id) => {
  try {
    dispatch(fetchParleyBegin());
    const timestamp = new Date().getTime();
    const { data } = await api.get(`${CONFIG.api_url}/parleys/${id}?time=${timestamp}`);
    dispatch(fetchParleySuccess(data.parley));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console

    if (error.response.status === 402) {
      dispatch(parleyPaymentRequired(error.response.data));
    } else {
      dispatch(fetchParleyError(error));
    }
  }
}

export const getBundle = async (dispatch, id) => {
  try {
    dispatch(fetchBundleBegin());
    const timestamp = new Date().getTime();
    const { data } = await api.get(`${CONFIG.api_url}/bundles/${id}?time=${timestamp}`);
    dispatch(fetchBundleSuccess(data.bundle));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console

    if (error.response.status === 402) {
      dispatch(bundlePaymentRequired(error.response.data));
    } else {
      dispatch(fetchBundleError(error));
    }
  }
}

export const getUserPicksFinal = async (dispatch, id, period, sportId, page, limit) => {
  try {
    dispatch(fetchUserPicksFinalBegin(id));
    const time = new Date().getTime();
    const { data } = await api.get(`${CONFIG.api_url}/picks/${id}/final`, {
      params: { period, sport: sportId, page, limit, time }
    });
    dispatch(fetchUserPicksFinalSuccess(data, id));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(fetchUserPicksFinalError(error));
  }
}

export const sendMePick = async (dispatch,
  email,
  teams,
  gameDate,
  spreadTeam,
  spreadNumber,
  spreadLine,
  moneyTeam,
  moneyLine,
  totalDirection,
  totalLine,
  draw = null,
  drawLine = null,
  OwnerId = null,
  headLine = null) => {
  try {
    dispatch(sendMePickBegin());
    await api.post(`${CONFIG.api_url}/picks/anonynous/send`, { email,
      teams,
      gameDate,
      spreadTeam,
      spreadNumber,
      spreadLine,
      moneyTeam,
      moneyLine,
      totalDirection,
      totalLine,
      draw,
      drawLine,
      OwnerId,
      headLine});
    dispatch(sendMePickSuccess());
    return true;
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(sendMePickError(error));
    return false;
  }
}

export const getMyCreatedPicksIds = async (dispatch) => {
  try {
    dispatch(fetchCreatedPicksIdsBegin());
    const timestamp = new Date().getTime();
    const { data: { ids }} = await api.get(`${CONFIG.api_url}/picks/my/created?time=${timestamp}`);
    dispatch(fetchCreatedPicksIdsSuccess(ids));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(fetchCreatedPicksIdsError(error));
  }
};
