import { Map, List, fromJS } from 'immutable';
import {
    FETCH_SUBSCRIPTION_BEGING,
    FETCH_SUBSCRIPTION_SUCCESS,
    FETCH_SUBSCRIPTION_FAILURE,
    FETCH_MY_SUBSCRIPTION_BEGIN
} from '../actions/subscriptions';


const initialState = Map({
    items: List(),
    selectedSport: Map(),
    loading: false,
    error: null,
    mySubscriptions: List()
});

export default function subscriptionsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SUBSCRIPTION_BEGING:
            state = state.set('loading', true);
            break;

        case FETCH_SUBSCRIPTION_SUCCESS:
            state = state.set('items', fromJS(action.data.subscriptions.subscriptions));
            state = state.set('loading', false);
            break;

        case FETCH_SUBSCRIPTION_FAILURE:
            state = state.set('error', action.error);
            break;
        case FETCH_MY_SUBSCRIPTION_BEGIN:
            state = state.set('mySubscriptions', fromJS(action.data.data.subscriptions));
            break;
        default:
            return state;
    }

    return state;
}
