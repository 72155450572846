import React from 'react';
import './style.scss';
import ScrollableAnchor from 'react-scrollable-anchor'


const Advantages = () => {
  return (
    <ScrollableAnchor id={'advantages'}>
      <section className="landing-advantages">
        <div className="container">
          <div className="great_title">
            <h2>OUR GREAT FEATURES</h2>
          </div>
          <div className="great_feature_inner row">
            <div className="col-md-4">
              <div className="great_feature_item">
                <i className="fa fa-exchange" />
                <h4>Picks market place</h4>
                <p>Anyone can join to platform and start creating picks, to make money or do it for free to test their sports knowledge.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="great_feature_item">
                <i className="fa fa-rss" />
                <h4>Live games and odds</h4>
                <p>You will be able to track games scores and lines changes in real time.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="great_feature_item">
                <i className="fa fa-shield" />
                <h4>Customer protection</h4>
                <p>We make sure that every client is aware of what they are buying before they buy something.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="great_feature_item">
                <i className="fa fa-folder-open" />
                <h4>Full transparency</h4>
                <p>We will show you all statistics on consultants, their ROI, Deviation, Win Rate for each sport on daily bases.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="great_feature_item">
                <i className="fa fa-comments" />
                <h4>Live messenger</h4>
                <p>You will be able to communicate with consultant/client in real time via our custom messenger.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="great_feature_item">
                <i className="fa fa-money" />
                <h4>Fast Payment processing</h4>
                <p>We use PayPal to process payments, one of world leaders in online payments.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ScrollableAnchor>
  );
};

export default Advantages;
