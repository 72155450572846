import React from 'react';
import i from 'immutable';
import PropTypes from 'prop-types';
import { connect } from "../../store/context";
import './style.scss';


const Banner = (props) => {
  const { location, odds } = props;
  const { country, region } = location;
  const availableRegions = ['NJ', 'PA'].includes(region);
  const isUSA = country === 'US';
  const type = odds ? 'Odds' : 'Stats'

  const SugarHouse = () => {
    const handleClick = () => {
      const url = 'https://wlsugarhouseaffiliates.adsrv.eacdn.com/C.ashx?btag=a_872b_297c_&affid=312&siteid=872&adid=297&c=_';
      window.open(url, '_blank');
    }

    return (
      <div className="item" onClick={handleClick}>
        <img src="/img/banners/sugar-house.png" width="250" alt="Sugar House" />
        <button className="btn btn-warning">Join Now</button>
      </div>
    )
  };

  const Bovada = () => {
    const handleClick = () => {
      const url = 'https://record.bettingpartners.com/_CAuXsyZSyy-Pdh4jW9iXJmNd7ZgqdRLk/1/';
      window.open(url, '_blank');
    }

    return (
      <div className="item" onClick={handleClick}>
        <img src="/img/banners/bovada.svg" width="250" alt="Bovada" />
        <button className="btn btn-warning">Join Now</button>
      </div>
    )
  };

  return (
    <div className="banner">
      <div className="text">{type} provided by:</div>
      {isUSA && availableRegions
        ? <SugarHouse />
        : <Bovada />
      }
    </div>
  );
};

const select = (state) => {
  const location = state.getIn(['users', 'location'], i.Map()).toJS();
  return { location }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Banner);

Banner.propTypes = {
  location: PropTypes.object,
  odds: PropTypes.bool
};
