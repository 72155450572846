import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Head from './parts/Head';
import Statistic from './parts/Statistic';
import StatisticSoccer from './parts/SoccerStat';
import MlbScores from './parts/MlbScores';
import NbaScores from './parts/NbaScores';


const Info = (props) => {
  const { matchInfo } = props;
  const { status, competitions } = matchInfo;
  const { sport } = queryString.parse(window.location.search);
  if (!status || !competitions) {
    return (
      <div className="match-info">
        <h4 className="text-center">No data for this game at the moment</h4>
      </div>
    );
  }

  const getTemplate = () => {
    switch (sport) {
      case 'soccer':
        return (
        <>
          <StatisticSoccer competitions={competitions} />
          <Statistic competitions={competitions} />
        </>
        );
      case 'baseball':
        return <MlbScores competitions={competitions} />;
      case 'nba':
        return <NbaScores competitions={competitions} />;
      default:
        return null;
    }
  };

  return (
    <div className="match-info">

      <Head status={status} competitions={competitions} sport={sport} matchInfo={matchInfo} />
      {/* @TODO get the template working without deepstream */}
      {/* {getTemplate()} */}

    </div>
  );
}


export default Info;


Info.propTypes = {
  matchInfo: PropTypes.object
}
