import React, { Component } from "react";
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Loader from '../ui/Loader/index';
import { connect } from "../../store/context";
import { buySubscription, executeSubscription } from "../../api/paypal";
import CONFIG from '../../config/config';
import api from '../../actions/api';


class Subscription extends Component {
  state = {
    hasSubscription: false
  };

  componentDidMount = async () => {
    const { token, ba_token } = queryString.parse(window.location.search);

    if (token && ba_token) {
      const { dispatch } = this.props;
      await executeSubscription(dispatch, token);
    }

    this.getUserSubscription()
  }

  getUserSubscription = async () => {
    const { data } = await api.get(`${CONFIG.api_url}/subscription/agreement/get`);
    this.setState({ hasSubscription: data.hasSubscription })
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { dispatch, history } = this.props;
    buySubscription(dispatch, history)
  }

  render() {
    const { hasSubscription } = this.state;

    const { loading } = this.props;

    return (
      <div>
        {loading && <Loader />}
        {!loading && !hasSubscription &&
          <form className="form-horizontal no-overflow" onSubmit={this.handleSubmit}>
            <h5 className="title">Don't want your bets to stop?</h5>
            <p className="text-center">Buy SportsTrader subscription to watch all picks, parlays and bundles only ${CONFIG.SUBSCRIPTION_PRICE} for a month.</p>
            <button className="btn btn-default paypal" onClick={this.handleSendTipsPaypal}>
              <img className="paypal-img" src="/img/paypal/paypal.svg" alt="paypal" />
            </button>
          </form>
        }

        {!loading && hasSubscription &&
          <h5 className="title">You are already subscribed to SportsTrader feed</h5>
        }
      </div>
    );
  }
}

function select(state) {
  const loading = state.getIn(['paypal', 'loading'], false);
  return { loading }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Subscription);

Subscription.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool
};
