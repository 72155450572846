import validator from 'validator';


export const isValidTextField = (data, field, data2 = null, required = false) => {
  if (validator.trim(data).length === 0 && required) {
    const name = field.split('_');
    return {isValidField: false, error: `${name.length <= 1 ? field.replace(/^\w/, (c) => c.toUpperCase()) : 'Field'} is required`}
  }

  if (!required && !data) {
    return {isValidField: true};
  }

  switch (field) {
    case 'email':
      if (!validator.isEmail(data)) {
        return {isValidField: false, error: 'Input does not look like a valid email'}
      }

      return {isValidField: true};

    case 'password':
      if (validator.trim(data).length < 6) {
        return {isValidField: false, error: 'Your password must be at least 6 characters'}
      }

      if (validator.trim(data).search(/[a-z]/i) < 0) {
        return {isValidField: false, error: 'Your password must contain at least one letter'}
      }

      if (validator.trim(data).search(/[0-9]/) < 0) {
        return {isValidField: false, error: 'Your password must contain at least one digit'}
      }

      if (data.search(/\s/) > 0) {
        return {isValidField: false, error: 'Your password must not contain spaces'}
      }

      return {isValidField: true};

    case 'confirmPassword':
      if (validator.trim(data).length < 6) {
        return {isValidField: false, error: 'Password should be at least 6 characters long'}
      }

      if (data !== data2) {
        return {isValidField: false, error: 'Passwords do not match'}
      }

      return {isValidField: true};

    case 'price':
      if (Number.parseFloat(validator.trim(data)) < 5) {
        return {isValidField: false, error: 'Amount cannot be less than 5'}
      }

      if (!data) {
        return {isValidField: false, error: 'Amount cannot be empty'}
      }

      return {isValidField: true};

    case 'withdrawAmount':
      if (Number.parseFloat(validator.trim(data)) < 50) {
        return {isValidField: false, error: 'Withdraw amount can\'t be less than 50'}
      }

      if (!data) {
        return {isValidField: false, error: 'Withdraw amount can\'t be empty'}
      }

      return {isValidField: true};

    case 'url':

      if (!validator.isURL(data)) {
        return {isValidField: false, error: 'Not valid URL'}
      }

      return {isValidField: true};

    case 'name':

      if (!validator.isAlpha(data)) {
        return {isValidField: false, error: 'String contains invalid characters'}
      }

      return {isValidField: true};

    case 'name_with_space':

      if (!validator.isAscii(data)) {
        return {isValidField: false, error: 'String contains invalid characters'}
      }

      return {isValidField: true};

    case 'bio':

      if (!validator.isAscii(data)) {
        return {isValidField: false, error: 'String contains invalid characters'}
      }

      if (data.length > 120) {
        return {isValidField: false, error: 'Bio is too long'}
      }

      return {isValidField: true};


    default:
      return {isValidField: true};
  }
}

export const isFormValid = (fields) => {
  return Object.values(fields).includes(false);
}
