import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect } from "../../../store/context";
import { sendTipsFromBalance } from "../../../api/payments";
import { getProfile } from "../../../api/users";
import { alertErrorText } from '../../../actions/alerts';
import { pay } from "../../../api/paypal";
import './style.scss';
import User from './user';


class MembersModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { showModal, handleCloseModal, loading, members, user, currentRoom, dispatch } = this.props;
    
    let owner = false
    if (currentRoom) {
      if (user.id == currentRoom.room.ownerId) {
        owner = true
      }
    }

    return (
      <Modal show={showModal} className="members-modal">
        <button type="button" className="close" data-dismiss="modal" onClick={handleCloseModal} aria-hidden="true" >×</button>
        <Modal.Body>
          <span className="help-block">Members</span>
          <ul className="chat-user-list">
            {members.map((member) => {
              if (currentRoom && member.user.id != user.id) {
                const  ownerRow = member.user.id == currentRoom.room.ownerId
                
                let isUserOnline = member.user.online === 1

                return (
                  <User
                    dispatch={dispatch}
                    key={member.user.id}
                    member={member}
                    online={isUserOnline}
                    owner={owner}
                    ownerRow={ownerRow}
                  />
                );
              }

            }
            )}
          </ul>
        </Modal.Body>

      </Modal>);
  }
}

const select = (state) => {
  const selectedUserId = state.getIn(['chat', 'currentUserId'], null);

  return { selectedUserId }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(MembersModal);

MembersModal.propTypes = {
  dispatch: PropTypes.func,
  handleCloseModal: PropTypes.func,
  loading: PropTypes.bool,
  selectedUserId: PropTypes.number,
  showModal: PropTypes.bool,
  members: PropTypes.object,
  user: PropTypes.object,
  currentRoom: PropTypes.object,
};
