import React from 'react';
import AdBlockDetect from 'react-ad-block-detect';
import CONFIG from '../../../config/config';
import './style.scss';


const AdBlockDetectAlert = () => {
  if (CONFIG.showAdBlockAlert) {
    return (
      <AdBlockDetect>
        <div className="adBlocker-detected">
          <div className="content">
            <div className="icon"><i className="fa fa-exclamation" /></div>
            <p>You are using adBlock</p>
            <p>As we offer free services we have to use ads</p>
            <p>If you want to see this page, please signup or turn off adBlock and reload this page</p>
          </div>
        </div>
      </AdBlockDetect>
    )
  }

  return null;
}

export default AdBlockDetectAlert;
