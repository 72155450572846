import React from 'react';
import PropTypes from 'prop-types';
import FormattedToBetType from '../../general/bet-type-formatter';
import "./style.scss";


export const PickCell = (props) => {
  const { itemName, itemNumber, itemLine, colSpan } = props;

  return (
    <td colSpan={colSpan ? colSpan : 4}>
      <span className="disply-block">{itemName}</span>
      <FormattedToBetType price={0} value={itemNumber} spread />
      &nbsp;(<FormattedToBetType price={1} value={itemLine} />)
    </td>
  );
};

PickCell.propTypes = {
  colSpan: PropTypes.number,
  itemLine: PropTypes.string,
  itemName: PropTypes.string,
  itemNumber: PropTypes.string
};

export const LinePickCell = (props) => {
  const { itemName, itemLine, colSpan } = props;

  return (
    <td colSpan={colSpan ? colSpan : 4}>
      <span className="disply-block">{itemName}</span>
      <FormattedToBetType price={1} value={itemLine} />
    </td>
  );
};

LinePickCell.propTypes = {
  colSpan: PropTypes.number,
  itemLine: PropTypes.string,
  itemName: PropTypes.string
};

export const EmptyCell = (props) => {
  const { colSpan } = props;

  return (
    <td colSpan={colSpan ? colSpan : 4}>
      -
    </td>
  );
};

EmptyCell.propTypes = {
  colSpan: PropTypes.number
};
