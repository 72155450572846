import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i from 'immutable';
import moment from 'moment';
import { TeamSearch } from '../components/search/TeamSearch';
import { Switch } from '../components/picks/DecimalSwitch';
import PicksTable from '../components/odds/PicksTable/index';
import { connect } from "../store/context";
import { getOddsByApi } from '../api/odds';
import { getSportById } from '../api/sports';
import { createPick, getMyPicksIncomplete, getMyCreatedPicksIds } from '../api/picks';
import CONFIG from '../config/config';
import { setBetsType } from '../api/users';
import Loader from '../components/ui/Loader/index';
import Wrapper from '../components/ui/Wrapper';
import { removeStartedGames } from '../actions/picks';
import EmptyPage from '../components/general/EmptyPage';
import catchRunTimeError from '../util/ds-error';


class Picks extends Component {
  static propTypes = {
    betsType: PropTypes.string,
    dispatch: PropTypes.func,
    location: PropTypes.object,
    matches: PropTypes.array,
    oddsLoading: PropTypes.bool,
    pickLoading: PropTypes.bool,
    sport: PropTypes.object,
    sportsId: PropTypes.string
  }

  constructor(props) {
    super(props);
    console.log('Connect WSS Odds')
    this.socketConnection = new WebSocket(CONFIG.wss_client_connection_url);
    this.socketConnection.onopen = this.sendMessageToWs

    // Log errors
    this.socketConnection.onerror = catchRunTimeError;

    // Log messages from the server
    this.socketConnection.onmessage = this.receivedMessageFromWss;
    this.socketConnection.onclose = function (e) {
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
      setTimeout(function () {
        this.socketConnection = new WebSocket(CONFIG.wss_client_connection_url);
      }, 1000);
    }
    this.state = {
      newSportId: null,
      searchTeam: ''
    }
    this.initialState = this.state;
    this.timerInterval = null;
  }

  componentDidMount = () => {
    const { dispatch, sportsId } = this.props;
    getSportById(dispatch, sportsId);
    getMyCreatedPicksIds(dispatch);
    getOddsByApi(dispatch, sportsId)
    this.timerInterval = setInterval(() => dispatch(removeStartedGames()), 6000);
  }

  componentWillReceiveProps = (newProps) => {
    const { dispatch, sportsId } = this.props;

    if (sportsId === newProps.sportsId) {
      return;
    }
    getSportById(dispatch, newProps.sportsId);
    getOddsByApi(dispatch, newProps.sportsId)
    this.props = newProps
    this.sendMessageToWs()
  }

  sendMessageToWs = () => {
    console.log("received odds from ws message");
    const { sportsId } = this.props;
    this.oddsLoading = true
    let data = JSON.stringify({ "action": "oddsNow", "page": `sport/${sportsId}/odds/now` })
    this.socketConnection.send(data); // Send the message 'Ping' to the server
  }

  receivedMessageFromWss = (e) => {
    const { dispatch, sportsId } = this.props;
    console.log("received odds from ws message");
    this.oddsLoading = false
    getOddsByApi(dispatch, sportsId)
    // getOdds(dispatch, JSON.parse(e.data));
  }

  componentWillUnmount = () => {
    // this.record.discard();
    // this.client.close();
    clearInterval(this.timerInterval);
  }

  handleSaveIncompletePick = async (MoneyLine, PointSpread, Total, OddID, homePitcher, awayPitcher, draw) => {
    const { dispatch } = this.props;

    const pickResult = await createPick(dispatch, MoneyLine, PointSpread, Total, OddID, homePitcher, awayPitcher, draw);

    if (pickResult) {
      this.setState(this.initialState);
      getMyPicksIncomplete(dispatch);
    }
  }

  handleClickAmerican = () => {
    const { dispatch } = this.props;
    setBetsType(dispatch, 'american');
  }

  handleClickDecimal = () => {
    const { dispatch } = this.props;
    setBetsType(dispatch, 'decimal');
  }

  handleSearch = (value) => {
    this.setState({ searchTeam: value })
  }

  render() {
    const { matches, sport, betsType, oddsLoading, pickLoading, location } = this.props;
    const { 3: selectedLeague } = location.pathname.split("/");
    const { Name } = sport;
    const sportName = Name ? Name.toUpperCase() : '';
    let picksAvailable = false;

    let showLoading;
    const { newSportId } = this.state;

    if (oddsLoading && ((sport && newSportId && sport.id !== newSportId) || !matches.length)) {
      showLoading = oddsLoading;
    }

    return (
      <Wrapper
        className="picks-page"
        title={sportName}
        counter={matches.length}
        icon="fa fa-diamond"
      >

        <div className="row">
          <div className="col-md-4">
            <Switch
              betsType={betsType}
              onClickAmerican={this.handleClickAmerican}
              onClickDecimal={this.handleClickDecimal}
            />
          </div>

          <div className="col-md-4" />

          <div className="col-md-4">
            <TeamSearch onClickSearch={this.handleSearch} />
          </div>
        </div>

        {showLoading && <Loader />}

        {!showLoading && matches.length > 0 && matches.map((odd) => {
          const awayTeam = odd.Match.AwayTeam.toLowerCase();
          const homeTeam = odd.Match.HomeTeam.toLowerCase();
          const searchTeam = this.state.searchTeam.toLowerCase();

          if ((!searchTeam && odd.OddID) || (awayTeam.includes(searchTeam) || homeTeam.includes(searchTeam))) {
            if (selectedLeague) {
              if (selectedLeague === odd.Match.DisplayLeague) {
                picksAvailable = true;
                return odd.OddID && <PicksTable key={odd.id} odd={odd} onCreatePick={this.handleSaveIncompletePick} type={betsType} sportName={Name} loading={pickLoading} />
              }
            } else {
              picksAvailable = true;
              return odd.OddID && <PicksTable key={odd.id} odd={odd} onCreatePick={this.handleSaveIncompletePick} type={betsType} sportName={Name} loading={pickLoading} />
            }
          }

          return '';
        })}

        {!showLoading && !picksAvailable && <EmptyPage text="No more picks available" />}
      </Wrapper>
    );
  }
}

function select(state, ownProps) {
  let matches = state.getIn(['odds', 'items'], i.List()).toJS();
  const oddsLoading = state.getIn(['odds', 'loading'], false);
  const sport = state.getIn(['sports', 'selectedSport'], i.Map()).toJS();
  const myPicks = state.getIn(['picks', 'createdPicks'], i.List()).toJS();
  const betsType = state.getIn(['users', 'profile', 'betsType'], 'american');
  const sportsId = ownProps.match.params.sport;
  const pickLoading = state.getIn(['picks', 'loading'], false);

  if (matches) {
    matches = matches.filter((odd) => {
      return !myPicks.includes(odd.OddID) && moment(odd.Match.MatchTime) > moment();
    });
  }

  return { matches, sport, sportsId, betsType, oddsLoading, pickLoading }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Picks);
