export const FETCH_ROOMS_BEGIN   = 'FETCH_ROOMS_BEGIN';
export const FETCH_ROOMS_SUCCESS = 'FETCH_ROOMS_SUCCESS';
export const FETCH_ROOMS_FAILURE = 'FETCH_ROOMS_FAILURE';
export const ADD_MESSAGE_BEGIN   = 'ADD_MESSAGE_BEGIN';
export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS';
export const ADD_MESSAGE_FAILURE = 'ADD_MESSAGE_FAILURE';
export const ADD_CURRENT_ROOM_BEGIN   = 'ADD_CURRENT_ROOM_BEGIN';
export const ADD_CURRENT_ROOM_SUCCESS = 'ADD_CURRENT_ROOM_SUCCESS';
export const ADD_CURRENT_ROOM_FAILURE = 'ADD_CURRENT_ROOM_FAILURE';
export const ADD_MESSAGES_LOADED_STATUS_BEGIN = 'ADD_MESSAGES_LOADED_STATUS_BEGIN';
export const ADD_MESSAGES_LOADED_STATUS_SUCCESS = 'ADD_MESSAGES_LOADED_STATUS_SUCCESS';
export const ADD_MESSAGES_LOADED_STATUS_FAILURE = 'ADD_MESSAGES_LOADED_STATUS_FAILURE';
export const ADD_ROOMS_LOADED_STATUS_BEGIN = 'ADD_ROOMS_LOADED_STATUS_BEGIN';
export const ADD_ROOMS_LOADED_STATUS_SUCCESS = 'ADD_ROOMS_LOADED_STATUS_SUCCESS';
export const ADD_ROOMS_LOADED_STATUS_FAILURE = 'ADD_ROOMS_LOADED_STATUS_FAILURE';
export const ADD_ROOMS_LISTEN_STATUS_BEGIN = 'ADD_ROOMS_LISTEN_STATUS_BEGIN';
export const ADD_ROOMS_LISTEN_STATUS_SUCCESS = 'ADD_ROOMS_LISTEN_STATUS_SUCCESS';
export const ADD_ROOMS_LISTEN_STATUS_FAILURE = 'ADD_ROOMS_LISTEN_STATUS_FAILURE';
export const FETCH_ROOM_ID_BEGIN   = 'FETCH_ROOM_ID_BEGIN';
export const FETCH_ROOM_ID_SUCCESS = 'FETCH_ROOM_ID_SUCCESS';
export const FETCH_ROOM_ID_FAILURE = 'FETCH_ROOM_ID_FAILURE';
export const ADD_MESSAGES_BEGIN   = 'ADD_MESSAGES_BEGIN';
export const ADD_MESSAGES_SUCCESS = 'ADD_MESSAGES_SUCCESS';
export const ADD_MESSAGES_FAILURE = 'ADD_MESSAGES_FAILURE';
export const BLOCK_USER_CHAT_ROOM_BEGIN   = 'BLOCK_USER_CHAT_ROOM_BEGIN';
export const BLOCK_USER_CHAT_ROOM_SUCCESS = 'BLOCK_USER_CHAT_ROOM_SUCCESS';
export const BLOCK_USER_CHAT_ROOM_FAILURE = 'BLOCK_USER_CHAT_ROOM_FAILURE';
export const UNBLOCK_USER_CHAT_ROOM_BEGIN   = 'UNBLOCK_USER_CHAT_ROOM_BEGIN';
export const UNBLOCK_USER_CHAT_ROOM_SUCCESS = 'UNBLOCK_USER_CHAT_ROOM_SUCCESS';
export const UNBLOCK_USER_CHAT_ROOM_FAILURE = 'UNBLOCK_USER_CHAT_ROOM_FAILURE';
export const FETCH_ROOMS_STATE_BEGIN   = 'FETCH_ROOMS_STATE_BEGIN';
export const FETCH_ROOMS_STATE_SUCCESS = 'FETCH_ROOMS_STATE_SUCCESS';
export const FETCH_ROOMS_STATE_FAILURE = 'FETCH_ROOMS_STATE_FAILURE';
export const JOINED_ROOMS_BEGIN = 'JOINED_ROOMS_BEGIN'
export const JOINED_ROOMS_SUCCESS = 'JOINED_ROOMS_SUCCESS';
export const JOINED_ROOMS_FAILURE = 'JOINED_ROOMS_FAILURE';
export const GET_ONLINE_USERS_BEGIN   = 'GET_ONLINE_USERS_BEGIN';
export const GET_ONLINE_USERS_SUCCESS = 'GET_ONLINE_USERS_SUCCESS';
export const GET_ONLINE_USERS_FAILURE = 'GET_ONLINE_USERS_FAILURE';
export const GET_ROOM_MEMBERS_BEGIN   = 'GET_ROOM_MEMBERS_BEGIN';
export const GET_ROOM_MEMBERS_SUCCESS = 'GET_ROOM_MEMBERS_SUCCESS';
export const GET_ROOM_MEMBERS_FAILURE = 'GET_ROOM_MEMBERS_FAILURE';
export const CREATE_ROOM_BEGIN =  'CREATE_ROOM_BEGIN';
export const CREATE_ROOM_SUCCESS = 'CREATE_ROOM_SUCCESS';
export const CREATE_ROOM_FAILURE = 'CREATE_ROOM_FAILURE';
export const FETCH_SPORT_ROOMS_BEGIN   = 'FETCH_SPORT_ROOMS_BEGIN';
export const FETCH_SPORT_ROOMS_SUCCESS = 'FETCH_SPORT_ROOMS_SUCCESS';
export const FETCH_SPORT_ROOMS_FAILURE = 'FETCH_SPORT_ROOMS_FAILURE';
export const MARK_ROOM_AS_READ = 'MARK_ROOM_AS_READ'


export const markRoomAsRead = (roomId) => ({
  type: MARK_ROOM_AS_READ,
  payload: { roomId }
})

export const fetchSportRoomsBegin = () => ({
  type: FETCH_SPORT_ROOMS_BEGIN
});

export const fetchSportRoomsSuccess = (rooms) => ({
  type: FETCH_SPORT_ROOMS_SUCCESS,
  rooms
});

export const fetchSportRoomsError = (error) => ({
  type: FETCH_SPORT_ROOMS_FAILURE,
  payload: { error }
});


export const createRoomBegin = () => ({
  type: CREATE_ROOM_BEGIN
});

export const createRoomSuccess = () => ({
  type: CREATE_ROOM_SUCCESS
});

export const createRoomFailure = (error) => ({
  type: CREATE_ROOM_FAILURE,
  payload: { error }
});

export const getOnlineUsersBegin = () => ({
  type: GET_ONLINE_USERS_BEGIN
});

export const getOnlineUsersSuccess = (users) => ({
  type: GET_ONLINE_USERS_SUCCESS,
  data: { users }
});

export const getOnlineUsersError = (error) => ({
  type: GET_ONLINE_USERS_FAILURE,
  data: { error }
});

export const fetchRoomsBegin = () => ({
  type: FETCH_ROOMS_BEGIN
});

export const fetchRoomsSuccess = (rooms) => ({
  type: FETCH_ROOMS_SUCCESS,
  rooms
});

export const fetchRoomsError = (error) => ({
  type: FETCH_ROOMS_FAILURE,
  payload: { error }
});

export const addMessageBegin = () => ({
  type: ADD_MESSAGE_BEGIN
});

export const addMessageSuccess = (message, roomId) => ({
  type: ADD_MESSAGE_SUCCESS,
  payload: { message, roomId }
});

export const addMessageError = (error) => ({
  type: ADD_MESSAGE_FAILURE,
  payload: { error }
});

export const addCurrentRoomBegin = () => ({
  type: ADD_CURRENT_ROOM_BEGIN
});

export const addCurrentRoomSuccess = (roomId) => ({
  type: ADD_CURRENT_ROOM_SUCCESS,
  payload: { roomId }
});

export const addCurrentRoomError = (error) => ({
  type: ADD_CURRENT_ROOM_FAILURE,
  payload: { error }
});

export const addMessagesLoadedStatusBegin = () => ({
  type: ADD_MESSAGES_LOADED_STATUS_BEGIN
});

export const addMessagesLoadedStatusSuccess = (status, roomId) => ({
  type: ADD_MESSAGES_LOADED_STATUS_SUCCESS,
  payload: { status, roomId }
});

export const addMessagesLoadedStatusError = (error) => ({
  type: ADD_MESSAGES_LOADED_STATUS_FAILURE,
  payload: { error }
});

export const addRoomsLoadedStatusBegin = () => ({
  type: ADD_ROOMS_LOADED_STATUS_BEGIN
});

export const addRoomsLoadedStatusSuccess = (status) => ({
  type: ADD_ROOMS_LOADED_STATUS_SUCCESS,
  payload: { status }
});

export const addRoomsLoadedStatusError = (error) => ({
  type: ADD_ROOMS_LOADED_STATUS_FAILURE,
  payload: { error }
});

export const addRoomsListenStatusBegin = () => ({
  type: ADD_ROOMS_LISTEN_STATUS_BEGIN
});

export const addRoomsListenStatusSuccess = (status) => ({
  type: ADD_ROOMS_LISTEN_STATUS_SUCCESS,
  payload: { status }
});

export const addRoomsListenStatusError = (error) => ({
  type: ADD_ROOMS_LISTEN_STATUS_FAILURE,
  payload: { error }
});

export const fetchRoomIdBegin = () => ({
  type: FETCH_ROOM_ID_BEGIN
});

export const fetchRoomIdSuccess = (currentRoom, userId) => ({
  type: FETCH_ROOM_ID_SUCCESS,
  data: {currentRoom, userId }
});

export const fetchRoomIdError = (error) => ({
  type: FETCH_ROOM_ID_FAILURE,
  payload: { error }
});

export const addMessagesBegin = () => ({
  type: ADD_MESSAGES_BEGIN
});

export const addMessagesSuccess = (messages, count, roomId, limit, offset) => ({
  type: ADD_MESSAGES_SUCCESS,
  data: {roomId, messages, count, limit, offset}
});

export const addMessagesError = (error) => ({
  type: ADD_MESSAGES_FAILURE,
  payload: { error }
});

export const blockUserChatRoomBegin = () => ({
  type: BLOCK_USER_CHAT_ROOM_BEGIN
});

export const blockUserChatRoomSuccess = () => ({
  type: BLOCK_USER_CHAT_ROOM_SUCCESS
});

export const blockUserChatRoomError = (error) => ({
  type: BLOCK_USER_CHAT_ROOM_FAILURE,
  payload: { error }
});

export const unblockUserChatRoomBegin = () => ({
  type: UNBLOCK_USER_CHAT_ROOM_BEGIN
});

export const unblockUserChatRoomSuccess = () => ({
  type: UNBLOCK_USER_CHAT_ROOM_SUCCESS
});

export const unblockUserChatRoomError = (error) => ({
  type: UNBLOCK_USER_CHAT_ROOM_FAILURE,
  payload: { error }
});

// export const leaveRoomBegin = () => ({
//   type: LEAVE_ROOM_BEGIN
// });

// export const leaveRoomSuccess = () => ({
//   type: LEAVE_ROOM_SUCCESS
// });

// export const leaveRoomError = (error) => ({
//   type: LEAVE_ROOM_FAILURE,
//   payload: { error }
// });

export const fetchRoomsStateBegin = () => ({
  type: FETCH_ROOMS_STATE_BEGIN
});

export const fetchRoomsStateSuccess = (rooms) => ({
  type: FETCH_ROOMS_STATE_SUCCESS,
  data: {rooms}
});

export const fetchRoomsStateError = (error) => ({
  type: FETCH_ROOMS_STATE_FAILURE,
  payload: { error }
});

export const joinedRoomsBegin = () => ({
  type: JOINED_ROOMS_BEGIN
});

export const joinedRoomsSuccess = (rooms, state) => ({
  type: JOINED_ROOMS_SUCCESS,
  rooms,
  state
});

export const joinedRoomsError = (error) => ({
  type: JOINED_ROOMS_FAILURE,
  payload: { error }
});

export const getRoomMembersBegin = (roomId) => ({
  type: GET_ROOM_MEMBERS_BEGIN,
  payload: { roomId }
});

export const getRoomMembersSuccess = (data, roomId) => ({
  type: GET_ROOM_MEMBERS_SUCCESS,
  payload: { data, roomId }
});

export const getRoomMembersError = (error, roomId) => ({
  type: GET_ROOM_MEMBERS_FAILURE,
  payload: { error, roomId }
});
