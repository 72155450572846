import CONFIG from '../config/config';
import api from '../actions/api';
import { getContentFeedBegin,
  getContentFeedSuccess,
  getContentFeedError,
  setSingleFeedBegin,
  setSingleFeedSuccess,
  setSingleFeedError,
  getSponsoredContentFeedBegin,
  getSponsoredContentFeedSuccess,
  getSponsoredContentFeedError,
  getUserAPIContentfeedBegin,
  getUserAPIContentfeedSuccess,
  getUserAPIContentfeedError } from '../actions/content-feed';


export const getContentfeed = async (dispatch, data) => {
  try {
    dispatch(setSingleFeedBegin());
    dispatch(setSingleFeedSuccess(data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(setSingleFeedError(error));
  }
}

export const getAPIContentfeed = async (dispatch, id, limit, offset, isNewFeed) => {
  try {
    dispatch(getContentFeedBegin());
    const result = await api.get(`${CONFIG.api_url}/feed/${id}?limit=${limit}&offset=${offset}`);
    dispatch(getContentFeedSuccess(result, isNewFeed));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(getContentFeedError(error));
  }
}

export const getAnonimusAPIContentfeed = async (dispatch, limit, offset) => {
  try {
    dispatch(getContentFeedBegin());
    const result = await api.get(`${CONFIG.api_url}/feed/anonimus?limit=${limit}&offset=${offset}`);
    dispatch(getContentFeedSuccess(result));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(getContentFeedError(error));
  }
}

export const getSponsoredAPIContentfeed = async (dispatch) => {
  try {
    dispatch(getSponsoredContentFeedBegin());
    const result = await api.get(`${CONFIG.api_url}/feed/sponsored`);
    dispatch(getSponsoredContentFeedSuccess(result));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(getSponsoredContentFeedError(error));
  }
}

export const getUserAPIContentfeed = async (dispatch, id) => {
  try {
    dispatch(getUserAPIContentfeedBegin());
    const { data } = await api.get(`${CONFIG.api_url}/feed/for-sale/${id}`);
    dispatch(getUserAPIContentfeedSuccess(data.data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(getUserAPIContentfeedError(error));
  }
}
