import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { EmptyCell, LinePickCell, PickCell } from '../PickCells';
import "./style.scss";


const PicksToCompleteTable = (props) => {
  const { pick, onSelectPick } = props;
  const { MoneyLine, PointSpread, Total, Match, Odd, HomePitcher, AwayPitcher, DrawLine } = pick;

  const handleSelectPick = (e) => {
    e.preventDefault();
    onSelectPick(pick);
  };

  const spreadCellName = () => {
    if (Match.Sport === 5) { //hockey
      return 'Puck Line';
    }

    if (Match.Sport === 0) { //baseball
      return 'Run Line';
    }

    return 'Spread'
  }

  const totalCellName = () => {
    if ([11, 23].includes(Match.Sport)) { //['mma', 'wbc']
      return 'Rounds';
    }

    return 'Total'
  }

  const cellWidth = DrawLine ? 3 : 4;

  return (
    <div className="selected-picks-to-complete">
      <table className="table user-pick picks-to-complete-table">
        <thead>
          <tr className="main-head">
            <th colSpan="7" className="text-left">
              <span className="team-name">{Match.AwayTeam}</span>{Match.AwayROT && <span className="team-number">({Match.AwayROT})</span>}
              <span className="team-separator">VS</span>
              <span className="team-name">{Match.HomeTeam}</span>{Match.HomeROT && <span className="team-number">({Match.HomeROT})</span>}
            </th>
            <th colSpan="5" className="text-right">
              Game Date: {moment(Match.MatchTime).format('L')} {moment(Match.MatchTime).format('LT')}
            </th>
          </tr>
          <tr className="head">
            <th colSpan={cellWidth}>{spreadCellName()}</th>
            <th colSpan={cellWidth}>Money Line</th>
            <th colSpan={cellWidth}>{totalCellName()}</th>
            {DrawLine && <th colSpan={cellWidth}>Draw</th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            {PointSpread ? <PickCell itemName={Match[`${PointSpread}Team`]} itemNumber={Odd[`PointSpread${PointSpread}`]} itemLine={Odd[`PointSpread${PointSpread}Line`]} colSpan={cellWidth} /> : <EmptyCell colSpan={cellWidth} />}
            {MoneyLine ? <LinePickCell itemName={Match[`${MoneyLine}Team`]} itemLine={Odd[`MoneyLine${MoneyLine}`]} colSpan={cellWidth} /> : <EmptyCell colSpan={cellWidth} />}
            {Total ? <LinePickCell itemName={`${Total} ${Odd.TotalNumber}`} itemLine={Odd[`${Total}Line`]} colSpan={cellWidth} /> : <EmptyCell colSpan={cellWidth} />}
            {DrawLine &&  <LinePickCell itemName={`Draw`} itemLine={Odd.DrawLine} colSpan={cellWidth} />}
          </tr>
          {(HomePitcher || AwayPitcher) &&
          <tr>
            <td colSpan="4">
              Pitchers listed:
            </td>
            <td colSpan="4">
              {HomePitcher}(Home)
            </td>
            <td colSpan="4">
              {AwayPitcher}(Away)
            </td>
          </tr>}
        </tbody>
      </table>
      <span onClick={handleSelectPick} className="remove">
        <i className="fa fa-minus-circle" />
      </span>
    </div>
  );
}

export default PicksToCompleteTable;

PicksToCompleteTable.propTypes = {
  onSelectPick: PropTypes.func,
  pick: PropTypes.object
};
