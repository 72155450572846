import punycode from 'punycode';
import CONFIG from '../config/config';
import api from '../actions/api';
import { alertError, alertSuccess, alertErrorText } from '../actions/alerts'
import {
  fetchUsersBegin,
  fetchUsersSuccess,
  fetchUsersError,
  fetchProfileBegin,
  fetchProfileSuccess,
  fetchProfileError,
  updateProfileBegin,
  updateProfileSuccess,
  updateProfileError,
  uploadAvatarBegin,
  uploadAvatarSuccess,
  uploadAvatarError,
  uploadProfileImageBegin,
  uploadProfileImageSuccess,
  uploadProfileImageError,
  getUserBegin,
  getUserSuccess,
  getUserError,
  followUserBegin,
  followUserSuccess,
  followUserError,
  unfollowUserBegin,
  unfollowUserSuccess,
  unfollowUserError,
  verifyPaypalBegin,
  verifyPaypalSuccess,
  verifyPaypalError,
  becomeConsultantBegin,
  becomeConsultantSuccess,
  becomeConsultantError,
  userSignupBegin,
  userSignupSuccess,
  userSignupError,
  userLoginBegin,
  userLoginSuccess,
  userLoginError,
  getUserStatisticBegin,
  getUserStatisticSuccess,
  getUserStatisticError,
  getPaypalVerifiedInfoBegin,
  getPaypalVerifiedInfoSuccess,
  getPaypalVerifiedInfoError,
  sendConfirmEmailBegin,
  sendConfirmEmailSuccess,
  sendConfirmEmailError,
  setBetsTypeBegin,
  setBetsTypeSuccess,
  setBetsTypeError,
  setAnonimusBetsTypeSuccess,
  getLocationBegin,
  getLocationSuccess,
  getLocationError,
  updateMailingBegin,
  updateMailingSuccess,
  updateMailingError
} from '../actions/users';


export const getConsultants = async (dispatch) => {
  try {
    dispatch(fetchUsersBegin());
    const { data } = await api.get(`${CONFIG.api_url}/users/top`);
    dispatch(fetchUsersSuccess(data));
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
    dispatch(fetchUsersError(error));
  }
}

export const getProfile = async (dispatch) => {
  try {
    dispatch(fetchProfileBegin());
    const timestamp = new Date().getTime();
    const { data } = await api.get(`${CONFIG.api_url}/users?time=${timestamp}`);
    dispatch(fetchProfileSuccess(data));
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    dispatch(fetchProfileError(error));
  }
}

export const updateProfile = async (dispatch, params) => {
  try {
    dispatch(updateProfileBegin());
    const { data } = await api.patch(`${CONFIG.api_url}/users`, params);
    alertSuccess('Profile has been updated!')
    dispatch(updateProfileSuccess(data));
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
    dispatch(updateProfileError(error));
  }
}

export const uploadAvatar = async (dispatch, file) => {
  try {
    const base64Data = new Buffer(file.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    const [, type] = file.split(';')[0].split(':');
    const newFile = new Blob([base64Data], { type });
    const fileData = new FormData()
    fileData.append('upfile', newFile, newFile.name)

    dispatch(uploadAvatarBegin());
    const { data } = await api.post(`${CONFIG.api_url}/users/avatar`, fileData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    dispatch(uploadAvatarSuccess(data.fileUrl));
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
    dispatch(uploadAvatarError(error));
  }
}

export const uploadProfileImage = async (dispatch, formData) => {
  try {
    dispatch(uploadProfileImageBegin());
    const { data } = await api.patch(`${CONFIG.api_url}/users/profile/image`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    dispatch(uploadProfileImageSuccess(data.profileImage));
  } catch (error) {
    alertError(error)
    dispatch(uploadProfileImageError(error));
  }
}

export const getUser = async (dispatch, id) => {
  try {
    dispatch(getUserBegin());
    const timestamp = new Date().getTime();
    const { data } = await api.get(`${CONFIG.api_url}/users/${Number(id)}?time=${timestamp}`);
    dispatch(getUserSuccess(data));
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    dispatch(getUserError(error));
  }
}

export const followUser = async (dispatch, userId, users) => {
  try {
    dispatch(followUserBegin());
    const userObj = users.find((user) => user.id === Number(userId));
    const { data } = await api.post(`${CONFIG.api_url}/users/follow/${userId}`);
    await dispatch(followUserSuccess(userObj));
    let { firstName = 'Anonymous', lastName } = data.userData;
    firstName = firstName ? firstName : 'Anonymous';
    lastName = lastName ? lastName : '';
    alertSuccess(`<p>You have started following</p><p class="user-name">${firstName} ${lastName}</p>`);
  } catch (error) {
    alertError(error);
    dispatch(followUserError(error));
  }
}

export const unfollowUser = async (dispatch, userId, followingUsers) => {
  try {
    dispatch(unfollowUserBegin());
    const { data } = await api.delete(`${CONFIG.api_url}/users/follow/${userId}`);
    await dispatch(unfollowUserSuccess(Number(userId), followingUsers));
    let { firstName = 'Anonymous', lastName } = data.userData;
    firstName = firstName ? firstName : 'Anonymous';
    lastName = lastName ? lastName : '';
    alertSuccess(`<p>You have unfollowed</p><p class="user-name">${firstName} ${lastName}</p>`);
  } catch (error) {
    alertError(error);
    dispatch(unfollowUserError(error));
  }
}

export const verifyPaypal = async (dispatch, payment) => {
  try {
    dispatch(verifyPaypalBegin());
    const { data: { success } } = await api.get(payment.returnUrl);

    if (success) {
      const res = await api.patch(`${CONFIG.api_url}/users/verifyconsultant`, { paymentID: payment.paymentID });
      alertSuccess(res.data.message);
      dispatch(verifyPaypalSuccess(success));
    }
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
    dispatch(verifyPaypalError(error));
  }
}

export const becomeConsultant = async (dispatch, firstName, lastName, bio) => {
  try {
    dispatch(becomeConsultantBegin());
    const body = { firstName, lastName, bio };
    const { data } = await api.patch(`${CONFIG.api_url}/users/becomeconsultant`, body);
    dispatch(becomeConsultantSuccess(data));
    alertSuccess('User has become consultant!')
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
    dispatch(becomeConsultantError(error));
  }
}

export const searchConsultants = async (dispatch, params) => {
  try {
    dispatch(fetchUsersBegin());

    const { data } = await api.get(`${CONFIG.api_url}/users/consultants/search`, { params });
    dispatch(fetchUsersSuccess(data.users));
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
    dispatch(fetchUsersError(error));
  }
}

export const signUp = async (dispatch, payload, history) => {
  try {
    dispatch(userSignupBegin());
    const { email, password, confirmPassword, ref, redirectUrl } = payload;

    const { data } = await api.post(
      `${CONFIG.api_url}/users`,
      { email: punycode.toUnicode(email), password, confirmPassword, ref, redirectUrl }
    );

    dispatch(userSignupSuccess(data));
    const path = redirectUrl.length > 1 ? redirectUrl : '/select-role'
    history.push(path);
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
    dispatch(userSignupError(error));
  }
}

export const getUserStatistic = async (dispatch, userId, period = 7, SportId = null) => {
  try {
    dispatch(getUserStatisticBegin());
    const { data } = await api.get(`${CONFIG.api_url}/statistics/${userId}`, { params: { period, SportId } });
    dispatch(getUserStatisticSuccess(data.statistics));
  } catch (error) {
    console.log(error);  //eslint-disable-line no-console
    alertError(error)
    dispatch(getUserStatisticError(error));
  }
}

export const getPaypalVerifiedInfo = async (dispatch, code) => {
  try {
    dispatch(getPaypalVerifiedInfoBegin());
    const { data } = await api.get(`${CONFIG.api_url}/auth/paypal/callback`, { params: { code, verify: true } });
    dispatch(getPaypalVerifiedInfoSuccess(data.verified));
  } catch (error) {
    window.history.pushState({}, document.title, '/profile/become-consultant');
    console.log(error);  //eslint-disable-line no-console
    alertError(error)
    dispatch(getPaypalVerifiedInfoError(error));
  }
}

export const resendConfirmationEmail = async (dispatch, redirectUrl) => {
  try {
    dispatch(sendConfirmEmailBegin());
    const { data } = await api.post(`${CONFIG.api_url}/users/email/resend`, { redirectUrl });
    alertSuccess(data.message);
    dispatch(sendConfirmEmailSuccess(data.message));
  } catch (error) {
    alertErrorText("Error occured while resending email");
    dispatch(sendConfirmEmailError(error));
  }
}

export const confirmEmail = async (confirmationCode) => {
  try {
    const { data } = await api.post(`${CONFIG.api_url}/users/email/confirm`, { confirmationCode });
    alertSuccess(data.message);
  } catch (error) {
    alertErrorText("Error while confirming your email. Try again later");
  }
}

export const setBetsType = async (dispatch, betsType) => {
  try {
    dispatch(setBetsTypeBegin());
    const { data } = await api.patch(`${CONFIG.api_url}/users/bets-type`, { betsType });
    dispatch(setBetsTypeSuccess(data.betsType));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(setAnonimusBetsTypeSuccess(betsType));
    dispatch(setBetsTypeError(error));
  }
}

export const changePassword = async (oldPassword, newPassword, confirmNewPassword) => {
  try {
    const { data } = await api.patch(`${CONFIG.api_url}/users/password/change`, { oldPassword, newPassword, confirmNewPassword });
    alertSuccess(data.message);
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    alertError(error);
  }
}

export const getLocation = async (dispatch) => {
  try {
    dispatch(getLocationBegin());
    const { data } = await api.get(`${CONFIG.api_url}/users/location`);
    dispatch(getLocationSuccess(data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(getLocationError(error));
  }
}

export const updateMailing = async (dispatch, params) => {
  try {
    dispatch(updateMailingBegin());
    await api.patch(`${CONFIG.api_url}/users/profile/mailing`, params);
    dispatch(updateMailingSuccess());
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
    dispatch(updateMailingError(error));
  }
}

export const login = async (dispatch, payload, history) => {
  try {
    dispatch(userLoginBegin());
    const { email, password, redirectUrl } = payload;
    const { data } = await api.post(`${CONFIG.api_url}/users/login`, { email, password });
    dispatch(userLoginSuccess(data));
    history.push(redirectUrl);
    return true;
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
    dispatch(userLoginError(error));
    return false;
  }
}

export const userOnline = async (online) => {
  try {
    let status = 'offline'
    if (online == 1) {
      status = 'online'
    }
    api.patch(`${CONFIG.api_url}/users/${status}`, { online });
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
  }
}