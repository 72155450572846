import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Button, Modal, Form, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import MultiSelect from "@kenshooui/react-multi-select";
import { createRoom, fetchRooms } from "../../../api/chat";
import './style.scss';


class CreateRoomModal extends Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);

    this.state = {
      show: false,
      roomName: '',
      selectedUsers: [],
      error: false,
      sportId: null,
      type: null,
    };
  }

  handleCreateRoom = (event) => {
    event.preventDefault();
    const { dispatch, profile } = this.props;
    const { roomName, selectedUsers, sportId, type } = this.state;
    const getIds = selectedUsers.map((user) => user.id);

    if (roomName.length < 2) {
      return this.setState({error: 'Please add room name'});
    }
    if (sportId === null) {
      return this.setState({error: 'Please choose relevant sport'});
    }
    if (type === null) {
      return this.setState({error: 'Please choose room type'});
    }
    // postData
    createRoom(dispatch, {name: roomName, sportId: sportId, members: getIds, type: type});
    this.setState({error: false});
    this.handleClose();
    fetchRooms(dispatch, {index: 0, limit: 0});
  }

  handleClose() {
    this.setState({ show: false, roomName: '' });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleInputChange = (event) => {
    this.setState({ roomName: event.target.value });
  }

  handleSportChange = (event) => {
    this.setState({ sportId: event.target.value });
  }
  handleRoomTypeChange = (event) => {
    this.setState({ type: event.target.value });
  }

  handleSelectChange(selectedUsers) {
    this.setState({ selectedUsers });
  }
  mergeUsers = (followers, following) => {
    const uniqueUsers = [];
  
    followers.forEach((follower) => {
      const isDuplicate = following.some((follow) => follower.id === follow.id);
  
      if (!isDuplicate) {
        uniqueUsers.push(follower);
      }
    });
  
    return following.concat(uniqueUsers);
  };

  render() {
    const { error } = this.state;
    let followings = [];
    let followers = [];
    const {Sports} = this.props

    if (this.props.profile.following) {
      followings = this.props.profile.following;
    }
    if (this.props.profile.followers) {
      followers = this.props.profile.followers
    }
    followings = this.mergeUsers(followers, followings)
    const followingUsers = followings.map((user) => {
      let firstName = user.firstName ? user.firstName : 'Anonymous'
      let lastName = user.lastName ? user.lastName : ''
      return {
        ...user,
        label: `${firstName} ${lastName}`
      }
    });

    return (
      <div className="create-new-room">
        <Button bsStyle="primary" bsSize="small" onClick={this.handleShow}>
          Create Room
        </Button>

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create new room</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <FormGroup>
                <ControlLabel>Room Name</ControlLabel>
                <FormControl type="text" onChange={this.handleInputChange} />
              </FormGroup>

              <FormGroup>
              <ControlLabel>Select Sport</ControlLabel>
                <FormControl componentClass="select" placeholder="Select Relevant Sport" onChange={this.handleSportChange}>
                <option disabled selected hidden>Sport</option>
                            {Sports.map((sport) => <option key={sport.id} value={sport.id}>{sport.Name.toUpperCase()}</option>)}
                </FormControl>
              </FormGroup>

              <FormGroup>
              <ControlLabel>Room Type</ControlLabel>
                <FormControl componentClass="select" placeholder="Select room type" onChange={this.handleRoomTypeChange}>
                <option disabled selected hidden>Room Type</option>
                <option key={1} value={2}>Public (Anyone can join. You will be able to block/unblock members)</option>
                <option key={2} value={3}>Private (Invite only others can still send requests to be a member)</option>
                </FormControl>
              </FormGroup>
              
              <FormGroup>
                <ControlLabel>Choose users for room</ControlLabel>
                <MultiSelect
                  items={followingUsers}
                  selectedItems={this.state.selectedUsers}
                  onChange={this.handleSelectChange}
                />
              </FormGroup>
            </Form>
            {error && <p style={{color: 'red'}}>{error}</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCreateRoom}>Submit</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CreateRoomModal;

CreateRoomModal.propTypes = {
  dispatch: PropTypes.func,
  profile: PropTypes.object,
  Sports: PropTypes.array,
};

