import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from "../../../store/context";
import { blockUserRoom, unblockUserRoom  } from "../../../api/chat";
import TipsModal from "../TipsModal";
import './style.scss';


class Head extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  handleClickBlock = () => {
    const { dispatch } = this.props;
    const { currentRoom, blockedByMe } = this.props;

    if (blockedByMe) {
      unblockUserRoom(dispatch, currentRoom.id);
    } else {
      blockUserRoom(dispatch, currentRoom.id);
    }
  }

  handleOpenModal = () => {
    this.setState({showModal: true});
  }

  onCloseModal = () => {
    this.setState({showModal: false});
  }

  render() {
    const { currentRoom, blockedByMe, onCloseRoom } = this.props;
    const { showModal } = this.state;
    let label = 'There no open chat';

    if (currentRoom) {
      let { firstName, lastName } = currentRoom;
      firstName = firstName ? firstName : '';
      lastName = lastName ? lastName : '';
      const name = `${firstName} ${lastName}`
      const userName = `${firstName || lastName ? name : 'Anonymous'}`;

      label = <><span>Chat with:</span> <Link to={`/user/${currentRoom.id}`}>{userName}</Link></>;
    }

    return (
      <div className="chat-header">
        <div className="chat-with">
          <div className="close-room" onClick={onCloseRoom}><i className="fa fa-angle-left" /></div>
          <div className="title">{label}</div>
          <div>
            <span className="send-tip" onClick={this.handleOpenModal}>
              <i className="fa fa-gift" />
              <span>Send tip</span>
            </span>
            <TipsModal showModal={showModal} handleCloseModal={this.onCloseModal} />
            <span className="block-unblock-user" onClick={this.handleClickBlock}>
              {
                blockedByMe
                  ? <><i className="fa fa-unlock" /><span>Unblock</span></>
                  : <><i className="fa fa-lock" /><span>Block</span></>
              }
            </span>
          </div>
        </div>
      </div>);
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(mapDispatchToProps)(Head);

Head.propTypes = {
  blockedByMe: PropTypes.bool,
  currentRoom: PropTypes.object,
  dispatch: PropTypes.func,
  onCloseRoom: PropTypes.func
};
