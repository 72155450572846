export const FETCH_PAYMENTS_HISTORY_BEGIN   = 'FETCH_PAYMENTS_HISTORY_BEGIN';
export const FETCH_PAYMENTS_HISTORY_SUCCESS = 'FETCH_PAYMENTS_HISTORY_SUCCESS';
export const FETCH_PAYMENTS_HISTORY_FAILURE = 'FETCH_PAYMENTS_HISTORY_FAILURE';
export const FETCH_SALES_BEGIN   = 'FETCH_SALES_BEGIN';
export const FETCH_SALES_SUCCESS = 'FETCH_SALES_SUCCESS';
export const FETCH_SALES_FAILURE = 'FETCH_SALES_FAILURE';
export const FETCH_SALES_STATS_BEGIN   = 'FETCH_SALES_STATS_BEGIN';
export const FETCH_SALES_STATS_SUCCESS = 'FETCH_SALES_STATS_SUCCESS';
export const FETCH_SALES_STATS_FAILURE = 'FETCH_SALES_STATS_FAILURE';
export const PROMOTE_CONTENT_BEGIN   = 'PROMOTE_CONTENT_BEGIN';
export const PROMOTE_CONTENT_SUCCESS = 'PROMOTE_CONTENT_SUCCESS';
export const PROMOTE_CONTENT_FAILURE = 'PROMOTE_CONTENT_FAILURE';
export const GET_AFFILIATE_BEGIN   = 'GET_AFFILIATE_BEGIN';
export const GET_AFFILIATE_SUCCESS = 'GET_AFFILIATE_SUCCESS';
export const GET_AFFILIATE_FAILURE = 'GET_AFFILIATE_FAILURE';
export const GET_PENDING_BALANCE_BEGIN   = 'GET_PENDING_BALANCE_BEGIN';
export const GET_PENDING_BALANCE_SUCCESS = 'GET_PENDING_BALANCE_SUCCESS';
export const GET_PENDING_BALANCE_FAILURE = 'GET_PENDING_BALANCE_FAILURE';
export const GET_PENDING_AFFILIATE_BALANCE_BEGIN   = 'GET_PENDING_AFFILIATE_BALANCE_BEGIN';
export const GET_PENDING_AFFILIATE_BALANCE_SUCCESS = 'GET_PENDING_AFFILIATE_BALANCE_SUCCESS';
export const GET_PENDING_AFFILIATE_BALANCE_FAILURE = 'GET_PENDING_AFFILIATE_BALANCE_FAILURE';
export const GET_AFFILIATE_LINK_BEGIN   = 'GET_AFFILIATE_LINK_BEGIN';
export const GET_AFFILIATE_LINK_SUCCESS = 'GET_AFFILIATE_LINK_SUCCESS';
export const GET_AFFILIATE_LINK_FAILURE = 'GET_AFFILIATE_LINK_FAILURE';
export const SUBMIT_WITHDRAW_REQUEST_BEGIN   = 'SUBMIT_WITHDRAW_REQUEST_BEGIN';
export const SUBMIT_WITHDRAW_REQUEST_SUCCESS = 'SUBMIT_WITHDRAW_REQUEST_SUCCESS';
export const SUBMIT_WITHDRAW_REQUEST_FAILURE = 'SUBMIT_WITHDRAW_REQUEST_FAILURE';
export const GET_AFFILIATE_REVENUE_BEGIN   = 'GET_AFFILIATE_REVENUE_BEGIN';
export const GET_AFFILIATE_REVENUE_SUCCESS = 'GET_AFFILIATE_REVENUE_SUCCESS';
export const GET_AFFILIATE_REVENUE_FAILURE = 'GET_AFFILIATE_REVENUE_FAILURE';
export const SEND_TIPS_FROM_BALANCE_BEGIN   = 'SEND_TIPS_FROM_BALANCE_BEGIN';
export const SEND_TIPS_FROM_BALANCE_SUCCESS = 'SEND_TIPS_FROM_BALANCE_SUCCESS';
export const SEND_TIPS_FROM_BALANCE_FAILURE = 'SEND_TIPS_FROM_BALANCE_FAILURE';

export const fetchPaymentsHistoryBegin = () => ({
  type: FETCH_PAYMENTS_HISTORY_BEGIN
});

export const fetchPaymentsHistorySuccess = (payments) => ({
  type: FETCH_PAYMENTS_HISTORY_SUCCESS,
  data: { payments }
});

export const fetchPaymentsHistoryError = (error) => ({
  type: FETCH_PAYMENTS_HISTORY_FAILURE,
  data: { error }
});

export const fetchSalesBegin = () => ({
  type: FETCH_SALES_BEGIN
});

export const fetchSalesSuccess = (sales) => ({
  type: FETCH_SALES_SUCCESS,
  data: { sales }
});

export const fetchSalesError = (error) => ({
  type: FETCH_SALES_FAILURE,
  data: { error }
});

export const fetchSalesStatsBegin = () => ({
  type: FETCH_SALES_STATS_BEGIN
});

export const fetchSalesStatsSuccess = (stats) => ({
  type: FETCH_SALES_STATS_SUCCESS,
  data: { stats }
});

export const fetchSalesStatsError = (error) => ({
  type: FETCH_SALES_STATS_FAILURE,
  data: { error }
});

export const promoteContentBegin = () => ({
  type: PROMOTE_CONTENT_BEGIN
});

export const promoteContentSuccess = (content) => ({
  type: PROMOTE_CONTENT_SUCCESS,
  data: { content }
});

export const promoteContentError = (error) => ({
  type: PROMOTE_CONTENT_FAILURE,
  data: { error }
});

export const getAffiliateBegin = () => ({
  type: GET_AFFILIATE_BEGIN
});

export const getAffiliateSuccess = (users) => ({
  type: GET_AFFILIATE_SUCCESS,
  data: { users }
});

export const getAffiliateError = (error) => ({
  type: GET_AFFILIATE_FAILURE,
  data: { error }
});

export const getPendingBalanceBegin = () => ({
  type: GET_PENDING_BALANCE_BEGIN
});

export const getPendingBalanceSuccess = (balance) => ({
  type: GET_PENDING_BALANCE_SUCCESS,
  data: { balance }
});

export const getPendingBalanceError = (error) => ({
  type: GET_PENDING_BALANCE_FAILURE,
  data: { error }
});

export const getPendingAffiliateBalanceBegin = () => ({
  type: GET_PENDING_AFFILIATE_BALANCE_BEGIN
});

export const getPendingAffiliateBalanceSuccess = (balance) => ({
  type: GET_PENDING_AFFILIATE_BALANCE_SUCCESS,
  data: { balance }
});

export const getPendingAffiliateBalanceError = (error) => ({
  type: GET_PENDING_AFFILIATE_BALANCE_FAILURE,
  data: { error }
});

export const getAffiliateRevenueBegin = () => ({
  type: GET_AFFILIATE_REVENUE_BEGIN
});

export const getAffiliateRevenueSuccess = (balance) => ({
  type: GET_AFFILIATE_REVENUE_SUCCESS,
  data: { balance }
});

export const getAffiliateRevenueError = (error) => ({
  type: GET_AFFILIATE_REVENUE_FAILURE,
  data: { error }
});

export const getAffiliateLinkBegin = () => ({
  type: GET_AFFILIATE_LINK_BEGIN
});

export const getAffiliateLinkSuccess = (link) => ({
  type: GET_AFFILIATE_LINK_SUCCESS,
  data: { link }
});

export const getAffiliateLinkError = (error) => ({
  type: GET_AFFILIATE_LINK_FAILURE,
  data: { error }
});

export const submitWithdrawRequestBegin = () => ({
  type: SUBMIT_WITHDRAW_REQUEST_BEGIN
});

export const submitWithdrawRequestSuccess = (result) => ({
  type: SUBMIT_WITHDRAW_REQUEST_SUCCESS,
  data: { result }
});

export const submitWithdrawRequestError = (error) => ({
  type: SUBMIT_WITHDRAW_REQUEST_FAILURE,
  data: { error }
});

export const sendTipsFromBalanceBegin = () => ({
  type: SEND_TIPS_FROM_BALANCE_BEGIN
});

export const sendTipsFromBalanceSuccess = () => ({
  type: SEND_TIPS_FROM_BALANCE_SUCCESS
});

export const sendTipsFromBalanceError = (error) => ({
  type: SEND_TIPS_FROM_BALANCE_FAILURE,
  data: { error }
});
