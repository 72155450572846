export const FETCH_SPORT_BEGIN   = 'FETCH_SPORT_BEGIN';
export const FETCH_SPORT_SUCCESS = 'FETCH_SPORT_SUCCESS';
export const FETCH_SPORT_FAILURE = 'FETCH_SPORT_FAILURE';
export const FETCH_SPORTS_BEGIN   = 'FETCH_SPORTS_BEGIN';
export const FETCH_SPORTS_SUCCESS = 'FETCH_SPORTS_SUCCESS';
export const FETCH_SPORTS_FAILURE = 'FETCH_SPORTS_FAILURE';


export const fetchSportBegin = () => ({
  type: FETCH_SPORT_BEGIN
});

export const fetchSportSuccess = (sport) => ({
  type: FETCH_SPORT_SUCCESS,
  data: { sport }
});

export const fetchSportError = (error) => ({
  type: FETCH_SPORT_FAILURE,
  data: { error }
});

export const fetchSportsBegin = () => ({
  type: FETCH_SPORTS_BEGIN
});

export const fetchSportsSuccess = (sports) => ({
  type: FETCH_SPORTS_SUCCESS,
  data: { sports }
});

export const fetchSportsError = (error) => ({
  type: FETCH_SPORTS_FAILURE,
  data: { error }
});

