import React from "react";
import PropTypes from 'prop-types';
import './style.scss';


const Loader = (props) => {
  const { show = true, spinnerSize = 100 } = props;

  return (
    <div className={show ? "loader show" : "loader hide"}>
      <img alt="Loader" src="/img/pics/loader.svg" width={spinnerSize} height={spinnerSize} />
    </div>);
}

export default Loader;

Loader.propTypes = {
  show: PropTypes.bool,
  spinnerSize: PropTypes.number
};
