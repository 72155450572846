import React, {Component} from 'react';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import MediaQuery from 'react-responsive';
import "./styles.scss";


export class EmbededPlayer extends Component {
  static propTypes = {
    url: PropTypes.string
  };
  constructor(props) {
    super(props);
    this.state = {
      videoId: null,
      play: false,
      showModal: false
    };
  }

  componentDidMount = () => {
    const { url } = this.props;

    if (url) {
      const { src } = Parser(url).props;

      if (src && src.match("youtube.com")) {
        const url = src.split('/');
        const [videoId] = url[url.length - 1].split('?');
        this.setState({videoId});
      }
    }
  }

  handleClickPlay = () => {
    this.setState({play: true});
  };

  handleIframeLoad = () => {
    const { url } = this.props;
    return {
      __html: url
    }
  }

  handleClose = () => {
    this.setState({ showModal: false });
  }

  handleShow = () => {
    this.setState({ showModal: true });
  }

  render() {
    const { videoId, play } = this.state;
    const shouldShowButton = !play && videoId !== null;

    return (
      <div className="media-block">
        <MediaQuery minDeviceWidth={767}>
          {videoId !== null ?
            <div className="youtube-cover" onClick={this.handleShow}>
              <div className="play-button" />
              <img src={`https://img.youtube.com/vi/${videoId}/sddefault.jpg`} alt={videoId} />
            </div> :
            <div className="content" onClick={this.handleShow} dangerouslySetInnerHTML={this.handleIframeLoad()} />}

          <Modal
            show={this.state.showModal}
            onHide={this.handleClose}
            className="feed-media-modal"
          >
            <Modal.Header closeButton />
            <Modal.Body>
              <div className="content" dangerouslySetInnerHTML={this.handleIframeLoad()} />
            </Modal.Body>
          </Modal>
        </MediaQuery>

        <MediaQuery maxDeviceWidth={767}>
          {shouldShowButton ?
            <div className="youtube-cover">
              <div onClick={this.handleClickPlay} className="play-button" />
              <img src={`https://img.youtube.com/vi/${videoId}/sddefault.jpg`} alt={videoId} />
            </div> :
            <div className="content" dangerouslySetInnerHTML={this.handleIframeLoad()} />}
        </MediaQuery>
      </div>
    );
  }
}

