import React from 'react';
import PropTypes from 'prop-types';


const SportsFilter = (props) => {
  const { showAllSports, selectedSports, onClickAll, includedSports, onClickSport } = props;
  const buttons = [];

  buttons.push(<button key="all" type="button" className={`btn btn-warning inline-button ${showAllSports ? '' : 'btn-stroke'}`} onClick={onClickAll}>ALL</button>)

  if (includedSports.length > 0) {
    includedSports.map((sport) => {
      const selected = selectedSports.indexOf(sport) > -1;
      return buttons.push(<button key={sport} type="button" className={`btn btn-warning inline-button ${selected ? '' : 'btn-stroke'}`} onClick={onClickSport}>{sport}</button>)
    })
  }

  const panel = (
    <div className="panel feed-filter">
      <div className="panel-heading">
        {buttons}
      </div>
    </div>)

  return panel;
}

export default SportsFilter;

SportsFilter.propTypes = {
  onClickAll: PropTypes.func,
  text: PropTypes.string
}


