import { isIos } from '../util/browserDetectors';


export const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);

  // handle iOS as a special case
  if (isIos) {
    // save current contentEditable/readOnly status
    const { contentEditable: oldEditable, readOnly: oldReadOnly } = el;

    // convert to editable with readonly to stop iOS keyboard opening
    el.contentEditable = true;
    el.readOnly = true;

    // create a selectable range
    const range = document.createRange();
    range.selectNodeContents(el);

    // select the range
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    el.setSelectionRange(0, 999999);

    // restore contentEditable/readOnly to original state
    el.contentEditable = oldEditable;
    el.readOnly = oldReadOnly;
  } else {
    el.select();
  }

  document.execCommand('copy');
  document.body.removeChild(el);
}
