import React, { Component } from "react";
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { FacebookLoginButton } from 'react-social-login-buttons';
import { connect } from "../../../store/context";
import { isValidTextField, isFormValid } from "../../../helpers/validators";
import { login as paypalAuthLink, executeLogin as executePaypalLogin } from '../../../api/paypal';
import { signUp, getProfile } from "../../../api/users";
import CONFIG from '../../../config/config';
import Loader from '../../ui/Loader/index';
import './style.scss';


class SignUp extends Component {
  state = {
    email: "",
    password: "",
    confirmPassword: "",
    errorMessage: {},
    validFields: { "email": false, "password": false, "confirmPassword": false, "termsBox": false },
    ref: null,
    redirectUrl: CONFIG.ui_home
  };

  componentDidMount = async () => {
    const { dispatch, history, location: { search = "" }} = this.props;
    const { ref, code, redirectUrl } = queryString.parse(search);

    if (code) {
      const referral = localStorage.getItem('referral')
      localStorage.removeItem('referral');
      await executePaypalLogin(dispatch, history, code, referral)
      window.history.pushState({}, document.title, `/signup`);
      getProfile(dispatch);
    }

    if (ref) {
      localStorage.setItem('referral', ref);
      this.setState({ ref });
    }

    if (redirectUrl) {
      this.setState({ redirectUrl });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
    const { validFields, password, errorMessage } = this.state;
    const { isValidField, error } = isValidTextField(event.target.value, event.target.name, password);
    const key = event.target.name;
    errorMessage[key] = error;

    if (isValidField) {
      validFields[key] = true;
    } else {
      validFields[key] = false;
    }

    this.setState({errorMessage, validFields});
  }

  handleCheckOfTerms = (e) => {
    const { validFields } = this.state;
    validFields['termsBox'] = e.target.checked;
    this.setState({validFields});
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    const success = await signUp(dispatch, this.state);

    if (success) {
      window.location.href = this.state.redirectUrl;
    }
  }

  handleCancel = () => {
    window.location = `/login`;
  }

  handlePayPalAuth = (event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    paypalAuthLink(dispatch, 'signup');
  }

  handleFacebookAuth = () => {
    window.location = `${CONFIG.api_url}/auth/facebook`;
  }

  render() {
    const { email, password, confirmPassword, errorMessage, validFields } = this.state;
    const formIsInvalid = isFormValid(validFields);
    const { loading } = this.props;

    return (
      <div className="landing-sign-up">
        {loading && <Loader />}
        {!loading &&
          <div className="auth">
            <form
              className="form-horizontal rounded shadow no-overflow"
              onSubmit={this.handleSubmit}
            >
              <div className="sign-header">
                <div className="form-group">
                  <div className="sign-text">
                    <span>Create free account</span>
                  </div>
                </div>
              </div>
              <div className="sign-body">
                <div className={errorMessage.email ? "form-group has-error" : "form-group"}>
                  <div className="input-group input-group-lg rounded no-overflow">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      className="form-control"
                      placeholder="Your Email"
                      onChange={this.handleChange}
                      required
                    />
                    <span className="input-group-addon"><i className="fa fa-envelope fa-fw" /></span>
                  </div>
                  <span className="help-block">{errorMessage.email}</span>
                </div>
                <div className={errorMessage.password ? "form-group has-error" : "form-group"}>
                  <div className="input-group input-group-lg rounded no-overflow">
                    <input
                      id="password"
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={this.handleChange}
                      required
                    />
                    <span className="input-group-addon"><i className="fa fa-lock fa-fw" /></span>
                  </div>
                  <span className="help-block">{errorMessage.password}</span>
                </div>
                <div className={errorMessage.confirmPassword ? "form-group has-error" : "form-group"}>
                  <div className="input-group input-group-lg rounded no-overflow">
                    <input
                      id="confirmPassword"
                      type="password"
                      name="confirmPassword"
                      className="form-control"
                      placeholder="Confirm Password"
                      onChange={this.handleChange}
                      value={confirmPassword}
                      disabled={!validFields.password || !password}
                      required
                    />
                    <span className="input-group-addon"><i className="fa fa-check fa-fw" /></span>
                  </div>
                  <span className="help-block">{errorMessage.confirmPassword}</span>
                </div>
              </div>
              <div className="sign-footer">
                <div className="form-group">
                  <div className="ckbox ckbox-theme">
                    <input id="term-of-service" value="1" type="checkbox" required onChange={this.handleCheckOfTerms} />
                    <label htmlFor="term-of-service" className="rounded">I agree with <NavLink to="/tos" target="_blank">Terms Of Service</NavLink></label>
                  </div>
                </div>
                <div className="form-group">
                  <button disabled={formIsInvalid} type="submit" className="btn btn-theme btn-lg btn-block no-margin rounded">Sign Up</button>
                  <div className="social-login-btns">
                    <FacebookLoginButton onClick={this.handleFacebookAuth}>
                      <span>Sign up with Facebook</span>
                    </FacebookLoginButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
        }
      </div>
    );
  }
}

function select(state) {
  const loading = state.getIn(['users', 'userLoading'], true);

  return { loading }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default withRouter(connect(select, mapDispatchToProps)(SignUp));

SignUp.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool
};
