import i from 'immutable';
import {
  UPDATE_MENU_TAB_ACTIVE_BEGIN,
  UPDATE_MENU_TAB_ACTIVE_SUCCESS,
  UPDATE_MENU_TAB_ACTIVE_FAILURE,
  UPDATE_FRIENDS_TAB_ACTIVE_BEGIN,
  UPDATE_FRIENDS_TAB_ACTIVE_SUCCESS,
  UPDATE_FRIENDS_TAB_ACTIVE_FAILURE,
  UPDATE_SIDEBAR_OPEN_STATUS
} from '../actions/general';


const initialState = i.Map({
  activeTab: '',
  activeFriendsTab: 'following',
  loading: false,
  loadingActiveFriendsTab: false,
  error: null,
  sidebarOpen: false
});

export default function generalReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MENU_TAB_ACTIVE_BEGIN:
      state = state.set('loading', true);
      break;

    case UPDATE_MENU_TAB_ACTIVE_SUCCESS:
      state = state.set('activeTab', action.data.activeTab);
      state = state.set('loading', false);
      break;

    case UPDATE_MENU_TAB_ACTIVE_FAILURE:
      state = state.set('error', action.error);
      break;

    case UPDATE_FRIENDS_TAB_ACTIVE_BEGIN:
      state = state.set('loadingActiveFriendsTab', true);
      break;

    case UPDATE_FRIENDS_TAB_ACTIVE_SUCCESS:
      state = state.set('activeFriendsTab', action.data.activeTab);
      state = state.set('loadingActiveFriendsTab', false);
      break;

    case UPDATE_FRIENDS_TAB_ACTIVE_FAILURE:
      state = state.set('error', action.error);
      break;

    case UPDATE_SIDEBAR_OPEN_STATUS:
      state = state.set('sidebarOpen', action.data.sidebarStatus);
      break;

    default:
      return state;
  }

  return state;
}
