import React, { Component } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import i from 'immutable';
import { Steps } from 'intro.js-react';
import { Button, Modal } from 'react-bootstrap';
import throttle from 'lodash.throttle';
import CONFIG from '../config/config';
import { PickFeed } from '../components/dashboard/PickFeed/index';
import { OnlineUser } from '../components/users/OnlineUser/index';
import SportsFilter from '../components/general/SportsFilter';
import {
  getAPIContentfeed,
  getAnonimusAPIContentfeed,
  getSponsoredAPIContentfeed
} from '../api/content-feed';
import { fetchMySubscription } from '../api/subscription'
import { connect } from "../store/context";
import Loader from '../components/ui/Loader/index';
import Wrapper from '../components/ui/Wrapper';
import { dashboardIntro, becameConsultantIntro, introOptions } from '../helpers/intro';
import { getProfile, updateProfile } from "../api/users";
import { setAuthHeader } from '../actions/api';
import catchRunTimeError from '../util/ds-error';
import 'intro.js/introjs.css';
import { getScrollPoint } from '../actions/scroll-restore';


class Dashboard extends Component {
  static propTypes = {
    contentFeed: PropTypes.array,
    contentFeedLoading: PropTypes.bool,
    followingsCount: PropTypes.number,
    profile: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.socketConnection = new WebSocket(CONFIG.wss_client_connection_url);
    this.socketConnection.onopen = this.sendMessageToWS

    // Log errors
    this.socketConnection.onerror = catchRunTimeError;

    // Log messages from the server
    this.socketConnection.onmessage = this.receivedMsgFromWs;
    this.socketConnection.onclose = function (e) {
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
      setTimeout(function () {
        this.socketConnection = new WebSocket(CONFIG.wss_client_connection_url);
      }, 1000);
    }

    const { selectedSports = [], showAllSports = true } = queryString.parse(props.location.search);

    this.state = {
      showAllSports: typeof showAllSports === 'boolean' ? showAllSports : JSON.parse(showAllSports),
      selectedSports: typeof selectedSports === 'object' ? selectedSports : [selectedSports],
      stepsEnabled: false,
      introSteps: [],
      showModal: false,
      rememberFeedChoice: false
    };
    this.sponsoredTimer = null;
    this.removeStartedItemsTimer = null;
    this.pageContent = React.createRef();
    this.throttleScroll = throttle(this.Scroll, 1000);
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleOnScroll, true);
    const {
      profile,
      anonimus,
      contentFeed,
      contentFeedLoading,
      sponsoredFeedLoading,
      sponsoredFeed,
      scrollPoint,
      location,
      dispatch
    } = this.props;
    const { id, allowAnonimusFeed } = this.props.profile;
    const { token } = queryString.parse(location.search);

    const scrolledContent = this.pageContent.current;
    scrolledContent.scrollTo(0, scrollPoint)

    if (token && token.includes('Bearer')) {
      setAuthHeader(token);
      localStorage.setItem('token', JSON.stringify(token));
      this.getUserProfile();
    }

    this.sponsoredTimer = setInterval(() => {
      this.getSponsoredData();
    }, 300000);

    this.removeStartedItemsTimer = setInterval(() => {
      this.removeStartedItems();
    }, 60000);

    if (profile.id && !allowAnonimusFeed) {
      this.getData();
    }
    if (profile.id && allowAnonimusFeed) {
      this.getAnonimusData();
    }

    if (anonimus && !contentFeedLoading && contentFeed.length === 0) {
      this.getAnonimusData();
    }

    if (sponsoredFeed !== false && !sponsoredFeedLoading && sponsoredFeed.length === 0) {
      this.getSponsoredData();
    }

    fetchMySubscription(dispatch)
  }

  componentDidUpdate(prevProps) {
    const { id, allowAnonimusFeed } = this.props.profile;
    const { anonimus, dispatch, sponsoredFeedLoading, sponsoredFeed, contentFeed, contentFeedLoading, profileLoading } = this.props;

    if (id !== prevProps.profile.id && !allowAnonimusFeed) {
      this.getData(true);
    }


    if (anonimus && !contentFeedLoading && contentFeed.length === 0 && !profileLoading) {
      this.getAnonimusData();
    }

    if (sponsoredFeed !== false && !sponsoredFeedLoading && sponsoredFeed.length === 0) {
      this.getSponsoredData();
    }

    if (!contentFeedLoading && !profileLoading && contentFeed.length === 0 && allowAnonimusFeed) {
      this.getAnonimusData(false);
    }

    if (prevProps.contentFeedLoading && !contentFeedLoading && !profileLoading && contentFeed.length === 0 && prevProps.contentFeed.length === 0 && allowAnonimusFeed === null) {
      this.setState({ showModal: true })
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleOnScroll, true);
    // this.record && this.record.discard();
    // this.client && this.client.close();
    this.sponsoredTimer && clearInterval(this.sponsoredTimer);
    this.removeStartedItemsTimer && clearInterval(this.removeStartedItemsTimer);
  }

  sendMessageToWS = () => {
    //get user id
    const { id } = this.props.profile
    let data = JSON.stringify({ "action": "sendMessage", "page": `my/contentFeed/${id}` })
    this.socketConnection.send(data);
  }
  receivedMsgFromWs = () => {
    const { id } = this.props.profile
    const { allowAnonimusFeed, anonimus, sponsoredFeedLoading, sponsoredFeed, contentFeed, contentFeedLoading, profileLoading } = this.props;
    if (id) {
      this.getData();
    }
    if (anonimus && !contentFeedLoading && contentFeed.length === 0 && !profileLoading) {
      this.getAnonimusData();
    }

    if (sponsoredFeed !== false && !sponsoredFeedLoading && sponsoredFeed.length === 0) {
      this.getSponsoredData();
    }

    if (!contentFeedLoading && !profileLoading && contentFeed.length === 0 && allowAnonimusFeed) {
      this.getAnonimusData(false);
    }
  }

  getData = (force = false) => {
    const { id } = this.props.profile;
    const { contentFeedLoading, dispatch, feedCount, limit, offset } = this.props;
    const allPicksLoaded = Number.isInteger(feedCount) && offset >= feedCount;

    if (!force && (contentFeedLoading || id === undefined || allPicksLoaded)) {
      return;
    }

    getAPIContentfeed(dispatch, id, limit, offset, force);
  };

  getAnonimusData = (force = false) => {
    const { contentFeedLoading, dispatch, feedCount } = this.props;
    let { limit, offset } = this.props
    const allPicksLoaded = Number.isInteger(feedCount) && offset >= feedCount;

    if ((contentFeedLoading || allPicksLoaded) && !force) {
      return;
    }

    if (force) {
      limit = 10;
      offset = 0;
    }

    getAnonimusAPIContentfeed(dispatch, limit, offset);
  };

  getSponsoredData = () => {
    const { contentFeedLoading, dispatch } = this.props;

    if (contentFeedLoading) {
      return;
    }

    getSponsoredAPIContentfeed(dispatch);
  };

  removeStartedItems = () => {
    const { contentFeedLoading, dispatch } = this.props;

    if (contentFeedLoading) {
      return;
    }

    dispatch({ type: 'REMOVE_STARTED_ITEMS' });
  };

  handleHideIntro = () => {
    this.setState({ stepsEnabled: false });
  }

  Scroll = () => {
    if (!this.props.buyingPick) {
      const { dispatch } = this.props;
      const scrolledContent = this.pageContent.current;
      dispatch(getScrollPoint(scrolledContent.scrollTop));
    }
  }

  handleOnScroll = () => {
    const { anonimus } = this.props;
    const scrolledContent = this.pageContent.current;
    const nearBottom = scrolledContent.scrollTop + scrolledContent.offsetHeight + 250 >= scrolledContent.scrollHeight;

    if (nearBottom) {
      anonimus ? this.getAnonimusData() : this.getData();
    }

    this.throttleScroll();
  }

  getFeedData = () => {
    const { contentFeed, pageHistory, dispatch, sponsoredFeed, profile, mySubscriptions } = this.props;
    const { emailVerified = null } = profile;
    const sponsoredItems = [];
    const regularItems = [];

    sponsoredFeed.length > 0 && sponsoredFeed.map((feed) => {
      const keyId = feed.pickId || feed.parleyId || feed.bundleId

      if (keyId) {
        sponsoredItems.push(<PickFeed key={keyId} {...feed} pageHistory={pageHistory} dispatch={dispatch} confirmed={emailVerified} mySubscriptions={mySubscriptions} />)
      }

      return feed;
    })
    const allSports = [];
    contentFeed.length > 0 && contentFeed.map((feed) => {
      const { pickId, parleyId, bundleId, createdAt, sports } = feed;
      const keyId = createdAt + (pickId || parleyId || bundleId);
      const shouldShow = this.isSportsSelected(sports);

      if (keyId && shouldShow) {
        regularItems.push(<PickFeed key={keyId} {...feed} pageHistory={pageHistory} dispatch={dispatch} confirmed={emailVerified} mySubscriptions={mySubscriptions} />)
      }

      feed.sports && feed.sports.forEach((sport) => {
        allSports.push(sport);
      })

      return feed;
    })

    this.includedSports = [...new Set(allSports)];

    return (
      <>
        <div className="feed-sponsored">
          {sponsoredItems}
        </div>
        <div className="feed-regular">
          {regularItems}
        </div>
      </>
    )
  }

  handleClickSport = (event) => {
    const { history } = this.props;
    const clickedSport = event.target.innerText;
    const { selectedSports, showAllSports } = this.state;

    if (showAllSports) {
      this.setState({ showAllSports: false, selectedSports: [clickedSport] })
      history.push({
        search: queryString.stringify({ selectedSports: [clickedSport], showAllSports: false })
      });
    } else {
      const index = selectedSports.indexOf(clickedSport);

      if (index === -1) {
        selectedSports.push(clickedSport);
      } else {
        selectedSports.splice(index, 1);
      }

      if (selectedSports.length === 0) {
        this.setState({ showAllSports: true, selectedSports })
        history.push({ search: queryString.stringify({ selectedSports, showAllSports: true }) });
      } else {
        this.setState({ showAllSports: false, selectedSports });
        history.push({ search: queryString.stringify({ selectedSports, showAllSports: false }) });
      }
    }
  }

  handleClickAll = () => {
    const { showAllSports } = this.state;
    const { history } = this.props;

    if (showAllSports) {
      this.setState({ showAllSports: !showAllSports, selectedSports: this.includedSports });
      history.push({
        search: queryString.stringify({ showAllSports: !showAllSports, selectedSports: this.includedSports })
      });
    } else {
      this.setState({ showAllSports: !showAllSports, selectedSports: [] });
      history.push({ search: queryString.stringify({ showAllSports: !showAllSports, selectedSports: [] }) });
    }
  }

  handleRememberChoice = (event) => {
    this.setState({ rememberFeedChoice: event.target.checked });
  }

  handleFeedChoice = async (event) => {
    const { id } = event.target;
    const { rememberFeedChoice } = this.state;
    const { dispatch } = this.props;

    if (rememberFeedChoice) {
      const allowAnonimusFeed = id === 'random-choice';
      await updateProfile(dispatch, { allowAnonimusFeed });
    }

    if (id === 'random-choice') {
      this.getAnonimusData(true);
    }

    this.setState({ showModal: false });
  }

  isSportsSelected = (includedSports) => {
    const { selectedSports, showAllSports } = this.state;
    let shouldShow = false;

    if (showAllSports) {
      shouldShow = true;
    } else {
      selectedSports.forEach((selected) => {
        if (includedSports.indexOf(selected) > -1) {
          shouldShow = true;
        }
      })
    }

    return shouldShow;
  }

  getUserProfile = async () => {
    const { dispatch } = this.props;
    await getProfile(dispatch, 'dashboard');
  };

  render() {
    const { stepsEnabled, introSteps, showModal, rememberFeedChoice, showAllSports, selectedSports } = this.state;
    const { contentFeed, onlineUsers, contentFeedLoading, buyingPick, profile, offset, feedCount, anonimus } = this.props;
    const feedData = this.getFeedData();

    if (feedData.length < 10 && offset < feedCount) {
      anonimus ? this.getAnonimusData() : this.getData();
    }

    if (profile.id) {
      const firstLogin = localStorage.getItem('dashboardIntro');
      const picksIntro = localStorage.getItem('becameConsultantIntro');

      if (!stepsEnabled && firstLogin === 'true') {
        const steps = [].concat(dashboardIntro.steps);

        if (profile.role === 'user' && steps[3].element === '#picks-page') {
          steps.splice(3, 1);
        }

        this.setState({ stepsEnabled: true, introSteps: steps });
        localStorage.removeItem('dashboardIntro');
      } else if (!stepsEnabled && picksIntro === 'true') {
        this.setState({ stepsEnabled: true, introSteps: becameConsultantIntro.steps });
        localStorage.removeItem('becameConsultantIntro');
      }
    }

    return (
      <Wrapper
        className="feed-page"
        title="Picks Feed"
        icon="fa fa-home"
        pageContent={this.pageContent}
      >
        <Steps
          enabled={stepsEnabled}
          steps={introSteps}
          initialStep={0}
          onExit={this.handleHideIntro}
          options={introOptions}
        />
        <Modal show={showModal} className="feed-modal" onHide={() => this.setState({ showModal: false })}>
          <Modal.Header closeButton>
            <Modal.Title>You do not have any pick items in a feed right now. Do you want to see random pick from all consultants?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input id="remember" checked={rememberFeedChoice} type="checkbox" onChange={this.handleRememberChoice} />
            <label htmlFor="remember" className="rounded">Remember this choice</label>
          </Modal.Body>
          <Modal.Footer>
            <Button id="random-choice" onClick={this.handleFeedChoice}>Use random feed</Button>
            <Button id="continue-choice" onClick={this.handleFeedChoice}>Continue as is</Button>
          </Modal.Footer>
        </Modal>
        <div className="row">
          <div id="blog-list" className="col-lg-9">
            {contentFeed.length > 0 &&
              <SportsFilter
                showAllSports={showAllSports}
                selectedSports={selectedSports}
                onClickAll={this.handleClickAll}
                includedSports={this.includedSports}
                onClickSport={this.handleClickSport}
              />}
            {!buyingPick && feedData}
            {contentFeed.length !== 0 && contentFeedLoading ? <Loader spinnerSize={30} /> : ''}
            {contentFeed.length === 0 && contentFeedLoading && <Loader spinnerSize={90} />}
            {!contentFeedLoading && buyingPick && <Loader spinnerSize={90} />}
          </div>
          <div className="col-lg-3 feed-sidebar">
            {onlineUsers.length > 0 &&
              <div className="panel panel-primary rounded shadow">
                <div className="panel-body no-padding">
                  <h4 className="feed-sidebar-title">Online Friends</h4>
                  {
                    onlineUsers.map((user) => {
                      return user.id && <OnlineUser key={user.id} {...user} />
                    })
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </Wrapper>
    );
  }
}

function select(state, ownProps) {
  let profile = state.getIn(['users', 'profile'], i.Map());
  const contentFeed = state.getIn(['contentFeed', 'feed'], i.List()).toJS();
  const feedCount = state.getIn(['contentFeed', 'count'], 0);
  const contentFeedLoading = state.getIn(['contentFeed', 'loading'], false);
  const following = profile.get('following', i.List());
  const onlineIds = state.getIn(['users', 'onlineUsers'], i.List());
  profile = profile.toJS();
  let onlineUsers = following.filter((user) => {
    return onlineIds.includes(Number(user.get('id')));
  });
  onlineUsers = onlineUsers.toJS();
  const pageHistory = ownProps.history;
  const anonimus = state.getIn(['users', 'anonimus'], false);
  const limit = state.getIn(['contentFeed', 'limit'], 20);
  const offset = state.getIn(['contentFeed', 'offset'], 0);
  const buyingPick = state.getIn(['contentFeed', 'buying'], false);
  const sponsoredFeedLoading = state.getIn(['contentFeed', 'loadingSponsored'], false);
  let sponsoredFeed = state.getIn(['contentFeed', 'sponsoredFeed'], i.List());
  sponsoredFeed = sponsoredFeed ? sponsoredFeed.toJS() : false;
  const profileLoading = state.getIn(['users', 'loading'], false);
  const scrollPoint = state.getIn(['scrollRestore', 'scrollPoint', 'scrollPoint'], 0);
  const mySubscriptions = state.getIn(['subscriptions', 'mySubscriptions'], i.List()).toJS()

  return {
    profile,
    contentFeed,
    onlineUsers,
    contentFeedLoading,
    pageHistory,
    feedCount,
    anonimus,
    limit,
    offset,
    buyingPick,
    sponsoredFeedLoading,
    sponsoredFeed,
    profileLoading,
    scrollPoint,
    mySubscriptions,

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Dashboard);
