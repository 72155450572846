import React, {Component} from 'react';
import i from 'immutable';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { NavLink, withRouter } from 'react-router-dom';
import { subscribeRoomsState, getUserRooms, fetchMessages, setUsersOnline, activateChatSound  } from "../../../../api/chat";
import { userOnline } from "../../../../api/users";
import { connect } from "../../../../store/context";
import ProfileDropdown from '../../../dropdowns/Profile/index';
import NotificationsDropdown from '../../../dropdowns/Notifications';
import CONFIG from '../../../../config/config';
import './style.scss';


class RightHeader extends Component {
  state = {
    notificationsIsOpen: false,
    profileIsOpen: false
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('click', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
    this.subscribeRoomsState();
    if (typeof this.props.myUserId != 'undefined' && this.props.myUserId > 0) {
      this.socketConnection = new WebSocket(CONFIG.wss_client_connection_url);
      this.socketConnection.onopen = this.sendMessageToWS
      // Log messages from the server
      this.socketConnection.onmessage = this.receivedMsgFromWs;
      this.socketConnection.onclose = function (e) {
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
        userOnline(0)
        setTimeout(function () {
          this.socketConnection = new WebSocket(CONFIG.wss_client_connection_url);
        }, 1000);
      }
    }

  }

  sendMessageToWS = () => {
    let data = JSON.stringify({"action":"sendMessage","page":`chat/messages/${this.props.myUserId}`})
    this.socketConnection.send(data);
    userOnline(1)
    
  }
  receivedMsgFromWs = (message) => {
    if (message.data != '') {
      const data = JSON.parse(message.data)
      if (data.action == '/chat/messages/') {
        fetchMessages(this.props.dispatch, data.chatRoomId, 20, 0, true);
        subscribeRoomsState(this.props.dispatch, this.props.myUserId);
      } else if(data.action == '/users/online') {
        setUsersOnline(this.props.dispatch, JSON.parse(data.users))
      }
      
    }
    
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener('touchstart', this.handleClickOutside);
  }

  subscribeRoomsState = () => {
    const { dispatch, myUserId } = this.props;

    if (myUserId) {
      subscribeRoomsState(dispatch, myUserId);
    }
  }

  toggleNotificationsDropdown = () => {
    this.resetState();

    this.setState(() => ({
      notificationsIsOpen: !this.state.notificationsIsOpen
    }));
  }

  toggleProfileDropdown = () => {
    this.resetState();

    this.setState(() => ({
      profileIsOpen: !this.state.profileIsOpen
    }));
  }

  resetState = () => {
    this.setState(() => {
      return {
        notificationsIsOpen: false,
        profileIsOpen: false
      }
    })
  }

  setNotificationsRef = (node) => {
    this.notificationsRef = node;
  }

  setProfileRef = (node) => {
    this.profileRef = node;
  }

  handleClickOutside = (e) => {
    if (this.notificationsRef && !this.notificationsRef.contains(e.target) && this.profileRef && !this.profileRef.contains(e.target)) {
      this.resetState();
    }
  }

  handleNavigateChat = () => {
    const { dispatch } = this.props;
    getUserRooms(dispatch, 1);
    activateChatSound()
  }

  render() {
    const { isUserLoaded, location, profileLoading, selectedUser } = this.props;
    let { roomsWithNewMessages } = this.props;
    const { room, redirectUrl } = queryString.parse(location.search);

    if (room === selectedUser) {
      roomsWithNewMessages = roomsWithNewMessages.filter((room) => room !== selectedUser);
    }

    return (
      <div className="header-right">
        <div className="navbar navbar-toolbar navbar-dark">
          <ul className="nav navbar-nav navbar-left" />

          {isUserLoaded && !profileLoading &&
          <ul className="nav navbar-nav navbar-right">

            <li id="myPicks" className="dropdown navbar-notification vertical">
              <NavLink
                to="/my-picks/pregame"
                activeClassName="active"
                className={location.pathname === "/my-parlays" ? "active" : ''}
              >
                <i className="fa fa-diamond" />
                <span>My picks</span>
              </NavLink>
            </li>

            <li id="myFriends" className="dropdown navbar-notification vertical">
              <NavLink
                to="/friends"
                activeClassName="active"
              >
                <i className="fa fa-fw fa-user-o" />
                <span>Friends</span>
              </NavLink>
            </li>

            <li className="dropdown navbar-notification vertical">
              <NavLink
                to="/chat"
                activeClassName="active"
                onClick={this.handleNavigateChat}
              >
                <i className="fa fa-fw fa-envelope-o" />
                {roomsWithNewMessages.length > 0 && <span className="count label label-danger rounded">{roomsWithNewMessages.length}</span>}
                <span>Messages</span>
              </NavLink>
            </li>

            <NotificationsDropdown
              notificationsRef={this.setNotificationsRef}
              id="myNotifications"
              toggleDropDown={this.toggleNotificationsDropdown}
              isOpen={this.state.notificationsIsOpen}
            />

            <ProfileDropdown
              profileRef={this.setProfileRef}
              id="myProfile"
              toggleDropDown={this.toggleProfileDropdown}
              isOpen={this.state.profileIsOpen}
            />

          </ul>}

          {!isUserLoaded &&
            <ul className="nav navbar-nav navbar-right">

              <li className="dropdown navbar-notification vertical">
                <NavLink
                  to={`/login${redirectUrl ? `?redirectUrl=${redirectUrl}` : ''}`}
                  activeClassName="active"
                >
                  <i className="fa fa-user" />
                  <span>Sign In</span>
                </NavLink>
              </li>

              <li className="dropdown navbar-notification vertical">
                <NavLink
                  to={`/signup${redirectUrl ? `?redirectUrl=${redirectUrl}` : ''}`}
                  activeClassName="active"
                >
                  <i className="fa fa-user-plus" />
                  <span>Sign Up</span>
                </NavLink>
              </li>

            </ul>
          }
        </div>
      </div>
    );
  }
}

const select = (state) => {
  const isUserLoaded = !state.getIn(['users', 'profile'], i.Map()).isEmpty();
  const myUserId = state.getIn(['users', 'myUserId'], null);
  const roomsWithNewMessages = state.getIn(['chat', 'roomsWithNewMessages'], []);
  const selectedUser = state.getIn(['chat', 'currentUserId'], null);
  const profileLoading = state.getIn(['users', 'profileLoading'], false);

  return { isUserLoaded, myUserId, roomsWithNewMessages, profileLoading, selectedUser }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default withRouter(connect(select, mapDispatchToProps)(RightHeader));

RightHeader.propTypes = {
  dispatch: PropTypes.func,
  isUserLoaded: PropTypes.bool,
  location: PropTypes.object,
  myUserId: PropTypes.number,
  profileLoading: PropTypes.bool,
  roomsWithNewMessages: PropTypes.array,
  selectedUser: PropTypes.number
};
