import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Button, Modal, Form, FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';
import { isValidTextField } from "../../../helpers/validators";


class WithdrawModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      withdrawAmount: '',
      formValid: false
    };
  }

  handleInputChange = (e) => {
    const { balance } = this.props;
    e && e.preventDefault();

    if (Number(e.currentTarget.value) || e.currentTarget.value === '') {
      const devidedString = (e.currentTarget.value).split('.');

      if (devidedString.length > 1 && devidedString[1].length > 2) {
        return
      }

      this.setState({ withdrawAmount: e.currentTarget.value });
    }

    if (Number(balance) < Number(e.currentTarget.value)) {
      this.setState({error: `You dont have enough balance`, formValid: false});
    } else {
      const { isValidField, error } = isValidTextField(e.currentTarget.value, e.currentTarget.name);

      if (isValidField) {
        this.setState({formValid: true, error});
      } else {
        this.setState({error, formValid: false});
      }
    }
  }

  handleSubmitRequest = (e) => {
    e.preventDefault();
    const { withdrawAmount, formValid } = this.state;

    if (!formValid) {
      return;
    }

    this.props.onSubmitWithdrawReqets(withdrawAmount);
  }

  render() {
    const { show, balance, onCloseModal, loading } = this.props;
    const { error, withdrawAmount, formValid } = this.state;
    const disableButton = !formValid || withdrawAmount.length === 0 || loading;

    return (
      <Modal show={show} onHide={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Withdraw - Available balance: {balance} </Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleSubmitRequest}>
          <Modal.Body>
            <h4>Your withdrawal request will be processed within 24 hours</h4>
            <FormGroup validationState={error ? 'error' : null}>
              <ControlLabel>Amount to withdraw</ControlLabel>
              <FormControl
                type="text"
                maxLength="10"
                onChange={this.handleInputChange}
                name="withdrawAmount"
                value={withdrawAmount}
                required={1}
              />
              <HelpBlock>{error}</HelpBlock>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" disabled={disableButton}>Withdraw</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default WithdrawModal;

WithdrawModal.propTypes = {
  balance: PropTypes.number,
  loading: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onSubmitWithdrawReqets: PropTypes.func,
  show: PropTypes.bool
};

