import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { hotjar } from 'react-hotjar';
import { getProfile } from "./api/users";
import CONFIG from './config/config';

import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import configureStore from './store/configureStore';


const store = configureStore();
getProfile(store.dispatch);

if (CONFIG.app === 'production') {
  hotjar.initialize(1177774, 6);
}

const jsx = (
  <Provider store={store}>
    <App />
  </Provider>
);
ReactDOM.render(jsx, document.getElementById('root'));

if (CONFIG.app === 'production') {
  // Add tag manager
  const tagManager = document.createElement("script");
  tagManager.src = "https://www.googletagmanager.com/gtag/js?id=UA-134416381-1";
  tagManager.async = true;
  document.body.appendChild(tagManager);

  // Add google analitics
  const analitics = document.createElement("script");
  analitics.innerHTML = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments)}gtag('js', new Date());gtag('config', 'UA-134416381-1');"
  analitics.type = "text/javascript";
  document.body.appendChild(analitics);

  // Add support chat
  const supportChat = document.createElement("script");
  supportChat.innerHTML = `var LHCChatOptions = {};
  LHCChatOptions.opt = {widget_height:340,widget_width:300,popup_height:520,popup_width:500,subdomain:true};
  (function() {
  var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true;
  var referrer = (document.referrer) ? encodeURIComponent(document.referrer.substr(document.referrer.indexOf('://')+1)) : '';
  var location  = (document.location) ? encodeURIComponent(window.location.href.substring(window.location.protocol.length)) : '';
  po.src = '//support-chat.sportstrader.pro//chat/index.php/chat/getstatus/(click)/internal/(position)/bottom_right/(ma)/br/(top)/350/(units)/pixels/(leaveamessage)/true?r='+referrer+'&l='+location;
  var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);
  })();`;
  supportChat.type = "text/javascript";
  document.body.appendChild(supportChat);
}

unregister() ;
// registerServiceWorker();
