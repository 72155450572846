import CONFIG from '../config/config';
import catchRunTimeError from '../util/ds-error';
import { fetchOddsBegin, fetchOddsSuccess, fetchOddsError } from '../actions/odds';
import api from '../actions/api';
import { alertError } from '../actions/alerts'


let client = {};
let token = 'guest';

try {
  const storageToken = localStorage.getItem('token');
  if (storageToken) {
    token = JSON.parse(storageToken);
  }
} catch (error) {
  console.log(error); // eslint-disable-line no-console
}


export const getOdds = async (dispatch, data) => {
  try {
    dispatch(fetchOddsBegin());
    dispatch(fetchOddsSuccess(data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(fetchOddsError(error));
  }
}


export const getOddsNowByApi = async (dispatch, sportId) => {
  try {
    dispatch(fetchOddsBegin());
    const { data } = await api.get(`${CONFIG.api_url}/sports/${sportId}/odds/now?limit=500`);
    dispatch(fetchOddsSuccess(data.odds));
  } catch (error) {
    console.log(error);  //eslint-disable-line no-console
    alertError(error)
    dispatch(fetchOddsError(error));
  }
}

export const getOddsByApi = async (dispatch, sportId) => {
  try {
    dispatch(fetchOddsBegin());
    const { data } = await api.get(`${CONFIG.api_url}/sports/${sportId}/odds?limit=100`);
    dispatch(fetchOddsSuccess(data.odds));
  } catch (error) {
    console.log(error);  //eslint-disable-line no-console
    alertError(error)
    dispatch(fetchOddsError(error));
  }
}