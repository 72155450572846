export const FETCH_SUBSCRIPTION_BEGING   = 'FETCH_SUBSCRIPTION_BEGING';
export const FETCH_SUBSCRIPTION_SUCCESS = 'FETCH_SUBSCRIPTION_SUCCESS';
export const FETCH_SUBSCRIPTION_FAILURE = 'FETCH_SUBSCRIPTION_FAILURE';
export const FETCH_MY_SUBSCRIPTION_BEGIN = 'FETCH_MY_SUBSCRIPTION_BEGIN';


export const fetchSubscriptionBegin = () => ({
  type: FETCH_SUBSCRIPTION_BEGING
});

export const fetchSubscriptionSuccess = (subscriptions) => ({
  type: FETCH_SUBSCRIPTION_SUCCESS,
  data: { subscriptions }
});

export const fetchSubscriptionError = (error) => ({
  type: FETCH_SUBSCRIPTION_FAILURE,
  data: { error }
});

export const fetchMySubscriptionBegin = (data) => ({
  type: FETCH_MY_SUBSCRIPTION_BEGIN,
  data: { data }
});