import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "../../../store/context";
import { searchConsultants } from "../../../api/users";
import './style.scss';


class ConsultantSearch extends Component {
  static propTypes = {
    Sports: PropTypes.array,
    dispatch: PropTypes.func
  }

  state = {
    fullName: null,
    status: null,
    ROIFrom: null,
    ROITo: null,
    DeviationFrom: null,
    DeviationTo: null,
    ROISpreadFrom: null,
    ROISpreadTo: null,
    ROIMoneyLineFrom: null,
    ROIMoneyLineTo: null,
    ROITotalFrom: null,
    ROITotalTo: null,
    DeviationSpreadFrom: null,
    DeviationSpreadTo: null,
    DeviationTotalFrom: null,
    DeviationTotalTo: null,
    showDetailedSearch: false,
    SportId: null
  };

  handleSearchChange = (event) => {
    if (event.target.value === '') {
      this.setState({ [event.target.id]: null });
    } else if (event.target.tagName === 'SELECT' && event.target.value === 'null') {
      this.setState({ [event.target.id]: null });
    } else {
      this.setState({
        [event.target.id]: event.target.value
      });
    }
  }

  handleSearch = (event) => {
    event.preventDefault();
    const { dispatch } = this.props;

    const searchOptions =  Object.assign({}, this.state);
    Reflect.deleteProperty(searchOptions, 'showDetailedSearch');
    searchConsultants(dispatch, { ...searchOptions });
  }

  handleShowDetailed = (event) => {
    event.preventDefault();
    this.setState({showDetailedSearch: !this.state.showDetailedSearch});
  }

  render() {
    const { showDetailedSearch } = this.state;
    const { Sports } = this.props;

    return (
      <form className="consultant-search">
        {showDetailedSearch &&
          <div className="row detailed">
            <div className="col-md-12">
              <div className="form-group col-md-2 col-sm-6 col-xs-6 from">
                <input type="number" id="ROIFrom" className="form-control" placeholder="ROI From" onChange={this.handleSearchChange} />
              </div>

              <div className="form-group col-md-2 col-sm-6 col-xs-6">
                <input type="number" id="ROITo" className="form-control" placeholder="ROI To" onChange={this.handleSearchChange} />
              </div>

              <div className="form-group col-md-2 col-sm-6 col-xs-6 from">
                <input type="number" id="DeviationFrom" className="form-control" placeholder="Deviation From" onChange={this.handleSearchChange} />
              </div>

              <div className="form-group col-md-2 col-sm-6 col-xs-6">
                <input type="number" id="DeviationTo" className="form-control" placeholder="Deviation To" onChange={this.handleSearchChange} />
              </div>

              <div className="form-group col-md-4">
                <select className="form-control" id="status" onChange={this.handleSearchChange}>
                  <option disabled selected hidden>Status</option>
                  <option value="null">All</option>
                  <option value="Top Rated">Top Rated</option>
                  <option value="Rising Talent">Rising Talent</option>
                </select>
              </div>

              {/* <div className="form-group col-md-2">
                <input type="text" id="ROISpreadFrom" className="form-control" placeholder="ROISpread From" onChange={this.handleSearchChange} />
              </div>

              <div className="form-group col-md-2">
                <input type="text" id="ROISpreadTo" className="form-control" placeholder="ROISpread To" onChange={this.handleSearchChange} />
              </div>

              <div className="form-group col-md-2">
                <input type="text" id="ROIMoneyLineFrom" className="form-control" placeholder="ROIMoneyLine From" onChange={this.handleSearchChange} />
              </div>

              <div className="form-group col-md-2">
                <input type="text" id="ROIMoneyLineTo" className="form-control" placeholder="ROIMoneyLine To" onChange={this.handleSearchChange} />
              </div>

              <div className="form-group col-md-2">
                <input type="text" id="ROITotalFrom" className="form-control" placeholder="ROITotal From" onChange={this.handleSearchChange} />
              </div>

              <div className="form-group col-md-2">
                <input type="text" id="ROITotalTo" className="form-control" placeholder="ROITotal To" onChange={this.handleSearchChange} />
              </div>

              <div className="form-group col-md-2">
                <input type="text" id="DeviationSpreadFrom" className="form-control" placeholder="DeviationSpread From" onChange={this.handleSearchChange} />
              </div>

              <div className="form-group col-md-2">
                <input type="text" id="DeviationSpreadTo" className="form-control" placeholder="DeviationSpread To" onChange={this.handleSearchChange} />
              </div>

              <div className="form-group col-md-2">
                <input type="text" id="DeviationTotalFrom" className="form-control" placeholder="DeviationTotal From" onChange={this.handleSearchChange} />
              </div>

              <div className="form-group col-md-2">
                <input type="text" id="DeviationTotalTo" className="form-control" placeholder="DeviationTotal To" onChange={this.handleSearchChange} />
              </div> */}
            </div>
          </div>
        }

        <div className="row">
          <div className="col-md-12">
            <div className="form-group col-md-4">
              <input className="form-control" id="fullName" type="text" placeholder="Name" onChange={this.handleSearchChange} />
            </div>

            <div className="form-group col-md-4">
              <select className="form-control" id="SportId" onChange={this.handleSearchChange}>
                <option disabled selected hidden>Sport</option>
                <option value="null">All</option>
                {Sports.map((sport) => <option key={sport.id} value={sport.id}>{sport.Name.toUpperCase()}</option>)}
              </select>
            </div>

            <div className="form-group col-md-4">
              <button type="submit" className="btn btn-default" onClick={this.handleSearch}>Search</button>
            </div>
          </div>
        </div>

        <div className="row show-detailed">
          <div className="text-center col-md-12">
            <a href="/#" onClick={this.handleShowDetailed}>
              <i className={showDetailedSearch ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} />
              <span>Advanced Search</span>
            </a>
          </div>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(mapDispatchToProps)(ConsultantSearch);
