import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Wrapper from '../components/ui/Wrapper';


class SelectRole extends Component {
  handleSelectUser = () => {
    const { history } = this.props;
    history.push('/')
  }

  handleSelectConsultant = () => {
    const { history } = this.props;
    history.push('/profile/become-consultant')
  }

  render() {
    return (
      <Wrapper
        className="odds-page"
        title={'Please select role for your account'}
      >
        <div className="row">
          <div className="role-hint col-md-6">
            <h4>
              <p>Regular user is able to view and buy picks only.</p>
              <p>Not worries you can always become consultant later on.</p>
            </h4>
          </div>
          <div className="role-hint col-md-6">
            <h4>
              <p>A consultant is able to view, create, sell and buy picks.</p>
              <p>If you become consultant you will be able to earn money from creating pick as well as promote them to all users on our platform.</p>
            </h4>
            <span>Note</span><span className="asterisk">*</span>: You need to have verified PayPal account in order to become consultant.
          </div>
        </div>
        <div className="row">
          <div className="role-btn col-md-6">
            <button className="btn btn-default btn-lg btn-block no-margin rounded" onClick={this.handleSelectUser}>Proceed as Regular User</button>
          </div>
          <div className="role-btn col-md-6">
            <button className="btn btn-warning btn-lg btn-block no-margin rounded" onClick={this.handleSelectConsultant}>Proceed as consultant</button>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default SelectRole;

SelectRole.propTypes = {
  history: PropTypes.object
};
