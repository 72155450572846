import CONFIG from '../config/config';
import api from '../actions/api';
import { alertError, alertErrorText, alertSuccess } from '../actions/alerts';
import {
  fetchPaymentsHistoryBegin,
  fetchPaymentsHistorySuccess,
  fetchPaymentsHistoryError,
  fetchSalesBegin,
  fetchSalesSuccess,
  fetchSalesError,
  fetchSalesStatsBegin,
  fetchSalesStatsSuccess,
  fetchSalesStatsError,
  promoteContentBegin,
  promoteContentSuccess,
  promoteContentError,
  getAffiliateBegin,
  getAffiliateSuccess,
  getAffiliateError,
  getPendingBalanceBegin,
  getPendingBalanceSuccess,
  getPendingBalanceError,
  getPendingAffiliateBalanceBegin,
  getPendingAffiliateBalanceSuccess,
  getPendingAffiliateBalanceError,
  getAffiliateLinkBegin,
  getAffiliateLinkSuccess,
  getAffiliateLinkError,
  submitWithdrawRequestBegin,
  submitWithdrawRequestSuccess,
  submitWithdrawRequestError,
  getAffiliateRevenueBegin,
  getAffiliateRevenueSuccess,
  getAffiliateRevenueError,
  sendTipsFromBalanceBegin,
  sendTipsFromBalanceSuccess,
  sendTipsFromBalanceError
} from '../actions/payments';
import { updateBalance } from '../actions/users';
import { updateMyBoughtItemBegin, updateMyBoughtItem, updateMyBoughtItemFailure } from '../actions/picks';
import { getPick, getParley, getBundle } from "./picks";


export const getPaymentsHistory = async (dispatch) => {
  try {
    dispatch(fetchPaymentsHistoryBegin());
    const { data } = await api.get(`${CONFIG.api_url}/payments`);
    dispatch(fetchPaymentsHistorySuccess(data.payments));
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
    dispatch(fetchPaymentsHistoryError(error));
  }
}

export const getSales = async (dispatch, page, limit, type) => {
  try {
    dispatch(fetchSalesBegin());
    const { data } = await api.get(`${CONFIG.api_url}/payments/sales?page=${page}&limit=${limit}&type=${type}`);
    dispatch(fetchSalesSuccess(data));
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
    dispatch(fetchSalesError(error));
  }
}

export const getSalesStats = async (dispatch) => {
  try {
    dispatch(fetchSalesStatsBegin());
    const tzoffset = -new Date().getTimezoneOffset();
    const { data } = await api.get(`${CONFIG.api_url}/payments/sales/stats?tzoffset=${tzoffset}`);
    dispatch(fetchSalesStatsSuccess(data));
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
    dispatch(fetchSalesStatsError(error));
  }
}

export const promoteContent = async (dispatch, id, type) => {
  try {
    dispatch(promoteContentBegin());
    const { data } = await api.post(`${CONFIG.api_url}/payments/promote`, {id, type, price: CONFIG.promotionPrice});
    data.type = type;
    data.id = id;
    dispatch(promoteContentSuccess(data));

    if (data.message) {
      alertSuccess(data.message);
    }

    return data.success;
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
    dispatch(promoteContentError(error));
    return false;
  }
}

export const getAffiliateUsers = async (dispatch) => {
  try {
    dispatch(getAffiliateBegin());
    const { data } = await api.get(`${CONFIG.api_url}/users/referrals`);
    dispatch(getAffiliateSuccess(data));
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
    dispatch(getAffiliateError(error));
  }
}

export const getPendingBalance = async (dispatch) => {
  try {
    dispatch(getPendingBalanceBegin());
    const { data } = await api.get(`${CONFIG.api_url}/payments/pending`);
    dispatch(getPendingBalanceSuccess(data));
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
    dispatch(getPendingBalanceError(error));
  }
}

export const getAffiliatePendingBalance = async (dispatch) => {
  try {
    dispatch(getPendingAffiliateBalanceBegin());
    const { data } = await api.get(`${CONFIG.api_url}/payments/pending/referrals`);
    dispatch(getPendingAffiliateBalanceSuccess(data));
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
    dispatch(getPendingAffiliateBalanceError(error));
  }
}

export const getAffiliateRevenue = async (dispatch) => {
  try {
    dispatch(getAffiliateRevenueBegin());
    const { data } = await api.get(`${CONFIG.api_url}/payments/referrals/revenue`);
    dispatch(getAffiliateRevenueSuccess(data));
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
    dispatch(getAffiliateRevenueError(error));
  }
}

export const getAffiliateLink = async (dispatch) => {
  try {
    dispatch(getAffiliateLinkBegin());
    const { data } = await api.get(`${CONFIG.api_url}/users/referral-link`);
    dispatch(getAffiliateLinkSuccess(data));
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    dispatch(getAffiliateLinkError(error));
  }
}

export const submitWithdrawRequest = async (dispatch, amount) => {
  try {
    dispatch(submitWithdrawRequestBegin());
    const { data } = await api.post(`${CONFIG.api_url}/payments/withdraw`, { amount });
    alertSuccess(data.message);
    dispatch(submitWithdrawRequestSuccess(data));
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    alertError(error)
    dispatch(submitWithdrawRequestError(error));
  }
}

export const sendTipsFromBalance = async (dispatch, userId, amount) => {
  try {
    dispatch(sendTipsFromBalanceBegin());
    const { data } = await api.post(`${CONFIG.api_url}/payments/tip`, {userId, amount});

    if (data.message) {
      alertSuccess(data.message);
    } else if (data.error) {
      alertErrorText(data.error);
      throw new Error(data.error);
    }

    dispatch(sendTipsFromBalanceSuccess());
  } catch (error) {
    console.log(error); //eslint-disable-line no-console
    dispatch(sendTipsFromBalanceError(error));
    throw error;
  }
}

export const buyFromBalance = async (dispatch, itemType, itemId, price, description, pageHistory) => {
  try {
    dispatch(updateMyBoughtItemBegin());
    const { data } = await api.get(
      `${CONFIG.api_url}/payments/payfrombalance`,
      { params: { itemType, itemId, price, description }}
    );

    if (data.success) {
      dispatch(updateBalance(price));

      if (itemType === 'Buy Pick') {
        await api.post(`${CONFIG.api_url}/picks/${itemId}/buy`, { itemType });
        const { pathname } = pageHistory.location;
        const newPath = `/pick/${itemId}`;

        if (pathname === newPath) {
          getPick(dispatch, itemId);
        } else {
          pageHistory.push(`/pick/${itemId}`)
        }

        dispatch(updateMyBoughtItem(itemId, itemType));
      }

      if (itemType === 'Buy Parlay') {
        await api.post(`${CONFIG.api_url}/parlays/${itemId}/buy`, { itemType });

        const { pathname } = pageHistory.location;
        const newPath = `/parlay/${itemId}`;

        if (pathname === newPath) {
          getParley(dispatch, itemId);
        } else {
          pageHistory.push(`/parlay/${itemId}`)
        }

        dispatch(updateMyBoughtItem(itemId, itemType));
      }

      if (itemType === 'Buy Bundle') {
        await api.post(`${CONFIG.api_url}/bundles/${itemId}/buy`, { itemType });

        const { pathname } = pageHistory.location;
        const newPath = `/bundle/${itemId}`;

        if (pathname === newPath) {
          getBundle(dispatch, itemId);
        } else {
          pageHistory.push(`/bundle/${itemId}`)
        }

        dispatch(updateMyBoughtItem(itemId, itemType));
      }
    }
  } catch (error) {
    dispatch(updateMyBoughtItemFailure(error));

    if (error.response && error.response.status === 401) {
      let item = ''

      switch (itemType) {
        case 'Buy Pick':
          item = 'pick';
          break

        case 'Buy Parlay':
          item = 'parlay';
          break

        case 'Buy Bundle':
          item = 'bundle';
          break
        default:
          item = '';
      }

      return pageHistory.push(`/signup?redirectUrl=/${item}/${itemId}`);
    }

    console.error(error); //eslint-disable-line no-console
    alertError(error)
  }
}


export const buySubscriptionFromBalance = async (dispatch, itemType, itemId, price, description, pageHistory) => {
  try {
    const { data } = await api.get(
      `${CONFIG.api_url}/payments/payfrombalance`,
      { params: { itemType, itemId, price, description }}
    );

    if (data.success) {
      await api.post(`${CONFIG.api_url}/subscriptions/${itemId}/buy`, { itemType });
      pageHistory.push(`/my-picks/pregame?selectedSubscriptionId=${itemId}`)
    }
    
  } catch(error) {
    alertError(error)
    if (error.response && error.response.data && error.response.data.error == 'Already bought') {
      await api.post(`${CONFIG.api_url}/subscriptions/${itemId}/buy`, { itemType });
    } else if (error.response && error.response.data && error.response.data.error &&error.response.data.error.includes('Not enough money on the balance')) {
      pageHistory.push(`/profile/refill`)
    }

  }
}