import React from 'react';
import PropTypes from 'prop-types';


const EmptyPage = ({text = "No Data"}) => {
  return (
    <div className="empty-component text-center">
      <h1>{text}</h1>
    </div>
  );
}

export default EmptyPage;

EmptyPage.propTypes = {
  text: PropTypes.string
}
