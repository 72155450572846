import React from 'react';
import { Link } from 'react-router-dom';
import { setDefaultAvatar } from '../../../helpers/defaultImage';
import './style.scss';


export const OnlineUser = (user) => {
  const { avatar, firstName, lastName, id, bio } = user;

  const userLastName = lastName ? lastName : '';

  return (
    <div>
      <div className="media inner-all no-margin online-user">
        <div className="avatar">
          <Link to={`/user/${id}`}>
            <img src={avatar} alt={firstName} width="60" height="60" onError={setDefaultAvatar} />
          </Link>
        </div>
        <div className="media-body full-width">
          <Link to={`/user/${id}`} className="full-name">
            {firstName
              ? <span title={`${firstName} ${userLastName}`.length > 13 ? `${firstName} ${userLastName}` : ''}>{firstName} {userLastName}</span>
              : <span>Anonymous</span>
            }
          </Link>
          <Link to={`/chat?room=${id}`} className="pull-right">
            <i className="fa fa-fw fa-envelope" />
          </Link>
          <small className="block text-muted">{bio}</small>
        </div>
      </div>
      <div className="line no-margin" />
    </div>
  );
}
