import React from 'react';
import PropTypes from 'prop-types';
import ReactHighcharts from 'react-highcharts';


const DeviationChart = (props) => {
  const { dates, spread, total } = props;

  const series = [{
    type: 'spline',
    name: 'Spread',
    data: spread
  },
  {
    type: 'spline',
    name: 'Total',
    data: total
  }]

  const config = {
    chart: {
      height: 300,
      type: 'line'
    },
    xAxis: {
      categories: dates
    },
    yAxis: {
      title: false
    },
    title: {
      text: 'Deviation'
    },
    series
  };

  return (
    <div className="chart-wrap">
      <ReactHighcharts config={config} />
    </div>
  );
}

export default DeviationChart;

DeviationChart.propTypes = {
  dates: PropTypes.array.isRequired,
  spread: PropTypes.array.isRequired,
  total: PropTypes.array.isRequired
}
