import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import { withRouter } from "react-router";
import PropTypes from 'prop-types';


class SubMenu extends Component {
  state = {
    showSubMenu: false,
    showSubSubMenu: false,
    subMenuId: this.props.location.pathname.split("/")[2] || null,
    subSubMenuId: null
  }

  handleToggle = (e, itemId, depthLevel) => {
    e.preventDefault();
    e.stopPropagation();
    const { showSubMenu, subMenuId, showSubSubMenu, subSubMenuId } = this.state;

    if (depthLevel === 1) {
      if (itemId === subMenuId) {
        this.setState({showSubMenu: !showSubMenu});
      } else {
        this.setState({showSubMenu: true, subMenuId: itemId});
      }
    }

    if (depthLevel === 2) {
      if (itemId === subSubMenuId) {
        this.setState({showSubSubMenu: !showSubSubMenu});
      } else {
        this.setState({showSubSubMenu: true, subSubMenuId: itemId});
      }
    }
  }

  buildMenu = (data, depthLevel = 0) => {
    const { location, type } = this.props;
    const { showSubMenu, showSubSubMenu, subSubMenuId, subMenuId } = this.state;
    const { 2: sportIdFromURL } = location.pathname.split("/");
    const { 1: sportTypeFromURL } = location.pathname.split("/");
    const isSameType = (type.toUpperCase() === sportTypeFromURL.toUpperCase());
    const isTopMenuActive = (id) => (Number(sportIdFromURL) === id) && isSameType;

    return (
      data.map((menuItem) => {
        let itemsCount = menuItem[`${type}Count`];
        let displaySport = itemsCount
        if (type == 'rooms') {
          itemsCount = 0
          displaySport = true
        }
        
        const isHaveGames = menuItem.EsportGames && menuItem.EsportGames.length > 0;
        const isHaveLeagues = menuItem.Leagues && menuItem.Leagues.length > 0;

        if (isHaveLeagues || isHaveGames) {
          const newDepthLevel = depthLevel + 1;
          const isSubMenuActive = (newDepthLevel === 2)
            ? (showSubSubMenu && subSubMenuId === menuItem.id)
            : (showSubMenu && subMenuId === menuItem.id);

          return (
            Boolean(displaySport) &&
            <li key={menuItem.id} className={isTopMenuActive(menuItem.id) && menuItem.Name ? "active" : ""}>
              <a
                href={`/${type}/${menuItem.id}`}
                onClick={(e) => this.handleToggle(e, menuItem.id, newDepthLevel)}
                className={isSubMenuActive ? 'opened leagues' : 'leagues'}
              >
                {menuItem.Name || menuItem.name} ({itemsCount})
              </a>
              <ul
                className={isSubMenuActive ? 'show' : ''}
              >
                {isHaveGames ? this.buildMenu(menuItem.EsportGames, newDepthLevel) : this.buildMenu(menuItem.Leagues, newDepthLevel)}
              </ul>
            </li >)
        }

        return (
          Boolean(displaySport) &&
          <li key={menuItem.id} className={isTopMenuActive(menuItem.id) && menuItem.Name ? "active" : ""}>
            <NavLink
              to={`/${type}/${menuItem.Name ? menuItem.id : subMenuId}/${menuItem.Name ? '' : menuItem.name}`}
            >{menuItem.Name || menuItem.name} ({itemsCount})
            </NavLink>
          </li >);
      })
    );
  }

  render() {
    const { sports = [] } = this.props;

    return (
      this.buildMenu(sports)
    );
  }
}

export default withRouter(SubMenu);

SubMenu.propTypes = {
  location: PropTypes.object,
  sports: PropTypes.array,
  type: PropTypes.string
};
