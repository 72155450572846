import React from 'react';
import './style.scss';
import ScrollableAnchor from 'react-scrollable-anchor'
import { isIE } from '../../../util/browserDetectors';


const AboutUs = () => {
  return (
    <ScrollableAnchor id={'about'}>
      <section className={`landing-about-us ${isIE ? 'ie' : ''}`}>
        <div className="container">
          <div className="row pawerfull_area_inner">
            <div className="col-md-5">
              <div className="power_left_img">
                <img src="./img/landing/sportstrader-feed.png" alt="" />
              </div>
            </div>
            <div className="col-md-7">
              <div className="power_right_content">
                <div className="power_right_content_inner">
                  <h3 className="single_title">OUR VALUES</h3>
                  <div className="content">
                    <p>As a company we see injustice the way current handicappers customer process works. Most of handicappers/consultant do not play fair. They create a customer base and then when they create a picks they would sell predictions for opposite  events for two parts of their audience. This way at least half of their audience is happy and they look a real deal, but in dead this is just a scam they use.</p>
                    <p>We are trying to fix this injustice and make sure that every handicapper on our platform can create only on pick for one event, this way he is either good consultant or bad one.</p>
                    <p>Another deformation that we see is that most of the handicappers claim that they win almost all of picks they make, but there is no way to check that until you actually pay them money. We are fixing this problem by providing a comprehensive public statistics for each consultant. So any user can check how good consultant is in each sport before he actually buys from him.</p>
                    <p>We have a big idea to disrupt hegemony of handicapper/consultants and asking you to join our journey here and now!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ScrollableAnchor>
  );
};

export default AboutUs;
