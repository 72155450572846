import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


class SalesRow extends Component {
  static propTypes = {
    handlePromoteContent: PropTypes.func,
    sales: PropTypes.object
  };

  handleClickPromote = (e) => {
    e && e.preventDefault();
    const { itemId, Pick } = this.props.sales;
    const { handlePromoteContent } = this.props;
    handlePromoteContent(itemId, Pick);
  }

  getSubscriptionState = (subscription) => {
    console.log(subscription)
    if (subscription.status == 1) return 'Active'
    if (subscription.status == 0) return 'Active'
    if (subscription.status == 6) return 'Approved'
    return 'Pending'
  }

  render() {
    const { itemId, buyCount, Pick = {}, price } = this.props.sales;
    let { Parley = {}, Bundle = {}, ConsultantSubscription = {} } = this.props.sales;

    if (Parley === null) {
      Parley = {}
    }

    if (Bundle === null) {
      Bundle = {}
    }



    const { Match = {} } = Pick;
    let status;
    let Name = null;
    let updatedAt = null;
    let itemType;

    if (Object.keys(Pick).length) {
      itemType = 'pick';
      ({ status, updatedAt } = Pick);
    } else if (Parley && Object.keys(Parley).length) {
      itemType = 'parlay';
      ({ status, Name, updatedAt } = Parley);
    } else if (Bundle && Object.keys(Bundle).length) {
      itemType = 'bundle';
      ({ status, Name, updatedAt } = Bundle);
    } else if (ConsultantSubscription && Object.keys(ConsultantSubscription).length) {
      itemType = 'subscription';
      ({ status, Name, updatedAt } = ConsultantSubscription);
    }

    const { HomeTeam, AwayTeam, MatchTime } = Match;
    const notFinished = status === null || status === 0;
    const gameStarted = MatchTime && new Date(MatchTime) < new Date();
    const shouldRenderButton = notFinished && (!Pick.Sponsored || !Parley.Sponsored || !Bundle.Sponsored) && !gameStarted;

    return (
      <tr>
        <td colSpan="1">{buyCount}</td>
        {!ConsultantSubscription && <td colSpan="1">{status ? 'Finished' : 'Pre Game'}</td>}
        {ConsultantSubscription && < td colSpan="1">
          {this.getSubscriptionState(ConsultantSubscription)}
        </td>}
        <td colSpan="3">
          <Link to={`/${itemType}/${itemId}`}>
            {itemType === 'pick' ? `${AwayTeam} vs ${HomeTeam}` : Name}
          </Link>
        </td>
        <td colSpan="1">${price * 1.25}</td>
        <td colSpan="1">${buyCount * Number.parseFloat(price)} </td>
        <td colSpan="2">{MatchTime ? moment(MatchTime).format('MMM Do YYYY') : 'Multiple games'} </td>
        <td colSpan="2">{moment(updatedAt).format('MMM Do YYYY')} </td>
        <td colSpan="1">{shouldRenderButton && <button className="btn btn-sm btn-warning" onClick={this.handleClickPromote}>Promote </button>}</td>
      </tr >
    );
  }
}

export default SalesRow;
