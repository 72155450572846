import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Wrapper from "../components/ui/Wrapper";

const Privacy = () => {
  return (
    <Wrapper className="404-page" title="Privacy policy" icon="fa fa-lock">
      <p>
        <b>
          This Privacy Notice is an evolving document where terms and policies
          can change without notice. Please check this notice frequently. Due to
          constant law changes the members of NXT SportsTrader® are fully
          responsible to know the laws in your jurisdiction of your residence.
        </b>
      </p>
      <p>
        <b>
          Members are defined by all users/consumers/consultants who have a
          verified account with paypal and are active users of the SportsTrader®
          platform.
        </b>
      </p>
      <p>
        <b>
          Certain privacy rights may not be afforded to those who visit our site
          but are not members.
        </b>
      </p>

      <h4>
        <b>1. Who we are</b>
      </h4>
      <p>
        Your personal information is collected by NEXT GENERATION, “NXT TECH
        LLC”, a company registered in Delaware, United States of America with a
        registration number [7273645] and our affiliates ("we"/"us"/"our").
      </p>

      <p>
        <b>NXT SportsTrader®</b> contact <b>information and protocols</b> are as
        follows:
      </p>
      <p>
        <b>Email:</b> privacy@SportsTrader.pro
      </p>
      <p>
        <b>Platform Direct Messaging/Chat:</b>
        You may contact us by sending a message to our customer service
        department in a{" "}
        <b>
          real time message that is private to alleviate any concerns about
          privacy or other customer service issues.
        </b>
      </p>
      <p>
        If you have questions, please contact the Data Protection Officer at:
      </p>
      <p>
        <b>Email:</b> privacy@SportsTrader.pro
      </p>
      <p>
        This Privacy Notice also covers our group of companies. We share
        information about you with other members of our group of companies so we
        can provide the best service across our group. They are bound to keep
        and use your information in accordance with this Privacy Notice:
      </p>
      <ul>
        <li>
          <b>
            Affiliates that includes casinos, online and brick and mortar, so
            special offers and better odds could be available exclusively to NXT
            SportsTrader® members;
          </b>
        </li>
        <li>
          <b>
            NXT, a Limited Liability Company organized under the laws of the
            State of Delaware.
          </b>
        </li>
      </ul>
      <h4>
        <b>2. Data collection and usage</b>
      </h4>
      <p>
        <b>Paypal will keep all payment information private.</b>
      </p>
      <p>
        <b>
          NXT SportsTrader® will never obtain or keep any payment information
          and NXT Sports Trader will never ask you for your payment information.
        </b>
      </p>
      <p>
        <b>
          If there is a concern of any illegal activity we will contact Paypal
          to investigate this further and work closely with them without
          jeopardizing your personal and private information.
        </b>
      </p>
      <p>
        Your information may be shared with members of our group of companies
        and some third parties as is set out in more detail below.
      </p>
      <p>
        <b>
          Public data: Public data is defined by your public profile, all free
          picks, all public chats, all public post including pictures will be
          accessible to anyone including any API’s. For instance casinos,
          affiliates and other partners may stream any PUBLIC data content to
          their own sites to increase excitement and engagement to our platform.
        </b>
      </p>
      <p>
        <b>
          Private Data: Private data is defined by any communication with the
          NXT SportsTrader® company, private messaging, private/premium picks
          and any type of subscriptions that are being sold on NXT SportsTrader®
          platform.
        </b>
      </p>

      <p>What information we collect:</p>
      <p>
        Information that we collect through <b>your use of our website</b>{" "}
        including:
      </p>
      <ul>
        <li>
          Device information such as operating system, unique device
          identifiers, the mobile network system;
        </li>
        <li>Hardware and browser settings;</li>
        <li>Date and time of requests;</li>
        <li>The requests you make;</li>
        <li>The pages you visit and search engine terms you use;</li>
        <li>Location information;</li>
        <li>IP address;</li>
        <li>Pick history.</li>
      </ul>
      <p>
        <b>How we use your data:</b>
      </p>
      <p>
        <b>We use this information, including to:</b>
      </p>
      <ul>
        <li>
          <b>
            Provide and adapt our services to take account of the technical
            capabilities of our users;
          </b>
        </li>
        <li>
          <b>
            Develop new services based on the information being collected, the
            behaviours of our users and the technical capabilities of our users;
          </b>
        </li>
        <li>
          <b>
            Improve our services to better suit the behaviours and technical
            capabilities of the users of our service;
          </b>
        </li>
        <li>
          <b>
            Identify issues with the website and our users' experience of it;
          </b>
        </li>
        <li>
          <b>
            Monitor the way our website is used (including locations it is
            accessed from, devices it is accessed from, understanding peak usage
            times and analysing what functionality and information is most and
            least accessed);
          </b>
        </li>
        <li>
          <b>
            We use third party tools, including programmatic advertising, and
            retargeting, to make the advertising we show you on our Website
            and/or our partner websites more relevant and interesting to you.
            For this purpose, we use cookies, web beacons or similar
            technologies <span style={{ color: "orange" }}>cross-device</span>{" "}
            to collect information about your browsing behaviour (see our
            Cookies Policy for more detailed information) and deliver
            interest-based ads to you.
            <span style={{ color: "orange" }}>
              {" "}
              We also ensure that mechanisms are made available to end-users to
              opt out of such data collection in both browsers and mobile
              applications.
            </span>
          </b>
        </li>
      </ul>
      <p>
        <b>Why we use your data:</b>
      </p>
      <p>
        <b>We have a legitimate interest in managing our business including:</b>
      </p>
      <ul>
        <li>
          <b>
            understanding how our customers use our products, services and
            websites;
          </b>
        </li>
        <li>
          <b>researching and analysing the services our customers want;</b>
        </li>
        <li>
          <b>improving our services;</b>
        </li>
        <li>
          <b>providing security over our business and who we trade with;</b>
        </li>
        <li>
          <b>ensuring network and information security;</b>
        </li>
        <li>
          <b>fraud prevention and detection;</b>
        </li>
        <li>
          <b>
            assessing the number of visitors and page views in order to optimise
            future marketing campaigns;
          </b>
        </li>
        <li>
          <b>
            providing advertising space on our Website to third party
            advertisers and providing information to third parties to ensure
            that to provide relevant adverts to you.
          </b>
        </li>
      </ul>
      <p>
        <b>
          Information provided when you become a member and sign up to our
          services that includes having a verified account on PayPal which means
          you have subscribed to our services and as part of your account with
          us including:
        </b>
      </p>
      <ul>
        <li>
          <b>Your username;</b>
        </li>
        <li>
          <b>Your password;</b>
        </li>
        <li>
          <b>Your account settings;</b>
        </li>
        <li>
          <b>Your account preferences;</b>
        </li>
        <li>
          <b>Your email address;</b>
        </li>
        <li>
          <b>Your age;</b>
        </li>
        <li>
          <b>Your gender;</b>
        </li>
        <li>
          <b>Your occupation;</b>
        </li>
        <li>
          <b>
            When you use our forums, if you use Social Media Single Sign-on,
            your Facebook name or Twitter handle, date of birth and language
            preferences;
          </b>
        </li>
        <li>
          <b>
            If at anytime you post anything in a public forum, chat, post or
            anything that can be considered public you do so at your own risks
            and do not hold NXT SportsTrader® and our affiliates/partners
            liable.
          </b>
        </li>
      </ul>
      <p>
        <b>We use this info because:</b>
      </p>
      <ul>
        <li>
          <b>Provide our products and services;</b>
        </li>
        <li>
          <b>Manage and administer our systems;</b>
        </li>
        <li>
          <b>
            To communicate with you regarding your account and our services;
          </b>
        </li>
        <li>
          <b>
            To market our goods and services to you, including via newsletters
            and emails relating to e-commerce sales, non e-commerce promotions
            and product launches;
          </b>
        </li>
        <li>
          <b>To identify instances of account impersonation;</b>
        </li>
        <li>
          <b>
            Where you have provided consent, to provide your personal data to
            third parties so that they make market their goods or services to
            you, and/or to market third parties' goods or services to you.
          </b>
        </li>
      </ul>
      <p>
        <b>
          We use this information because this is necessary to enter into or
          perform our contract with you. For example, we use your account
          details to supply our services to you, to communicate with you, and to
          enable you to log in to your account and use our services.
        </b>
      </p>
      <p>
        <b>
          We use this information because this is necessary as in the public
          interest e.g. to prevent, investigate and/or report fraud.
        </b>
      </p>
      <p>
        <b>
          In addition, we have a good faith legitimate interest in: using your
          information to market our goods and services to you; and maintaining
          suppression records to ensure we have a record of your objection to
          direct marketing (if given). In addition, we use this information
          where we have collected your consent – for example, in order to send
          you direct marketing messages from or relating to third parties. You
          can withdraw your consent at any time (in your account preferences or
          settings) or by contacting the Data Protection Officer.
        </b>
      </p>
      <p>
        <b>
          Our third party marketing partners use this information where we have
          collected your consent to do so.
        </b>
      </p>
      <p>
        <b>Information about the way you use our services including:</b>
      </p>
      <ul>
        <li>The things that we've provided or you've purchased;</li>
        <li>When and where you've made the purchases;</li>
        <li>What you paid and how;</li>
        <li>Whether you've opened electronic communications from us;</li>
        <li>
          Whether you've clicked on links in electronic communications from us;
        </li>
        <li>Your marketing preferences.</li>
      </ul>
      <p>
        <b>We use this information, including to:</b>
      </p>
      <ul>
        <li>
          Develop new services based on what we learn from your activity and the
          activity of other customers;
        </li>
        <li>
          Improve our services and efficiency of service delivery to help us
          meet your needs and expectations;
        </li>
        <li>Identify products and marketing that may be of interest to you;</li>
        <li>
          Personalise our service to you: to provide you with, make available,
          and show you more of the things which interest you, based on your
          activity and feedback;
        </li>
        <li>
          Do statistical analysis and research with the purpose of allowing us
          to better understand the breakdown of our customers, the products /
          services being bought, the demographics of those buying our products
          and services, what attracts our customers to our products and
          services, where our customers have come from online (such as from
          links on other websites or advertising banners), and the way in which
          our website is used by different users groups.
        </li>
      </ul>
      <p>
        <b>We have a good faith interest in managing our business including:</b>
      </p>
      <ul>
        <li>
          understanding how our customers use our products, services and
          websites;
        </li>
        <li>researching and analysing the services our customers want;</li>
        <li>improving our services;</li>
        <li>
          understanding the type of products and services our customers buy and
          how they use them;
        </li>
        <li>
          assessing the number of visitors, page views etc in order to optimise
          future marketing campaigns.
        </li>
      </ul>
      <p>
        Information when you communicate with us whether in person, through our
        website or via email, over the phone, through social media or via any
        other medium, including:
      </p>
      <ul>
        <li>
          Your contact details (this may include your social media account if
          that's the way you communicate with us);
        </li>
        <li>
          The details of your communications with us (including when you sent
          it, when we received it and where you sent it from (such as our
          website, via email, through social media));
        </li>
        <li>The details of our messages to you.</li>
      </ul>

      <p>
        <b>We use this information, including to:</b>
      </p>
      <ul>
        <li>Answer any issues or concerns;</li>
        <li>Moderate our forums;</li>
        <li>
          Monitor customer communications for quality and training purposes;
        </li>
        <li>
          Develop new services which is based on the information your provide;
        </li>
        <li>
          Improve our services based on the information and feedback you provide
          and the information and feedback provided by others;
        </li>
        <li>
          Personalise our service to you to take account of the information and
          feedback you have provided;
        </li>
        <li>Verify your identity when you contact us.</li>
      </ul>
      <p>
        <b>
          We use this information because this is necessary to enter into or
          perform our contract with you.
        </b>
      </p>
      <p>
        <b>We have a legitimate interest in managing our business including:</b>
      </p>
      <ul>
        <li>understanding and responding to customer feedback;</li>
        <li>improving our services;</li>
        <li>
          personalising our offers so we're better able to provide offers and
          ideas that are relevant to you.
        </li>
      </ul>
      <p>
        <b>
          We use this information because it is necessary in the public
          interest, for example, undertaking anti-fraud screening and verifying
          our customer's identities.
        </b>
      </p>
      <p>
        <b>
          Information that we collect from individuals representing
          organisations, including:
        </b>
      </p>
      <ul>
        <li>Contact details of individuals working for organisations;</li>
        <li>Other personal information regarding such individuals.</li>
      </ul>

      <p>
        <b>We use this information, including to:</b>
      </p>
      <ul>
        <li>Build relationships with other organisations;</li>
        <li>Provide marketing communications to these individuals;</li>
        <li>
          Improve our services and develop new services based on the preferences
          and behaviours of these individuals.
        </li>
      </ul>
      <p>
        <b>
          We have a legitimate business interest in managing our business
          including:
        </b>
      </p>
      <ul>
        <li>
          developing and maintaining relationships with vendors, partners and
          other companies;
        </li>
        <li>dealing with individuals who work for them;</li>
        <li>direct marketing to business contacts;</li>
        <li>ensuring that debts are paid;</li>
        <li>providing security over our business and who we trade with.</li>
      </ul>
      <p>
        <b>
          We also collect, use and share Aggregated Data such as statistical or
          demographic data for any purpose. Aggregated Data may be derived from
          your personal data but is not considered personal data in law as this
          data does not directly or indirectly reveal your identity. For
          example, we may aggregate information about how you use our website,
          products and services to calculate the percentage of users accessing a
          specific website feature. However, if we combine or connect Aggregated
          Data with your personal data so that it can directly or indirectly
          identify you, we treat the combined data as personal data which will
          be used in accordance with this privacy notice.
        </b>
      </p>
      <h4>
        <b>3. Legal requirements</b>
      </h4>
      <p>
        <b>
          We (or our merchants on our behalf) need to collect certain types of
          information for compliance with legal requirements relating to our
          anti-fraud and know your customer obligations. If this information is
          not provided we cannot agree to provide a product or service for you.
        </b>
      </p>
      <p>
        <b>
          Your personal information may also be processed if it is necessary on
          reasonable request by a law enforcement or regulatory authority, body
          or agency or in the defence of a legal claims. We will not delete
          personal information if relevant to an investigation or a dispute. It
          will continue to be stored until those issues are fully resolved.
        </b>
      </p>
      <p>
        <b>How long do we keep your information</b>
      </p>
      <p>
        We will keep your information for as long as it is reasonably necessary.
        It will depend on factors such as whether you've any outstanding
        purchases or an account with us or have interacted with recent offers.
        We will also retain your information as necessary to comply with legal,
        accounting or reporting requirements.
      </p>
      <p>
        We will also routinely refresh our information to ensure we keep it
        up-to-date.
      </p>
      <p>
        It is important that the personal data we hold about you is accurate and
        current. Please keep us informed if your personal data changes during
        your relationship with us.
      </p>
      <p>
        <b>Information we share</b>
      </p>
      <p>
        There are certain circumstances where we transfer your personal data to
        employees, contractors and to other parties:
      </p>
      <ul>
        <li>
          As noted above, we share information about you with other members of
          our group of companies;
        </li>
        <li>
          we also share your information with certain suppliers, contractors or
          service providers. They may process your personal data for us. Our
          service providers include credit card merchants, CRM platforms,
          customer service software providers, surveys, e-commerce fulfilment
          suppliers, and cloud data storage suppliers. Other service providers
          include programmatic advert integrators, IT suppliers, database
          providers and backup and disaster recovery specialists;
        </li>
        <li>
          Our suppliers and service providers will be required to meet our
          standards on processing information and security. The information we
          provide them, including your information, will only be provided in
          connection with the performance of their function. They will not be
          permitted to use your information for any purposes other than those
          outlined in this Privacy Notice;
        </li>
        <li>
          We also share your information with certain third parties. We will do
          this either when we receive your consent or because we need them to
          see your information to provide products or services to you. These
          include credit reference agencies, anti-fraud databases, screening
          agencies and other partners we do business with.
        </li>
      </ul>
      <p>
        <b>
          Your personal information may be transferred to other third party
          organisations in certain scenarios:
        </b>
      </p>
      <ul>
        <li>
          If we discuss selling or transferring part or all of our business –
          the information may be transferred to prospective purchasers under
          suitable terms as to confidentiality;
        </li>
        <li>
          If we are reorganised or sold, information may be transferred to a
          buyer who can continue to provide services to you;
        </li>
        <li>
          If we're required to by law, or under any regulatory code or practice
          we follow, or if we are asked by any public or regulatory authority –
          for example the Police;
        </li>
        <li>
          If we are defending a legal claim your information may be transferred
          as required in connection with defending such claim.
        </li>
      </ul>
      <p>
        <b>Your information will be stored at:</b>
      </p>
      <p>
        <h5>
          <b>
            16192 Coastal Highway
            <br />
            Lewes, DE 19958
          </b>
        </h5>
      </p>
      <h4>
        <b>Your Rights</b>
      </h4>
      <p>
        You have certain rights in relation to your information. The
        availability of these rights and the ways in which you can use them are
        set out below in more detail. Some of these rights will only apply in
        certain circumstances. If you would like to exercise, or discuss, any of
        these rights, please contact the Data Protection Officer at
        privacy@SportsTrader.pro
      </p>
      <ul>
        <li>
          <b>Access:</b> you are entitled to ask us if we are processing your
          information and, if we are, you can request access to your personal
          information. This enables you to receive a copy of the personal
          information we hold about you and certain other information about it
          to check that we are lawfully processing it.
        </li>
        <li>
          <b>Correction:</b> you are entitled to request that any incomplete or
          inaccurate personal information we hold about you is corrected.
        </li>
        <li>
          <b>Erasure:</b> you are entitled to ask us to delete or remove
          personal information in certain circumstances. There are also certain
          exceptions where we may refuse a request for erasure, for example,
          where the personal data is required for compliance with law or in
          connection with claims.
        </li>
        <li>
          <b>Restriction:</b>you are entitled to ask us to suspend the
          processing of certain of your personal information about you, for
          example if you want us to establish its accuracy or the reason for
          processing it.
        </li>
        <li>
          <b>Transfer:</b> you may request the transfer of certain of your
          personal information to another party.
        </li>
        <li>
          <b>Objection:</b> where we are processing your personal information
          based on a legitimate interests (or those of a third party) you may
          challenge this. However we may be entitled to continue processing your
          information based on the our legitimate interests or where this is
          relevant to legal claims. You also have the right to object where we
          are processing your personal information for direct marketing
          purposes.
        </li>
        <li>
          <b>Automated decisions:</b> We do not currently make any automated
          decisions. However, if we did, you may contest any automated decision
          made about you where this has a legal or similar significant effect
          and ask for it to be reconsidered.
        </li>
      </ul>
      <p>
        If you want to exercise any of these rights, please contact our Data
        Protection Officer in writing at privacy@SportsTrader.pro
      </p>
      <h5>
        <b>Right to object</b>
      </h5>
      <p>
        You have a right to object to us processing your information in certain
        circumstances.
      </p>
      <p>
        <b>Objection:</b> where we are processing your personal information
        based on a legitimate interests (or those of a third party) you may
        challenge this. However we may be entitled to continue processing your
        information based on the our legitimate interests or where this is
        relevant to legal claims. You also have the right to object where we are
        processing your personal information for direct marketing purposes.
        <br />
        <b>You can ask us to stop processing</b> your information at any time.
        In certain circumstances we may not be able to do this or may not be
        required to do this. For example, if the information is relevant to
        legal claims.
      </p>
      <h5>
        <b>Direct Marketing</b>
      </h5>
      <p>
        As described above, you can opt-out of receiving direct marketing from
        us at any time.
      </p>
      <p>
        We may use the information you give us on our website/app for direct
        marketing purposes to provide updates; newsletters; events; or other
        communications that we think may interest you. We will only do this with
        your consent (where required by law).
      </p>
      <p>
        You can opt-out of receiving directing marketing from us at any time.
        You can do this by changing your marketing preferences on your online
        accounts settings page, clicking on the "unsubscribe" link included at
        the end of any marketing email we send to you, or by contacting the Data
        Protection Officer at privacy@SportsTrader.pro
      </p>

      <h5>
        <b>Analysis and Marketing</b>
      </h5>
      <p>
        We use third party tools to personalise the advertising displayed to you
        on our website/app.
      </p>
      <p>
        We use third party tools, including programmatic advertising, and
        retargeting, to make the advertising we show you on our Website and/or
        our partner websites more relevant and interesting to you.
      </p>
      <p>
        For this purpose, we use cookies, web beacons or similar technologies to
        collect information about your browsing behaviour (see our Cookies
        Policy for more detailed information) and deliver interest-based ads to
        you.
      </p>

      <h5>
        <b>Cookies</b>
      </h5>

      <p>
        We use cookies that identify your browser or device. They collect and
        store information when you visit our website about how you use it.
      </p>
      <p>
        For more information about cookies, the types of cookies we use and how
        we use them please see our:{" "}
        <NavLink to="/cookie-policy">Cookie Policy</NavLink>.
      </p>

      <h5>
        <b>Security</b>
      </h5>

      <p>
        We are committed to keeping your personal information safe. We've got
        physical, technical and administrative measures in place to prevent
        unauthorised access or use of your information.
      </p>
      <h5>
        <b>Link to third party websites</b>
      </h5>

      <p>
        Our website, newsletters, email updates and other communications may,
        from time to time, contain links to and from the websites of others
        including our partner networks, advertisers and other group companies
        and/or social networks as offered to you and supported by your browser.
      </p>
      <p>
        The personal data that you provide through these websites is not subject
        to this Privacy Policy and the treatment of your personal data by such
        websites is not our responsibility. If you follow a link to any of these
        websites, please note that these websites have their own privacy notices
        which will set out how your information is collected and processed when
        visiting those sites. For more information about the purpose and scope
        of data collection and processing in connection with social sharing
        features, please visit the privacy notices of entities through which you
        chose to share.
      </p>

      <h5>
        <b>Children</b>
      </h5>

      <p>
        We do not knowingly collect information from children or other persons
        who are under 18 years old. If you are under 18 years old, you must not
        submit any personal information to us or subscribe for the services. If
        you believe we might have any personal information from or about a
        person under 18 years old, please contact the Data Protection Officer.
        We do not allow anyone under the age of 18 to be on our platform
        SportsTrader.pro
      </p>

      <h4>
        <b>4. How can I manage or delete information about me?</b>
      </h4>
      <p>
        We provide you with the ability to access, rectify, port and erase your
        data. Contact info@sportstrader.pro to contact an administrator to
        request a deletion of your account. We store data until it is no longer
        necessary to provide our services and NXT Sportstrader Products, or
        until your account is deleted - whichever comes first. This is a
        case-by-case determination that depends on things like the nature of the
        data, why it is collected and processed, and relevant legal or
        operational retention needs. For example, when you search for something
        on Sportstrader.pro, you can access and delete that query from within
        your search history at any time, but the log of that search is deleted
        after 6 months. If you submit a copy of your government-issued ID for
        account verification purposes, we delete that copy 30 days after review,
        unless otherwise stated. Learn more about deletion of content you have
        shared and cookie data obtained through social plugins. When you delete
        your account, we delete things you have posted, such as your photos and
        status updates, and you won't be able to recover that information later.
        Information that others have shared about you isn't part of your account
        and won't be deleted.
      </p>
      <h4>
        <b>5. How do we respond to legal requests or prevent harm?</b>
      </h4>
      <p>
        We access, preserve and share your information with regulators, law
        enforcement or others:
        <li>
          In response to a legal request (like a search warrant, court order or
          subpoena) if we have a good faith belief that the law requires us to
          do so. This may include responding to legal requests from
          jurisdictions outside of the United States when we have a good-faith
          belief that the response is required by law in that jurisdiction,
          affects users in that jurisdiction, and is consistent with
          internationally recognized standards.
        </li>
        <li>
          When we have a good-faith belief it is necessary to: detect, prevent
          and address fraud, unauthorized use of the Products, violations of our
          terms or policies, or other harmful or illegal activity; to protect
          ourselves (including our rights, property or Products), you or others,
          including as part of investigations or regulatory inquiries; or to
          prevent death or imminent bodily harm. For example, if relevant, we
          provide information to and receive information from third-party
          partners about the reliability of your account to prevent fraud, abuse
          and other harmful activity on and off our Products.
        </li>
        <br />
        Information we receive about you (including financial transaction data
        related to purchases made on our Products) can be accessed and preserved
        for an extended period when it is the subject of a legal request or
        obligation, governmental investigation, or investigations of possible
        violations of our terms or policies, or otherwise to prevent harm. We
        also retain information from accounts disabled for terms violations for
        at least a year to prevent repeat abuse or other term violations.
      </p>
    </Wrapper>
  );
};

export default Privacy;

Privacy.propTypes = {
  text: PropTypes.string,
};
