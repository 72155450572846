import React,  { Component } from 'react';
import i from 'immutable';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import CONFIG from '../config/config';
import ProfilePick from "../components/users/ProfilePick/index";
import { connect } from "../store/context";
import { getMyPicksPregame, getMyPicksInprogress, getMyPicksFinal } from '../api/picks';
import { fetchMyPicksPregameBegin } from '../actions/picks';
import Wrapper from '../components/ui/Wrapper';
import Paginator from '../components/general/Paginator';
import EmptyPage from '../components/general/EmptyPage';
import Loader from '../components/ui/Loader/index';
import catchRunTimeError from '../util/ds-error';
import { alertErrorText } from '../actions/alerts';
import DropdownFilter from '../components/dropdowns/Filter'
import {fetchMySubscription} from '../api/subscription';

class MyPicks extends Component {
  constructor(props) {
    super(props);
    const { type: pickType } = props.match.params;
    this.state = {
      pickType,
      order: pickType === 'pregame' ? 'Match Time Asc' : 'Match Time Desc',
      picksPerPage: 10,
      showFilter: false,
      selectedSport: 'ALL',
      selectedSportId: null,
      selectePeriod: 'All Time',
      selectePeriodNumber: null,
      selectedSubscription: 'All',
      selectedSubscriptionId: 0,
    }
    this.socketConnection = new WebSocket(CONFIG.wss_client_connection_url);
    this.socketConnection.onopen = this.sendMessageToWS

    // Log errors
    this.socketConnection.onerror = catchRunTimeError;

    // Log messages from the server
    this.socketConnection.onmessage = this.receivedMsgFromWs;
    this.socketConnection.onclose = function (e) {
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
      setTimeout(function () {
        this.socketConnection = new WebSocket(CONFIG.wss_client_connection_url);
      }, 1000);
    }

  }

  componentDidMount() {
  
    const { id } = this.props.profile;
    const { dispatch, pregamePicks, location: { search } } = this.props;
    fetchMySubscription(dispatch)
    const { selectedSubscriptionId=0 } = queryString.parse(search);

    // begin loader if preGames not loaded yet
    if (!pregamePicks.length) {
      dispatch(fetchMyPicksPregameBegin());
    }
    if (selectedSubscriptionId)  {
      this.setState({selectedSubscriptionId})
      this.handleShowFilter()
    } //{ [Op.in]: Picks }
    if (id) {
      this.getData(id);
    }
    
    
  }

  sendMessageToWS = () => {
    //get user id
    const { id } = this.props.profile
    let data = JSON.stringify({"action":"sendMessage","page":`picks/my/${id}`})
    this.socketConnection.send(data);
  }
  receivedMsgFromWs = () => {
  }

  componentWillReceiveProps(nextProps) {
    const { emailVerified = null } = nextProps.profile;
    const { history } = this.props;

    if (emailVerified === false) {
      alertErrorText("Your email is not confirmed. Please check you email box and click confirmation link. If you did not get email, please use 'Resend email' button below.");
      history.goBack();
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch, location: { search }, profile: { id }, match: { params: { type }}} = this.props;
    const { picksPerPage, selectedSportId, selectePeriodNumber, order } = this.state;
    const { page = 1, selectedSubscriptionId} = queryString.parse(search);
    if (type !== prevProps.match.params.type) {
      //eslint-disable-next-line react/no-did-update-set-state
      this.setState({ pickType: type });
      type === 'pregame' && getMyPicksPregame(dispatch, page, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
      type === 'final' && getMyPicksFinal(dispatch, page, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
      type === 'inprogress' && getMyPicksInprogress(dispatch, page, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
    }

    if (id !== prevProps.profile.id) {
      this.getData(id);
    }
    
  }

  componentWillUnmount = () => {
    this.socketConnection.close()
  }

  getData = (id) => {
    const { dispatch, location } = this.props;
    const { picksPerPage, selectedSportId, selectePeriodNumber, order } = this.state;
    const { page = 1, selectedSubscriptionId=0 } = queryString.parse(location.search);

    getMyPicksPregame(dispatch, page, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
    getMyPicksFinal(dispatch, page, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
    getMyPicksInprogress(dispatch, page, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
  }

  handleClickOnTab = (e) => {
    e && e.preventDefault();
    const pickType = e.currentTarget.attributes.picktype.value;
    const { selectedSubscriptionId } = this.state;
    const { history } = this.props;
    history.push(`/my-picks/${pickType}?page=1&selectedSubscriptionId=${selectedSubscriptionId}`);
    this.setState({ pickType });
    pickType === 'pregame' && this.setState({ order: 'Match Time Asc' });
    pickType === 'inprogress' && this.setState({ order: 'Match Time Desc' });
    pickType === 'final' && this.setState({ order: 'Match Time Desc' });
  }

  onChangeSorting = (event) => {
    const { pickType, picksPerPage, selectedSportId, selectePeriodNumber, selectedSubscriptionId } = this.state;
    const newOrder = event.target.innerText
    const { dispatch, location } = this.props;
    const { page = 1 } = queryString.parse(location.search);
    this.setState({ order: newOrder })

    if (pickType === 'pregame') {
      getMyPicksPregame(dispatch, page, picksPerPage, selectedSportId, selectePeriodNumber, newOrder, selectedSubscriptionId);
    } else if (pickType === 'final') {
      getMyPicksFinal(dispatch, page, picksPerPage, selectedSportId, selectePeriodNumber, newOrder, selectedSubscriptionId);
    } else if (pickType === 'inprogress') {
      getMyPicksInprogress(dispatch, page, picksPerPage, selectedSportId, selectePeriodNumber, newOrder, selectedSubscriptionId);
    }
  }

  sortPicks = (picks) => {
    const { order } = this.state;

    switch (order.toLowerCase().replace(/ /g, '')) {
      case 'matchtimedesc':
        return [].concat(picks).sort((a, b) => a.Match.MatchTime > b.Match.MatchTime ? -1 : 1);

      case 'matchtimeasc':
        return [].concat(picks).sort((a, b) => a.Match.MatchTime < b.Match.MatchTime ? -1 : 1);

      case 'homerotdesc':
        return [].concat(picks).sort((a, b) => a.Match.HomeROT > b.Match.HomeROT ? -1 : 1);

      case 'homerotasc':
        return [].concat(picks).sort((a, b) => a.Match.HomeROT < b.Match.HomeROT ? -1 : 1);

      case 'awayrotdesc':
        return [].concat(picks).sort((a, b) => a.Match.AwayROT > b.Match.AwayROT ? -1 : 1);

      case 'awayrotasc':
        return [].concat(picks).sort((a, b) => a.Match.AwayROT < b.Match.AwayROT ? -1 : 1);

      default:
        return picks;
    }
  }

  getSubscriptionNameById = (id) => {
    const {mySubscriptions} = this.props;
    for (let subscription of mySubscriptions) {
    if (subscription.id == id) {
      return `${subscription.id}-${subscription.name}`
    }
      
    }
    return 'ALL'
  }
  filterBySport = (items) => {
    const { selectedSportId } = this.state;

    if (selectedSportId !== null) {
      items = items.filter((pick) => pick.Match.Sport === Number(selectedSportId));
    }

    return items;
  }

  onClickPagination = async (event) => {
    const page = event.target.innerText;
    const { pickType, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId } = this.state;
    const { dispatch, pregamePicksPages, inprogressPicks, history, finalPicksPages } = this.props;
    let pages = 0;

    if (pickType === 'inprogress') {
      pages = Math.ceil(inprogressPicks.length / picksPerPage);
    } else if (pickType === 'pregame') {
      pages = pregamePicksPages;
    } else {
      pages = finalPicksPages;
    }

    let newPage = 1;

    if (page === 'First') {
      newPage = 1;
    } else if (page === 'Last') {
      newPage = pages;
    } else {
      newPage = Number(page) || 1;
    }

    history.push(`/my-picks/${pickType}?page=${newPage}&selectedSubscriptionId=${selectedSubscriptionId}`);

    if (pickType === 'final') {
      getMyPicksFinal(dispatch, newPage, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
    } else if (pickType === 'pregame') {
      getMyPicksPregame(dispatch, newPage, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
    } else if (pickType === 'inprogress') {
      getMyPicksInprogress(dispatch, newPage, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
    }
  }

  handleShowFilter = () => {
    const { showFilter } = this.state;
    this.setState({ showFilter: !showFilter })
  }

  getSportIdByName = (name) => {
    const { sports } = this.props;
    let selectedSportId;

    if (name === 'ALL') {
      return null;
    }

    sports.forEach((element) => {
      if (element.Name.toLowerCase() === name.toLowerCase()) {
        selectedSportId = element.id;
      }
    });

    return selectedSportId;
  }

  onChangeSport = (event) => {
    const { pickType, picksPerPage, selectePeriodNumber, order, selectedSubscriptionId } = this.state;
    const { dispatch, history } = this.props;
    const selectedSportName = event.target.innerText;
    const selectedSportId = this.getSportIdByName(selectedSportName)
    this.setState({ selectedSport: selectedSportName, selectedSportId });

    if (pickType === 'final') {
      getMyPicksFinal(dispatch, 1, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
    } else if (pickType === 'pregame') {
      getMyPicksPregame(dispatch, 1, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
    }

    history.push(`/my-picks/${pickType}?page=1`);
  }
  onChangeSubscription = (event) => {
    let subscriptionName = event.target.innerText;
    const details = subscriptionName.split('-')
    let selectedSubscriptionId = 0
    if (subscriptionName != 'ALL'){
      subscriptionName = details[1]
      selectedSubscriptionId = details[0] 
    }
    this.setState({selectedSubscription: subscriptionName,  selectedSubscriptionId: selectedSubscriptionId})
    const { pickType, picksPerPage, selectedSportId, order, selectePeriodNumber} = this.state;
    const { dispatch, history } = this.props;
    if (pickType === 'final') {
      getMyPicksFinal(dispatch, 1, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
    } else if (pickType === 'pregame') {
      getMyPicksPregame(dispatch, 1, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
    } else if (pickType === 'inprogress') {
      getMyPicksInprogress(dispatch, 1, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
    }
  }

  onChangeDate = (event) => {
    const { pickType, picksPerPage, selectedSportId, order, selectedSubscriptionId } = this.state;
    const { dispatch, history } = this.props;
    const selectedDate = event.target.innerText;
    let [selectePeriodNumber] = selectedDate.split(' ');
    selectePeriodNumber = Number(selectePeriodNumber);
    this.setState({selectePeriod: selectedDate, selectePeriodNumber});

    if (pickType === 'final') {
      getMyPicksFinal(dispatch, 1, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
    } else if (pickType === 'pregame') {
      getMyPicksPregame(dispatch, 1, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
    } else if (pickType === 'inprogress') {
      getMyPicksInprogress(dispatch, 1, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
    }

    history.push(`/my-picks/${pickType}?page=1`);
  }

  checkAndUpdatePickList = (pickId) => {
    const { pregamePicks, dispatch, location } = this.props;
    const { picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId } = this.state;
    const { page = 1 } = queryString.parse(location.search);

    if (pregamePicks.length > 0) {
      pregamePicks.forEach((pick) => {
        if (pick.id === pickId) {
          getMyPicksPregame(dispatch, page, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
        }
      });
    }
  };

  updateFinalPicks = (userId) => {
    const { picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId } = this.state;
    const { dispatch, profile: { id } } = this.props;

    if (userId !== id) {
      return;
    }

    getMyPicksFinal(dispatch, 1, picksPerPage, selectedSportId, selectePeriodNumber, order, selectedSubscriptionId);
  }

  render() {
    const { pickType, order, picksPerPage, showFilter, selectedSport, selectePeriod, selectedSubscriptionId } = this.state;
    const { pregamePicks, inprogressPicks, finalPicks, totalPicksSize, location, finalPicksCount,
      finalPicksPages, loadingPreGame, loadingInProgress, loadingFinal, sports, pregamePicksPages,
      pregamePicksCount, mySubscriptions } = this.props;
    const { page = 1 } = queryString.parse(location.search);
    let loading = false;
    let picks = [];

    switch (pickType) {
      case 'pregame':
        picks = pregamePicks;
        loading = loadingPreGame;
        break;
      case 'inprogress':
        picks = inprogressPicks;
        loading = loadingInProgress;
        break;
      case 'final':
        picks = finalPicks;
        loading = loadingFinal;
        break;
      default:
        break;
    }

    let sortedPicks = [];

    if (picks.length > 0 && pickType === 'inprogress') {
      picks = this.filterBySport(picks);
      sortedPicks = this.sortPicks(picks);
    } else {
      sortedPicks = picks;
    }

    let pages = 0;

    if (pickType === 'final') {
      pages = finalPicksPages;
    } else if (pickType === 'pregame') {
      pages = pregamePicksPages;
    } else {
      pages = Math.ceil(sortedPicks.length / picksPerPage);

      if (sortedPicks.length > 0) {
        sortedPicks = sortedPicks.slice((page * picksPerPage) - picksPerPage, page * picksPerPage);
      }
    }

    const sportsNames = sports.map((sport) => sport.Name)
    sportsNames.unshift('ALL');
    const subscriptionNames = mySubscriptions.map((sub) =>`${sub.id}-${sub.name}`)
    subscriptionNames.unshift('All')
    const datesList = ['All Time', '1 Day', '7 Days', '14 Days', '30 Days'];
    const sortingList = ['Match Time Desc', 'Match Time Asc', 'Home ROT Desc', 'Home ROT Asc', 'Away ROT Desc', 'Away ROT Asc'];

    return (
      <Wrapper
        className="mypicks-page"
        title="My picks"
        counter={totalPicksSize}
        icon="fa fa-diamond"
      >
        <MediaQuery maxDeviceWidth={1199}>
          <Link to="/my-parlays" className="btn btn-default switch-btn"><i className="fa fa-diamond" />My Parlays</Link>
        </MediaQuery>
        <div className="panel panel-tab panel-tab-double shadow">
          <div className="panel-heading no-padding">
            <ul className="nav nav-tabs">
              <li className={`nav-border nav-border-top-danger ${pickType === 'pregame' ? 'active' : ''}`}>
                <a href onClick={this.handleClickOnTab} picktype="pregame" data-toggle="tab" aria-expanded="true">
                  <i className="fa fa-calendar fg-danger" />
                  <div>
                    <span className="text-strong">Pre-Game ({pregamePicksCount})</span>
                    <span>Not Started games</span>
                  </div>
                </a>
              </li>
              <li className={`nav-border nav-border-top-warning ${pickType === 'inprogress' ? 'active' : ''}`}>
                <a href onClick={this.handleClickOnTab} picktype="inprogress" data-toggle="tab" aria-expanded="true">
                  <i className="fa fa-clock-o fg-warning" />
                  <div>
                    <span className="text-strong">In Progress ({inprogressPicks.length})</span>
                    <span>Games in progress</span>
                  </div>
                </a>
              </li>
              <li className={`nav-border nav-border-top-success ${pickType === 'final' ? 'active' : ''}`}>
                <a href onClick={this.handleClickOnTab} picktype="final" data-toggle="tab" aria-expanded="true">
                  <i className="fa fa-flag-checkered fg-success" />
                  <div>
                    <span className="text-strong">Final ({finalPicksCount})</span>
                    <span>Finished games</span>
                  </div>
                </a>
              </li>
            </ul>
            <div className="filters-switcher" onClick={this.handleShowFilter}>
              <span>Filters</span>
              <i className={`fa ${showFilter ? 'fa-angle-up' : 'fa-angle-down'}`} />
            </div>
            <MediaQuery minDeviceWidth={1200}>
              <Link to="/my-parlays" className="btn btn-default switch-btn"><i className="fa fa-diamond" />My Parlays</Link>
            </MediaQuery>
          </div>
          {showFilter &&
          <div className="filters-panel">
            
                  <div className="form-group sports">
              <label htmlFor="DropdownFilter">Subscription:</label>
              <DropdownFilter itemsList={subscriptionNames} selectedItem={this.getSubscriptionNameById(selectedSubscriptionId)} handleSelect={this.onChangeSubscription} />
            </div>
            <div className="form-group">
              <label htmlFor="DropdownFilter">Sort by:</label>
              <DropdownFilter itemsList={sortingList} selectedItem={order} handleSelect={this.onChangeSorting} />
            </div>

            <div className="form-group sports">
              <label htmlFor="DropdownFilter">Sports:</label>
              <DropdownFilter itemsList={sportsNames} selectedItem={selectedSport} handleSelect={this.onChangeSport} />
            </div>

            {pickType !== 'inprogress' &&
            <div className="form-group">
              <label>Period:</label>
              <DropdownFilter itemsList={datesList} selectedItem={selectePeriod} handleSelect={this.onChangeDate} />
            </div>}
          </div>}
          {loading && <Loader />}
          {!loading && !sortedPicks.length && <EmptyPage />}
          {!loading && sortedPicks.length > 0 && <ProfilePick getData={this.getData} picks={sortedPicks} pickType={pickType} />}
        </div>
        {!loading && <Paginator pages={pages} currentPage={Number(page) || 1} handleClick={this.onClickPagination} />}
      </Wrapper>
    );
  }
}

function select(state) {
  const profile = state.getIn(['users', 'profile'], i.Map()).toJS();
  const pregamePicks = state.getIn(['picks', 'pregamePicks', 'picks'], i.List()).toJS();
  const pregamePicksPages = state.getIn(['picks', 'pregamePicks', 'pages'], 0);
  const pregamePicksCount = state.getIn(['picks', 'pregamePicks', 'count'], 0);
  const inprogressPicks = state.getIn(['picks', 'progressPicks', 'picks'], i.List()).toJS();
  const finalPicks = state.getIn(['picks', 'finalPicks', 'picks'], i.List()).toJS();
  const finalPicksCount = state.getIn(['picks', 'finalPicks', 'count'], 0);
  const finalPicksPages = state.getIn(['picks', 'finalPicks', 'pages'], 0);
  const totalPicksSize = pregamePicksCount + inprogressPicks.length + finalPicksCount;
  const loadingPreGame = state.getIn(['picks', 'loadingPreGame'], false);
  const loadingInProgress = state.getIn(['picks', 'loadingInProgress'], false);
  const loadingFinal = state.getIn(['picks', 'loadingFinal'], false);
  const sports = state.getIn(['sports', 'items'], i.List()).toJS();
  const mySubscriptions = state.getIn(['subscriptions', 'mySubscriptions'], i.List()).toJS();

  return {
    profile, pregamePicks, inprogressPicks, finalPicks, totalPicksSize, finalPicksCount,
    finalPicksPages, loadingPreGame, loadingInProgress, loadingFinal, sports, pregamePicksPages, pregamePicksCount, mySubscriptions
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(MyPicks);

MyPicks.propTypes = {
  dispatch: PropTypes.func,
  finalPicks: PropTypes.array,
  finalPicksCount: PropTypes.number,
  finalPicksPages: PropTypes.number,
  history: PropTypes.object,
  id: PropTypes.number,
  inprogressPicks: PropTypes.array,
  loadingFinal: PropTypes.bool,
  loadingInProgress: PropTypes.bool,
  loadingPreGame: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  pregamePicks: PropTypes.array,
  pregamePicksCount: PropTypes.number,
  pregamePicksPages: PropTypes.number,
  profile: PropTypes.object,
  sports: PropTypes.array,
  totalPicksSize: PropTypes.number
};
