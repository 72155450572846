import React, { Component } from 'react';
import i from 'immutable';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from "../store/context";
import { getAffiliateUsers, getAffiliateRevenue, getAffiliateLink } from "../api/payments";
import Wrapper from '../components/ui/Wrapper'
import AffiliateUser from '../components/users/AffiliateUser';


class Affiliate extends Component {
  static propTypes = {
    affiliateLink: PropTypes.string,
    affiliateRevenue: PropTypes.number,
    dispatch: PropTypes.func,
    users: PropTypes.array
  }

  state = {
    copied: false
  };

  componentDidMount = () => {
    const { dispatch } = this.props;
    getAffiliateUsers(dispatch);
    getAffiliateRevenue(dispatch);
    getAffiliateLink(dispatch);
  }

  render() {
    const { users, affiliateRevenue, affiliateLink } = this.props;
    const { copied } = this.state;
    const affiliateLinkGenerated = /^http/g.test(affiliateLink);

    return (
      <Wrapper
        className="affiliates-page"
        title="Affiliates board"
        icon="fa fa-calendar-check-o"
      >
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className="mini-stat-type-4 shadow">
              <h4>Users invited</h4>
              <h1>{users.length}</h1>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className="mini-stat-type-4 shadow">
              <h4>Revenue generated</h4>
              <h1>{affiliateRevenue === null ? 0 : affiliateRevenue.toFixed(2)}</h1>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="panel shadow panel-team-board">
              <div className="panel-heading">
                <h3 className="panel-title">Affiliate link</h3>
                <div className="affiliate-message">You will be getting 5% of any purchase made by user that signs up with this link</div>
                <div className="affiliate-link">
                  <input value={affiliateLink || 'Loading ...'} disabled />
                  {affiliateLinkGenerated &&
                    <CopyToClipboard
                      text={affiliateLink}
                      onCopy={() => this.setState({ copied: true })}
                    >
                      <button type="submit" className="btn btn-warning" disabled={copied}>{copied ? 'Copied' : 'Copy'}</button>
                    </CopyToClipboard>
                  }
                </div>

                <table>
                  <tbody>
                    {users.map((user) => {
                      return <AffiliateUser key={user.id} user={user} />;
                    })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

function select(state) {
  const users = state.getIn(['payments', 'affiliate_users'], i.List()).toJS();
  const affiliateRevenue = state.getIn(['payments', 'affiliate_revenue'], 0);
  const affiliateLink = state.getIn(['payments', 'affiliate_link'], '');
  return { users, affiliateRevenue, affiliateLink }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Affiliate);
