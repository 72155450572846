export const FETCH_MATCH_BEGIN   = 'FETCH_MATCH_BEGIN';
export const FETCH_MATCH_SUCCESS = 'FETCH_MATCH_SUCCESS';
export const FETCH_MATCH_FAILURE = 'FETCH_MATCH_FAILURE';
export const FETCH_MATCH_LIVE_DATA_BEGIN   = 'FETCH_MATCH_LIVE_DATA_BEGIN';
export const FETCH_MATCH_LIVE_DATA_SUCCESS = 'FETCH_MATCH_LIVE_DATA_SUCCESS';
export const FETCH_MATCH_LIVE_DATA_FAILURE = 'FETCH_MATCH_LIVE_DATA_FAILURE';

export const fetchMatchBegin = () => ({
  type: FETCH_MATCH_BEGIN
});

export const fetchMatchSuccess = (match) => ({
  type: FETCH_MATCH_SUCCESS,
  data: { match }
});

export const fetchMatchError = (error) => ({
  type: FETCH_MATCH_FAILURE,
  data: { error }
});

export const fetchMatchLiveDataBegin = () => ({
  type: FETCH_MATCH_LIVE_DATA_BEGIN
});

export const fetchMatchLiveDataSuccess = (competitions, status) => ({
  type: FETCH_MATCH_LIVE_DATA_SUCCESS,
  data: { competitions, status }
});

export const fetchMatchLiveDataError = (error) => ({
  type: FETCH_MATCH_LIVE_DATA_FAILURE,
  data: { error }
});
