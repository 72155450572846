import {
  updateFriendsTabActiveBegin,
  updateFriendsTabActiveSuccess,
  updateFriendsTabActiveError
} from '../actions/general';


export const updateFriendsTab = (dispatch, tabName) => {
  try {
    dispatch(updateFriendsTabActiveBegin());
    dispatch(updateFriendsTabActiveSuccess(tabName));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(updateFriendsTabActiveError(error));
  }
}
