import React from 'react';
import PropTypes from 'prop-types';
import "./style.scss";
import LinesEllipsis from 'react-lines-ellipsis';
import { Link } from 'react-router-dom';
import { setDefaultAvatar } from '../../../helpers/defaultImage';


const UserStatProfile = (props) => {
  const {
    avatar,
    firstName,
    lastName,
    id,
    followersCount,
    isCurrentUser = false,
    isFollowing,
    isAuthorized,
    bio,
    status,
    role,
    ROISpread,
    ROIMoneyLine,
    ROITotal,
    ROIDrawLine,
    DeviationSpread,
    DeviationTotal,
    onFollowUser,
    onUnFollowUser,
    showSendMessageForAll = false
  } = props;

  let buttonText = "Follow";
  let buttonClasses = "btn btn-warning";
  let buttonHandler = onFollowUser;
  const ROI = ROISpread + ROIMoneyLine + ROITotal + ROIDrawLine;
  const isConsultant = Boolean(role === "consultant");

  if (isFollowing) {
    buttonText = 'Unfollow';
    buttonClasses = "btn btn-default";
    buttonHandler = onUnFollowUser;
  }

  return (
    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 user-stats-profile">
      <div className="panel panel-bg-primary rounded shadow">
        <div className="panel-body">
          {status &&
            <div className="ribbon-wrapper top-left">
              <div className="ribbon ribbon-primary">{status}</div>
            </div>
          }
          <ul className="inner-all list-unstyled">
            <li className="image">
              <Link to={`/user/${id}`}>
                <img className="img-circle img-bordered-default hundred-px" src={avatar} alt={firstName} onError={setDefaultAvatar} />
              </Link>
            </li>
            <li className="username">
              <h4>
                <Link to={`/user/${id}`}>
                  {firstName
                    ? <span title={`${firstName} ${lastName}`.length > 18 ? `${firstName} ${lastName}` : ''}>{firstName} {lastName}</span>
                    : <span>Anonymous</span>}
                </Link>

                {(isFollowing || showSendMessageForAll) &&
                  <Link
                    className="send-message"
                    title="Send message"
                    to={`/chat?room=${id}`}
                  >
                    <i className="fa fa-fw fa-envelope" />
                  </Link>
                }
              </h4>
            </li>
            <li className="statistics">
              {isConsultant && <p><strong>Followers: </strong>{followersCount}</p>}
              {!isConsultant && <p><strong>Role: </strong>user</p>}
              <p><strong>ROI: </strong>{ROI ? ROI.toFixed(2) : 'Unknown'}</p>
              {isConsultant &&
                <span>
                  <p><strong>Deviation Spread: </strong>{DeviationSpread ? DeviationSpread.toFixed(2) : 'Unknown'}</p>
                  <p><strong>Deviation Total: </strong>{DeviationTotal ? DeviationTotal.toFixed(2) : 'Unknown'}</p>
                </span>}
            </li>
            <li className="bio">
              <span>
                {bio && <LinesEllipsis text={bio} maxLine="1" ellipsis="..." trimRight />}
              </span>
            </li>
            {isAuthorized && !isCurrentUser && isConsultant &&
              <li className="text-center">
                <a href userId={id} onClick={buttonHandler} className={buttonClasses}>{buttonText}</a>
              </li>}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default UserStatProfile;

UserStatProfile.propTypes = {
  DeviationSpread: PropTypes.number,
  DeviationTotal: PropTypes.number,
  ROIDrawLine: PropTypes.number,
  ROIMoneyLine: PropTypes.number,
  ROISpread: PropTypes.number,
  ROITotal: PropTypes.number,
  avatar: PropTypes.string,
  bio: PropTypes.string,
  firstName: PropTypes.string,
  followersCount: PropTypes.number,
  id: PropTypes.number,
  isAuthorized: PropTypes.bool,
  isCurrentUser: PropTypes.bool,
  isFollowing: PropTypes.bool,
  lastName: PropTypes.string,
  onFollowUser: PropTypes.func,
  onUnFollowUser: PropTypes.func,
  role: PropTypes.string,
  showSendMessageForAll: PropTypes.bool,
  status: PropTypes.string
}
