import React, { Component } from "react";
import { FacebookLoginButton, TwitterLoginButton } from 'react-social-login-buttons';
import { NavLink } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import CONFIG from '../../config/config';
import { connect } from "../../store/context";
import { login as paypalAuthLink, executeLogin as executePaypalLogin } from '../../api/paypal';
import { getProfile, login } from "../../api/users";
import Loader from '../ui/Loader/index';
import Wrapper from '../../components/ui/Wrapper';
import { alertError } from '../../actions/alerts'

class Login extends Component {
  static propTypes = {
    location: PropTypes.object
  };
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      redirectUrl: `${CONFIG.ui_home}`
    };
  }
  componentDidMount = async () => {
    const { dispatch, history, location: { search } } = this.props;
    const { redirectUrl, code, errorMsg } = queryString.parse(search);
    if (typeof errorMsg != 'undefined' && errorMsg != '') {
      alertError(errorMsg)
    }
    dispatch({ type: 'GET_USER_FAILURE' });

    if (redirectUrl) {
      this.setState({ redirectUrl });
    }

    if (code) {
      await executePaypalLogin(dispatch, history, code)
      window.history.pushState({}, document.title, `/login`);
      getProfile(dispatch);
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFacebookAuth = () => {
    window.location = `${CONFIG.api_url}/auth/facebook`;
  }

  handleTwitterAuth = () => {
    window.location = `${CONFIG.api_url}/auth/twitter`;
  }

  handleGoogleAuth = () => {
    window.location = `${CONFIG.api_url}/auth/google`;
  }

  handlePayPalAuth = (event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    paypalAuthLink(dispatch, 'login')
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { dispatch, history } = this.props;
    const { email, password, redirectUrl } = this.state;
    const payload = { email, password, redirectUrl };
    login(dispatch, payload, history);
  }

  render() {
    const { loading } = this.props;

    return (
      <Wrapper
        className="login-page auth-page"
      >
        {loading && <Loader />}
        {
          !loading &&
          <div className="auth">
            <form className="sign-in form-horizontal shadow no-overflow" onSubmit={this.handleSubmit} method="post">
              <div className="sign-header">
                <div className="form-group">
                  <div className="sign-text">
                    <span>Member Area</span>
                  </div>
                </div>
              </div>
              <div className="sign-body">
                <div className="form-group">
                  <div className="input-group input-group-lg rounded no-overflow">
                    <input
                      type="text"
                      className="form-control input-sm"
                      placeholder="Email "
                      id="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      required
                    />
                    <span className="input-group-addon"><i className="fa fa-envelope fa-fw" /></span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group input-group-lg rounded no-overflow">
                    <input
                      type="password"
                      className="form-control input-sm"
                      placeholder="Password"
                      id="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      required
                    />
                    <span className="input-group-addon"><i className="fa fa-lock fa-fw" /></span>
                  </div>
                </div>
              </div>
              <div className="sign-footer">
                <div className="form-group">
                  <div className="row">
                    <div className="col-xs-6">
                      <NavLink to="/signup">Sign Up (Free)</NavLink>
                    </div>
                    <div className="col-xs-6 text-right">
                      <NavLink to="/forgot-password">Lost your password?</NavLink>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-theme btn-lg btn-block no-margin rounded" id="login-btn">Sign In</button>
                  <div className="social-login-title">Sign in with Socials</div>
                  <div className="social-login-btns">
                    <FacebookLoginButton onClick={this.handleFacebookAuth}>
                      <span tabIndex="0">Sign in with Facebook</span>
                    </FacebookLoginButton>
                    <TwitterLoginButton onClick={this.handleTwitterAuth}>
                      <span tabIndex="0">Sign in with Twitter</span>
                    </TwitterLoginButton>
                    {/* <GoogleLoginButton onClick={this.handleGoogleAuth} /> */}
                    {/* <button className="btn btn-primary paypal-login" onClick={this.handlePayPalAuth}>
                      <i className="fa fa-paypal fa-2x pull-left paypal-icon" />Sign in with PayPal
                    </button> */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        }
      </Wrapper>
    );
  }
}

const select = (state) => {
  const generalLoading = state.getIn(['users', 'loading'], true);
  const userLoading = state.getIn(['users', 'userLoading'], true);
  const profileLoading = state.getIn(['users', 'profileLoading'], true);

  const loading = generalLoading || userLoading || profileLoading;

  return { loading }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Login);

Login.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  loading: PropTypes.bool
};
