import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { connect } from "../../store/context";
import { updateProfile, uploadAvatar } from "../../api/users";
import AvatarModal from '../users/UploadAvatar/index';


class Avatar extends Component {
  state = {
    selectedFile: '',
    errorMessage: '',
    avatar: this.props.avatar
  };

  handleFileChanged = (event) => {
    const [file] = event.target.files;
    const [type] = file.type.split('/');

    if (type !== 'image' || file.size > 1000000) {
      this.setState({errorMessage: 'Wrong file type or size > 1MB'});
    }

    this.setState({selectedFile: URL.createObjectURL(file), errorMessage: ''})
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { dispatch } = this.props;
    const { selectedFile } = this.state;

    if (selectedFile) {
      await uploadAvatar(dispatch, selectedFile);
      const { avatar } = this.props;
      updateProfile(dispatch, {avatar});
    }
  }

  setAvatar = (avatar) => {
    this.setState({ selectedFile: avatar })
  }

  render() {
    const {
      avatar,
      selectedFile
    } = this.state;

    const { loading } = this.props;

    return (
      <form className="form-horizontal no-overflow" onSubmit={this.handleSubmit}>
        <div className="avatar">
          <img className="img-circle img-bordered-theme" src={selectedFile || avatar} alt="avatar" width="130" height="130" />
          <div>{this.state.errorMessage}</div>
          <AvatarModal newAvatar={this.setAvatar} />
        </div>

        <div className="navigation">
          <Link to="/profile" className="btn btn-default" >Cancel</Link>
          <button
            disabled={!selectedFile || loading}
            type="submit"
            className={`btn btn-warning ${loading && 'loading'}`}
          >Save
          </button>
        </div>
      </form>
    );
  }
}

function select(state) {
  const avatar = state.getIn(['users', 'profile', 'avatar'], String());
  const loading = state.getIn(['users', 'loading'], false);
  return { avatar, loading }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Avatar);

Avatar.propTypes = {
  avatar: PropTypes.string,
  dispatch: PropTypes.func,
  loading: PropTypes.bool
};
