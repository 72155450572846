import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "../../../store/context";
import {fetchSubscriptions} from "../../../api/subscription"
import './style.scss';


class SubscriptionSearch extends Component {
  static propTypes = {
    Sports: PropTypes.array,
    dispatch: PropTypes.func
  }

  state = {
    
  };

  handleSearchChange = (event) => {
    if (event.target.value === '') {
      this.setState({ [event.target.name]: null });
    } else {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  }

  handleSearch = (event) => {
    event.preventDefault();
    const {sportId} = this.state
    const { dispatch } = this.props;
    fetchSubscriptions(dispatch, {sportId, offset:0, limit:100});
  }

  handleShowDetailed = (event) => {
    event.preventDefault();
    this.setState({showDetailedSearch: !this.state.showDetailedSearch});
  }

  render() {
    const { showDetailedSearch } = this.state;
    const { Sports } = this.props;

    return (
      <form className="consultant-search">

        <div className="row">
          <div className="col-md-12">
            <div className="form-group col-md-4">
              <input className="form-control" name="userName" type="text" placeholder="Subscription Name" onChange={this.handleSearchChange} autoComplete="off" />
            </div>

            <div className="form-group col-md-4">
              <select className="form-control" name="sportId" onChange={this.handleSearchChange}>
                <option value="null">All Sports</option>
                {Sports.map((sport) => <option key={sport.id} value={sport.id}>{sport.Name.toUpperCase()}</option>)}
              </select>
            </div>

            <div className="form-group col-md-4">
              <button type="submit" className="btn btn-default" onClick={this.handleSearch}>Search</button>
            </div>
          </div>
        </div>

        {/* <div className="row show-detailed">
          <div className="text-center col-md-12">
            <a href="/#" onClick={this.handleShowDetailed}>
              <i className={showDetailedSearch ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} />
              <span>Advanced Search</span>
            </a>
          </div>
        </div> */}
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(mapDispatchToProps)(SubscriptionSearch);
