import { Map } from 'immutable';
import {
  PAY_BEGIN,
  PAY_SUCCESS,
  PAY_FAILURE,
  EXECUTE_PAYMENT_BEGIN,
  EXECUTE_PAYMENT_SUCCESS,
  EXECUTE_PAYMENT_FAILURE,
  BUY_SUBSCRIPTION_BEGIN,
  BUY_SUBSCRIPTION_SUCCESS,
  BUY_SUBSCRIPTION_FAILURE,
  EXECUTE_SUBSCRIPTION_BEGIN,
  EXECUTE_SUBSCRIPTION_SUCCESS,
  EXECUTE_SUBSCRIPTION_FAILURE
} from '../actions/paypal';


const initialState = Map({
  loading: false
});

export default function sportsReducer(state = initialState, action) {
  switch (action.type) {
    case PAY_BEGIN:
      state = state.set('loading', true);
      break;

    case PAY_SUCCESS:
      state = state.set('loading', false);
      break;

    case PAY_FAILURE:
      state = state.set('loading', false)
      break;

    case EXECUTE_PAYMENT_BEGIN:
      state = state.set('loading', true);
      break;

    case EXECUTE_PAYMENT_SUCCESS:
      state = state.set('loading', false);
      break;

    case EXECUTE_PAYMENT_FAILURE:
      state = state.set('loading', false)
      break;

    case BUY_SUBSCRIPTION_BEGIN:
      state = state.set('loading', true);
      break;

    case BUY_SUBSCRIPTION_SUCCESS:
      state = state.set('loading', false);
      break;

    case BUY_SUBSCRIPTION_FAILURE:
      state = state.set('loading', false)
      break;

    case EXECUTE_SUBSCRIPTION_BEGIN:
      state = state.set('loading', true);
      break;

    case EXECUTE_SUBSCRIPTION_SUCCESS:
      state = state.set('loading', false);
      break;

    case EXECUTE_SUBSCRIPTION_FAILURE:
      state = state.set('loading', false)
      break;

    default:
      return state;
  }

  return state;
}
