import { fromJS, Map, List } from 'immutable';
import decode from 'jwt-decode';
import { setAuthHeader } from '../actions/api';
import {
  FETCH_USERS_BEGIN,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_PROFILE_BEGIN,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  UPDATE_PROFILE_BEGIN,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPLOAD_AVATAR_BEGIN,
  UPLOAD_AVATAR_SUCCESS,
  UPLOAD_AVATAR_FAILURE,
  UPLOAD_PROFILE_IMAGE_BEGIN,
  UPLOAD_PROFILE_IMAGE_SUCCESS,
  UPLOAD_PROFILE_IMAGE_FAILURE,
  GET_USER_BEGIN,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  FOLLOW_USER_BEGIN,
  FOLLOW_USER_SUCCESS,
  FOLLOW_USER_FAILURE,
  UNFOLLOW_USER_BEGIN,
  UNFOLLOW_USER_FAILURE,
  UNFOLLOW_USER_SUCCESS,
  VERIFY_PAYPAL_BEGIN,
  VERIFY_PAYPAL_SUCCESS,
  VERIFY_PAYPAL_FAILURE,
  BECOME_CONSULTANT_BEGIN,
  BECOME_CONSULTANT_SUCCESS,
  BECOME_CONSULTANT_FAILURE,
  GET_USER_STATISTIC_BEGIN,
  GET_USER_STATISTIC_SUCCESS,
  GET_USER_STATISTIC_FAILURE,
  GET_PAYPAL_VERIFIED_INFO_BEGIN,
  GET_PAYPAL_VERIFIED_INFO_SUCCESS,
  GET_PAYPAL_VERIFIED_INFO_FAILURE,
  CLEAR_USER_DATA,
  SET_BETS_TYPE_BEGIN,
  SET_BETS_TYPE_SUCCESS,
  SET_BETS_TYPE_FAILURE,
  SET_ANONIMUS_BETS_TYPE_SUCCESS,
  GET_LOCATION_BEGIN,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAILURE,
  UPDATE_MAILING_BEGIN,
  UPDATE_MAILING_SUCCESS,
  UPDATE_MAILING_FAILURE,
  USER_SIGNUP_BEGIN,
  USER_SIGNUP_SUCCESS,
  USER_SIGNUP_FAILURE,
  USER_LOGIN_BEGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  ADD_FOLLOWERS_COUNT,
  SUBTRACT_FOLLOWERS_COUNT,
  UPDATE_BALANCE
} from '../actions/users';


const getUserId = () => {
  const jwt = localStorage.getItem('token');

  if (!jwt) {
    return null;
  }

  jwt.replace("Bearer", "");
  const { user_id } =  decode(jwt);

  return user_id;
}

const initialState = Map({
  myUserId: getUserId(),
  items: List(),
  loading: false,
  location: Map(),
  profile: Map(),
  userLoading: false,
  profileLoading: false,
  error: null,
  anonimus: false,
  paypalVerified: false
});

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USERS_BEGIN:
      state = state.set('loading', true);
      break;

    case FETCH_USERS_SUCCESS:
      state = state.set('items', fromJS(action.data.users));
      state = state.set('loading', false);
      break;

    case FETCH_USERS_FAILURE:
      state = state.set('error', action.error);
      break;

    case FETCH_PROFILE_BEGIN:
      state = state.set('loading', true);
      state = state.set('profileLoading', true);
      break;

    case FETCH_PROFILE_SUCCESS:
      state = state.set('profile', fromJS(action.data.users.user));
      state = state.set('loading', false);
      state = state.set('anonimus', false);
      state = state.set('profileLoading', false);
      break;

    case FETCH_PROFILE_FAILURE:
      state = state.remove('profile');
      state = state.set('loading', false);
      state = state.set('error', action.error);
      state = state.set('anonimus', true);
      state = state.set('profileLoading', false);
      break;

    case UPDATE_PROFILE_BEGIN:
      state = state.set('loading', true);
      break;

    case UPDATE_PROFILE_SUCCESS:
      state = state.set('profile', fromJS(action.data.users.user));
      state = state.set('loading', false);
      break;

    case UPDATE_PROFILE_FAILURE:
      state = state.set('error', action.error);
      break;

    case UPLOAD_AVATAR_BEGIN:
      state = state.set('loading', true);
      break;

    case UPLOAD_AVATAR_SUCCESS:
      state = state.setIn(["profile", "avatar"], fromJS(action.data.avatar));
      state = state.set('loading', false);
      break;

    case UPLOAD_AVATAR_FAILURE:
      state = state.set('error', action.error);
      break;

    case UPLOAD_PROFILE_IMAGE_BEGIN:
      state = state.set('loading', true);
      break;

    case UPLOAD_PROFILE_IMAGE_SUCCESS:
      state = state.setIn(["profile", "profileImage"], fromJS(action.data.profileImage));
      state = state.set('loading', false);
      break;

    case UPLOAD_PROFILE_IMAGE_FAILURE:
      state = state.set('error', action.error);
      break;

    

    case GET_USER_BEGIN:
      state = state.set('userLoading', true);
      break;

    case GET_USER_SUCCESS:
      state = state.set('userProfile', fromJS(action.data.user.user));
      state = state.set('userLoading', false);
      break;

    case GET_USER_FAILURE:
      state = state.set('userLoading', false);
      state = state.set('error', action.error);
      break;

    case FOLLOW_USER_BEGIN:
      state = state.set('loading', true);
      break;

    case FOLLOW_USER_SUCCESS:
      state = state.updateIn(['profile', 'following'], (arr) => arr.concat([fromJS(action.data.userId)]));
      state = state.set('loading', false);
      break;

    case FOLLOW_USER_FAILURE:
      state = state.set('error', action.error);
      break;

    case UNFOLLOW_USER_BEGIN:
      state = state.set('loading', true);
      break;

    case UNFOLLOW_USER_SUCCESS: {
      const { followingUsers, userId } = action.data;
      const deletedUser = followingUsers.find((user) => user.id === userId)
      const newArray = followingUsers.filter((user) => user !== deletedUser);
      state = state.setIn(['profile', 'following'], fromJS(newArray));
      state = state.set('loading', false);
      break;
    }
    case UNFOLLOW_USER_FAILURE:
      state = state.set('error', action.error);
      break;

    case VERIFY_PAYPAL_BEGIN:
      state = state.set('loading', true);
      break;

    case VERIFY_PAYPAL_SUCCESS:
      state = state.setIn(['profile', 'verified'], fromJS(action.data.status));
      state = state.set('loading', false);
      break;

    case VERIFY_PAYPAL_FAILURE:
      state = state.set('error', action.error);
      break;

    case BECOME_CONSULTANT_BEGIN:
      state = state.set('loading', true);
      break;

    case BECOME_CONSULTANT_SUCCESS:
      state = state.set('profile', fromJS(action.data.user));
      state = state.set('loading', false);
      break;

    case BECOME_CONSULTANT_FAILURE:
      state = state.set('error', action.error);
      break;

    case GET_USER_STATISTIC_BEGIN:
      state = state.set('loading', true);
      break;

    case GET_USER_STATISTIC_SUCCESS:
      state = state.set('userStatistic', fromJS(action.data.statistic));
      state = state.set('loading', false);
      break;

    case GET_USER_STATISTIC_FAILURE:
      state = state.set('error', action.error);
      break;

    case GET_PAYPAL_VERIFIED_INFO_BEGIN:
      state = state.set('loading', true);
      break;

    case GET_PAYPAL_VERIFIED_INFO_SUCCESS:
      state = state.set('paypalVerified', fromJS(JSON.parse(action.data.verified)));
      state = state.set('loading', false);
      break;

    case GET_PAYPAL_VERIFIED_INFO_FAILURE:
      state = state.set('error', action.error);
      break;

    case CLEAR_USER_DATA:
      state = state.set('userProfile', fromJS({}));
      break;

    case SET_BETS_TYPE_BEGIN:
      state = state.set('loading', true);
      break;

    case SET_BETS_TYPE_SUCCESS:
      state = state.setIn(['profile', 'betsType'], action.data.type);
      state = state.set('loading', false);
      break;

    case SET_BETS_TYPE_FAILURE:
      state = state.set('error', action.error);
      break;

    case SET_ANONIMUS_BETS_TYPE_SUCCESS:
      state = state.set('betsType', action.data.type);
      state = state.set('loading', false);
      break;

    case GET_LOCATION_BEGIN:
      state = state.set('loading', true);
      break;

    case GET_LOCATION_SUCCESS:
      state = state.set('location', fromJS(action.data.data.location));
      state = state.set('loading', false);
      break;

    case GET_LOCATION_FAILURE:
      state = state.set('loading', false);
      break;

    case UPDATE_MAILING_BEGIN:
      state = state.set('loading', true);
      break;

    case UPDATE_MAILING_SUCCESS:
      state = state.set('loading', false);
      break;

    case UPDATE_MAILING_FAILURE:
      state = state.set('loading', false);
      break;

    case USER_SIGNUP_BEGIN:
      state = state.set('loading', true);
      state = state.set('profileLoading', true);
      break;

    case USER_SIGNUP_SUCCESS:
      localStorage.setItem('token', JSON.stringify(action.data.token));
      localStorage.setItem('dashboardIntro', true);
      localStorage.setItem('profileIntro', true);
      localStorage.setItem('createPickIntro', true);
      localStorage.setItem('completeCreatePickIntro', true);
      setAuthHeader(action.data.token)
      state = state.set('profile', fromJS(action.data.user));
      state = state.set('anonimus', false);
      state = state.set('loading', false);
      state = state.set('profileLoading', false);
      break;

    case USER_SIGNUP_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loading', false);
      state = state.set('profileLoading', false);
      break;

    case USER_LOGIN_BEGIN:
      state = state.set('loading', true);
      state = state.set('profileLoading', true);
      break;

    case USER_LOGIN_SUCCESS:
      localStorage.setItem('token', JSON.stringify(action.data.token));
      setAuthHeader(action.data.token)
      state = state.set('profile', fromJS(action.data.user));
      state = state.set('anonimus', false);
      state = state.set('loading', false);
      state = state.set('profileLoading', false);
      break;

    case USER_LOGIN_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loading', false);
      state = state.set('profileLoading', false);
      break;

    case ADD_FOLLOWERS_COUNT: {
      const users = state.getIn(['items'], List());
      const index = users.findIndex((user) => user.get('id') === Number(action.data.userId));

      if (index >= 0) {
        const followersCount = users.getIn([index, 'followersCount'])
        const updatedUsers = users.setIn([index, 'followersCount'], followersCount + 1);
        state = state.set('items', updatedUsers);
      }

      break;
    }

    case SUBTRACT_FOLLOWERS_COUNT: {
      const users = state.getIn(['items'], List());
      const index = users.findIndex((user) => user.get('id') === Number(action.data.userId));

      if (index >= 0) {
        const followersCount = users.getIn([index, 'followersCount'])
        const updatedUsers = users.setIn([index, 'followersCount'], followersCount - 1);
        state = state.set('items', updatedUsers);
      }

      break;
    }

    case UPDATE_BALANCE: {
      let balance = state.getIn(['profile', 'balance'], 0);
      balance = balance - action.data.price;
      state = state.setIn(['profile', 'balance'], balance);
      break;
    }

    default:
      return state;
  }

  return state;
}
