import React from 'react';
import './style.scss';
import ScrollableAnchor from 'react-scrollable-anchor'


const WhyWe = () => {
  return (
    <ScrollableAnchor id={'why-we'}>
      <section className="landing-why-we">
        <div className="container">
          <div className="row choose_server_inner">
            <div className="col-md-7">
              <div className="server_left_text">
                <div className="server_title">
                  <h2>Why choose SportsTrader®?</h2>
                  <p>We are innovative company that are doing everything we can to make sure our customers are happy with our services and making some extra income from information that our users sell on a platform. As we are marketplace we do not set any prices for picks and services that we have, market drives prices. So you are able find a best match between price and quality here. Stick with us and you will not regret!</p>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="media">
                      <div className="media-left">
                        <i className="fa fa-money" />
                      </div>
                      <div className="media-body">
                        <h4>Market Prices</h4>
                        <p>You can always find best service for the best price.</p>
                      </div>
                    </div>
                    <div className="media">
                      <div className="media-left">
                        <i className="fa fa-folder-open" />
                      </div>
                      <div className="media-body">
                        <h4>Transparency</h4>
                        <p>We are open to work with our users and always listen their feedback.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="media">
                      <div className="media-left">
                        <i className="fa fa-phone" />
                      </div>
                      <div className="media-body">
                        <h4>Customer support</h4>
                        <p>We will help you in your journey and advice with any questions that may raise on a way.</p>
                      </div>
                    </div>
                    <div className="media">
                      <div className="media-left">
                        <i className="fa fa-thumbs-up" />
                      </div>
                      <div className="media-body">
                        <h4>Best experience on a market</h4>
                        <p>We are constantly improving our application just to make it perfect.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="server_right_img">
                <img src="./img/landing/handshake.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </ScrollableAnchor>
  );
};

export default WhyWe;
