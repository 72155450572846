import CONFIG from '../config/config';
import api from '../actions/api';
import {
  getNotificationsBegin,
  getNotificationsSuccess,
  getNotificationsError,
  readNotificationsBegin,
  readNotificationsSuccess,
  readNotificationsError,
  deleteNotificationBegin,
  deleteNotificationSuccess,
  deleteNotificationError,
  deleteAllNotificationsBegin,
  deleteAllNotificationsSuccess,
  deleteAllNotificationsError
} from '../actions/notifications';


export const getNotifications = async (dispatch, notificationsList) => {
  try {
    dispatch(getNotificationsBegin());
    let notifications = [];

    if (notificationsList) {
      notifications = notificationsList;
    } else {
      const { data } = await api.get(`${CONFIG.api_url}/events/notifications`);
      notifications = data.events;
    }

    dispatch(getNotificationsSuccess(notifications));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(getNotificationsError(error));
  }
}

export const readNotifications = async (dispatch, notifications) => {
  try {
    dispatch(readNotificationsBegin());
    await api.patch(`${CONFIG.api_url}/events/read`);
    notifications.forEach((notification) => {
      notification.read = true;
    })
    dispatch(readNotificationsSuccess(notifications));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(readNotificationsError(error));
  }
}

export const deleteNotification = async (dispatch, id) => {
  try {
    dispatch(deleteNotificationBegin(id));
    await api.patch(`${CONFIG.api_url}/events/delete/${id}`);
    dispatch(deleteNotificationSuccess());
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(deleteNotificationError(error));
  }
}

export const deleteAllNotifications = async (dispatch) => {
  try {
    dispatch(deleteAllNotificationsBegin());
    await api.patch(`${CONFIG.api_url}/events/delete/all`);
    dispatch(deleteAllNotificationsSuccess());
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(deleteAllNotificationsError(error));
  }
}
