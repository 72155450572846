import {
  payBegin,
  paySuccess,
  payError,
  executePaymentBegin,
  executePaymentSuccess,
  executePaymentError,
  loginBegin,
  loginSuccess,
  loginError,
  executeLoginBegin,
  executeLoginSuccess,
  executeLoginError,
  buySubscriptionBegin,
  buySubscriptionSuccess,
  buySubscriptionError,
  executeSubscriptionBegin,
  executeSubscriptionSuccess,
  executeSubscriptionError
} from '../actions/paypal';

import { updateMyBoughtItemBegin, updateMyBoughtItem, updateMyBoughtItemFailure } from '../actions/picks';
import { alertError, alertSuccess, alertErrorText } from '../actions/alerts';
import CONFIG from '../config/config';
import api, { setAuthHeader } from '../actions/api';


export const pay = async (dispatch, itemId, itemType, price, pageHistory) => {
  try {
    const isFirefoxPrivate = await isFireFoxPrivate(dispatch, 'Buy');

    if (isFirefoxPrivate) {
      throw new Error('firefox in private mode');
    }

    dispatch(payBegin());
    const { data } = await api.get(`${CONFIG.api_url}/pay`, { params: { itemId, itemType, price }});
    window.location = data.approvalUrl
    dispatch(updateMyBoughtItemBegin());
    dispatch(paySuccess());
    dispatch(updateMyBoughtItem(itemId, `Buy ${itemType}`));
  } catch (error) {
    if (error.response && error.response.status === 401) {
      return pageHistory.push(`/signup?redirectUrl=/${itemType.toLowerCase()}/${itemId}`);
    }

    console.log(error); // eslint-disable-line no-console
    alertError(error);
    dispatch(payError(error));
    dispatch(updateMyBoughtItemFailure(error));
    throw error;
  }
}

export const executePayment = async (dispatch, PayerID, paymentId, itemType, itemId, buying, receiverId = null) => {
  try {
    const name = itemType && itemType !== 'Refill' && itemType !== 'Send Tips Paypal' ? `Buy ${capitalizeFirstLetter(itemType)} PayPal` : itemType;
    dispatch(executePaymentBegin());
    await api.get(`${CONFIG.api_url}/pay/callback`, { params: { PayerID, paymentId }});
    let res;

    if (itemType) {
      res = await api.get(`${CONFIG.api_url}/payments/success?itemType=${name}&itemId=${itemId}&paymentID=${paymentId}&receiverId=${receiverId}`);
    }

    if (buying) {
      await api.post(`${CONFIG.api_url}/${itemType.toLowerCase()}s/${itemId}/buy`, { itemType: name });
    }

    dispatch(executePaymentSuccess());

    if (itemType === 'Refill' && res.data.success) {
      alertSuccess('Balance has refilled successfully!');
      window.history.pushState({}, document.title, '/profile/refill');
    }

    if (buying) {
      window.history.pushState({}, document.title, `/${itemType.toLowerCase()}/${itemId}`);
    }

    if (receiverId && res.data.success) {
      window.history.pushState({}, document.title, `/chat?room=${receiverId}`);
      alertSuccess('Tips sent successfully');
    }
  } catch (error) {
    if (buying) {
      window.history.pushState({}, document.title, `/${itemType.toLowerCase()}/${itemId}`);
    }

    console.log(error); // eslint-disable-line no-console
    alertError(error)
    dispatch(executePaymentError(error));
  }
}

export const login = async (dispatch, page) => {
  try {
    const isFirefoxPrivate = await isFireFoxPrivate(dispatch, 'Login');

    if (isFirefoxPrivate) {
      return;
    }

    dispatch(loginBegin());
    dispatch({ type: 'GET_USER_BEGIN' });
    const { data } = await api.get(`${CONFIG.api_url}/auth/paypal`, { params: { page }});
    dispatch(loginSuccess());
    window.location = data.authUrl;
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    alertError(error)
    dispatch(loginError(error));
  }
}

export const executeLogin = async (dispatch, history, code, ref) => {
  try {
    dispatch({ type: 'GET_USER_BEGIN' });
    dispatch(executeLoginBegin());
    const { data } = await api.get(`${CONFIG.api_url}/auth/paypal/callback`, { params: { code, ref }});
    dispatch(executeLoginSuccess());
    localStorage.setItem("token", JSON.stringify(data.token));
    setAuthHeader(data.token)

    if (data.firstLogin) {
      localStorage.setItem("dashboardIntro", true);
      localStorage.setItem("profileIntro", true);
      localStorage.setItem("createPickIntro", true);
      localStorage.setItem("completeCreatePickIntro", true);
      history.push('/select-role');
    } else {
      history.push('/');
    }
  } catch (error) {
    dispatch({ type: 'GET_USER_FAILURE' });
    console.log(error); // eslint-disable-line no-console
    alertError(error)
    dispatch(executeLoginError(error));
  }
}

export const buySubscription = async (dispatch, pageHistory) => {
  try {
    const isFirefoxPrivate = await isFireFoxPrivate(dispatch, 'Buy');

    if (isFirefoxPrivate) {
      throw new Error('firefox in private mode');
    }

    dispatch(buySubscriptionBegin());
    const { data } = await api.post(`${CONFIG.api_url}/subscription/agreement/create`);
    window.location = data.approvalUrl
    dispatch(buySubscriptionSuccess());
  } catch (error) {
    if (error.response && error.response.status === 401) {
      return pageHistory.push('/signup?redirectUrl=/profile/edit/subscription');
    }

    console.log(error); // eslint-disable-line no-console
    alertError(error);
    dispatch(buySubscriptionError(error));
    throw error;
  }
}

export const executeSubscription = async (dispatch, token) => {
  try {
    dispatch(executeSubscriptionBegin());
    await api.post(`${CONFIG.api_url}/subscription/agreement/execute`, { token });
    dispatch(executeSubscriptionSuccess());
    alertSuccess('Subscription created successfully');
    window.history.pushState({}, document.title, '/profile/edit/subscription');
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    alertError(error)
    dispatch(executeSubscriptionError(error));
  }
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const returnFireFoxError = (dispatch, action) => {
  alertErrorText(`${action} with PayPal is not supported for FireFox in "Private Mode"`);
  dispatch(payError('Buy with PayPal is not supported for FireFox in "Private Mode"'));
  dispatch(updateMyBoughtItemFailure(''));
}

const isFireFoxPrivate = async (dispatch, action) => {
  // Firefox 1.0+
  const isFirefox = typeof InstallTrigger !== 'undefined';

  if (isFirefox) {
    const isPrivate = await new Promise((resolve) => {
      const on = () => resolve(true); // is in private mode
      const off = () => resolve(false); // not private mode

      if ('MozAppearance' in document.documentElement.style) {
        if (indexedDB === null) {
          return on();
        }

        const db = indexedDB.open('test');
        db.onerror = on;
        db.onsuccess = off;
        return void 0;
      }
    });

    if (isPrivate) {
      returnFireFoxError(dispatch, action);
      return isFirefox
    }
  }

  return false;
}
