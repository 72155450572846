import React from 'react';
import './style.scss';
import ScrollableAnchor from 'react-scrollable-anchor'
import SignUp from '../SignUp';
import Logo from '../../../components/ui/Logo';


class Header extends React.Component {
  state = {
    menuOpen: false
  };

  handleMenuClick = () => {
    this.setState({menuOpen: !this.state.menuOpen});
  }

  handleLinkClick = () => {
    this.setState({menuOpen: false});
  }

  render() {
    const { menuOpen } = this.state;

    return (
      <ScrollableAnchor id="sign-up">
        <header className="landing-header" >
          <div className="container menu">
            <Logo />
            <nav className={menuOpen ? 'active' : ''}>
              <a href="#about" onClick={this.handleLinkClick}>Our values</a>
              <a href="#advantages" onClick={this.handleLinkClick}>Our features</a>
              <a href="#why-we" onClick={this.handleLinkClick}>Why Us</a>
            </nav>
            <div className="menu-burger" onClick={this.handleMenuClick}>
              <i className={`fa fa-${menuOpen ? 'remove' : 'reorder'}`} />
            </div>
          </div>
          <div className="container hero">
            <div>
              <h1>#1 sports betting marketplace</h1>
            </div>
            <SignUp />
          </div>
          <a href="#about" className="down">
            <i className="fa fa-angle-double-down" aria-hidden="true" />
          </a>
        </header>
      </ScrollableAnchor>
    );
  }
}

export default Header;
