import React from "react";
import PropTypes from 'prop-types';
import { setDefaultAvatar } from '../../../helpers/defaultImage';
import './style.scss';


const User = (props) => {
  const { user, online, onOpenRoom, active, newMessages, blockedByMe } = props;
  const { id, avatar, chatRoomId } = user;
  let { firstName, lastName } = user;

  const clickOnChannel = () => {
    onOpenRoom(id, chatRoomId);
  }
  const isActive = active ? 'active' : '';
  const haveNewMessage = newMessages ? 'new' : '';
  const isOnline = online ? 'online' : '';
  const isBlocked = blockedByMe ? 'blocked' : '';

  firstName = firstName ? firstName : '';
  lastName = lastName ? lastName : '';
  const userName = `${firstName} ${lastName}`;

  return (
    <li className={`${isActive} ${haveNewMessage} ${isOnline} ${isBlocked}`} key={id} onClick={clickOnChannel} >
      <img src={avatar} alt="avatar" width="50" height="50" onError={setDefaultAvatar} />
      <div className="about">
        <div className="name" title={userName.length > 18 ? userName : ''}>
          {firstName ? userName : 'Anonymous'}
        </div>
        <div className="status">
          {online ?
            <p><i className="fa fa-circle online" /> online</p> :
            <p><i className="fa fa-circle offline" /> offline</p>
          }
        </div>
      </div>
      {blockedByMe && <div className="blocked-icon"><i className="fa fa-lock" /></div>}
    </li>);
}

export default User;

User.propTypes = {
  active: PropTypes.bool,
  blockedByMe: PropTypes.bool,
  newMessages: PropTypes.bool,
  onOpenRoom: PropTypes.func,
  online: PropTypes.bool,
  user: PropTypes.object
};
