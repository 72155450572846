import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect } from "../../../store/context";
import { sendTipsFromBalance  } from "../../../api/payments";
import { getProfile  } from "../../../api/users";
import { alertErrorText } from '../../../actions/alerts';
import { pay } from "../../../api/paypal";
import './style.scss';


class TipsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: ''
    };
  }

  handleSendTipsPaypal = () => {
    const { amount } = this.state;
    const { dispatch, handleCloseModal } = this.props;

    if (amount <= 0) {
      return alertErrorText('Please enter amount you want to tip');
    }

    pay(dispatch, 0, 'Send Tips Paypal', amount)
    this.setState({amount: 0});
    return handleCloseModal();
  }

  handleSendTipsBalance = async () => {
    const { dispatch, selectedUserId, handleCloseModal } = this.props;
    const { amount } = this.state;

    try {
      await sendTipsFromBalance(dispatch, selectedUserId, amount);
    } catch (e) {
      return;
    }

    getProfile(dispatch);
    this.setState({amount: 0});
    handleCloseModal();
  }

  handleChangeAmount = (e) => {
    const { value } = e.currentTarget;

    if (Number(value) || value === '') {
      this.setState({amount: value});
    }
  }

  render() {
    const { showModal, handleCloseModal, loading } = this.props;
    const { amount } = this.state;

    return (
      <Modal show={showModal} className="chat-tips-modal">
        <button type="button" className="close" data-dismiss="modal" onClick={handleCloseModal} aria-hidden="true" >×</button>
        <Modal.Body>
          <span className="help-block">Please enter sum you want to tip</span>
          <input
            onChange={this.handleChangeAmount}
            value={amount}
            className="form-control"
            placeholder="Price*"
            type="text"
            name="price"
            maxLength="10"
            required
          />
          <i className="fee-massage">20% fee will be applied for this tip</i>
        </Modal.Body>
        <Modal.Footer>
          <div className="buy-buttons">
            <button className="btn btn-default paypal" onClick={this.handleSendTipsPaypal}>
              <img className="paypal-img" src="/img/paypal/paypal.svg" alt="paypal" />
            </button>
            <button
              className="btn btn-default"
              onClick={this.handleSendTipsBalance}
              disabled={loading}
            >Pay From Balance
            </button>
          </div>
        </Modal.Footer>
      </Modal>);
  }
}

const select = (state) => {
  const selectedUserId = state.getIn(['chat', 'currentUserId'], null);
  const loading = state.getIn(['payments', 'loading'], false);

  return { selectedUserId, loading }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(TipsModal);

TipsModal.propTypes = {
  dispatch: PropTypes.func,
  handleCloseModal: PropTypes.func,
  loading: PropTypes.bool,
  selectedUserId: PropTypes.number,
  showModal: PropTypes.bool
};
