import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { updateSidebarOpenStatus } from '../../../../actions/general';
import Logo from '../../../../components/ui/Logo';
import { connect } from "../../../../store/context";
import './style.scss';


class LeftHeader extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    isSidebarOpen: PropTypes.bool,
    location: PropTypes.object
  }

  componentDidUpdate(prevProps) {
    const { dispatch } = this.props;

    if (this.props.location !== prevProps.location) {
      dispatch(updateSidebarOpenStatus(false));
    }
  }

  handleToggleMinimize = (e) => {
    const { dispatch, isSidebarOpen } = this.props;
    e.preventDefault();
    e.stopPropagation();

    if (isSidebarOpen) {
      dispatch(updateSidebarOpenStatus(false));
    } else {
      dispatch(updateSidebarOpenStatus(true));
    }
  }

  render() {
    return (
      <div className="header-left">
        <div className="navbar-minimize-mobile left" onClick={this.handleToggleMinimize} >
          <i className="fa fa-bars" />
        </div>
        <div className="navbar-header">
          <Logo />
        </div>
        <div className="clearfix" />
      </div>
    );
  }
}

function select(state) {
  const isSidebarOpen = state.getIn(['general', 'sidebarOpen'], false);
  return { isSidebarOpen }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default withRouter(connect(select, mapDispatchToProps)(LeftHeader));
