import React, { Component } from 'react';
import i from 'immutable';
import moment from 'moment'
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from '../store/context';
import { Switch } from '../components/picks/DecimalSwitch';
import { TeamSearch } from '../components/search/TeamSearch';
import { OddsTable } from '../components/odds/OddsTable/index';
import { getOdds, getOddsNowByApi } from '../api/odds';
import { getSportById } from '../api/sports';
import { setBetsType } from '../api/users';
import CONFIG from '../config/config';
import Loader from '../components/ui/Loader/index';
import Wrapper from '../components/ui/Wrapper';
import EmptyPage from '../components/general/EmptyPage';
import catchRunTimeError from '../util/ds-error';


class Odds extends Component {
  static propTypes = {
    betsType: PropTypes.string,
    dispatch: PropTypes.func,
    matches: PropTypes.array,
    oddsLoading: PropTypes.bool,
    sport: PropTypes.object,
    sportsId: PropTypes.string
  }

  constructor(props) {
    super(props);

    try {
      console.log('Connect WSS OddsNow')
      this.socketConnection = new WebSocket(CONFIG.wss_client_connection_url);
      this.socketConnection.onopen = this.sendMessageToWs

      // Log errors
      this.socketConnection.onerror = catchRunTimeError;

      // Log messages from the server
      this.socketConnection.onmessage = this.receivedMessageFromWss;
      this.socketConnection.onclose = function(e){
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
        setTimeout(function () {
          this.socketConnection = new WebSocket(CONFIG.wss_client_connection_url);
        }, 1000);
      }
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    }

    this.state = {
      searchTeam: '',
      newSportId: null
    }
  }

  componentDidMount = () => {
    const { dispatch, sportsId } = this.props;
    getSportById(dispatch, sportsId);
    getOddsNowByApi(dispatch, sportsId)

    const oldMetaUrl = document.getElementById("og-url");
    oldMetaUrl && oldMetaUrl.parentNode.removeChild(oldMetaUrl);
  }

  componentWillReceiveProps = (newProps) => {
    const { dispatch, sportsId } = this.props;
    if (sportsId === newProps.sportsId) {
      return;
    }
    

    this.setState({ newSportId: Number(newProps.sportsId) });
    // unsubscribe
    getSportById(dispatch, newProps.sportsId);
    getOddsNowByApi(dispatch, newProps.sportsId)
    this.props = newProps
    this.sendMessageToWs()
  }

  componentWillUnmount = () => {
    // this.record.discard();
    this.socketConnection.close()
  }
  sendMessageToWs = () => {
    const { sportsId } = this.props;
    this.oddsLoading = true
    let data = JSON.stringify({"action":"oddsNow","page":`sport/${sportsId}/odds/now`})
    this.socketConnection.send(data); // Send the message 'Ping' to the server
  }
  receivedMessageFromWss = (e) => {
    const { dispatch, sportsId } = this.props;
    console.log("received odds now from ws message");
    getOddsNowByApi(dispatch, sportsId )
  }

  handleClickAmerican = () => {
    const { dispatch } = this.props;
    setBetsType(dispatch, 'american');
  }

  handleClickDecimal = () => {
    const { dispatch } = this.props;
    setBetsType(dispatch, 'decimal');
  }

  handleSearch = (value) => {
    this.setState({ searchTeam: value })
  }

  render() {
    const { matches, sport, betsType, oddsLoading, location } = this.props;
    const { 3: selectedLeague } = location.pathname.split("/");
    const { Name, oddsCount } = sport;
    const odds = [];
    let tempLastUpdate;

    let showLoading;
    const { newSportId } = this.state;

    if (oddsLoading && ((sport && newSportId && sport.id !== newSportId) || !matches.length)) {
      showLoading = oddsLoading;
    }

    // helmet description
    const descriptions = [];

    if (matches.length > 0) {
      matches.forEach((odd) => {
        const awayTeam = odd.Match.AwayTeam.toLowerCase();
        const homeTeam = odd.Match.HomeTeam.toLowerCase();
        const searchTeam = this.state.searchTeam.toLowerCase();
        descriptions.push(`${odd.Match.AwayTeam} vs ${odd.Match.HomeTeam}`);

        if ((!searchTeam && odd.OddID) || (awayTeam.includes(searchTeam) || homeTeam.includes(searchTeam))) {
          let lastUpdate = [];

          if (odd.LastUpdated && odd.LastUpdated !== tempLastUpdate) {
            tempLastUpdate = odd.LastUpdated;
            lastUpdate = (
              <div key={`updated-${odd.id}`} className="last-update">
                <i className="fa fa-refresh" aria-hidden="true" />
                LAST UPDATE: {moment(odd.LastUpdated).format('MMMM Do YYYY, h:mm:ss a')}
              </div>
            )
          }

          if (selectedLeague) {
            if (selectedLeague === odd.Match.DisplayLeague) {
              odds.push(lastUpdate);
              odds.push(<OddsTable key={`table-${odd.id}`} {...odd} sportName={Name} betsType={betsType} />);
            }
          } else {
            odds.push(lastUpdate);
            odds.push(<OddsTable key={`table-${odd.id}`} {...odd} sportName={Name} betsType={betsType} />);
          }
        }
      });
    }

    const sugarHouseUrl = 'https://wlsugarhouseaffiliates.adsrv.eacdn.com/C.ashx?btag=a_872b_297c_&affid=312&siteid=872&adid=297&c=_';

    return (
      <Wrapper
        className="odds-page"
        title={Name ? `${Name.toUpperCase()} Odds` : 'Odds'}
        counter={oddsCount}
        icon="fa fa-rss"
      >
        <Helmet>
          <title>{Name ? Name.toUpperCase() : ''} Odds</title>
          <meta property="og:description" content={descriptions.toString().replace(/,/g, ', ')} />
          <meta property="og:title" content={`${Name ? Name.toUpperCase() : ''} Odds`} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        <div className="row">
          <div className="col-md-4">
            <Switch
              betsType={betsType}
              onClickAmerican={this.handleClickAmerican}
              onClickDecimal={this.handleClickDecimal}
            />
          </div>

          <div className="col-md-4 hidden-sm" />

          <div className="col-md-4">
            <TeamSearch onClickSearch={this.handleSearch} />
          </div>
        </div>

        {!showLoading && odds.length > 0 &&
          <h5>Odds powered by <a href={sugarHouseUrl} target="_blank" rel="noopener noreferrer">Sugar House</a></h5>
        }
        {showLoading ? <Loader /> : odds}
        {!showLoading && !odds.length && <EmptyPage />}
      </Wrapper>
    );
  }
}

function select(state, ownProps) {
  const matches = state.getIn(['odds', 'items'], i.List()).toJS();
  const oddsLoading = state.getIn(['odds', 'loading'], false);
  const sport = state.getIn(['sports', 'selectedSport'], i.Map()).toJS();
  const sportsId = ownProps.match.params.sport;
  const anonimus = state.getIn(['users', 'anonimus']);
  let betsType = 'american';

  if (anonimus === true) {
    betsType = state.getIn(['users', 'betsType'], 'american');
  } else {
    betsType = state.getIn(['users', 'profile', 'betsType'], 'american');
  }

  return { matches, sport, sportsId, betsType, oddsLoading };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Odds);
