import React, { Component } from 'react';
import 'babel-polyfill';
import 'core-js';
import smoothscroll from 'smoothscroll-polyfill';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import userIsAuthenticated from './util/userIsAuthenticated'
import CONFIG from './config/config';
import './styles/App.scss'

import { connect } from "./store/context";
import { getLocation } from './api/users';
import Header from './components/ui/Header/index';
import LeftNav from './components/ui/LeftNav/index';
import Dashboard from './pages/Dashboard';
import SelectRole from './pages/SelectRole';
import Odds from './pages/Odds';
import Picks from './pages/Picks';
import ChatPage from './pages/Chat';
import Profile from './pages/Profile';
import Friends from './pages/Friends';
import User from './pages/User';
import EditProfile from './pages/EditProfile';
import Login from "./components/auth/Login";
import ForgotPassword from "./components/auth/ForgotPassword";
import ConfirmPassword from "./components/auth/ConfirmPassword";
import SignUp from "./components/auth/SignUp";
import Consultants from './pages/Consultants';
import RefillBalance from './components/balance/refill';
import BecomeCunsultant from './pages/BecomeConsultant';
import Pick from './pages/Pick';
import MyPicks from './pages/MyPicks';
import NotFound from "./pages/NotFound";
import MyParlays from './pages/MyParlays';
import IncompletePicks from './pages/IncompletePicks';
import Sales from './components/balance/sales';
import Affiliate from './pages/Affiliate';
import ConfirmEmail from './pages/ConfirmEmail';
import TOS from "./pages/TOS";
import Privacy from "./pages/Privacy";
import Cookies from "./pages/Cookies";
import MatchInfo from './pages/MatchInfo';
import Landing from './pages/Landing';
import Rooms from './pages/Rooms'
import ConsultantSubscription from './pages/ConsultantSubscription'
import CreateRoom from './pages/CreateRoom'

smoothscroll.polyfill();
Sentry.init({
  dsn: CONFIG.SENTRY_REPORTING_KEY
});

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => userIsAuthenticated() === true ? <Component {...props} /> : (
      <Redirect
        to={{
          pathname: '/login',
          search: `?redirectUrl=${encodeURIComponent(props.location.pathname + props.location.search)}`,
          state: { from: props.location }
        }}
      />)
    }
  />
);

class App extends Component {
  componentDidMount = () => {
    const { dispatch } = this.props;
    getLocation(dispatch);
  }

  componentDidCatch = (error, errorInfo) => {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    const { isSidebarOpen } = this.props;
    const currentUrl = window.location.href.split('/').pop();
    const isLanding = currentUrl.substring(0, 7) === 'landing';

    return (
      <BrowserRouter>
        <>
          {isLanding && <Route path="/landing" component={Landing} />}

          {!isLanding &&
            <section id="wrapper" className={isSidebarOpen ? 'page-sidebar-left-show' : ''}>
              <Header />
              <LeftNav />

              {/*/  START @PAGE CONTENT */}
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/signup" component={SignUp} />
                <Route path="/" component={Dashboard} exact />
                <Route path="/odds/:sport" component={Odds} />
                <Route path="/consultants" component={Consultants} />
                <Route path="/forgot-password/confirm" component={ConfirmPassword} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/email/confirm" component={ConfirmEmail} />
                <PrivateRoute path="/select-role" component={SelectRole} />
                <PrivateRoute path="/picks/incomplete" component={IncompletePicks} />
                <PrivateRoute path="/picks/:sport" component={Picks} />
                <PrivateRoute path="/profile/edit/" component={EditProfile}>
                  <PrivateRoute path="/information" component={EditProfile} />
                  <PrivateRoute path="/avatar" component={EditProfile} />
                  <PrivateRoute path="/socials" component={EditProfile} />
                  <PrivateRoute path="/email" component={EditProfile} />
                  <PrivateRoute path="/password" component={EditProfile} />
                  <PrivateRoute path="/general" component={EditProfile} />
                  <PrivateRoute path="/subscroption" component={EditProfile} />
                </PrivateRoute>
                <PrivateRoute path="/profile/refill" component={RefillBalance} />
                <PrivateRoute path="/profile/become-consultant" component={BecomeCunsultant} />
                <PrivateRoute path="/profile/sales" component={Sales} />
                <PrivateRoute path="/profile" component={Profile} />
                <PrivateRoute path="/my-picks/:type" component={MyPicks} />
                <Route path="/user/:id" component={User} />
                <PrivateRoute path="/friends" component={Friends} />
                <PrivateRoute path="/chat" component={ChatPage} />
                <Route path="/match/:id" component={MatchInfo} />
                <Route path="/create/room" component={CreateRoom} />
                <Route path="/rooms" component={Rooms} />
                <Route path="/subscriptions" component={ConsultantSubscription} />
                <Route path="/:itemType/:id" match-tab={/^(pick|parlay|bundle)$/} component={Pick} />
                <PrivateRoute path="/my-parlays" component={MyParlays} />
                <PrivateRoute path="/affiliate" component={Affiliate} />
                <Route path="/tos" component={TOS} />
                <Route path="/privacy" component={Privacy} />
                <Route path="/cookie-policy" component={Cookies} />
                <Route component={NotFound} />
              </Switch>
              {/*/ / END PAGE CONTENT */}
            </section>
          }
        </>
      </BrowserRouter>
    )
  }
}

function select(state) {
  const isSidebarOpen = state.getIn(['general', 'sidebarOpen'], false);
  return { isSidebarOpen }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(App);

App.propTypes = {
  dispatch: PropTypes.func,
  isSidebarOpen: PropTypes.bool
};
