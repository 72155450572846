export const FETCH_USERS_BEGIN   = 'FETCH_USERS_BEGIN';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const FETCH_PROFILE_BEGIN   = 'FETCH_PROFILE_BEGIN';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';
export const UPDATE_PROFILE_BEGIN   = 'UPDATE_PROFILE_BEGIN';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';
export const UPLOAD_AVATAR_BEGIN   = 'UPLOAD_AVATAR_BEGIN';
export const UPLOAD_AVATAR_SUCCESS = 'UPLOAD_AVATAR_SUCCESS';
export const UPLOAD_AVATAR_FAILURE = 'UPLOAD_AVATAR_FAILURE';
export const UPLOAD_PROFILE_IMAGE_BEGIN   = 'UPLOAD_PROFILE_IMAGE_BEGIN';
export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'UPLOAD_PROFILE_IMAGE_SUCCESS';
export const UPLOAD_PROFILE_IMAGE_FAILURE = 'UPLOAD_PROFILE_IMAGE_FAILURE';
export const GET_USER_BEGIN   = 'GET_USER_BEGIN';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const FOLLOW_USER_BEGIN   = 'FOLLOW_USER_BEGIN';
export const FOLLOW_USER_SUCCESS = 'FOLLOW_USER_SUCCESS';
export const FOLLOW_USER_FAILURE = 'FOLLOW_USER_FAILURE';
export const UNFOLLOW_USER_BEGIN   = 'UNFOLLOW_USER_BEGIN';
export const UNFOLLOW_USER_SUCCESS = 'UNFOLLOW_USER_SUCCESS';
export const UNFOLLOW_USER_FAILURE = 'UNFOLLOW_USER_FAILURE';

export const VERIFY_PAYPAL_BEGIN   = 'VERIFY_PAYPAL_BEGIN';
export const VERIFY_PAYPAL_SUCCESS = 'VERIFY_PAYPAL_SUCCESS';
export const VERIFY_PAYPAL_FAILURE = 'VERIFY_PAYPAL_FAILURE';
export const BECOME_CONSULTANT_BEGIN   = 'BECOME_CONSULTANT_BEGIN';
export const BECOME_CONSULTANT_SUCCESS = 'BECOME_CONSULTANT_SUCCESS';
export const BECOME_CONSULTANT_FAILURE = 'BECOME_CONSULTANT_FAILURE';
export const USER_SIGNUP_BEGIN   = 'USER_SIGNUP_BEGIN';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SIGNUP_FAILURE = 'USER_SIGNUP_FAILURE';
export const USER_LOGIN_BEGIN   = 'USER_LOGIN_BEGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const GET_USER_STATISTIC_BEGIN   = 'GET_USER_STATISTIC_BEGIN';
export const GET_USER_STATISTIC_SUCCESS = 'GET_USER_STATISTIC_SUCCESS';
export const GET_USER_STATISTIC_FAILURE = 'GET_USER_STATISTIC_FAILURE';
export const GET_PAYPAL_VERIFIED_INFO_BEGIN   = 'GET_PAYPAL_VERIFIED_INFO_BEGIN';
export const GET_PAYPAL_VERIFIED_INFO_SUCCESS = 'GET_PAYPAL_VERIFIED_INFO_SUCCESS';
export const GET_PAYPAL_VERIFIED_INFO_FAILURE = 'GET_PAYPAL_VERIFIED_INFO_FAILURE';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const SEND_CONFIRMATION_EMAIL_BEGIN   = 'SEND_CONFIRMATION_EMAIL_BEGIN';
export const SEND_CONFIRMATION_EMAIL_SUCCESS = 'SEND_CONFIRMATION_EMAIL_SUCCESS';
export const SEND_CONFIRMATION_EMAIL_FAILURE = 'SEND_CONFIRMATION_EMAIL_FAILURE';
export const SET_BETS_TYPE_BEGIN = 'SET_BETS_TYPE_BEGIN';
export const SET_BETS_TYPE_SUCCESS = 'SET_BETS_TYPE_SUCCESS';
export const SET_BETS_TYPE_FAILURE = 'SET_BETS_TYPE_FAILURE';
export const SET_ANONIMUS_BETS_TYPE_SUCCESS = 'SET_ANONIMUS_BETS_TYPE_SUCCESS';
export const GET_LOCATION_BEGIN = 'GET_LOCATION_BEGIN';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAILURE = 'GET_LOCATION_FAILURE';
export const UPDATE_MAILING_BEGIN = 'UPDATE_MAILING_BEGIN';
export const UPDATE_MAILING_SUCCESS = 'UPDATE_MAILING_SUCCESS';
export const UPDATE_MAILING_FAILURE = 'UPDATE_MAILING_FAILURE';
export const ADD_FOLLOWERS_COUNT = 'ADD_FOLLOWERS_COUNT';
export const SUBTRACT_FOLLOWERS_COUNT = 'SUBTRACT_FOLLOWERS_COUNT';
export const UPDATE_BALANCE = 'UPDATE_BALANCE';

export const fetchUsersBegin = () => ({
  type: FETCH_USERS_BEGIN
});

export const fetchUsersSuccess = (users) => ({
  type: FETCH_USERS_SUCCESS,
  data: { users }
});

export const fetchUsersError = (error) => ({
  type: FETCH_USERS_FAILURE,
  data: { error }
});

export const fetchProfileBegin = () => ({
  type: FETCH_PROFILE_BEGIN
});

export const fetchProfileSuccess = (users) => ({
  type: FETCH_PROFILE_SUCCESS,
  data: { users }
});

export const fetchProfileError = (error) => ({
  type: FETCH_PROFILE_FAILURE,
  data: { error }
});

export const updateProfileBegin = () => ({
  type: UPDATE_PROFILE_BEGIN
});

export const updateProfileSuccess = (users) => ({
  type: UPDATE_PROFILE_SUCCESS,
  data: { users }
});

export const updateProfileError = (error) => ({
  type: UPDATE_PROFILE_FAILURE,
  data: { error }
});

export const uploadAvatarBegin = () => ({
  type: UPLOAD_AVATAR_BEGIN
});

export const uploadAvatarSuccess = (avatar) => ({
  type: UPLOAD_AVATAR_SUCCESS,
  data: { avatar }
});

export const uploadAvatarError = (error) => ({
  type: UPLOAD_AVATAR_FAILURE,
  data: { error }
});

export const uploadProfileImageBegin = () => ({
  type: UPLOAD_PROFILE_IMAGE_BEGIN
});

export const uploadProfileImageSuccess = (profileImage) => ({
  type: UPLOAD_PROFILE_IMAGE_SUCCESS,
  data: { profileImage }
});

export const uploadProfileImageError = (error) => ({
  type: UPLOAD_PROFILE_IMAGE_FAILURE,
  data: { error }
});

export const getUserBegin = () => ({
  type: GET_USER_BEGIN
});

export const getUserSuccess = (user) => ({
  type: GET_USER_SUCCESS,
  data: { user }
});

export const getUserError = (error) => ({
  type: GET_USER_FAILURE,
  data: { error }
});

export const followUserBegin = () => ({
  type: FOLLOW_USER_BEGIN
});

export const followUserSuccess = (userId) => ({
  type: FOLLOW_USER_SUCCESS,
  data: { userId }
});

export const followUserError = (error) => ({
  type: FOLLOW_USER_FAILURE,
  data: { error }
});

export const unfollowUserBegin = () => ({
  type: UNFOLLOW_USER_BEGIN
});

export const unfollowUserSuccess = (userId, followingUsers) => ({
  type: UNFOLLOW_USER_SUCCESS,
  data: { userId, followingUsers }
});

export const unfollowUserError = (error) => ({
  type: UNFOLLOW_USER_FAILURE,
  data: { error }
});



export const verifyPaypalBegin = () => ({
  type: VERIFY_PAYPAL_BEGIN
});

export const verifyPaypalSuccess = (status) => ({
  type: VERIFY_PAYPAL_SUCCESS,
  data: { status }
});

export const verifyPaypalError = (error) => ({
  type: VERIFY_PAYPAL_FAILURE,
  data: { error }
});

export const becomeConsultantBegin = () => ({
  type: BECOME_CONSULTANT_BEGIN
});

export const becomeConsultantSuccess = (user) => ({
  type: BECOME_CONSULTANT_SUCCESS,
  data: user
});

export const becomeConsultantError = (error) => ({
  type: BECOME_CONSULTANT_FAILURE,
  data: { error }
});

export const userSignupBegin = () => ({
  type: USER_SIGNUP_BEGIN
});

export const userSignupSuccess = (data) => ({
  type: USER_SIGNUP_SUCCESS,
  data
});

export const userSignupError = (error) => ({
  type: USER_SIGNUP_FAILURE,
  data: { error }
});

export const userLoginBegin = () => ({
  type: USER_LOGIN_BEGIN
});

export const userLoginSuccess = (data) => ({
  type: USER_LOGIN_SUCCESS,
  data
});

export const userLoginError = (error) => ({
  type: USER_LOGIN_FAILURE,
  data: { error }
});

export const getUserStatisticBegin = () => ({
  type: GET_USER_STATISTIC_BEGIN
});

export const getUserStatisticSuccess = (statistic) => ({
  type: GET_USER_STATISTIC_SUCCESS,
  data: { statistic }
});

export const getUserStatisticError = (error) => ({
  type: GET_USER_STATISTIC_FAILURE,
  data: { error }
});

export const clearUserData = () => ({
  type: CLEAR_USER_DATA
});

export const getPaypalVerifiedInfoBegin = () => ({
  type: GET_PAYPAL_VERIFIED_INFO_BEGIN
});

export const getPaypalVerifiedInfoSuccess = (verified) => ({
  type: GET_PAYPAL_VERIFIED_INFO_SUCCESS,
  data: { verified }
});

export const getPaypalVerifiedInfoError = (error) => ({
  type: GET_PAYPAL_VERIFIED_INFO_FAILURE,
  data: { error }
});

export const sendConfirmEmailBegin = () => ({
  type: SEND_CONFIRMATION_EMAIL_BEGIN
});

export const sendConfirmEmailSuccess = (message) => ({
  type: SEND_CONFIRMATION_EMAIL_SUCCESS,
  data: { message }
});

export const sendConfirmEmailError = (error) => ({
  type: SEND_CONFIRMATION_EMAIL_FAILURE,
  data: { error }
});

export const setBetsTypeBegin = () => ({
  type: SET_BETS_TYPE_BEGIN
});

export const setBetsTypeSuccess = (type) => ({
  type: SET_BETS_TYPE_SUCCESS,
  data: { type }
});

export const setBetsTypeError = (error) => ({
  type: SET_BETS_TYPE_FAILURE,
  data: { error }
});

export const setAnonimusBetsTypeSuccess = (type) => ({
  type: SET_ANONIMUS_BETS_TYPE_SUCCESS,
  data: { type }
});

export const getLocationBegin = () => ({
  type: GET_LOCATION_BEGIN
});

export const getLocationSuccess = (data) => ({
  type: GET_LOCATION_SUCCESS,
  data: { data }
});

export const getLocationError = (error) => ({
  type: GET_LOCATION_FAILURE,
  data: { error }
});

export const addFollowersCount = (userId) => ({
  type: ADD_FOLLOWERS_COUNT,
  data: { userId }
});

export const substractFollowersCount = (userId) => ({
  type: SUBTRACT_FOLLOWERS_COUNT,
  data: { userId }
});

export const updateMailingBegin = () => ({
  type: UPDATE_MAILING_BEGIN
});

export const updateMailingSuccess = () => ({
  type: UPDATE_MAILING_SUCCESS
});

export const updateMailingError = (error) => ({
  type: UPDATE_MAILING_FAILURE,
  data: { error }
});

export const updateBalance = (price) => ({
  type: UPDATE_BALANCE,
  data: { price }
});
