import React from "react";
import PropTypes from 'prop-types';
import { setDefaultAvatar } from '../../../helpers/defaultImage';
import {blockUnblockUser, getRoomMembers } from '../../../api/chat'
import './style.scss';


const User = (props) => {
  const { member, online, owner, dispatch, ownerRow } = props;
  const { user, chatRoomId } = member
  const { id, avatar } = user;
  let { firstName, lastName } = user;

  const adminUser = () => {
    // onOpenRoom(id, chatRoomId);
    // alert(1)
    if (member.status == 1) {
      blockUnblockUser(dispatch, chatRoomId, {action: 'blocked', userId: id})
      getRoomMembers(dispatch, chatRoomId)
    } else if (member.status == 2) {
      blockUnblockUser(dispatch, chatRoomId, {action: 'unblocked', userId: id})
      getRoomMembers(dispatch, chatRoomId)
    } else if (member.status == 3) {
      blockUnblockUser(dispatch, chatRoomId, {action: 'unblocked', userId: id})
      getRoomMembers(dispatch, chatRoomId)
    }
  }

  const isOnline = online ? 'online' : '';

  firstName = firstName ? firstName : '';
  lastName = lastName ? lastName : '';
  const userName = `${firstName} ${lastName}`;
  let buttonTxt = 'Block'
  let buttonClass = 'btn btn-danger'
  if (member.status == 2) {
    buttonTxt = 'Unblock'
    buttonClass = 'btn btn-warning'
  } else if (member.status == 3) {
    buttonTxt = 'Accept'
    buttonClass = 'btn btn-warning'
  }

  return (
    <li className={`${isOnline}`} key={id} >
      <img src={avatar} alt="avatar" width="50" height="50" onError={setDefaultAvatar} />
      <div className="about">
        <div className="name" title={userName.length > 18 ? userName : ''}>
          {firstName ? userName : 'Anonymous'}
        </div>
        <div className="status">
          {online ?
            <p><i className="fa fa-circle online" /> online</p> :
            <p><i className="fa fa-circle offline" /> offline</p>
          }
        </div>
      </div>
      {owner && <div className="blocked-icon"><button className={buttonClass} onClick={adminUser}>{buttonTxt}</button></div>}
      {ownerRow && <div className="blocked-icon">Owner</div>}
    </li>);
}

export default User;

User.propTypes = {
  dispatch: PropTypes.func,
  active: PropTypes.bool,
  online: PropTypes.bool,
  member: PropTypes.object,
  owner: PropTypes.bool,
  ownerRow: PropTypes.bool
};
