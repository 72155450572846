import CONFIG from '../config/config';
import api from '../actions/api';
import { fetchMyParlaysPregameBegin,
  fetchMyParlaysPregameSuccess,
  fetchMyParlaysPregameError,
  fetchMyParlaysInprogressBegin,
  fetchMyParlaysInprogressSuccess,
  fetchMyParlaysInprogressError,
  fetchMyParlaysFinalBegin,
  fetchMyParlaysFinalSuccess,
  fetchMyParlaysFinalError } from '../actions/parlays';


export const getMyParlaysPregame = async (dispatch, data = null) => {
  try {
    dispatch(fetchMyParlaysPregameBegin());

    if (!data) {
      const result = await api.get(`${CONFIG.api_url}/parlays/my/pregame`);
      data = result.data.parlays;
    }

    dispatch(fetchMyParlaysPregameSuccess(data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(fetchMyParlaysPregameError(error));
  }
}

export const getMyParlaysInprogress = async (dispatch, data = null) => {
  try {
    dispatch(fetchMyParlaysInprogressBegin());

    if (!data) {
      const result = await api.get(`${CONFIG.api_url}/parlays/my/inprogress`);
      data = result.data.parlays;
    }

    dispatch(fetchMyParlaysInprogressSuccess(data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(fetchMyParlaysInprogressError(error));
  }
}

export const getMyParlaysFinal = async (dispatch, page, limit) => {
  try {
    dispatch(fetchMyParlaysFinalBegin());
    const { data } = await api.get(`${CONFIG.api_url}/parlays/my/final?page=${page}&limit=${limit}`);
    dispatch(fetchMyParlaysFinalSuccess(data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(fetchMyParlaysFinalError(error));
  }
}
